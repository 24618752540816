.paginationv2 {
  align-items: center;
  margin: 0 auto;
  font-size: .875rem;
  display: flex;
  overflow-x: auto;
}

.paginationv2__number-list {
  justify-content: space-between;
  margin: 3em 0;
  display: flex;
}

.paginationv2__number {
  color: #6b7280;
  cursor: pointer;
  margin: 0 .4286em;
  padding: .3571em .5714em;
  font-weight: bold;
}

.paginationv2__number--selected {
  color: #fff;
  cursor: default;
  background-color: #50a9e2;
  border-radius: 4px;
}

.paginationv2__navigation-button {
  color: #50a9e2;
  cursor: pointer;
  font-size: 1.7em;
}

.paginationv2__navigation-button--disabled {
  color: #d1d5db;
  font-size: 1.7em;
}

.paginationv2__navigation-button--right {
  margin-left: 1.1765em;
}

.paginationv2__navigation-button--left {
  margin-right: 1.1765em;
}
