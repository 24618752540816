.collapsible-area {
  margin-bottom: 1em;
}

.collapsible-area__summary {
  cursor: default;
  align-items: center;
  margin-bottom: .5em;
  display: flex;
}

.collapsible-area__summary > * {
  margin: 0;
}
