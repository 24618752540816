.video-supplement .responsive-video iframe, .video-supplement .responsive-video video {
  width: 100%;
  height: 100%;
  position: absolute;
}

@media (max-width: 600px) {
  .video-supplement .responsive-video {
    padding: 0;
  }
}

.video-supplement .responsive-video__sizer {
  padding-bottom: 56.25%;
  position: relative;
}
