.supplement-translation__name {
  margin-left: var(--space-x1-half);
}

.supplement-translation__fields {
  margin-left: 28px;
}

.supplement-translation__field-spacer {
  margin-right: var(--space-x1);
  width: 17px;
}

.supplement-translation__field-spacer--not-last {
  border-left: 1px solid var(--border-bold);
}

.supplement-translation__field-bullet {
  border-bottom: 1px solid var(--border-bold);
  border-left: 1px solid var(--border-bold);
  box-sizing: content-box;
  border-bottom-left-radius: 5px;
  height: 23px;
  margin-left: -1px;
}

.supplement-translation__field-bullet--last {
  border-left: 1px solid var(--border-bold);
  margin-left: 0;
}
