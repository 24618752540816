.image-supplement {
  justify-content: center;
  padding: 16px;
  display: flex;
}

.image-supplement__card--with-zoom {
  cursor: zoom-in;
}

.image-figure {
  text-align: center;
  margin: 0;
  display: flex;
}

.image-figure img {
  border-radius: 8px;
  min-width: 200px;
  max-height: 520px;
}

.image-figure figcaption {
  display: none;
}

.image-figure--with-caption {
  display: table;
}

.image-figure--with-caption figcaption {
  caption-side: bottom;
  padding: 8px;
  display: table-caption;
  bottom: 0;
}

.image-figure--inverse figcaption {
  color: #fff;
  background-color: rgba(0, 0, 0, .8);
  border-radius: 8px;
  margin: 8px 0;
}

.image-figure--inverse a {
  color: #fff;
  text-decoration: underline;
}

.image-figure--inverse a:hover {
  color: #f9fafb;
}

.image-supplement-zoom__close {
  width: 100%;
}

.image-supplement--zoom-modal img {
  max-width: 100%;
  max-height: 60vh;
}
