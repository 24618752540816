.o-table__wrapper {
  overflow: auto hidden;
}

.o-table {
  border-collapse: collapse;
  border-style: hidden;
  width: 100%;
}

.o-table__loading {
  text-align: center;
  padding: 80px;
}

.o-table__reloading-indicator {
  cursor: not-allowed;
  opacity: .9;
  z-index: 1;
  background-color: #fff;
  justify-content: center;
  align-items: flex-start;
  display: flex;
  position: absolute;
  inset: 0;
}

.o-table__loading-spinner {
  font-size: 40px;
}

.o-table--data .o-table__row:last-child .o-table__cell:first-child {
  border-bottom-left-radius: 10px;
}

.o-table--data .o-table__row:last-child .o-table__cell:last-child {
  border-bottom-right-radius: 10px;
}

.o-table--list .o-table__row .o-table__header:first-child, .o-table--list .o-table__row .o-table__cell:first-child {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.o-table--list .o-table__row .o-table__header:last-child, .o-table--list .o-table__row .o-table__cell:last-child {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}
