.question-set-v2 {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.question-set-v2__questions-wrapper {
  margin: 0;
  padding: 0;
}

.question-set-v2__questions-wrapper--active-rearrange-item {
  background-color: #f9fafb;
  transition: all .2s ease-in-out;
  box-shadow: inset 0 0 8px rgba(0, 0, 0, .1);
}

.question-set-v2__questions-wrapper--with-multiple-questions:after {
  content: "";
  opacity: .04;
  pointer-events: none;
  background: linear-gradient(to top, #000, rgba(0, 0, 0, 0));
  width: 100%;
  height: 16px;
  transition: opacity .1s;
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
}

.question-set-wrapper:last-child .question-set-v2__questions-wrapper--with-multiple-questions:after {
  opacity: 0;
}

.question-set-wrapper:last-child .sortable-element__hover-area:before {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  transform: translate(-50%, -100%);
}

.question-set-wrapper:first-child .sortable-element__hover-area:before {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  transform: translate(-50%);
}
