.m-tab-navigation {
  box-shadow: inset 0 -1px var(--border-primary);
  -webkit-overflow-scrolling: touch;
  display: flex;
}

.m-tab-navigation--fill > * {
  text-overflow: ellipsis;
  width: 100%;
  overflow: hidden;
}

.m-tab-navigation--align-center > :first-child {
  margin-left: auto;
}

.m-tab-navigation--align-center > :last-child {
  margin-right: auto;
}

.m-tab-navigation--align-right > :first-child {
  margin-left: auto;
}

@media (max-width: 440px) {
  .m-tab-navigation {
    padding: 0 16px;
  }
}
