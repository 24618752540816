.content-primary {
  color: var(--content-primary);
}

.content-primary-inverse {
  color: var(--content-primary-inverse);
}

.content-primary-inverse-static {
  color: var(--content-primary-inverse-static);
}

.content-secondary {
  color: var(--content-secondary);
}

.content-secondary-inverse {
  color: var(--content-secondary-inverse);
}

.content-tertiary {
  color: var(--content-tertiary);
}

.content-tertiary-inverse {
  color: var(--content-tertiary-inverse);
}

.content-link {
  color: var(--content-link);
}

.content-link-pressed {
  color: var(--content-link-pressed);
}

.content-link-hover {
  color: var(--content-link-hover);
}

.content-negative {
  color: var(--content-negative);
}

.content-positive {
  color: var(--content-positive);
}

.content-warning {
  color: var(--content-warning);
}

.content-brand {
  color: var(--content-brand);
}

.content-info {
  color: var(--content-info);
}

.content-inherit {
  color: inherit;
}

.background-primary {
  background-color: var(--background-primary);
}

.background-primary-hover {
  background-color: var(--background-primary-hover);
}

.background-primary-pressed {
  background-color: var(--background-primary-pressed);
}

.background-subtle {
  background-color: var(--background-subtle);
}

.background-subtle-hover {
  background-color: var(--background-subtle-hover);
}

.background-subtle-pressed {
  background-color: var(--background-subtle-pressed);
}

.background-bold {
  background-color: var(--background-bold);
}

.background-bold-hover {
  background-color: var(--background-bold-hover);
}

.background-bold-pressed {
  background-color: var(--background-bold-pressed);
}

.background-brand {
  background-color: var(--background-brand);
}

.background-brand-hover {
  background-color: var(--background-brand-hover);
}

.background-brand-pressed {
  background-color: var(--background-brand-pressed);
}

.background-brand-subtle {
  background-color: var(--background-brand-subtle);
}

.background-brand-subtle-hover {
  background-color: var(--background-brand-subtle-hover);
}

.background-brand-subtle-pressed {
  background-color: var(--background-brand-subtle-pressed);
}

.background-positive-subtle {
  background-color: var(--background-positive-subtle);
}

.background-positive-subtle-hover {
  background-color: var(--background-positive-subtle-hover);
}

.background-positive-subtle-pressed {
  background-color: var(--background-positive-subtle-pressed);
}

.background-positive {
  background-color: var(--background-positive);
}

.background-positive-hover {
  background-color: var(--background-positive-hover);
}

.background-positive-pressed {
  background-color: var(--background-positive-pressed);
}

.background-negative-subtle {
  background-color: var(--background-negative-subtle);
}

.background-negative-subtle-hover {
  background-color: var(--background-negative-subtle-hover);
}

.background-negative-subtle-pressed {
  background-color: var(--background-negative-subtle-pressed);
}

.background-negative {
  background-color: var(--background-negative);
}

.background-negative-hover {
  background-color: var(--background-negative-hover);
}

.background-negative-pressed {
  background-color: var(--background-negative-pressed);
}

.background-info {
  background-color: var(--background-info);
}

.background-info-subtle {
  background-color: var(--background-info-subtle);
}

.background-warning {
  background-color: var(--background-warning);
}

.background-warning-subtle {
  background-color: var(--background-warning-subtle);
}

.background-warning-subtle-hover {
  background-color: var(--background-warning-subtle-hover);
}

.background-warning-subtle-pressed {
  background-color: var(--background-warning-subtle-pressed);
}

.background-disabled {
  background-color: var(--background-disabled);
}

.background-primary-inverse {
  background-color: var(--background-primary-inverse);
}

.background-domain-default {
  background-color: var(--background-domain-default);
}

.background-domain-social-studies {
  background-color: var(--background-domain-social-studies);
}

.background-domain-world-language {
  background-color: var(--background-domain-world-language);
}

.background-domain-computer-science {
  background-color: var(--background-domain-computer-science);
}

.background-domain-math {
  background-color: var(--background-domain-math);
}

.background-domain-science {
  background-color: var(--background-domain-science);
}

.background-domain-ela {
  background-color: var(--background-domain-ela);
}

.background-domain-other {
  background-color: var(--background-domain-other);
}

.border-primary {
  border: 1px solid var(--border-primary);
}

.border-subtle {
  border: 1px solid var(--border-subtle);
}

.border-bold {
  border: 1px solid var(--border-bold);
}

.border-positive {
  border: 1px solid var(--border-positive);
}

.border-positive-bold {
  border: 1px solid var(--border-positive-bold);
}

.border-negative {
  border: 1px solid var(--border-negative);
}

.border-negative-bold {
  border: 1px solid var(--border-negative-bold);
}

.border-warning {
  border: 1px solid var(--border-warning);
}

.border-info {
  border: 1px solid var(--border-info);
}

.border-brand {
  border: 1px solid var(--border-brand);
}

.border-selected {
  border: 1px solid var(--border-selected);
}

.background-mastery {
  background-color: var(--data-mastery);
}

.background-proficient {
  background-color: var(--data-proficient);
}

.background-excelling {
  background-color: var(--data-excelling);
}

.background-passing {
  background-color: var(--data-passing);
}

.background-struggling {
  background-color: var(--data-struggling);
}

.background-na {
  background-color: var(--data-na);
}

.content-mastery {
  color: var(--data-mastery-bold);
}

.content-proficient {
  color: var(--data-proficient-bold);
}

.content-excelling {
  color: var(--data-excelling-bold);
}

.content-passing {
  color: var(--data-passing-bold);
}

.content-struggling {
  color: var(--data-struggling-bold);
}

.content-na {
  color: var(--data-na-bold);
}

.elevation-background-l0 {
  background-color: var(--elevation-background-l0);
}

.elevation-background-l1 {
  background-color: var(--elevation-background-l1);
}

.elevation-background-l2 {
  background-color: var(--elevation-background-l2);
}

.elevation-background-l3 {
  background-color: var(--elevation-background-l3);
}

.elevation-background-l4 {
  background-color: var(--elevation-background-l4);
}

.elevation-background-l5 {
  background-color: var(--elevation-background-l5);
}

.elevation-background-l6 {
  background-color: var(--elevation-background-l6);
}

.elevation-shadow-l0 {
  box-shadow: var(--elevation-shadow-l0);
}

.elevation-shadow-l1 {
  box-shadow: var(--elevation-shadow-l1);
}

.elevation-shadow-l2 {
  box-shadow: var(--elevation-shadow-l2);
}

.elevation-shadow-l3 {
  box-shadow: var(--elevation-shadow-l3);
}

.elevation-shadow-l4 {
  box-shadow: var(--elevation-shadow-l4);
}

.elevation-shadow-l5 {
  box-shadow: var(--elevation-shadow-l5);
}

.elevation-shadow-l6 {
  box-shadow: var(--elevation-shadow-l6);
}

.overlay-35 {
  background-color: var(--overlay-35);
}

.overlay-70 {
  background-color: var(--overlay-70);
}

.overlay-100 {
  background-color: var(--overlay-100);
}

.duration-fast {
  animation-duration: var(--duration-fast);
}

.duration-medium {
  animation-duration: var(--duration-medium);
}

.duration-slow {
  animation-duration: var(--duration-slow);
}

.text-xs {
  font-size: var(--text-xs);
}

.text-s {
  font-size: var(--text-s);
}

.text-m {
  font-size: var(--text-m);
}

.text-l {
  font-size: var(--text-l);
}

.text-xl {
  font-size: var(--text-xl);
}

.text-xxl {
  font-size: var(--text-xxl);
}
