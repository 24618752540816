.fitb-select-wrapper, .fitb-select-container {
  display: inline-block;
}

.fitb-select-container .fitb-select__control {
  border-radius: 4px;
  margin: 4px 0;
}

@media (pointer: coarse) {
  .fitb-select-container .fitb-select__control {
    min-height: 44px;
  }
}

.fitb-select-container .fitb-select__control--is-disabled {
  background-color: #fff;
}

.fitb-select-container .fitb-select__control--is-focused, .fitb-select-container .fitb-select__control--is-focused:hover {
  border-color: #50a9e2;
  box-shadow: 0 0 0 2px #50a9e2;
}

.fitb-select-container .fitb-select__value-container {
  padding: 8px;
}

.fitb-select-container .fitb-select__placeholder {
  top: initial;
  transform: initial;
  position: relative;
}

.fitb-select-container .fitb-select__single-value {
  max-width: 100%;
  top: initial;
  transform: initial;
  margin-left: 0;
  position: relative;
}

.fitb-select-container .fitb-select__single-value .image-supplement .image-supplement__card img {
  max-width: 215px;
}

.fitb-select-container .fitb-select__single-value .image-supplement figcaption a {
  white-space: normal;
}

.fitb-select-container .fitb-select__single-value--is-disabled {
  color: inherit;
}

.fitb-select-container .fitb-select__menu {
  width: max-content;
  max-width: 360px;
}

.fitb-select-container .fitb-select__menu .image-supplement figcaption {
  min-width: 100px;
}

.fitb-select-container .fitb-select__menu .image-supplement figcaption a {
  white-space: normal;
}

.fitb-select-container .fitb-select__indicator {
  padding: 0 2px;
}

.fitb-select-container--has-selection .fitb-select__control:not(.fitb-select__control--is-focused) {
  border-color: #111827;
  box-shadow: 0 0 0 1px #111827;
}

.fitb-select-indicator {
  margin: 0 4px;
}

.fitb-presentational-item {
  border: 2px solid #50a9e2;
  border-radius: 4px;
  padding: 8px;
  display: inline-block;
}

.fitb-presentational-item .image-supplement {
  max-width: 215px;
}
