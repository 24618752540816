.two-way-editor hr {
  border: 0;
  border-top: 2px solid #f3f4f6;
  margin: 2em 0;
  display: block;
}

.two-way-editor__headers {
  display: flex;
}

.two-way-editor__header {
  flex-wrap: wrap;
  width: 33.3333%;
  display: flex;
}

.two-way-editor__header:not(:last-child) {
  margin-right: 1em;
}

.two-way-editor__header-input {
  flex-basis: 100%;
  margin: 0;
}

.sg-button.two-way-editor__add-supplement-btn {
  margin-top: .5em;
  padding: 0;
  text-decoration: underline;
}

.sg-button.two-way-editor__add-supplement-btn:not(.two-way-editor__add-supplement-btn--header):not(.two-way-editor__row-add-supplement-btn--statement) {
  margin-left: 2.2em;
}

.two-way-editor__column-headers {
  justify-content: space-between;
  max-width: 70%;
  margin-bottom: 1.5em;
  margin-left: 6em;
  display: flex;
}

.two-way-editor__row-wrapper {
  margin-bottom: 2em;
}

.two-way-editor__row {
  align-items: flex-start;
  display: flex;
}

.two-way-editor__row button {
  padding: .85em 1.15em;
}

.two-way-editor__row .a-form-input__label {
  width: 100%;
  margin-bottom: 1em;
}

@media (max-width: 600px) {
  .two-way-editor__row {
    flex-wrap: wrap;
  }

  .two-way-editor__row button {
    margin-top: 1em;
    margin-left: 1.8em;
  }
}

.two-way-editor__column {
  flex-wrap: wrap;
  flex-basis: 33.3333%;
  margin-right: 1em;
  display: flex;
}

.two-way-editor__row {
  flex-wrap: wrap;
  width: 100%;
  display: flex;
}

.two-way-editor__row--nowrap {
  flex-wrap: nowrap;
}

.two-way-editor__row-checkbox {
  align-items: center;
}

.two-way-editor__row-input {
  width: calc(100% - 2.2em);
  margin: 0;
}

.two-way-editor__row-add-supplement-btn {
  margin: .5em 0 0 auto;
}

.two-way-editor__row-left, .two-way-editor__row-right {
  align-items: center;
  display: flex;
}

.two-way-editor__row-left .is-correct-choice input, .two-way-editor__row-right .is-correct-choice input {
  border-color: #059669;
}

.two-way-editor__row-left .a-form-checkbox-wrapper, .two-way-editor__row-right .a-form-checkbox-wrapper {
  margin-right: .5em;
}

@media (max-width: 600px) {
  .two-way-editor__row-left, .two-way-editor__row-right {
    width: 50%;
  }
}

.two-way-editor__column-header {
  margin-left: 2.2em;
}

.two-way-editor__row-statement-input {
  width: 100%;
  margin: 0;
}

.two-way-editor__row-statement-input textarea {
  height: 3em;
}
