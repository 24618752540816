.form-divider {
  justify-content: space-between;
  align-items: center;
  padding: 16px 0;
  display: flex;
}

.form-divider__line {
  background: #e5e7eb;
  flex-grow: 1;
  height: 1px;
}

.form-divider__content {
  padding: 0 24px;
}
