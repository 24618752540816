.global-assignments__no-results {
  background-color: var(--background-subtle);
  border-radius: var(--space-x1);
  font-size: var(--font-size-md);
  padding: var(--space-x6) var(--space-x7);
  text-align: center;
  width: fit-content;
  margin: auto;
}

.global-assignments__no-results__header {
  margin-bottom: var(--space-x1-half);
  font-weight: 700;
}

.global-assignment__no-filtered-results {
  max-width: 320px;
}

.global-assignments__filters-grid-container {
  margin-right: var(--space-x2);
  width: 100%;
  max-width: none;
}

.global-assignments__content {
  max-width: calc(138 * var(--space-x1));
  padding: var(--space-x4) min(2%, var(--space-x5));
  margin: 0 auto;
}

.global-assignments__search {
  --input-text-color-placeholder: var(--content-secondary);
  width: clamp(280px, 33%, 480px);
}

.global-assignments__search-filter-sort {
  margin-bottom: var(--space-x2);
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.global-assignments__grid-container {
  container: global-assignment-card / inline-size;
}

.global-assignments__filters, .global-assignments__grid-container {
  background-color: var(--background-subtle);
  border-radius: var(--space-x3);
  padding: var(--space-x2);
  border: none;
}

.global-assignments__filters {
  border-radius: var(--space-x2);
  margin-bottom: var(--space-x3);
  align-items: flex-end;
  display: flex;
}

.global-assignments__filters-clear {
  color: var(--content-tertiary);
  cursor: pointer;
  font-size: var(--font-size-md);
  background-color: rgba(0, 0, 0, 0);
  border: none;
  padding: 0;
}

.global-assignments__filters-archived-toggle {
  background-color: var(--color-neutral-0);
  border-top: 1px solid var(--border-primary);
  z-index: 2;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  display: flex;
  position: sticky;
  bottom: 0;
}

.global-assignments__content .m-dropdown__target, .global-assignments__content .m-dropdown__target button, .global-assignments__filters .m-dropdown__content-wrapper, .global-assignments__search-filter-sort .m-dropdown__content-wrapper {
  width: 100%;
}

.global-assignments__grid-container .m-dropdown__content {
  width: max-content;
}

.global-assignments__filters svg {
  margin-left: auto;
}

.global-assignments__doc-icon-wrapper {
  background-color: var(--background-brand);
  border-radius: var(--space-x10);
  height: var(--space-x5);
  width: var(--space-x5);
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  display: flex;
}

.global-assignments__card {
  border-radius: var(--space-x2);
  box-shadow: 0px 0px 0px 1px var(--border-subtle), var(--elevation-shadow-l1);
  cursor: pointer;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  display: flex;
}

.global-assignments__card:hover {
  background-color: var(--background-brand-subtle-hover);
  box-shadow: 0px 0px 0px var(--space-x0-quarter) var(--border-brand), var(--elevation-shadow-l1);
}

.global-assignments__card:active {
  background-color: var(--background-brand-subtle-pressed);
}

.global-assignments__card :focus-visible {
  background-color: var(--background-brand-subtle-pressed);
}

.global-assignments__card-more-menu {
  margin-left: calc(-1 * var(--space-x1));
}

.global-assignments__card-open-status {
  font-size: var(--space-x1);
}

.global-assignments__card-border-line {
  border-bottom: 1px solid var(--border-subtle);
  margin: var(--space-x1) var(--space-x1);
}

.global-assignments__card-dropdown-item {
  display: block;
}

@container global-assignment-card (width < 620px) {
  .global-assignments__card {
    align-items: start;
    gap: var(--space-x2);
  }

  .global-assignments__card-details {
    flex-direction: column;
    align-items: start;
  }
}

.global-assignments__archived-class-entry .a-text {
  color: var(--color-neutral-500);
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.global-assignments__confirm-delete-modal-icon {
  font-size: 32px;
}
