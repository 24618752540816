.a-tab {
  --tab-text-color: var(--content-secondary);
  --tab-selected-color: var(--tab-text-color);
  --tab-text-color-selected: var(--content-brand);
  --tab-background-color: transparent;
  --tab-background-color-hover: var(--background-primary-hover);
  --tab-background-color-active: var(--background-primary-pressed);
  --tab-underline-color: var(--background-brand);
  --tab-underline-width: 0px;
  color: var(--tab-text-color);
  text-align: center;
  white-space: nowrap;
  background: none;
  border: none;
  border-radius: 8px;
  justify-content: center;
  align-items: center;
  min-height: 44px;
  margin: 0;
  font-size: .9375rem;
  font-weight: bold;
  text-decoration: none;
  display: flex;
}

.a-tab:before {
  background-color: var(--tab-underline-color);
  content: "";
  height: 3px;
  width: var(--tab-underline-width);
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
  transition: width .125s ease-in-out;
  position: absolute;
  bottom: 0;
}

.a-tab:focus:not(:disabled) {
  outline-offset: -3px;
  transition: all var(--duration-fast);
  outline: 3px solid #50a9e2;
}

.a-tab:focus:not(:focus-visible) {
  outline: 0;
}

.a-tab:focus-visible {
  outline: 3px solid #50a9e2;
}

.a-tab--selected {
  color: var(--tab-text-color-selected);
  transition: all var(--duration-fast);
  font-weight: bold;
}

.a-tab--selected:before {
  --tab-underline-width: calc(100% - 12px);
}

.a-tab--content-wrapper {
  border: 8px;
  border-radius: 8px;
  margin: 10px 0;
  padding: 8px 14px;
}

.a-tab--content-wrapper:hover:not(:disabled):not(.a-tab--selected) {
  background-color: var(--tab-background-color-hover);
  cursor: pointer;
  transition: all .1s;
}

.a-tab--content-wrapper:active:not(:disabled):not(.a-tab--selected) {
  background-color: var(--tab-background-color-active);
}

.a-tab--disabled {
  --tab-text-color: var(--content-tertiary);
}
