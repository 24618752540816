.m-input-group {
  display: flex;
}

.m-input-group--row {
  flex-direction: row;
}

@media (min-width: 441px) {
  .m-input-group--row > .m-input-group__item {
    margin-bottom: 0;
  }
}

.m-input-group--column {
  flex-direction: column;
}

@media (min-width: 441px) {
  .m-input-group--column > .m-input-group__item {
    margin-right: 0;
  }
}

@media (max-width: 440px) {
  .m-input-group--column .m-input-group__item {
    margin-right: 0;
  }

  .m-input-group--row:not(.m-input-group--always-row) {
    flex-direction: column;
  }

  .m-input-group--row:not(.m-input-group--always-row) > .m-input-group__item {
    margin-right: 0;
  }

  .m-input-group--always-row .m-input-group__item {
    margin-bottom: 0;
  }
}
