.a-grid__container {
  box-sizing: border-box;
  max-width: 1280px;
  margin-left: auto;
  margin-right: auto;
}

.a-grid__container--nested {
  width: 100%;
}

.a-grid__container--debug {
  background-color: rgba(204, 50, 36, .4);
}
