.question-actions-wrapper {
  background-color: var(--background-primary);
  border-top: 1px solid var(--border-primary);
  box-shadow: var(--elevation-shadow-l3);
  width: 100%;
  position: sticky;
  bottom: 0;
}

@media (max-width: 600px) {
  .question-actions-wrapper {
    box-shadow: none;
    position: static;
  }
}

.question-actions-wrapper__draft-guess-status {
  background-color: var(--background-subtle);
  border-bottom: 1px solid var(--border-primary);
  align-items: center;
  gap: var(--space-x1);
  padding: var(--space-x0-half) var(--space-x1-half);
  justify-content: center;
  transition: background-color .3s;
  display: flex;
}

@media (max-width: 600px) {
  .question-actions-wrapper__draft-guess-status {
    align-items: flex-start;
  }
}

.question-actions-wrapper__draft-guess-status--error {
  background-color: var(--background-negative-subtle);
}

.question-actions-wrapper__draft-guess-status--has-draft-guess {
  background-color: var(--background-bold);
}

.question-actions-wrapper__draft-guess-status-icon-wrapper {
  height: 24px;
}
