.split-button__wrapper {
  --leftButton-radius: 8px 0 0 8px;
  --rightButton-radius: 0 8px 8px 0;
  --leftButton-border-color: #50a9e2;
  --leftButton-border-width: 1px;
  display: flex;
}

.split-button__wrapper > .a-button:first-child:not(:only-child) {
  --button-border-radius: var(--leftButton-radius);
}

.split-button__wrapper > .a-button:not(:first-child):not(:last-child):not(:only-child) {
  --button-border-radius: 0px;
  border-left: var(--leftButton-border-width) solid var(--leftButton-border-color);
}

.split-button__wrapper > .a-button:not(:first-child):not(:last-child):not(:only-child).a-button-faded--primary {
  --leftButton-border-color: #a8d4f0;
}

.split-button__wrapper > .a-button:not(:first-child):not(:last-child):not(:only-child).a-button-solid--secondary, .split-button__wrapper > .a-button:not(:first-child):not(:last-child):not(:only-child).a-button-faded--secondary {
  --leftButton-border-color: #d1d5db;
}

.split-button__wrapper > .a-button:not(:first-child):not(:last-child):not(:only-child).a-button-solid--destructive, .split-button__wrapper > .a-button:not(:first-child):not(:last-child):not(:only-child).a-button-faded--destructive {
  --leftButton-border-color: #eb968e;
}

.split-button__wrapper > .a-button:not(:first-child):not(:last-child):not(:only-child).a-button-solid--green {
  --leftButton-border-color: #10b981;
}

.split-button__wrapper > .a-button:not(:first-child):not(:last-child):not(:only-child).a-button-faded--green {
  --leftButton-border-color: #6ee7b7;
}

.split-button__wrapper > .a-button:not(:first-child):not(:last-child):not(:only-child)[class*="a-button-text"] {
  --leftButton-border-width: 0px;
}

.split-button__wrapper > .a-button:not(:first-child):not(:last-child):not(:only-child)[class*="a-button-outlined"] {
  border: 1px solid var(--button-border-color);
  border-left: 0 solid rgba(0, 0, 0, 0);
}

.split-button__wrapper > .a-button:not(:first-child):not(:last-child):not(:only-child)[class*="a-button-outlined"]:not(:hover) {
  box-shadow: none;
}

.split-button__wrapper > .a-button:not(:first-child):not(:last-child):not(:only-child)[class*="a-button-outlined"]:hover {
  border-color: rgba(0, 0, 0, 0);
}

.split-button__wrapper > .a-button:last-child:not(:only-child) {
  --button-border-radius: var(--rightButton-radius);
  border-left: var(--leftButton-border-width) solid var(--leftButton-border-color);
}

.split-button__wrapper > .a-button:last-child:not(:only-child).a-button-faded--primary {
  --leftButton-border-color: #a8d4f0;
}

.split-button__wrapper > .a-button:last-child:not(:only-child).a-button-solid--secondary, .split-button__wrapper > .a-button:last-child:not(:only-child).a-button-faded--secondary {
  --leftButton-border-color: #d1d5db;
}

.split-button__wrapper > .a-button:last-child:not(:only-child).a-button-solid--destructive, .split-button__wrapper > .a-button:last-child:not(:only-child).a-button-faded--destructive {
  --leftButton-border-color: #eb968e;
}

.split-button__wrapper > .a-button:last-child:not(:only-child).a-button-solid--green {
  --leftButton-border-color: #10b981;
}

.split-button__wrapper > .a-button:last-child:not(:only-child).a-button-faded--green {
  --leftButton-border-color: #6ee7b7;
}

.split-button__wrapper > .a-button:last-child:not(:only-child)[class*="a-button-text"] {
  --leftButton-border-width: 0px;
}

.split-button__wrapper > .a-button:last-child:not(:only-child)[class*="a-button-outlined"] {
  border: 1px solid var(--button-border-color);
  border-left: 0 solid rgba(0, 0, 0, 0);
}

.split-button__wrapper > .a-button:last-child:not(:only-child)[class*="a-button-outlined"]:not(:hover) {
  box-shadow: none;
}

.split-button__wrapper > .a-button:last-child:not(:only-child)[class*="a-button-outlined"]:hover {
  border-color: rgba(0, 0, 0, 0);
}

.split-button__wrapper > div:first-child:not(:only-child) .a-button {
  --button-border-radius: var(--leftButton-radius);
}

.split-button__wrapper > div:not(:first-child):not(:last-child):not(:only-child) .a-button {
  --button-border-radius: 0px;
  border-left: var(--leftButton-border-width) solid var(--leftButton-border-color);
}

.split-button__wrapper > div:not(:first-child):not(:last-child):not(:only-child) .a-button.a-button-faded--primary {
  --leftButton-border-color: #a8d4f0;
}

.split-button__wrapper > div:not(:first-child):not(:last-child):not(:only-child) .a-button.a-button-solid--secondary, .split-button__wrapper > div:not(:first-child):not(:last-child):not(:only-child) .a-button.a-button-faded--secondary {
  --leftButton-border-color: #d1d5db;
}

.split-button__wrapper > div:not(:first-child):not(:last-child):not(:only-child) .a-button.a-button-solid--destructive, .split-button__wrapper > div:not(:first-child):not(:last-child):not(:only-child) .a-button.a-button-faded--destructive {
  --leftButton-border-color: #eb968e;
}

.split-button__wrapper > div:not(:first-child):not(:last-child):not(:only-child) .a-button.a-button-solid--green {
  --leftButton-border-color: #10b981;
}

.split-button__wrapper > div:not(:first-child):not(:last-child):not(:only-child) .a-button.a-button-faded--green {
  --leftButton-border-color: #6ee7b7;
}

.split-button__wrapper > div:not(:first-child):not(:last-child):not(:only-child) .a-button[class*="a-button-text"] {
  --leftButton-border-width: 0px;
}

.split-button__wrapper > div:not(:first-child):not(:last-child):not(:only-child) .a-button[class*="a-button-outlined"] {
  border: 1px solid var(--button-border-color);
  border-left: 0 solid rgba(0, 0, 0, 0);
}

.split-button__wrapper > div:not(:first-child):not(:last-child):not(:only-child) .a-button[class*="a-button-outlined"]:not(:hover) {
  box-shadow: none;
}

.split-button__wrapper > div:not(:first-child):not(:last-child):not(:only-child) .a-button[class*="a-button-outlined"]:hover {
  border-color: rgba(0, 0, 0, 0);
}

.split-button__wrapper > div:last-child:not(:only-child) .a-button {
  --button-border-radius: var(--rightButton-radius);
  border-left: 1px solid var(--leftButton-border-color);
}

.split-button__wrapper > div:last-child:not(:only-child) .a-button.a-button-faded--primary {
  --leftButton-border-color: #a8d4f0;
}

.split-button__wrapper > div:last-child:not(:only-child) .a-button.a-button-solid--secondary, .split-button__wrapper > div:last-child:not(:only-child) .a-button.a-button-faded--secondary {
  --leftButton-border-color: #d1d5db;
}

.split-button__wrapper > div:last-child:not(:only-child) .a-button.a-button-solid--destructive, .split-button__wrapper > div:last-child:not(:only-child) .a-button.a-button-faded--destructive {
  --leftButton-border-color: #eb968e;
}

.split-button__wrapper > div:last-child:not(:only-child) .a-button.a-button-solid--green {
  --leftButton-border-color: #10b981;
}

.split-button__wrapper > div:last-child:not(:only-child) .a-button.a-button-faded--green {
  --leftButton-border-color: #6ee7b7;
}

.split-button__wrapper > div:last-child:not(:only-child) .a-button[class*="a-button-text"] {
  --leftButton-border-width: 0px;
}

.split-button__wrapper > div:last-child:not(:only-child) .a-button[class*="a-button-outlined"] {
  border: 1px solid var(--button-border-color);
  border-left: 0 solid rgba(0, 0, 0, 0);
}

.split-button__wrapper > div:last-child:not(:only-child) .a-button[class*="a-button-outlined"]:not(:hover) {
  box-shadow: none;
}

.split-button__wrapper > div:last-child:not(:only-child) .a-button[class*="a-button-outlined"]:hover {
  border-color: rgba(0, 0, 0, 0);
}
