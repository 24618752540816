.archive-icon {
  color: #fff;
  background-color: #6b7280;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  width: 100px;
  height: 100px;
  margin: 0 auto 1em;
}

.archive-icon.fa {
  display: flex;
}

.archive-icon:before {
  font-size: 3.7em;
  transform: translateY(2px);
}

.window-confirm__window {
  text-align: center;
  flex-direction: column;
  max-width: 26em;
  display: flex;
}

.window-confirm__title {
  color: #374151;
  margin: 1em 0 1.5em;
  font-weight: bold;
}

.window-confirm__message {
  color: #374151;
  white-space: pre-line;
  margin: 1em 0 1.5em;
}

.window-confirm__actions {
  justify-content: space-between;
  display: flex;
}

.window-confirm__actions button {
  width: 48%;
  margin: 0 0 0 .5em;
}
