.assign-modal__tooltip {
  background-color: #1f2937;
  width: 240px;
  padding: 6px 10px;
}

.assign-modal__tooltip p {
  margin: 0;
  line-height: 21px;
}
