.authoring-feedback-message-list {
  margin: 0;
  padding: .75em;
  list-style: none;
}

.authoring-feedback-message-list__item {
  background-color: #f9fafb;
  border-radius: 4px;
  margin: .75em;
  padding: .75em;
}

.authoring-feedback-message {
  line-height: 1.5;
}

.authoring-feedback-message p {
  padding: .75em;
  overflow: scroll;
}

.authoring-feedback-message__notification-indicator {
  text-align: right;
}

.authoring-feedback-message__notification-indicator--not-sent .fa {
  color: #dc4538;
}

.authoring-feedback-message__attribution {
  text-align: right;
}
