.selectable-date-range {
  display: flex;
}

@media (max-width: 600px) {
  .selectable-date-range {
    flex-direction: column;
  }
}

.selectable-date-range__item {
  flex-grow: 1;
  flex-basis: 0;
  margin: 0 1em 1em 0;
}

.selectable-date-range__item:last-child {
  margin-right: 0;
}

.selectable-date-range__item--simple-dropdown .simple-dropdown__input-wrapper, .selectable-date-range__item--simple-dropdown .simple-dropdown__input {
  width: 100%;
}

@media (max-width: 600px) {
  .selectable-date-range, .selectable-date-range__item--date-range {
    flex-direction: column;
    margin-right: 0;
  }

  .selectable-date-range__item {
    margin: 0 0 1em;
  }
}
