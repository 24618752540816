.reports-export-dropdown {
  border: 1px solid var(--border-primary);
  z-index: 9999;
  background: #fff;
  border-radius: 8px;
  flex-direction: column;
  min-width: 176px;
  max-width: 440px;
  height: fit-content;
  margin: 8px 0 0;
  list-style: none;
  position: absolute;
  overflow: auto;
  box-shadow: 0 5px 20px rgba(0, 0, 0, .05);
}

.reports-export__file-type-chip {
  letter-spacing: .5px;
  margin: 0 0 0 8px;
  padding: 4px;
  font-size: 10px;
  font-weight: 900;
}
