.filters-dropdown__loader {
  margin-bottom: 0;
  margin-left: 16px;
  margin-right: 32px;
}

.filters-dropdown__label {
  cursor: pointer;
  align-items: center;
  margin-bottom: 8px;
  display: flex;
}

.filters-dropdown__label input {
  cursor: pointer;
}
