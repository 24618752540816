.free-form-editor__input {
  flex-direction: column;
  margin: 0 0 20px;
  display: flex;
}

.free-form-editor__input--textarea textarea {
  height: 14em;
}

.free-form-editor__input--textarea textarea, .free-form-editor__markdown-renderer {
  margin: 0 0 20px;
}
