.o-table__title-bar {
  flex-wrap: wrap;
  align-items: flex-end;
  margin-bottom: 24px;
  display: flex;
}

.o-table__title-bar > :not(:last-child) {
  margin-right: 24px;
}

.o-table__title-bar-group {
  flex-direction: row;
  align-items: center;
  display: flex;
}

.o-table__title-bar-group:not(:last-child) {
  margin-right: 24px;
}

.o-table__title-bar-group > :not(:last-child) {
  margin-right: 12px;
}

@media (max-width: 780px) {
  .o-table__title-bar-group {
    margin-bottom: 12px;
  }
}

.o-table__title-bar-group--left {
  flex-wrap: wrap;
  margin-right: auto;
  display: flex;
}

.o-table__title-bar-group--right {
  flex-wrap: wrap;
  margin-left: auto;
  display: flex;
}
