:root {
  --gradebook-table-first-header-height: 80px;
  --gradebook-table-border: 1px solid #d1d5db;
}

.gradebook-table__wrapper {
  -webkit-overflow-scrolling: touch;
  scroll-behavior: smooth;
  border-top: none;
  width: fit-content;
  min-width: 100%;
  max-width: 100%;
  overflow: auto hidden;
}

.gradebook-table__table {
  border-spacing: 0;
  min-width: 100%;
}

.gradebook-table__table tr:first-child th {
  border-top: var(--gradebook-table-border);
}

.gradebook-table__table tr:first-child th:first-child {
  border-top-left-radius: 8px;
}

.gradebook-table__table tr:first-child th:last-child {
  border-top-right-radius: 8px;
}

.gradebook-table__table tr:first-child th:first-child:before {
  content: "";
  background: none;
  border-top-left-radius: 8px;
  width: 20px;
  height: 20px;
  display: block;
  position: absolute;
  top: -1px;
  left: -1px;
  overflow: hidden;
  box-shadow: -2px -2px #fff;
}

.gradebook-table__table tr:first-child th:last-child:after {
  content: "";
  background: none;
  border-top-right-radius: 8px;
  width: 20px;
  height: 20px;
  display: block;
  position: absolute;
  top: -1px;
  right: -1px;
  overflow: hidden;
  box-shadow: 2px -2px #fff;
}

.gradebook-table__table tr th:first-child, .gradebook-table__table td:first-child {
  border-left: none;
}

.gradebook-table__table tr:last-child td {
  border-bottom: none;
}

.gradebook-table__table tr th {
  border-top: var(--gradebook-table-border);
  text-align: left;
  z-index: 11;
  position: sticky;
}

.gradebook-table__table tr th:not(:last-child) {
  border-right: var(--gradebook-table-border);
}

.gradebook-table__table tr th:first-child {
  z-index: 12;
  left: 0;
}

.gradebook-table__table tr th.gradebook-table__students-header {
  z-index: 897;
}

.gradebook-table__table tr:last-child th {
  border-bottom: var(--gradebook-table-border);
}

.gradebook-table__table tr td:first-child {
  z-index: 11;
  min-width: 200px;
  position: sticky;
  left: 0;
}

.gradebook-table__table tr:not(:hover) td:first-child {
  background-color: #fff;
}

.gradebook-table__table tr td {
  border-bottom: var(--gradebook-table-border);
  min-width: 130px;
}

.gradebook-table__table tr td:not(:last-child) {
  border-right: var(--gradebook-table-border);
}

.gradebook-table__assignment-header {
  height: var(--gradebook-table-first-header-height);
  max-height: var(--gradebook-table-first-header-height);
}

.gradebook-table__assignment-header span {
  -webkit-line-clamp: 3;
  line-clamp: 3;
  -webkit-box-orient: vertical;
  display: -webkit-inline-box;
  overflow: hidden;
}

.gradebook-table__table th, .gradebook-table__table td {
  padding: 8px 12px;
}

.gradebook-table__dimension-header > th {
  background-color: #fff;
  top: 0;
}

.gradebook-table__thead {
  z-index: 12;
}

.gradebook-table__metrics-header > th {
  top: var(--gradebook-table-first-header-height);
  background-color: #f3f4f6;
}

.gradebook-table__metrics-header > th:not(.gradebook-table__students-header) {
  font-weight: normal;
}

.gradebook-table__row:hover > td {
  background-color: #f9fafb;
}

.gradebook-table__loading-spinner {
  border-left: var(--gradebook-table-border);
  position: sticky;
  top: 0;
}

.gradebook-table__loading-td {
  height: calc(60vh - 180px);
}

.gradebook-table__drilldown-button {
  transform: rotate(45deg);
}

tr:not(:hover) .gradebook-table__drilldown-button--student, .gradebook-table__drilldown-button--assignment {
  opacity: 0;
}

.gradebook-table__drilldown-button--assignment.gradebook-table__drilldown-button--visible {
  opacity: 1;
}
