.frq-view {
  padding: 2em 1em;
}

.frq-view h2 {
  text-transform: uppercase;
  margin-bottom: .5em;
  font-size: 1.15em;
}

.frq-view--error {
  text-align: center;
  margin: 1.5em;
  padding: 1.5em;
}

.frq-view__content-wrapper {
  max-width: 830px;
  margin: 0 auto;
  padding: 0;
}

.frq-view__content {
  padding: 1.75em 1.5em;
}

.frq-header {
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1em;
  display: flex;
}

@media (min-width: 440px) {
  .frq-header {
    flex-wrap: nowrap;
  }
}

.frq-header__name {
  margin: 0 0 1em;
  font-size: 1.3em;
}

@media (min-width: 440px) {
  .frq-header__name {
    margin: 0 auto 0 0;
  }
}

.frq-header__add-to-template-button {
  margin-left: 10px;
}

.frq-section {
  margin: 0 0 1em;
}

.frq-paywall {
  text-align: center;
  background-color: #f9fafb;
  flex-direction: column;
  align-items: center;
  max-width: 480px;
  margin: auto;
  padding: 2em;
  display: flex;
  position: static;
}

.frq-paywall:before {
  content: "";
  background-image: linear-gradient(rgba(0, 0, 0, 0), #f9fafb 70%, #f9fafb);
  display: block;
  position: absolute;
  inset: 0 -1em -1em;
}

.frq-paywall__top-line {
  display: block;
}

@media (max-width: 600px) {
  .frq-paywall__top-line {
    display: inline;
  }
}

.frq-paywall__upgrade-subject-button {
  text-align: center;
}

.frq-actions {
  justify-content: center;
  margin: 1.5em;
  display: flex;
}
