.actions-dropdown__button {
  margin-left: 8px;
}

.actions-dropdown__smart-assign {
  width: 264px;
}

.actions-dropdown__no-border {
  border: none;
}

.actions-dropdown__wrapper .m-dropdown__content-wrapper {
  z-index: 999;
}
