.content-personalization-body__onboarding {
  max-width: 60%;
  margin: auto;
}

.content-peronsalization-button__onboarding {
  margin-top: 2rem !important;
}

.content-personalization__section {
  width: 100%;
  display: flex;
}

.content-personalization__section-description {
  flex-direction: column;
  flex: 50%;
  padding-right: 67.5px;
  display: flex;
}

.content-personalization__section-content {
  flex: 50%;
}

.content-personalization__section-divider {
  border-bottom: 1px solid #e5e7eb;
  padding-top: 1rem;
}
