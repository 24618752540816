:export {
  drawerModeBreakpoint: 1040px;
  longCardBreakpoint: 580px;
}

.subject-card {
  width: 25%;
  margin-bottom: 30px;
  display: flex;
}

.subject-card:not(.subject-card--long) {
  max-width: 390px;
}

@media screen and (min-width: 1501px) {
  .subject-card:not(.subject-card--long) {
    margin-right: 30px;
  }
}

@media screen and (min-width: 1281px) and (max-width: 1500px) {
  .subject-card:not(.subject-card--long) {
    width: calc(33.3333% - 20px);
    width: calc(33.3333% - 20px);
    width: calc(33.3333% - 20px);
  }

  .subject-card:not(.subject-card--long):nth-child(3n+1), .subject-card:not(.subject-card--long):nth-child(3n+2) {
    margin-right: 30px;
  }
}

@media screen and (min-width: 1041px) and (max-width: 1280px) {
  .subject-card:not(.subject-card--long) {
    width: calc(50% - 15px);
    width: calc(50% - 15px);
  }

  .subject-card:not(.subject-card--long):nth-child(odd) {
    margin-right: 30px;
  }
}

@media screen and (min-width: 841px) and (max-width: 1040px) {
  .subject-card:not(.subject-card--long) {
    width: calc(33.3333% - 20px);
    width: calc(33.3333% - 20px);
    width: calc(33.3333% - 20px);
  }

  .subject-card:not(.subject-card--long):nth-child(3n+1), .subject-card:not(.subject-card--long):nth-child(3n+2) {
    margin-right: 30px;
  }
}

@media screen and (min-width: 581px) and (max-width: 840px) {
  .subject-card:not(.subject-card--long) {
    width: calc(50% - 15px);
    width: calc(50% - 15px);
  }

  .subject-card:not(.subject-card--long):nth-child(odd) {
    margin-right: 30px;
  }
}

@media screen and (max-width: 580px) {
  .subject-card {
    width: 100%;
    margin-bottom: 10px;
  }
}

.subject-card__container {
  color: #374151;
  border: 1px solid rgba(0, 0, 0, .075);
  border-radius: 10px;
  flex-grow: 1;
  text-decoration: none;
  display: block;
  box-shadow: 0 7px 20px rgba(0, 0, 0, .05);
}

a.subject-card__container:hover {
  box-shadow: 0 10px 25px rgba(0, 0, 0, .075);
}

a.subject-card__container:hover .subject-card__icon {
  transform: scale(1.075);
}

.subject-card__scoop-wrapper {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  overflow: hidden;
}

.subject-card__icon-wrapper {
  text-align: center;
  justify-content: center;
  align-items: center;
  padding: 25px 10px 30px;
  display: flex;
}

.subject-card__icon {
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: contain;
  width: 60px;
  height: 60px;
  transition: transform .25s;
}

.subject-card__body {
  padding: 30px 40px;
}

.subject-card__body p, .subject-card__body__md-renderer {
  color: #6b7280;
  font-size: 1.1em;
  line-height: 1.4;
}

.subject-card__body p:last-child, .subject-card__body__md-renderer:last-child {
  margin-bottom: 0;
}

.subject-card__title {
  text-align: center;
  margin-bottom: 15px;
  font-size: 1.3em;
  font-weight: 600;
}

.subject-card--long {
  width: 100%;
  max-width: 100%;
}

.subject-card--long .subject-card__container {
  width: 100%;
  display: flex;
}

.subject-card--long .subject-card__scoop-wrapper {
  border-radius: 10px 0 0 10px;
}

.subject-card--long .subject-card__icon-wrapper {
  width: 57px;
  min-height: 100%;
  padding: 12.5px;
  clip-path: none !important;
}

.subject-card--long .subject-card__icon {
  width: 32px;
  min-width: 32px;
  height: 32px;
  min-height: 32px;
}

.subject-card--long .subject-card__body {
  flex-grow: 1;
  align-items: center;
  max-width: calc(100% - 57px);
  padding: 25px;
  display: flex;
}

.subject-card--long .subject-card__body p {
  font-size: 14px;
}

.subject-card--long .subject-card__title {
  text-align: left;
  margin: 0;
  font-size: 1.1em;
}

.subject-card--long-reverse .subject-card__container {
  flex-direction: row-reverse;
}

.subject-card--long-reverse .subject-card__scoop-wrapper {
  border-radius: 0 10px 10px 0;
}
