.button-dropdown {
  display: inline-block;
}

.button-dropdown:not(:last-child) {
  margin-right: 7px;
}

.button-dropdown__button {
  justify-content: center;
  align-items: center;
  display: inline-flex;
}

.button-dropdown__button.button-dropdown__button {
  margin: 0;
}

.button-dropdown__button--unbutton, .button-dropdown__button--unbutton:hover {
  color: currentColor;
  background-color: rgba(0, 0, 0, 0);
  padding: 10px;
}

.button-dropdown-content {
  z-index: 9;
  background: #fff;
  border: 1px solid #d1d5db;
  border-radius: 4px;
  margin: .25em 0 0;
  padding: 0;
  list-style: none;
  position: fixed;
  right: 0;
  overflow: hidden;
  box-shadow: 0 2px 10px rgba(0, 0, 0, .18);
}

.button-dropdown-content--left-aligned {
  left: 0;
}

.button-dropdown-content__list-item {
  color: #374151;
  cursor: pointer;
  -webkit-font-smoothing: auto;
  text-align: left;
  white-space: nowrap;
  background: #fff;
  border-bottom: 1px solid #d1d5db;
  padding: .8572em;
  transition: all .1s;
  display: flex;
}

.button-dropdown-content__list-item--red {
  color: #dc4538;
}

.button-dropdown-content__list-item--green {
  color: #059669;
}

.button-dropdown-content__list-item--blue {
  color: #50a9e2;
}

.button-dropdown-content__list-item--disabled {
  cursor: not-allowed;
  opacity: .5;
}

.button-dropdown-content__list-item--inactive {
  cursor: default;
}

.button-dropdown-content__list-item:last-child {
  border-bottom: 0;
}

.button-dropdown-content__list-item:not(.button-dropdown-content__list-item--disabled):not(.button-dropdown-content__list-item--inactive):hover {
  color: #fff;
  background: #50a9e2;
}

.button-dropdown-content__list-item-icon {
  justify-content: center;
  align-items: center;
  margin: 0 1em 0 0;
  display: flex;
}

.button-dropdown__icon {
  margin-left: .5em;
  position: relative;
  top: 1px;
}

.button-dropdown__icon:only-child {
  margin-left: 0;
}

.button-dropdown__icon--open {
  top: 0;
}

.button-dropdown__icon--unbutton {
  color: #9ca3af;
}
