.question-editor--text-highlight textarea {
  min-height: 8em;
}

.question-editor--text-highlight .a-form-input__label {
  display: block;
}

.question-editor--text-highlight .answer-input--text-highlight {
  margin-top: 1rem;
  margin-bottom: .5rem;
  margin-left: 0;
  padding-right: 1rem;
}

.question-editor--text-highlight .question-editor__textarea--text-highlight-answer-content {
  margin-bottom: 1rem;
}

.question-editor--text-highlight .text-highlight-answer-permutation__header {
  justify-content: space-between;
  align-items: flex-end;
  display: flex;
}

.question-editor--text-highlight .text-highlight-answer-permutation__body {
  justify-content: center;
  display: flex;
}

.question-editor--text-highlight .text-highlight-editor__single-permutation {
  margin-top: 1rem;
  margin-bottom: .5rem;
}

.question-editor--text-highlight .text-highlight-editor__single-permutation hr {
  border: 0;
  border-top: 2px solid #f3f4f6;
  margin: 1em 0;
  display: block;
}

.question-editor--text-highlight .text-highlight-editor__permutations-wrapper {
  margin-bottom: 1rem;
}

.question-editor--text-highlight label.text-highlight-answer-permutation__correct-permutation-wrapper {
  align-items: center;
  font-size: .8rem;
  display: flex;
}

.question-editor--text-highlight .text-highlight-answer-permutation__correct-permutation-checkbox {
  margin: 0 1rem 0 .5rem;
  display: block;
}

.question-editor--text-highlight .question-editor__button--delete-permutation {
  -webkit-appearance: none;
  -moz-appearance: none;
  color: .75rem;
  cursor: pointer;
  background-color: #dc4538;
  border: 0;
  border-radius: .15em;
  padding: .85em 1em .8em;
  font-size: 1em;
  font-weight: bold;
  text-decoration: none;
  transition: box-shadow .1s ease-in-out, transform .1s ease-in-out;
}

.question-editor--text-highlight .question-editor__button--delete-permutation::selection {
  background-color: rgba(0, 0, 0, 0);
}

.question-editor--text-highlight .question-editor__button--delete-permutation ::selection {
  background-color: rgba(0, 0, 0, 0);
}

.question-editor--text-highlight .question-editor__button--delete-permutation.disabled, .question-editor--text-highlight .question-editor__button--delete-permutation.disabled:hover, .question-editor--text-highlight .question-editor__button--delete-permutation:disabled, .question-editor--text-highlight .question-editor__button--delete-permutation:disabled:hover {
  color: #d1d5db;
  background-color: #6b7280;
}

.question-editor--text-highlight .question-editor__button--delete-permutation:not(:disabled):not(:active):hover {
  transform: translateY(-1px)scale(1.01);
  box-shadow: 0 2px 9px rgba(0, 0, 0, .2);
}
