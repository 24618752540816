.how-others-answered__header {
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.how-others-answered__header-not-enough-answers {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.how-others-answered__text {
  display: flex;
}

@media (max-width: 440px) {
  .how-others-answered__popover {
    width: 70vw;
    margin: auto;
  }
}

.how-others-answered__header-icon {
  width: 18px;
  margin-top: 2px;
  margin-right: 16px;
}

.how-others-answered__list-item {
  padding-bottom: 8px;
}

.how-others-answered__list-item:last-child {
  padding-bottom: 0;
}

.how-others-answered_tooltip-icon {
  font-size: 20px;
  position: absolute;
}

.how-others-answered__breakdown {
  height: auto;
  padding-left: 16px;
  transition: height .25s;
}

.how-others-answered__breakdown--hide {
  opacity: 0;
  height: 0;
}
