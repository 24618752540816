:export {
  drawerModeBreakpoint: 1040px;
  longCardBreakpoint: 580px;
}

.subject-menu-renderer--with-hero .cl-sidebar-menu__category-list, .subject-menu-renderer--with-hero .subject-menu-renderer__subject-area-wrapper {
  padding-top: 32px;
}

.subject-menu-renderer--with-hero .subject-menu-renderer__menu-button {
  color: #fff;
  letter-spacing: 1px;
  opacity: .75;
  text-shadow: 0 1px 5px rgba(0, 0, 0, .25);
  text-transform: uppercase;
  flex-direction: row-reverse;
  align-items: center;
  font-size: 1rem;
  font-weight: bold;
  display: inline-flex;
}

.subject-menu-renderer--with-hero .subject-menu-renderer__menu-button:after {
  margin-right: 10px;
}

.subject-menu-renderer--with-hero .subject-menu-renderer__menu-button:hover {
  box-shadow: none !important;
  transform: none !important;
}

.subject-menu-renderer__main {
  background-color: var(--background-primary);
  min-height: 80vh;
  font-size: 14px;
  display: flex;
}

@media (max-width: 780px) {
  .subject-menu-renderer__main {
    flex-direction: column;
    padding-top: 16px;
  }
}

.subject-menu-renderer__subject-area-wrapper {
  flex-grow: 1;
  max-width: calc(100% - 320px);
}

@media screen and (max-width: 1040px) {
  .subject-menu-renderer__subject-area-wrapper {
    max-width: 100%;
    overflow: clip;
  }
}

.subject-cards-wrapper {
  flex-wrap: wrap;
  flex-grow: 1;
  padding: 40px;
  display: flex;
}

@media screen and (max-width: 420px) {
  .subject-cards-wrapper {
    padding: 25px;
  }
}

.subject-cards-wrapper--course-library {
  grid-template-columns: repeat(auto-fill, minmax(304px, 1fr));
  gap: 32px;
  display: grid;
}

@media screen and (max-width: 420px) {
  .subject-cards-wrapper--course-library {
    grid-template-columns: repeat(auto-fill, minmax(192px, 1fr));
    gap: 16px;
  }
}

.subject-cards-wrapper__no-subjects-found {
  opacity: .75;
  width: 100%;
  padding: 2em 0;
  font-size: 1.4em;
  font-weight: bold;
}

.subject-menu-renderer__dropdown-title {
  cursor: default;
  justify-content: space-between;
  display: flex;
}

.subject-menu-renderer__dropdown-item {
  cursor: default;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.subject-menu-renderer__dropdown-item-classroom-name {
  width: 120px;
}

.subject-menu-renderer__tooltip-icon {
  cursor: pointer;
}

.subject-menu-renderer__horizontal-menu {
  background-color: var(--background-primary);
  z-index: 1;
  flex-direction: row;
  display: flex;
  position: sticky;
  top: 50px;
}

.subject-menu-renderer__horizontal-menu-header {
  border-bottom: 1px solid var(--border-primary);
  white-space: nowrap;
  align-items: center;
  margin-bottom: 0;
  padding-left: 40px;
  padding-right: 24px;
  font-size: 16px;
  display: flex;
}
