.subject-course-card button:focus:not(:focus-visible) {
  outline: 0;
}

.subject-course-card a:focus:not(:focus-visible) {
  outline: 0;
}

.subject-course-card:focus {
  outline: 0;
}

.subject-course-card:focus-within {
  outline: 0;
}

.subject-course-card__details {
  cursor: pointer;
  border-bottom: none;
  border-radius: 24px;
  min-height: 120px;
  padding-right: 84px;
  transition: border-radius .25s cubic-bezier(.075, .82, .165, 1);
}

.subject-course-card__details:focus {
  outline: none;
}

.subject-course-card__details:hover {
  background-image: linear-gradient(rgba(0, 0, 0, .18) 0 0);
}

.subject-course-card__details:active {
  background-image: unset;
}

.subject-course-card--action-bar-in-view .subject-course-card__details, .subject-course-card--description-in-view .subject-course-card__details {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.subject-course-card--description-in-view .subject-course-card__description {
  box-shadow: 0 -1px 0 var(--border-subtle) inset, -1px 0 0 var(--border-subtle) inset, 1px 0 0 var(--border-subtle) inset;
  max-height: unset;
  overflow: unset;
  padding-top: 16px;
  padding-bottom: 16px;
}

.subject-course-card--action-bar-in-view .subject-course-card__action-bar {
  border-bottom-right-radius: 24px;
  border-bottom-left-radius: 24px;
}

.subject-course-card--action-bar-in-view .subject-course-card__description {
  box-shadow: -1px 0 0 var(--border-subtle) inset, 1px 0 0 var(--border-subtle) inset;
}

.subject-course-card--action-bar-in-view.subject-course-card--description-in-view .subject-course-card__description {
  box-shadow: 0 -1px 0 var(--border-subtle) inset, -1px 0 0 var(--border-subtle) inset, 1px 0 0 var(--border-subtle) inset;
}

.subject-course-card__popper {
  z-index: 1;
  max-width: 224px;
}

.subject-course-card__popper__dropdown-items {
  max-height: calc(100vh - 60px);
}

.subject-course-card__title {
  text-shadow: 0 2px 2px rgba(0, 0, 0, .25);
  font-size: 17px;
}

.subject-course-card__title a:hover {
  text-underline-offset: 4px;
  text-decoration: none;
}

.subject-course-card__title:focus-visible {
  outline: 3px solid #50a9e2;
}

.subject-course-card__description {
  max-height: 0;
  padding-bottom: unset;
  padding-top: unset;
  border: none;
  border-bottom-right-radius: 24px;
  border-bottom-left-radius: 24px;
  transition: max-height .32s cubic-bezier(.075, .82, .165, 1), padding .32s cubic-bezier(.075, .82, .165, 1);
}

.subject-course-card__description-link {
  opacity: .75;
  text-shadow: 0 2px 2px rgba(0, 0, 0, .25);
  align-self: flex-start;
  margin-top: 4px;
}

.subject-course-card__description-link:hover {
  opacity: 1;
}

.subject-course-card__action-bar {
  box-shadow: 0 -1px 0 var(--border-subtle) inset, -1px 0 0 var(--border-subtle) inset, 1px 0 0 var(--border-subtle) inset;
  border-radius: 0 0 24px 24px;
  min-height: 44px;
}

.subject-course-card__action-bar:hover, .subject-course-card__action-bar:focus {
  background-color: var(--background-primary-hover);
  cursor: pointer;
  text-decoration: none;
}

.subject-course-card__action-bar:active {
  background-color: var(--background-primary-pressed);
}

.subject-course-card__action-bar button:hover {
  text-decoration: none;
}

.subject-course-card__icon-wrapper {
  position: absolute;
  top: 0;
  right: 0;
}
