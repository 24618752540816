:root {
  --searchInput-border-radius: 1000px;
  --searchInput-padding-left: 40px;
  --searchInput-padding-right: 24px;
  --searchInput-icon-left-offset: 16px;
  --searchInput-iconButton-right-offset: 8px;
}

.m-search-input__wrapper {
  display: inline-flex;
  position: relative;
}

.m-search-input {
  border-radius: var(--searchInput-border-radius);
  padding-left: var(--searchInput-padding-left);
}

.m-search-input.m-search-input--regular {
  padding-right: var(--searchInput-padding-right);
}

.m-search-input.m-search-input--s {
  --searchInput-padding-right: 16px;
  --searchInput-padding-left: 32px;
  height: 32px;
}

.m-search-input.m-search-input--s ~ .m-search-input__icon {
  --searchInput-icon-left-offset: 8px;
}

.m-search-input.m-search-input--s ~ .m-search-input__icon-btn {
  --searchInput-iconButton-right-offset: 4px;
}

.m-search-input--variant::-webkit-search-cancel-button {
  -webkit-appearance: none;
}

.m-search-input--variant-focus[type="search"] {
  color: #1a699e;
  background-color: #ebf5fa;
}

.m-search-input--variant-focus[type="search"]::placeholder {
  color: #1a699e;
}

.m-search-input__icon {
  left: var(--searchInput-icon-left-offset);
  opacity: .54;
  pointer-events: none;
  align-self: center;
  position: absolute;
}

.m-search-input.a-input--focus ~ .m-search-input__icon {
  opacity: 1;
}

.m-search-input__icon-btn {
  right: var(--searchInput-iconButton-right-offset);
  align-self: center;
  position: absolute;
}

.m-search-input__icon-btn:disabled {
  clip: rect(1px, 1px, 1px, 1px);
  white-space: nowrap;
  clip-path: inset(100%);
  border: 0;
  width: 1px;
  height: 1px;
  padding: 0;
  position: absolute;
  overflow: hidden;
}

.m-search-input--rectangle {
  border-radius: 8px;
}
