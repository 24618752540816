.gl-topic-summary__wrapper {
  display: flex;
}

.gl-topic-summary__img-wrapper {
  width: 150px;
  min-width: 150px;
}

.gl-topic-summary__img {
  width: 100%;
}

.gl-topic-summary__button-wrapper {
  justify-content: flex-end;
  display: flex;
}

@media screen and (max-width: 780px) {
  .gl-topic-summary__img-wrapper {
    display: none;
  }
}
