.simple-modal__overflow-handler {
  height: 100vh;
  max-height: 100vh;
  padding: 1.75em;
  overflow: auto;
}

@media (max-width: 600px) {
  .simple-modal__overflow-handler {
    padding: .875em;
  }
}

.simple-modal__wrapper {
  width: 100%;
  min-width: 25em;
  margin: 0 auto;
  position: relative;
}

@media (max-width: 600px) {
  .simple-modal__wrapper {
    min-width: 100%;
  }
}

.simple-modal, .simple-modal__shade {
  position: fixed;
  inset: 0;
}

.simple-modal {
  z-index: 9999;
  animation-name: opacityFadeIn;
  animation-duration: .3s;
  animation-timing-function: cubic-bezier(.23, 1, .32, 1);
  animation-fill-mode: forwards;
  position: fixed;
  inset: 0;
}

@keyframes opacityFadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.simple-modal__shade {
  z-index: -1;
  background-color: rgba(0, 0, 0, .5);
}

.simple-modal__window {
  width: 100%;
  min-width: 25em;
  max-width: 550px;
  margin: 0 auto;
  position: relative;
}

@media (max-width: 600px) {
  .simple-modal__window {
    min-width: 100%;
  }
}

.simple-modal__header, .simple-modal__footer {
  color: #374151;
  background: #f3f4f6;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  justify-content: space-between;
  min-height: 3.75em;
  padding: 1.25em 3.5em 1.25em 1.25em;
  font-size: 1.05rem;
  display: flex;
  position: relative;
}

.simple-modal__title {
  flex-flow: wrap;
  align-items: center;
  padding: 0 .5em 0 0;
  font-weight: bold;
  display: flex;
}

.simple-modal__close-btn {
  color: #d1d5db;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 1.1539em;
  font-size: 1.1375rem;
  display: flex;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
}

.simple-modal__close-btn:hover, .simple-modal__close-btn:focus {
  color: #374151;
}

.simple-modal__body {
  color: #374151;
  background-color: #fff;
  border-radius: 4px;
  padding: 1.5em;
  font-size: .875rem;
}

.simple-modal__header + .simple-modal__body {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.simple-modal__footer {
  border-radius: 0 0 4px 4px;
  justify-content: flex-end;
  padding: 1.25em;
  display: flex;
}
