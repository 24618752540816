.question-editor {
  width: 100%;
  max-width: 830px;
  margin: 0 auto 10em;
}

.question-editor hr {
  border: 1px;
  border-top: 1px solid #f3f4f6;
  width: calc(100% + 2em);
  margin: 2em -1em;
  display: block;
}

.question-editor .simple-dropdown__input {
  width: 10em;
}

@media (max-width: 900px) {
  .question-editor {
    margin: 0 0 10em;
  }
}

.question-editor__question-edit-wrapper {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.edit-page__toolbar {
  justify-content: space-between;
  padding: .5em;
  display: flex;
}

.question-editor__question-details, .question-editor__question-otherstuff {
  flex-wrap: wrap;
  display: flex;
}

.question-editor__question-details, .question-editor__question-otherstuff, .question-editor__question-type {
  margin: 0 0 48px;
}

.question-editor__question-detail--fullwidth {
  flex-basis: 100%;
  margin: 0 0 1em;
}

.question-editor__short-id-label, .question-editor__status-label {
  text-transform: uppercase;
  background-color: #f3f4f6;
  border-radius: 5em;
  margin: .75em 0;
  padding: .5em 1em;
  font-size: .9em;
  font-weight: bold;
  line-height: 1;
  display: inline-block;
}

.question-editor__status-label-container {
  align-items: center;
  margin: 0 0 0 auto;
  display: flex;
}

.question-editor__difficulty-select-option {
  color: #374151;
  cursor: pointer;
  background: #f7f8f8;
  border: 1px solid rgba(0, 0, 0, 0);
  border-radius: 2px;
  width: 106px;
  height: 69px;
  margin-right: 7px;
  padding: 10px;
  display: inline-block;
}

.question-editor__difficulty-select-option::selection {
  background-color: rgba(0, 0, 0, 0);
}

.question-editor__difficulty-select-option ::selection {
  background-color: rgba(0, 0, 0, 0);
}

.question-editor__difficulty-select-option .label {
  font-size: 11px;
}

.question-editor__difficulty-select-option .fa {
  font-size: 10px;
}

.question-editor__difficulty-select-option:hover, .question-editor__difficulty-select-option.selected {
  color: #2b7096;
  background: #f3fbff;
  border: 1px solid #2b7096;
}

.question-editor__markdown-cheat-sheet-wrapper, .question-editor__add-supplement-btn {
  display: inline-flex;
}

button.question-editor__cheat-sheet-btn {
  cursor: pointer;
  font-size: 14px;
  font-weight: bold;
}

button.question-editor__cheat-sheet-btn, button.question-editor__cheat-sheet-btn:hover {
  color: #1e7cb8;
}

.question-editor__cheat-sheet-modal {
  max-width: 830px;
}

.question-editor__cheat-sheet-modal nav a {
  color: #1e7cb8;
  font-weight: bold;
  text-decoration: none;
}

.question-editor__cheat-sheet-modal nav a:hover {
  text-decoration: underline;
}

.question-editor__cheat-sheet-modal pre {
  color: #374151;
  background-color: #f9fafb;
  border: 1px solid #d1d5db;
  border-radius: .25em;
  padding: 1em;
  transform: translate3d(0, 0, 0);
}

.question-editor__add-supplement-btn {
  margin-left: auto;
}

.question-editor__prompt textarea {
  height: 12em;
}

.question-editor__solution textarea {
  height: 18em;
}
