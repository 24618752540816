.btn-group {
  margin: .25em;
  display: flex;
}

.btn-group--position-at-bottom {
  margin-top: auto;
}

.btn-group__items-wrapper {
  flex-wrap: nowrap;
  display: flex;
}

.btn-group__items-wrapper > .btn-group__item:first-child {
  margin-left: 0;
}

.btn-group__items-wrapper > .btn-group__item:last-child {
  margin-right: 0;
}

.btn-group__items-wrapper:not(.btn-group__items-wrapper--column-fill) {
  flex-basis: auto;
}

.btn-group__items-wrapper.btn-group__items-wrapper--column-fill {
  flex-basis: 100%;
}

@media (max-width: 780px) {
  .btn-group__items-wrapper {
    flex-direction: column;
    flex-basis: 780px;
  }

  .btn-group__items-wrapper--no-fill {
    flex-direction: row;
    flex-basis: auto;
  }
}

.btn-group__items-wrapper--column {
  flex-direction: column;
  flex-basis: 100%;
}

.btn-group__items-wrapper--column > .btn-group__item {
  margin: 4px 0;
}

.btn-group__items-wrapper--column > .btn-group__item:first-child {
  margin-top: 0;
}

.btn-group__items-wrapper--column > .btn-group__item:last-child {
  margin-bottom: 0;
}

.btn-group__items-wrapper--column-fill {
  flex-basis: 100%;
}

.btn-group__item {
  flex-basis: fit-content;
  flex-grow: 1;
  margin: 0 4px;
}
