.m-icon-button {
  --iconButton-size: 44px;
  --iconButton-icon-size: 16px;
  font-size: var(--iconButton-icon-size);
  max-height: var(--iconButton-size);
  max-width: var(--iconButton-size);
  min-height: var(--iconButton-size);
  min-width: var(--iconButton-size);
  padding: unset;
}

.m-icon-button--size-s {
  --iconButton-size: 32px;
  --iconButton-icon-size: 14px;
}

.m-icon-button--size-l {
  --iconButton-size: 48px;
  --iconButton-icon-size: 18px;
}
