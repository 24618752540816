.tooltip-wrapper {
  z-index: 99;
  background: #fff;
  border: 1px solid #d1d5db;
  border-radius: .2857em;
  min-width: 20em;
  max-width: 22.8571em;
  display: block;
  position: absolute;
  top: calc(100% + 1em);
  right: -1em;
  box-shadow: 0 2px 10px rgba(0, 0, 0, .18);
}

.tooltip-wrapper:before {
  content: "";
  z-index: 999;
  background: #fff;
  border: 1px solid #d1d5db;
  width: 1.5em;
  height: 1.5em;
  position: absolute;
  top: -.2857em;
  right: 1em;
  transform: rotate(45deg);
  box-shadow: 0 2px 10px rgba(0, 0, 0, .18);
}

@media (max-width: 600px) {
  .tooltip-wrapper {
    min-width: 20vh;
    max-width: 30vh;
  }
}

.tooltip-wrapper--right {
  top: -1em;
}

.tooltip-wrapper--right:before {
  top: 1em;
  left: -.2875em;
  transform: rotate(-45deg);
}

.tooltip-wrapper--hidden {
  display: none;
}

.tooltip-content {
  z-index: 9999;
  background: #fff;
  border-radius: .2857em;
  max-height: 50vh;
  padding: 1em;
  position: relative;
  overflow: auto;
}
