.a-form-input {
  flex-flow: column;
  flex: auto;
  margin: 0 1em 0 0;
  display: flex;
}

.a-form-input:last-child {
  margin: 0;
}

.a-form-input__label {
  margin: 0 0 .5em;
  font-weight: bold;
}

.a-form-input__label:empty {
  margin: 0;
}

.a-form-input__label-wrapper {
  justify-content: space-between;
  margin: 0 0 .25em;
  display: flex;
}

.a-form-input__label-wrapper .a-form-input__label, .a-form-input__label-wrapper:empty {
  margin: 0;
}

.a-form-input--required .a-form-input__label:after {
  color: #dc4538;
  content: "*";
}

.a-form-input__element {
  -webkit-appearance: none;
  -moz-appearance: none;
  background-color: #fff;
  border: 2px solid #e5e7eb;
  border-radius: 3px;
  height: 2.5rem;
  padding: 0 .5em;
  font-family: Lato, Helvetica, Arial, sans-serif;
  font-size: 1em;
  transition: border 50ms ease-in-out;
}

.a-form-input__element:focus, .a-form-input__element:hover {
  transition: border 50ms ease-in-out;
}

.a-form-input__element:focus {
  border-color: #a8d4f0;
  outline-width: 0;
}

.a-form-input__element:hover {
  border-color: #a8d4f0;
}

.a-form-input__element:disabled {
  cursor: not-allowed;
  opacity: .5;
}

.a-form-input__element:disabled:hover {
  border-color: #e5e7eb;
}

.a-form-input__element:disabled {
  opacity: .5;
}

.a-form-input__static-element {
  background-color: #f9fafb;
  border-radius: 3px;
  padding: .5em;
}

textarea.a-form-input__element {
  height: 6em;
  padding-top: .5em;
  padding-bottom: .5em;
}

.a-form-input__element--error, .a-form-input__element--error:hover {
  border-color: #dc4538;
}

.a-form-input__error, .a-form-input__note {
  margin: .25em 0 0;
  font-size: .875em;
}

.a-form-input__error {
  color: #dc4538;
}
