.join-code-form__modal__btn-group {
  margin: 40px -.25em -.25em;
  display: flex;
}

.join-code-form__modal__btn-group > :first-child {
  margin-left: auto;
}

.join-code-form__modal__btn-group__btn {
  margin: .25em;
}

@media (max-width: 780px) {
  .join-code-form__modal__btn-group {
    flex-direction: column;
  }

  .join-code-form__modal__btn-group > :first-child {
    margin-left: .25em;
  }

  .join-code-form__modal__btn-group__btn--cancel {
    order: 999;
  }
}
