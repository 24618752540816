.submit-for-review-modal__checklist {
  margin: 0 0 10px;
  padding-left: 30px;
  list-style-type: none;
}

.submit-for-review-modal__checklist-item {
  margin-bottom: 10px;
}

.submit-for-review-modal__checklist-item .fa {
  color: #059669;
  font-size: 1.3em;
  position: absolute;
  top: 50%;
  right: calc(100% + 10px);
  transform: translateY(-50%)translateY(1px);
}
