.sortable-table th {
  cursor: pointer;
}

.sortable-table th:after {
  margin-left: .5em;
  font-family: "Font Awesome 6 Pro";
  display: inline-block;
}

.sortable-table [aria-sort="ascending"]:after {
  content: "";
}

.sortable-table [aria-sort="descending"]:after {
  content: "";
}
