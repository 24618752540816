.progress-meter {
  appearance: none;
  color: #50a9e2;
  background: #d1d5db;
  border: 0;
  border-radius: .25em;
  width: 100%;
  min-width: 7em;
  max-width: 22em;
  height: .5em;
  font-size: .875rem;
  overflow: hidden;
}

.progress-meter::-webkit-progress-bar {
  background: #d1d5db;
  border-radius: .5em;
}

.progress-meter::-webkit-progress-value {
  will-change: width, background-color;
  background: #50a9e2;
  transition: width .2s cubic-bezier(.23, 1, .32, 1), background-color .2s cubic-bezier(.23, 1, .32, 1);
}

.progress-meter::-moz-progress-bar {
  background: #50a9e2;
  border-radius: .5em;
}

.progress-meter--medium {
  border-radius: .5em;
  height: 1em;
}

.progress-meter--large {
  border-radius: 1em;
  height: 2em;
}

.progress-meter--blue {
  color: #50a9e2;
}

.progress-meter--blue::-webkit-progress-value {
  background: #50a9e2;
}

.progress-meter--blue::-moz-progress-bar {
  background: #50a9e2;
}

.progress-meter--green {
  color: #059669;
}

.progress-meter--green::-webkit-progress-value {
  background: #059669;
}

.progress-meter--green::-moz-progress-bar {
  background: #059669;
}

.progress-meter--red {
  color: #e36e63;
}

.progress-meter--red::-webkit-progress-value {
  background: #e36e63;
}

.progress-meter--red::-moz-progress-bar {
  background: #e36e63;
}

.progress-meter--orange {
  color: #f0bf6c;
}

.progress-meter--orange::-webkit-progress-value {
  background: #f0bf6c;
}

.progress-meter--orange::-moz-progress-bar {
  background: #f0bf6c;
}

.progress-meter--gray {
  color: #6b7280;
}

.progress-meter--gray::-webkit-progress-value {
  background: #6b7280;
}

.progress-meter--gray::-moz-progress-bar {
  background: #6b7280;
}

.progress-meter--purple {
  color: #9d63cf;
}

.progress-meter--purple::-webkit-progress-value {
  background: #9d63cf;
}

.progress-meter--purple::-moz-progress-bar {
  background: #9d63cf;
}

.progress-meter--passing {
  color: #f0bf6c;
}

.progress-meter--passing::-webkit-progress-value {
  background: #f0bf6c;
}

.progress-meter--passing::-moz-progress-bar {
  background: #f0bf6c;
}

.progress-meter--proficient {
  color: #10b981;
}

.progress-meter--proficient::-webkit-progress-value {
  background: #10b981;
}

.progress-meter--proficient::-moz-progress-bar {
  background: #10b981;
}

.progress-meter--excelling {
  color: #50a9e2;
}

.progress-meter--excelling::-webkit-progress-value {
  background: #50a9e2;
}

.progress-meter--excelling::-moz-progress-bar {
  background: #50a9e2;
}

.progress-meter--mastery {
  color: #9d63cf;
}

.progress-meter--mastery::-webkit-progress-value {
  background: #9d63cf;
}

.progress-meter--mastery::-moz-progress-bar {
  background: #9d63cf;
}

.ie-stats-position-helper {
  width: 11em;
}

@media (max-width: 900px) {
  .ie-stats-position-helper {
    display: none;
  }
}

.ie-stats-wrapper {
  padding: 2em 1em 0 0;
  position: fixed;
  top: 120px;
  left: 100%;
}

.ie-stats-stat {
  flex-flow: column;
  margin: 0 0 1em;
  display: flex;
}

.ie-stats-stat__header {
  margin-bottom: .5em;
}

.ie-stats-stat__header-title {
  margin: 0 .25em 0 0;
  font-weight: bold;
  display: inline-block;
}

.ie-stats-stat__questions-answered {
  white-space: nowrap;
}
