.hard-cap-warning__error {
  color: #cc3224;
  background-color: #fbefef;
  padding: 1rem;
}

.hard-cap-warning__warn {
  color: #b45309;
  background-color: #fef3c7;
  padding: 1rem;
}

.hard-cap-warning__round {
  border-radius: 8px;
}

.hard-cap-warning__dismiss-error-btn {
  margin-left: auto;
  font-weight: bold;
  text-decoration: underline;
}

.hard-cap-warning__dismiss-warn-btn {
  color: #d97706;
  margin-left: auto;
  font-weight: bold;
  text-decoration: underline;
}
