.a-checkbox {
  --checkbox-background-color--checked: var(--background-brand);
  --checkbox-background-color--unchecked: var(--background-subtle);
  --checkbox-background-color--hover-checked: var(--background-brand-hover);
  --checkbox-background-color--hover-unchecked: var(--background-subtle-hover);
  --checkbox-background-color--active-checked: var(--background-brand-pressed);
  --checkbox-background-color--active-unchecked: var(--background-subtle-pressed);
  --checkbox-background-color--disabled-checked: var(--color-brand-300);
  --checkbox-border-color--unchecked: var(--border-primary);
  --checkbox-border-color--checked: var(--checkbox-background-color--checked);
  --checkbox-border-color--hover-checked: var(--checkbox-background-color--hover-checked);
  --checkbox-border-color--hover-unchecked: var(--border-primary);
  --checkbox-border-color--active-checked: var(--checkbox-background-color--active-checked);
  --checkbox-border-color--active-unchecked: var(--checkbox-background-color--unchecked);
  --checkbox-border-color--disabled-checked: var(--checkbox-background-color--disabled-checked);
  --checkbox-border-color--disabled-unchecked: var(--border-subtle);
  --checkbox-icon-color--unchecked: transparent;
  --checkbox-icon-color--checked: var(--content-primary-inverse-static);
  --checkbox-icon-color--disabled-checked: var(--content-primary-inverse-static);
  --checkbox-border-radius: 8px;
  --checkbox-size: 32px;
  --checkbox-icon-font-size: 16px;
  display: inline-flex;
}

.a-checkbox__input {
  cursor: pointer;
  opacity: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  margin: 0;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.a-checkbox__display {
  background-color: var(--checkbox-background-color--unchecked);
  border: 1px solid var(--checkbox-border-color--unchecked);
  border-radius: var(--checkbox-border-radius);
  color: var(--checkbox-icon-color--unchecked);
  height: var(--checkbox-size);
  width: var(--checkbox-size);
  justify-content: center;
  align-items: center;
  display: inline-flex;
}

.a-checkbox__icon {
  font-size: var(--checkbox-icon-font-size);
}

.a-checkbox .a-checkbox__input:checked ~ .a-checkbox__display, .a-checkbox .a-checkbox__input ~ .a-checkbox__display--indeterminate {
  background-color: var(--checkbox-background-color--checked);
  border-color: var(--checkbox-border-color--checked);
  color: var(--checkbox-icon-color--checked);
}

.a-checkbox:hover .a-checkbox__input:not(:checked):not(:disabled) ~ .a-checkbox__display, .a-checkbox:hover .a-checkbox__input:not(:checked):not(:disabled) .a-checkbox__display--indeterminate {
  background-color: var(--checkbox-background-color--hover-unchecked);
  border-color: var(--checkbox-border-color--hover-unchecked);
}

.a-checkbox:hover .a-checkbox__input:checked:not(:disabled) ~ .a-checkbox__display, .a-checkbox:hover .a-checkbox__input:checked:not(:disabled) .a-checkbox__display--indeterminate {
  background-color: var(--checkbox-background-color--hover-checked);
  border-color: var(--checkbox-border-color--hover-checked);
}

.a-checkbox:active .a-checkbox__input:not(:checked):not(:disabled) ~ .a-checkbox__display, .a-checkbox:active .a-checkbox__input:not(:checked):not(:disabled) .a-checkbox__display--indeterminate {
  background-color: var(--checkbox-background-color--active-unchecked);
  border-color: var(--checkbox-border-color--active-unchecked);
}

.a-checkbox:active .a-checkbox__input:checked:not(:disabled) ~ .a-checkbox__display, .a-checkbox:active .a-checkbox__input:checked:not(:disabled) .a-checkbox__display--indeterminate {
  background-color: var(--checkbox-background-color--active-checked);
  border-color: var(--checkbox-border-color--active-checked);
}

.a-checkbox:active .a-checkbox__input:not(:disabled) ~ .a-checkbox__display, .a-checkbox:active .a-checkbox__input:not(:disabled) .a-checkbox__display--indeterminate {
  transform: scale(.9);
}

.a-checkbox:active .a-checkbox__input:not(:disabled) ~ .a-checkbox__display .a-checkbox__icon, .a-checkbox:active .a-checkbox__input:not(:disabled) .a-checkbox__display--indeterminate .a-checkbox__icon {
  transform: scale(.7);
}

.a-checkbox .a-checkbox__input:not(:disabled):focus ~ .a-checkbox__display {
  outline: 3px solid #50a9e2;
}

.a-checkbox .a-checkbox__input:not(:disabled):focus:not(:focus-visible) ~ .a-checkbox__display {
  outline: none;
}

.a-checkbox--disabled {
  cursor: not-allowed;
}

.a-checkbox--disabled .a-checkbox__input ~ .a-checkbox__display {
  border-color: var(--checkbox-border-color--disabled-unchecked);
}

.a-checkbox--disabled .a-checkbox__input:checked:disabled ~ .a-checkbox__display {
  background-color: var(--checkbox-background-color--disabled-checked);
  border-color: var(--checkbox-border-color--disabled-checked);
  color: var(--checkbox-icon-color--disabled-checked);
}

.a-checkbox--disabled * {
  cursor: inherit;
}

.a-checkbox__display--size-s {
  --checkbox-size: 24px;
  --checkbox-border-radius: 5px;
}

.a-checkbox__icon--size-s {
  --checkbox-icon-font-size: 14px;
}

.a-checkbox__display--round {
  --checkbox-border-radius: 100%;
}

.a-checkbox__input:checked ~ .a-checkbox__display .a-checkbox__icon {
  animation: .15s cubic-bezier(.25, .4, .65, 1.9) cubic;
}

@keyframes cubic {
  0%, 20% {
    transform: scale(0);
  }

  80%, 100% {
    transform: scale(1);
  }
}
