.reports-table {
  padding-left: var(--reports-table-padding-inline);
  padding-right: var(--reports-table-padding-inline);
}

.reports-landing-page {
  background: var(--background-subtle);
  min-height: calc(100vh - 60px);
}

.reports-landing-page__header {
  border-bottom: 1px solid #e5e7eb;
  padding: 16px 32px;
}

.reports-filter__dropdown {
  z-index: 9999;
  position: absolute;
}

.reports-filter__dropdown-tray {
  z-index: 1;
  margin-top: 8px;
}

.reports-filter__dropdown--subjects--loading {
  height: 477px;
}

.reports-filter__dropdown--closed {
  display: none;
}

.reports-filter__dropdown--open {
  margin-top: 8px;
  display: block;
}

.reports-filter__date {
  width: max-content;
}

.reports-filter__dropdown--subjects {
  width: 464px;
}

.reports_submission-badge {
  padding: 4px 12px;
  font-size: 14px;
  font-weight: bold;
}
