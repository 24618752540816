:root {
  --color-neutral-0: #fff;
  --color-neutral-50: #f9f9fb;
  --color-neutral-100: #f3f4f6;
  --color-neutral-150: #ecedf1;
  --color-neutral-200: #e5e7eb;
  --color-neutral-250: #dbdee3;
  --color-neutral-300: #d1d5db;
  --color-neutral-350: #b7bcc5;
  --color-neutral-400: #9ca3af;
  --color-neutral-450: #838a97;
  --color-neutral-500: #777e8b;
  --color-neutral-550: #6b7280;
  --color-neutral-600: #4b5563;
  --color-neutral-650: #374151;
  --color-neutral-700: #2b3544;
  --color-neutral-750: #1f2937;
  --color-neutral-800: #1a2331;
  --color-neutral-850: #151c2b;
  --color-neutral-900: #0f1624;
  --color-neutral-950: #0e1421;
  --color-neutral-1000: #0a0f1a;
  --color-brand-50: #ebf5fa;
  --color-brand-100: #d3eaf8;
  --color-brand-200: #a8d4f0;
  --color-brand-300: #7cbfe9;
  --color-brand-400: #50a9e2;
  --color-brand-500: #1e7cb8;
  --color-brand-600: #1a699e;
  --color-brand-700: #155884;
  --color-brand-800: #104060;
  --color-brand-900: #0b2f46;
  --color-red-50: #fbefef;
  --color-red-100: #f7d7d4;
  --color-red-200: #eb968e;
  --color-red-300: #e36e63;
  --color-red-400: #dc4538;
  --color-red-500: #cc3224;
  --color-red-600: #9c251c;
  --color-red-700: #821f17;
  --color-red-800: #5f1711;
  --color-red-900: #45110c;
  --color-yellow-50: #fffbeb;
  --color-yellow-100: #fef3c7;
  --color-yellow-200: #fde68a;
  --color-yellow-300: #fcd34d;
  --color-yellow-400: #fbbf24;
  --color-yellow-500: #f59e0b;
  --color-yellow-600: #d97706;
  --color-yellow-700: #b45309;
  --color-yellow-800: #92400e;
  --color-yellow-900: #78350f;
  --color-green-50: #ecfdf5;
  --color-green-100: #d1fae5;
  --color-green-200: #a7f3d0;
  --color-green-300: #6ee7b7;
  --color-green-400: #34d399;
  --color-green-500: #10b981;
  --color-green-600: #059669;
  --color-green-700: #047857;
  --color-green-800: #065f46;
  --color-green-900: #064e3b;
  --color-blue-50: #ebf5fa;
  --color-blue-100: #d3eaf8;
  --color-blue-200: #a8d4f0;
  --color-blue-300: #7cbfe9;
  --color-blue-400: #50a9e2;
  --color-blue-500: #1e7cb8;
  --color-blue-600: #1a699e;
  --color-blue-700: #155884;
  --color-blue-800: #104060;
  --color-blue-900: #0b2f46;
  --color-purple-50: #f8f3fb;
  --color-purple-100: #e7d8f3;
  --color-purple-200: #ceb1e7;
  --color-purple-300: #b68adb;
  --color-purple-400: #9d63cf;
  --color-purple-500: #7d39b7;
  --color-purple-600: #652e94;
  --color-purple-700: #502475;
  --color-purple-800: #3a1b56;
  --color-purple-900: #2b133e;
  --color-data-purple-100: #f9d3ff;
  --color-data-purple-200: #e1a5ea;
  --color-data-purple-500: #a639b7;
  --color-data-purple-700: #752482;
  --color-data-green-100: #c9f797;
  --color-data-green-200: #a4e35f;
  --color-data-green-500: #61ab10;
  --color-data-green-700: #3f6f09;
  --color-data-blue-100: #d8d9fb;
  --color-data-blue-200: #9499eb;
  --color-data-blue-500: #6a71e5;
  --color-data-blue-700: #3039e3;
  --color-data-orange-100: #ffd9c8;
  --color-data-orange-200: #ffb08c;
  --color-data-orange-500: #ff7c43;
  --color-data-orange-700: #bd4714;
  --color-data-red-100: #ffcbdf;
  --color-data-red-200: #ff8bb8;
  --color-data-red-500: #d8286b;
  --color-data-red-700: #a20e46;
  --color-legacy-social-studies-50: #fcf1ec;
  --color-legacy-social-studies-100: #fae4db;
  --color-legacy-social-studies-200: #f4c1ae;
  --color-legacy-social-studies-300: #ef9f80;
  --color-legacy-social-studies-400: #e97d53;
  --color-legacy-social-studies-500: #e25a26;
  --color-legacy-social-studies-600: #c24819;
  --color-legacy-social-studies-700: #873211;
  --color-legacy-social-studies-800: #712a0f;
  --color-legacy-social-studies-900: #481a09;
  --color-legacy-world-language-50: #f8ebf0;
  --color-legacy-world-language-100: #f1d2dd;
  --color-legacy-world-language-200: #e4acc0;
  --color-legacy-world-language-300: #d885a2;
  --color-legacy-world-language-400: #cc5e85;
  --color-legacy-world-language-500: #bb3c69;
  --color-legacy-world-language-600: #9c3258;
  --color-legacy-world-language-700: #7d2846;
  --color-legacy-world-language-800: #5e1e35;
  --color-legacy-world-language-900: #3f1424;
  --color-legacy-computer-science-50: #fdf6eb;
  --color-legacy-computer-science-100: #fbe5c4;
  --color-legacy-computer-science-200: #f8c881;
  --color-legacy-computer-science-300: #f5b350;
  --color-legacy-computer-science-400: #f29a16;
  --color-legacy-computer-science-500: #d5840b;
  --color-legacy-computer-science-600: #ae6c09;
  --color-legacy-computer-science-700: #915a07;
  --color-legacy-computer-science-800: #6a4205;
  --color-legacy-computer-science-900: #4d3004;
  --color-legacy-math-50: #ecf9fc;
  --color-legacy-math-100: #c8eff8;
  --color-legacy-math-200: #89ddf0;
  --color-legacy-math-300: #40c9e7;
  --color-legacy-math-400: #1aafd0;
  --color-legacy-math-500: #1489a3;
  --color-legacy-math-600: #1489a3;
  --color-legacy-math-700: #117287;
  --color-legacy-math-800: #0c5463;
  --color-legacy-math-900: #093d48;
  --color-legacy-science-50: #f1eff9;
  --color-legacy-science-100: #d7d1ef;
  --color-legacy-science-200: #a89cdc;
  --color-legacy-science-300: #7d6bcb;
  --color-legacy-science-400: #6049bf;
  --color-legacy-science-500: #553fb5;
  --color-legacy-science-600: #402f87;
  --color-legacy-science-700: #352771;
  --color-legacy-science-800: #271d53;
  --color-legacy-science-900: #1c153c;
  --color-legacy-ela-50: #f9f0f9;
  --color-legacy-ela-100: #eed2ee;
  --color-legacy-ela-200: #da9eda;
  --color-legacy-ela-300: #c86ec8;
  --color-legacy-ela-400: #bb4dbb;
  --color-legacy-ela-500: #9b3a9b;
  --color-legacy-ela-600: #853285;
  --color-legacy-ela-700: #6f296f;
  --color-legacy-ela-800: #511e51;
  --color-legacy-ela-900: #3b163b;
  --color-legacy-other-50: #f9f5f0;
  --color-legacy-other-100: #eee1d2;
  --color-legacy-other-200: #dbbe9e;
  --color-legacy-other-300: #c99e6d;
  --color-legacy-other-400: #bd884b;
  --color-legacy-other-500: #b37e41;
  --color-legacy-other-600: #865e31;
  --color-legacy-other-700: #6f4f29;
  --color-legacy-other-800: #52391e;
  --color-legacy-other-900: #3b2a15;
  --text-xs: 12px;
  --text-s: 14px;
  --text-m: 16px;
  --text-l: 18px;
  --text-xl: 20px;
  --text-xxl: 24px;
  --heading-2xs: 14px;
  --heading-xs: 16px;
  --heading-s: 20px;
  --heading-m: 24px;
  --heading-l: 28px;
  --heading-xl: 34px;
  --space-x0: 0px;
  --space-x0-quarter: 2px;
  --space-x0-half: 4px;
  --space-x1: 8px;
  --space-x1-quarter: 10px;
  --space-x1-half: 12px;
  --space-x2: 16px;
  --space-x2-half: 20px;
  --space-x3: 24px;
  --space-x4: 32px;
  --space-x5: 40px;
  --space-x6: 48px;
  --space-x6-quarter: 50px;
  --space-x7: 56px;
  --space-x8: 64px;
  --space-x9: 72px;
  --space-x10: 80px;
}

.question-loading-wrapper {
  justify-content: center;
  align-items: center;
  height: 100%;
  font-size: 32px;
  display: flex;
}

.question-component-resizer {
  background: var(--color-neutral-0);
  border: 1px solid var(--color-neutral-300);
  cursor: grab;
  z-index: 9;
  border-radius: 8px;
  align-self: center;
  place-items: center;
  width: 24px;
  height: 32px;
  margin: 0 -12px;
  transition: background-color .2s ease-in-out, height .2s ease-in-out, border-radius .2s ease-in-out;
  display: grid;
}

.question-component-resizer:hover {
  background: var(--color-blue-100);
  border: 0px solid var(--color-neutral-300);
  border-radius: 0;
  height: 100%;
  margin: 0 -12px;
}

.question-component-resizer:active {
  background: var(--color-blue-200);
  cursor: grabbing;
  transition: none;
}

.question-component-resizer-mobile {
  z-index: 9;
  background: none;
  border: none;
  justify-content: flex-end;
  align-self: center;
  align-items: center;
  width: 100%;
  height: 32px;
  margin-bottom: -44px;
  padding: 0 16px;
  display: flex;
}

.resizer-grabber:before {
  color: var(--color-blue-500);
  content: "";
  font: var(--fa-font-solid);
  -webkit-font-smoothing: antialiased;
  text-rendering: auto;
  font-family: "Font Awesome 6 Pro";
  display: inline-block;
}

.question-split-pane {
  flex-direction: column;
  flex-grow: 1;
  display: flex;
  overflow: hidden;
}

.question-split-pane .question-wrapper__body {
  margin: unset;
  max-width: unset;
  overscroll-behavior: contain;
  flex: 1;
  overflow-y: auto;
}

.footer {
  background-color: rgba(0, 0, 0, 0);
  margin-top: 192px;
}

.footer-legal {
  opacity: 1;
  padding: 16px;
  font-weight: normal;
}

.footer-legal p {
  color: var(--color-neutral-400);
  font-size: 12px;
}

.question-wrapper.instructions-pane {
  border-right: none;
  border-radius: 8px 0 0;
}

.answer-options-pane {
  border-radius: 0 8px 0 0;
}

.question-wrapper__heading .a-list-group-item {
  border-bottom: 1px solid var(--color-neutral-300);
}

.question-wrapper__heading.question-wrapper__heading--answer-options-mobile .a-list-group-item {
  border-top: 1px solid var(--color-neutral-300);
}

@media (max-width: 780px) {
  .split-content-wrapper {
    padding: 8px 8px 0;
  }

  .split-content-wrapper .Pane2 {
    box-shadow: 0 -6px 10px rgba(0, 0, 0, .1);
  }

  .question-wrapper.instructions-pane {
    border-radius: 8px 8px 0 0;
  }
}

.horizontal-layout__instructions-card {
  border: 1px solid var(--color-neutral-200) !important;
  border-bottom: none !important;
  border-radius: 8px 8px 0 0 !important;
}

.horizontal-layout__answer-options-card {
  border-radius: 0;
  border: 1px solid var(--color-neutral-200) !important;
  border-top: none !important;
}
