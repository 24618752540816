.split-card__section {
  box-shadow: none;
  border-top: 0;
  border-left: 0;
  border-right: 0;
}

.split-card__section:not(:first-child) {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.split-card__section:not(:last-child) {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.split-card__section:last-child {
  border-bottom: 0;
}
