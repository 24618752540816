.select-account-type {
  background: #fff;
  flex-direction: column;
  flex: 1 0 0;
  align-self: stretch;
  align-items: center;
  gap: 40px;
  padding: 75px 32px;
  display: flex;
}

.select-account-type__container {
  flex-direction: column;
  align-items: center;
  gap: 24px;
  display: flex;
}

.select-account-type__cards {
  align-items: flex-start;
  gap: 24px;
  width: 430px;
  display: flex;
}

.select-account-type__card {
  cursor: pointer;
  border: 1px solid #d1d5db;
  border-radius: 8px;
  flex-direction: column;
  flex: 1 0 0;
  align-self: stretch;
  align-items: center;
  gap: 16px;
  padding: 32px 16px 24px;
  display: flex;
}

.select-account-type__card:hover {
  border-color: rgba(0, 0, 0, 0);
  box-shadow: 0 0 0 4px #d1d5db;
}

.select-account-type__card--selected {
  background-color: #ebf5fa;
  border-color: rgba(0, 0, 0, 0);
  box-shadow: 0 0 0 4px #1e7cb8;
}

.select-account-type__card--selected:hover {
  box-shadow: 0 0 0 4px #1e7cb8;
}

.select-account-type__card--selected .select-account-type__icon-wrapper {
  color: #1e7cb8;
  background: #d3eaf8;
}

.select-account-type__card--selected .select-account-type__icon {
  color: #1e7cb8;
}

.select-account-type__details {
  flex-direction: column;
  align-self: stretch;
  align-items: center;
  gap: 24px;
  display: flex;
}

.select-account-type__button {
  justify-content: center;
  align-items: center;
  gap: 10px;
  height: 44px;
  padding: 10px 16px;
  display: flex;
}

.select-account-type__icon-wrapper {
  background: #f3f4f6;
  border-radius: 108px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 12px;
  width: 60px;
  height: 60px;
  padding: 12px;
  display: flex;
}

.select-account-type__icon {
  color: #6b7280;
  font-size: 27px;
}
