.summary-stats {
  background-color: var(--background-primary);
  border: 1px solid var(--border-primary);
  border-radius: var(--space-x1);
  margin-bottom: var(--space-x2);
  margin-left: var(--reports-table-padding-inline);
  margin-right: var(--reports-table-padding-inline);
  padding: var(--space-x2);
  flex-wrap: wrap;
  justify-items: center;
}

@media (max-width: 600px) {
  .summary-stats {
    flex-direction: column;
  }
}
