.submit-section-modal__body {
  text-align: center;
  flex-direction: column;
  align-items: center;
  display: flex;
}

.submit-section-modal__warn {
  color: #f0bf6c;
  margin-bottom: 20px;
  font-size: 64px;
}

.submit-section-modal__buttons-wrapper {
  flex-direction: column;
  margin-top: 20px;
  display: inline-flex;
}

.submit-section-modal__button, .submit-section-modal__button:not(:last-child) {
  margin: 0 0 10px;
}

.submit-section-modal__button--leave {
  padding: 5px;
}
