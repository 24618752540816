.subject-menu-header__content-wrapper {
  color: #fff;
  text-shadow: 0 1px 5px rgba(0, 0, 0, .25);
  z-index: 1;
  background: #1e7cb8;
  align-items: center;
  padding: 24px;
  display: flex;
}

.subject-menu-header__content-wrapper * {
  margin: 0;
}

.subject-menu-header__search {
  color: #6b7280;
  background-color: #fff;
  border-radius: 10000px;
}

.subject-menu-header__text-container {
  max-width: 70ch;
  margin-right: 24px;
}

.subject-menu-header__text-container-subtext {
  color: #fff;
  text-shadow: 0 1px 5px rgba(0, 0, 0, .25);
  margin-top: 8px;
}

@media (max-width: 440px) {
  .subject-menu-header__content-wrapper {
    flex-direction: column;
  }

  .subject-menu-header__search {
    width: 100%;
    margin-top: 24px;
  }
}
