.o-table__cell {
  color: #1f2937;
  flex-wrap: wrap;
  flex: 120px;
  align-items: center;
  min-width: 120px;
  padding: 8px 16px;
  font-size: 14px;
}

.o-table__cell--left {
  justify-content: flex-start;
}

.o-table__cell--center {
  justify-content: center;
}

.o-table__cell--right {
  justify-content: flex-end;
}

.o-table__selection-cell {
  flex: 0 auto;
  min-width: auto;
  padding-right: 0;
}

.o-table__action-menu-cell {
  min-width: auto;
  max-width: 56px;
}

.o-table__cell--primary .o-table__cell-text {
  color: rgba(0, 0, 0, .78);
  font-weight: bold;
}

.o-table__cell:not(.o-table__action-menu-cell):not(.o-table__cell--no-clamp) .o-table__cell-text {
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
}

.o-table__cell:not(.o-table__action-menu-cell) .o-table__sub-value {
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  width: 100%;
  display: -webkit-box;
  overflow: hidden;
}

.o-table--data .o-table__cell {
  padding: 8px 12px;
}

.o-table--data .o-table__cell:not(:last-child), .o-table--basic .o-table__cell:not(:last-child) {
  border-right: 1px solid #d1d5db;
}
