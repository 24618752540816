.a-text {
  background-color: rgba(0, 0, 0, 0);
  border: 0;
  outline: 0;
  padding: 0;
}

.a-text--size-xl {
  font-size: 1.375rem;
}

.a-text--size-l {
  font-size: 1.125rem;
}

.a-text--size-regular {
  font-size: 1rem;
}

.a-text--size-m {
  font-size: .9375rem;
}

.a-text--size-s {
  font-size: .875rem;
}

.a-text--size-xs {
  font-size: .8125rem;
}

.a-text--disabled {
  opacity: .32;
}

.a-text--monospace {
  font-family: Cousine, Courier, monospace;
}

.a-text--bold {
  font-weight: bold;
}

.a-text--paragraph {
  display: block;
}

.a-text--with-margin {
  margin-bottom: 2em;
}
