.a-list-group-item {
  border: 0;
  border-bottom: 1px solid var(--border-primary);
  background-color: rgba(0, 0, 0, 0);
  list-style-type: none;
}

.a-list-group-item--header {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.a-list-group-item--footer {
  border-bottom: 0;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
}

.a-list-group-item--align-center, .a-list-group-item--align-right {
  flex-direction: column;
  display: flex;
}

.a-list-group-item--align-center {
  align-items: center;
}

.a-list-group-item--align-right {
  align-items: flex-end;
}
