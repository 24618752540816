.confirm-leave-component-modal .simple-modal__window {
  max-width: 480px;
}

.confirm-leave-component-modal__actions {
  justify-content: flex-end;
  display: flex;
}

.confirm-leave-component-modal__actions button:not(:last-child) {
  margin-right: 1rem;
}
