.horizontal-navigation {
  height: 60px;
}

.horizontal-navigation__wrapper {
  color: #fff;
  justify-content: space-between;
  align-items: center;
  max-width: 1088px;
  text-decoration: none;
  display: flex;
}

.horizontal-navigation__content {
  color: #fff;
  flex-grow: 2;
}

.horizontal-navigation__links {
  text-align: center;
}

.horizontal-navigation__links-link {
  padding: .5em 1em;
  display: inline-block;
}

.horizontal-navigation__content--right {
  flex-grow: 1;
  justify-content: flex-end;
  display: flex;
}

.horizontal-navigation__content--left {
  flex-grow: 1;
}
