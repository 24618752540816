.blocky-date-picker {
  cursor: default;
}

.blocky-date-picker::selection {
  background-color: rgba(0, 0, 0, 0);
}

.blocky-date-picker ::selection {
  background-color: rgba(0, 0, 0, 0);
}

.blocky-date-picker--open .blocky-date-picker__selection {
  border-radius: .25em .25em 0 0;
}

.blocky-date-picker__label {
  margin-bottom: .25em;
  font-weight: bold;
}

.blocky-date-picker__input {
  -webkit-appearance: none;
  -moz-appearance: none;
  cursor: default;
  background-color: #fff;
  border: 2px solid #e5e7eb;
  border-radius: 3px;
  align-items: center;
  width: 100%;
  height: 2.5rem;
  padding: .8em 0 .8em .75em;
  font-family: Lato, Helvetica, Arial, sans-serif;
  font-size: 1em;
  transition: border 50ms ease-in-out;
  display: flex;
}

.blocky-date-picker__input:focus, .blocky-date-picker__input:hover {
  transition: border 50ms ease-in-out;
}

.blocky-date-picker__input:focus {
  border-color: #a8d4f0;
  outline-width: 0;
}

.blocky-date-picker__input:hover {
  border-color: #a8d4f0;
}

.blocky-date-picker__input:disabled {
  cursor: not-allowed;
  opacity: .5;
}

.blocky-date-picker__input:disabled:hover {
  border-color: #e5e7eb;
}

.blocky-date-picker__input--error, .blocky-date-picker__input--error:hover {
  border-color: #dc4538;
}

.blocky-date-picker--required .blocky-date-picker__label:after {
  color: #dc4538;
  content: "*";
}
