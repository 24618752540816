.class-subjects {
  margin: 0 32px;
}

@media (max-width: 780px) {
  .class-subjects {
    margin: 0 16px;
  }
}

.class-subjects__course-cards-container {
  grid-template-columns: repeat(auto-fill, minmax(296px, 1fr));
  gap: 32px;
  display: grid;
}

.class-subjects__subject-cards-container {
  flex-wrap: wrap;
  padding-top: 40px;
  display: flex;
}
