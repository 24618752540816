@keyframes m-toast-fade-in {
  from {
    opacity: 0;
    transform: translateY(24px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes m-toast-fade-out {
  from {
    opacity: 1;
    transform: translateY(0);
  }

  to {
    opacity: 0;
    transform: translateY(24px);
  }
}

.m-toast {
  border: 1px solid var(--border-primary);
  border-radius: var(--space-x1);
  box-shadow: var(--elevation-shadow-l2);
  color: var(--content-primary);
  max-width: calc(var(--space-x1) * 45);
  opacity: 1;
  padding: 0;
  transition: opacity .25s;
  animation: .25s m-toast-fade-in;
  overflow: hidden;
}

.m-toast.m-toast--dismissing {
  animation: .25s m-toast-fade-out;
}

.m-toast__content-wrapper {
  flex-grow: 1;
  padding: 16px;
}

.m-toast__title {
  margin-bottom: 8px;
}

.m-toast__description {
  margin-bottom: 0;
}

.m-toast__dismiss-btn-wrapper {
  padding: var(--space-x1-half) var(--space-x1-quarter) 0 0;
}

.m-toast__dismiss-btn {
  margin: 0;
}

@media screen and (max-width: 380px) {
  .m-toast {
    max-width: 100%;
  }
}

.m-toast__decorative-line {
  width: 100%;
  height: 4px;
}

.m-toast__icon-wrapper {
  min-width: var(--space-x8);
  text-align: center;
  width: var(--space-x8);
  align-content: center;
}

.m-toast__icon-divider {
  background-color: var(--border-subtle);
  height: var(--space-x6);
  width: 1px;
  margin-block: auto;
}

.m-toast__icon {
  font-size: var(--text-xxl);
}
