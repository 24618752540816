.secondary-header {
  color: #fff;
  background-color: #1e7cb8;
  padding: 0 3em;
  font-size: 14px;
  display: flex;
}

.secondary-header .blocky-dropdown {
  padding: 0;
}

.secondary-header a {
  color: #fff;
  text-decoration: none;
}

.secondary-header .classrooms-subheader__title, .secondary-header .blocky-dropdown__selection {
  color: #fff;
  margin-bottom: 0;
  font-weight: bold;
}

.secondary-header .classrooms-subheader__title:after, .secondary-header .blocky-dropdown__selection:after {
  color: #fff;
}

.secondary-header .ie-status-bar__dropdown-label {
  background: none;
}

@media (max-width: 900px) {
  .secondary-header {
    padding: 0 1em;
  }
}

@media (max-width: 600px) {
  .secondary-header {
    padding: 0;
  }
}

.secondary-header--fixed {
  z-index: 106;
  width: 100%;
  position: fixed;
  top: 60px;
  left: 0;
}

.secondary-header__wrapper {
  width: 100%;
  margin: 0 auto;
  padding: 0 .75rem;
}

@media (max-width: 900px) {
  .secondary-header__wrapper {
    align-items: center;
  }
}

.secondary-header__content--left {
  flex-grow: 0;
}

.secondary-header__content {
  align-items: center;
  display: flex;
}

.secondary-header__links-link-wrapper {
  color: #fff;
}

.secondary-header__links-link-wrapper .secondary-header__links-link-icon {
  display: none;
}

.secondary-header__links-link {
  cursor: pointer;
  text-align: left;
  text-transform: capitalize;
  white-space: nowrap;
  border-radius: 2.5em;
  margin-right: .7em;
  padding: .5em 1em;
  font-size: 14px;
  font-weight: bold;
  transition: background .2s;
}

.secondary-header__links-link:hover {
  background: #1a699e;
}

.secondary-header__links-link:not(:last-child) {
  margin-right: .625em;
}

.secondary-header__links-link-wrapper--active .secondary-header__links-link {
  background-color: #1a699e;
}

@media (max-width: 900px) {
  .secondary-header {
    font-size: .875em;
  }

  .secondary-header__links {
    flex-flow: column;
    display: flex;
  }

  .secondary-header__simple-dropdown-nav-wrapper, .secondary-header__simple-dropdown-wrapper {
    align-items: center;
    display: flex;
  }
}

@media (max-width: 900px) and (max-width: 900px) {
  .secondary-header__simple-dropdown-nav-wrapper .simple-dropdown__icon, .secondary-header__simple-dropdown-wrapper .simple-dropdown__icon {
    margin: 0 0 0 .2em;
  }
}

@media (max-width: 900px) {
  .secondary-header__simple-dropdown-nav-wrapper .simple-dropdown__input, .secondary-header__simple-dropdown-wrapper .simple-dropdown__input {
    background: #1a699e;
    border-radius: 2.5em;
    padding: .5em 1em;
  }

  .secondary-header__links--open .secondary-header__links-link-wrapper--active {
    position: relative;
  }

  .secondary-header__links--open .secondary-header__links-link-wrapper--active .secondary-header__links-link {
    text-align: center;
    border-radius: 50%;
    width: auto;
  }

  .secondary-header__links--open .secondary-header__links-link-wrapper--active .secondary-header__links-link:hover {
    background: none;
  }

  .secondary-header__links--open .secondary-header__links-link-wrapper--active .secondary-header__links-link-wrapper {
    display: block;
  }

  .secondary-header__links--open .secondary-header__links-link-wrapper--active .secondary-header__links-link-wrapper .secondary-header__links-link {
    text-align: center;
    border-radius: 0;
    width: 100%;
  }

  .secondary-header__links--open .secondary-header__links-link-wrapper--active .secondary-header__links-link-wrapper .secondary-header__links-link:hover {
    background: #e5e7eb;
  }

  .secondary-header__links--open .secondary-header__links-link-wrapper {
    color: #9ca3af;
    background: #fff;
    border: 1px solid #d1d5db;
    border-top: 0;
    display: none;
  }

  .secondary-header__links--open .secondary-header__links-link-wrapper--active {
    color: #fff;
    background: none;
    border: none;
    order: -1;
    display: block;
  }
}

.secondary-header__content--right {
  font-size: .875rem;
}
