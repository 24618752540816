.tertiary-header {
  background: #f3f4f6;
  margin-bottom: 1em;
  box-shadow: inset 0 -.2px #6b7280;
}

.tertiary-header.tertiary-header {
  height: auto;
  min-height: 60px;
}

.tertiary-header--fixed {
  z-index: 105;
  width: 100%;
  position: fixed;
  top: 120px;
  left: 0;
}

.tertiary-header__wrapper {
  max-width: none;
}

.tertiary-header__links {
  display: block;
}

.tertiary-header__wrapper {
  white-space: nowrap;
  padding: 0;
  overflow: auto;
}

.tertiary-header__links-link {
  color: #6b7280;
  cursor: pointer;
  margin: 0 1em;
  padding: 1.79em 0 1.43em;
  font-size: .875rem;
  font-weight: bold;
}

.tertiary-header__links-link:after {
  content: "";
  width: 0;
  height: 2px;
  margin: 0 auto;
  transition: width .25s ease-out;
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.tertiary-header__links-link-wrapper--active .tertiary-header__links-link {
  color: #50a9e2;
}

.tertiary-header__links-link-wrapper--active .tertiary-header__links-link:after {
  background-color: #50a9e2;
  width: 100%;
}

.tertiary-header__links-link-icon {
  display: none;
}

@media (max-width: 900px) {
  .tertiary-header {
    overflow-x: auto;
  }

  .tertiary-header__links-link-wrapper {
    padding-bottom: 0;
    text-decoration: none;
  }

  .tertiary-header__links-link {
    white-space: nowrap;
  }

  .tertiary-header__links {
    flex-direction: row;
    justify-content: center;
    align-items: center;
    display: flex;
  }

  .tertiary-header__content, .tertiary-header__content--right, .tertiary-header__content--left {
    width: auto;
  }
}

.tertiary-header__link-icon {
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  min-width: 1em;
  height: 1em;
  margin-left: .5em;
  margin-right: -.5em;
  display: inline-block;
  transform: translateY(2px)translateX(-12px);
}
