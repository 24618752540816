.attempt-filter_tray {
  border: 1px solid #e5e7eb;
  min-width: 176px;
  max-height: 50vh;
  padding: 8px 0;
  overflow-y: auto;
  box-shadow: 0 7px 20px rgba(0, 0, 0, .05);
}

.attempt-filter_dropdown-item {
  border-bottom: 1px solid #e5e7eb;
}

.attempt-filter_dropdown-item-content {
  justify-content: space-between;
  display: flex;
}
