.fitb-input-area {
  margin: 20px 10px;
}

.fitb-input-area-dropdown {
  overflow: visible;
}

.fitb-dropdown {
  cursor: default;
  margin: 0 .25em .5em;
  line-height: 1em;
  display: inline-block;
}

.fitb-dropdown__selection {
  color: rgba(55, 65, 81, .65);
  justify-content: space-between;
  align-items: center;
  padding: .25em .75em calc(.25em + 1px);
  display: inline-flex;
}

.fitb-dropdown__selection:before {
  content: "";
  border: 1px solid #d1d5db;
  border-radius: 6px;
  display: block;
  position: absolute;
  inset: 0;
}

.fitb-dropdown__selection .fa-caret-down {
  color: #374151;
  margin-left: 1em;
}

.fitb-dropdown__selection--post-submission:not(.fitb-dropdown__selection--is-explanation) {
  padding-right: 35px;
}

.fitb-dropdown__selection--has-selection {
  color: #374151;
}

.fitb-dropdown__selection--has-selection:before {
  border: 2px solid #50a9e2;
}

.fitb-dropdown__correctness-indicator {
  position: absolute;
  top: 50%;
  right: 5px;
  transform: translateY(-50%);
}

.fitb-choices {
  z-index: 99;
  min-width: 100%;
  display: block;
  position: absolute;
  top: calc(100% + 5px);
  left: 0;
}

.fitb-choices__choice {
  white-space: nowrap;
  background-color: #fff;
  border: 1px solid #d1d5db;
  border-bottom-width: 0;
  padding: .75em;
  display: block;
}

.fitb-choices__choice .MathJax {
  pointer-events: none;
}

.fitb-choices__choice:hover {
  background-color: #f9fafb;
}

.fitb-choices__choice:first-child {
  border-radius: 6px 6px 0 0;
}

.fitb-choices__choice:last-child {
  border-bottom-width: 1px;
  border-radius: 0 0 6px 6px;
}

.fitb-v2-explanation {
  margin-bottom: 1.5em;
}

.fitb-v2-explanation__answer {
  border-radius: 6px;
  margin: 0 .25em .5em;
  padding: .25em .75em calc(.25em + 1px);
  line-height: 1em;
  display: inline-block;
}

.fitb-v2-explanation__answer--correct {
  background-color: #d1fae5;
}

.fitb-v2-explanation__answer--incorrect {
  background-color: #fbefef;
}
