.loading-indicator {
  width: 100%;
  padding: 4em 0;
  font-size: .65rem;
}

.loading-indicator__circles-wrapper {
  justify-content: center;
  align-items: center;
  height: 4em;
  margin: 0 auto;
  animation-name: opacityFadeIn;
  animation-duration: .3s;
  animation-timing-function: cubic-bezier(.23, 1, .32, 1);
  animation-fill-mode: forwards;
  display: flex;
}

@keyframes opacityFadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.loading-indicator__circle {
  background-color: #9ca3af;
  border-radius: .5em;
  width: .5em;
  height: .5em;
  margin: .25em;
  animation-name: loading-indicator-animation;
  animation-duration: .75s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}

.loading-indicator__circle:first-child {
  animation-delay: -1.25s;
}

.loading-indicator__circle:nth-child(2) {
  animation-delay: -2.5s;
}

.loading-indicator__circle:nth-child(3) {
  animation-delay: -3.75s;
}

.loading-indicator__circle:nth-child(4) {
  animation-delay: -5s;
}

@keyframes loading-indicator-animation {
  0% {
    height: .5em;
  }

  100% {
    height: 3em;
  }
}

.loading-indicator.inline {
  width: auto;
  padding: 0;
  font-size: .65rem;
}

.loading-indicator.inline .loading-indicator__circle {
  border-radius: 50%;
  width: 5px;
  height: 5px;
  margin: .2em;
  animation-name: loading-indicator-animation-small;
}

@keyframes loading-indicator-animation-small {
  0% {
    transform: translateY(-.25em);
  }

  100% {
    transform: translateY(.25em);
  }
}
