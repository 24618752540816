.background-task__trigger--running {
  position: relative;
}

.background-task__trigger--running:before {
  content: "";
  text-align: center;
  z-index: 1;
  background-color: rgba(156, 163, 175, .75);
  position: absolute;
  inset: 0;
}

.background-task__trigger--running:after {
  color: #fff;
  content: "";
  z-index: 2;
  font-family: "Font Awesome 6 Pro";
  animation-name: background-task-spin;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%)translateY(-50%)rotate(0);
}

@keyframes background-task-spin {
  to {
    transform: translateX(-50%)translateY(-50%)rotate(360deg);
  }
}
