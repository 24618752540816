.interaction-engine-v2 {
  font-size: 14px;
}

.interaction-engine-v2:not(.interaction-engine-v2--allow-highlight)::-moz-selection {
  background-color: rgba(0, 0, 0, 0);
}

.interaction-engine-v2:not(.interaction-engine-v2--allow-highlight)::selection {
  background-color: rgba(0, 0, 0, 0);
}

.interaction-engine-v2:not(.interaction-engine-v2--allow-highlight) ::-moz-selection {
  background-color: rgba(0, 0, 0, 0);
}

.interaction-engine-v2:not(.interaction-engine-v2--allow-highlight) ::selection {
  background-color: rgba(0, 0, 0, 0);
}

.interaction-engine-v2__navigation-wrapper {
  background-color: #1e7cb8;
  justify-content: space-between;
  display: flex;
}

.interaction-engine-v2__subheader {
  z-index: 100;
  margin-bottom: 0;
  overflow: auto;
}

.interaction-engine-v2__wrapper {
  min-height: calc(100vh - 120px);
}

.interaction-engine-v2__active-question-area {
  margin-left: 320px;
}

.interaction-engine-v2__active-question-with-stats-area {
  justify-content: center;
  align-items: flex-start;
  min-height: 100vh;
  display: flex;
}

.interaction-engine__footer {
  margin-top: 3em;
}

.ie-mobile-nav {
  color: #fff;
  background-color: #1e7cb8;
  align-items: stretch;
  height: 40px;
  padding: 0 .5em;
  font-weight: bold;
  display: flex;
}

.ie-mobile-nav__back {
  cursor: pointer;
  align-items: center;
  display: flex;
}

.ie-mobile-nav__back .fa {
  margin-right: .5em;
  font-size: 1.2em;
}

.interaction-engine-v2--mobile .questions-list {
  max-width: 100%;
}

.interaction-engine-v2--mobile .interaction-engine-v2__active-question-area {
  z-index: 9;
  -webkit-overflow-scrolling: touch;
  width: 100%;
  height: 100%;
  margin-left: 0;
  transition: transform .3s;
  position: absolute;
  overflow: auto;
  transform: translateX(100%);
  box-shadow: 0 2px 2px rgba(0, 0, 0, .25);
}

.interaction-engine-v2--mobile .interaction-engine-v2__active-question-area--in-view {
  transform: translateX(0);
}

.interaction-engine-v2--mobile .interaction-engine-v2__active-question-with-stats-area {
  min-height: 100%;
}

.interaction-engine-v2--mobile .active-question-wrapper {
  min-height: calc(100vh - 120px);
  margin: 0;
}

.interaction-engine-v2--mobile .interaction-engine__footer {
  display: none;
}

.interaction-engine-v2__actions {
  align-items: center;
  padding: 0 1em;
  display: flex;
}

.interaction-engine-v2__button {
  -webkit-appearance: none;
  -moz-appearance: none;
  color: #fff;
  cursor: pointer;
  background-color: #34d399;
  border: 0;
  border-radius: .15em;
  margin: 0 1em 0 0;
  padding: .5em .75em;
  font-size: .75rem;
  font-weight: bold;
  text-decoration: none;
  transition: box-shadow .1s ease-in-out, transform .1s ease-in-out;
}

.interaction-engine-v2__button::selection {
  background-color: rgba(0, 0, 0, 0);
}

.interaction-engine-v2__button ::selection {
  background-color: rgba(0, 0, 0, 0);
}

.interaction-engine-v2__button.disabled, .interaction-engine-v2__button.disabled:hover, .interaction-engine-v2__button:disabled, .interaction-engine-v2__button:disabled:hover {
  color: #d1d5db;
  background-color: #6b7280;
}

.interaction-engine-v2__button:not(:disabled):not(:active):hover {
  transform: translateY(-1px)scale(1.01);
  box-shadow: 0 2px 9px rgba(0, 0, 0, .2);
}

.interaction-engine-v2__button:only-child {
  margin: 0;
}

.interaction-engine-v2__button:disabled, .interaction-engine-v2__button:disabled:hover {
  color: #fff;
  cursor: not-allowed;
  opacity: .5;
  background-color: #34d399;
}

.interaction-engine-v2__button--submit {
  margin: 0 0 0 .5em;
  padding: .4em .55em;
  font-size: 1.1em;
}

.interaction-engine-v2__assign-button--old-IE {
  margin: 0 .5em 1em 0;
}

.interaction-engine-v2__empty-template {
  text-align: center;
  background: #fff;
  align-self: flex-start;
  width: 100%;
  max-width: 830px;
  margin: 20px 30px;
  padding: 5em 1em;
}
