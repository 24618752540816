.written-submission__input {
  min-height: 320px;
  max-height: 70vh;
  position: "relative";
  border: 1px solid #d1d5db;
  border-radius: 8px;
  width: 100%;
  padding: 16px;
  overflow-y: auto;
}

.written-submission__input code {
  background-color: rgba(209, 213, 219, .3);
}

.written-submission__input-popup {
  z-index: 1;
  background-color: #fff;
  border: 1px solid #d1d5db;
  border-radius: 8px;
  align-items: center;
  gap: 8px;
  padding: 4px 8px;
  display: flex;
  position: absolute;
  left: 0;
  box-shadow: 0 2px 10px rgba(0, 0, 0, .18);
}

.written-submission__input-popup a {
  -webkit-user-select: none;
  user-select: none;
  border-right: 1px solid #d1d5db;
  align-items: center;
  gap: 5px;
  padding-right: 8px;
  display: flex;
}

.written-submission__toolbar {
  flex-wrap: wrap;
  align-items: center;
  gap: 8px;
  width: 100%;
  margin-bottom: 16px;
  display: flex;
}

.written-submission__toolbar .a-button {
  border-radius: 8px;
}

.written-submission__toolbar-dropdown {
  z-index: 100;
  flex-direction: column;
  display: flex;
  position: absolute;
  top: 100%;
  left: 0;
}

.written-submission__toolbar-group {
  align-items: center;
  gap: 8px;
  display: flex;
}

.written-submission__toolbar--divider {
  align-self: stretch;
  align-items: center;
  gap: 8px;
  padding: 8px 4px;
  display: flex;
}

.written-submission__toolbar--divider-inside {
  background: var(--border-subtle);
  width: 1px;
  height: 28px;
}

.written-submission__title {
  font-size: 24px;
  font-weight: 900;
}

.written-submission__heading {
  font-size: 20px;
  font-weight: 700;
}

.written-submission__subheading {
  font-size: 18px;
  font-weight: 600;
}

.written-submission__image-container {
  margin: auto;
  position: relative;
}

.written-submission__image-container:focus, .written-submission__image-container:hover {
  outline: none;
}

.written-submission__image-container:focus .written-submission__image, .written-submission__image-container:hover .written-submission__image {
  box-shadow: 0 0 0 3px #b4d5ff;
}

.written-submission__image {
  object-fit: cover;
  min-width: 100%;
  max-width: 100%;
  height: auto;
  margin: 0 auto;
  display: block;
}

.written-submission__image:focus, .written-submission__image:hover {
  box-shadow: 0 0 0 3px #b4d5ff;
}

.written-submission__image.rotate {
  transform-origin: 0 0;
  height: auto;
  transform: rotate(-90deg)translateX(-100%);
}

.written-submission__image-focused {
  box-shadow: 0 0 0 3px #b4d5ff;
}

.written-submission__image-popup {
  background-color: #fff;
  border: 1px solid #d1d5db;
  border-radius: 8px;
  display: flex;
  position: absolute;
  top: 2.5%;
  right: 2.5%;
  box-shadow: 0 1px 8px rgba(0, 0, 0, .07);
}

[data-slate-node="element"] {
  margin-top: 0;
  margin-bottom: 0;
}

.written-submission__indent-1 {
  margin-left: 20px;
}

.written-submission__indent-2 {
  margin-left: 40px;
}

.written-submission__indent-3 {
  margin-left: 60px;
}

.written-submission__indent-4 {
  margin-left: 80px;
}

.written-submission__indent-5 {
  margin-left: 100px;
}

.written-submission__indent-6 {
  margin-left: 120px;
}

.written-submission__indent-7 {
  margin-left: 140px;
}

.written-submission__indent-8 {
  margin-left: 160px;
}

.written-submission__font-button {
  gap: 10px;
  padding: 10px 16px;
  display: flex;
}

.written-submission__audio-chip {
  border-radius: var(--space-x0-half, 4px);
  height: 29px;
  min-height: 24px;
  padding: var(--space-x0-half, 4px) var(--space-x1, 8px);
  align-items: center;
  gap: 10px;
  width: max-content;
  display: flex;
}

.written-submission__audio-chip:hover {
  cursor: pointer;
}

.written-submission__audio-chip--deleted {
  background: var(--background-negative-subtle);
}

.written-submission__audio-chip--deleted:hover {
  cursor: auto;
}

.written-submission__audio {
  border: 2px solid rgba(0, 0, 0, 0);
  margin: 4px 0;
  padding: 8px;
}

.written-submission__audio--focused {
  border-color: #b4d5ff;
}

.written-submission__audio__popup {
  background: var(--background-primary);
  border: 1px solid var(--border-primary);
  border-radius: 8px;
  align-items: flex-start;
  display: flex;
  position: absolute;
  top: -48px;
  right: 8px;
  box-shadow: 0 4px 6px -4px rgba(50, 55, 59, .04), 0 10px 20px rgba(35, 42, 47, .06);
}

.written-submission__modal {
  background: var(--elevation-background-l3);
  border: 1px solid var(--border-primary);
  border-radius: var(--space-x2);
  flex-direction: column;
  align-items: flex-start;
  gap: 40px;
  width: 320px;
  padding: 32px;
  display: flex;
  box-shadow: 0 4px 6px -4px rgba(35, 42, 47, .04), 0 10px 20px rgba(35, 42, 47, .06);
}

.written-submission__modal__body {
  align-self: stretch;
  align-items: center;
  gap: var(--space-x2);
  flex-direction: column;
  display: flex;
}

.written-submission__modal__button-group {
  align-self: stretch;
  gap: var(--space-x1);
  flex-direction: column;
  display: flex;
}

.written-submission__modal__icon {
  text-align: center;
  align-self: stretch;
  font-size: 32px;
}

.written-submission__add-media__container {
  display: inline-block;
  position: relative;
}

.written-submission__media-dropdown__container {
  background: var(--background-primary);
  border: 1px solid var(--border-primary);
  z-index: 100;
  border-radius: 8px;
  flex-direction: column;
  gap: 4px;
  width: 149px;
  margin-top: 4px;
  padding: 8px;
  position: absolute;
  top: 100%;
  right: 0;
  box-shadow: 0 4px 4px -2px rgba(35, 42, 47, .04), 0 8px 16px rgba(35, 42, 47, .1);
}

.written-submission__media-dropdown__item {
  justify-content: space-between;
  align-items: center;
  gap: 8px;
  width: 100%;
  padding: 8px 12px;
  display: flex;
}

.written-submission__audio-player-modal {
  align-items: flex-start;
  gap: var(--space-x2);
  flex-direction: column;
  padding: 32px;
  display: flex;
}

.written-submission__audio-player-modal__close-icon {
  position: absolute;
  top: 8.394px;
  right: 7.5px;
}
