.correctness-indicator-wrapper--with-children {
  position: relative;
}

.fa.correctness-indicator-wrapper__indicator {
  background-color: #fff;
  border: 1px solid #e1e4e6;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  display: flex;
}

.fa.correctness-indicator-wrapper__indicator--icon-only {
  background-color: rgba(0, 0, 0, 0);
  border: 0;
}

.correctness-indicator-wrapper__indicator--correct {
  color: #059669;
}

.correctness-indicator-wrapper__indicator--incorrect {
  color: #dc4538;
}

.correctness-indicator-wrapper--with-children .correctness-indicator-wrapper__indicator {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
