:export {
  titleBarHeight: 80px;
  mobileToolbarHeight: 53px;
}

.pv-title-bar {
  text-shadow: 0 1px 5px rgba(0, 0, 0, .25);
  align-items: center;
  display: flex;
}

.pv-title-bar__img {
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: contain;
  width: 32px;
  height: 32px;
}

@media screen and (max-width: 600px) {
  .pv-title-bar__img {
    display: none;
  }
}

.pv-title-bar__title {
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  flex-grow: 1;
  max-width: 70ch;
  margin-right: auto;
  display: -webkit-box;
  overflow: hidden;
}

.title-bar-dropdown {
  text-shadow: none;
}

:export {
  defaultBackgroundColor: #1a699e;
}
