.markdown-renderer-inline-area {
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;
  display: flex;
}

.markdown-renderer-inline-area > * {
  margin: 0 1em;
}

.markdown-renderer-inline-area--align-center {
  align-items: center;
}
