.advanced-search, .advanced-search__filters {
  grid-area: 1 / 2;
  justify-content: flex-end;
  align-items: center;
  gap: 8px;
  display: flex;
}

.advanced-search__filters {
  margin-left: auto;
}

.advanced-search__filters--hidden, .advanced-search__filter-toggle--hidden {
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: inset(100%);
  white-space: nowrap;
  border: 0;
  width: 1px;
  height: 1px;
  padding: 0;
  position: absolute;
  overflow: hidden;
}

@media (max-width: 780px) {
  .advanced-search__filter-toggle {
    margin-bottom: 20px;
  }
}

.advanced-search__standard-sets-button-wrapper-active {
  color: #1e7cb8;
  background-color: #d3eaf8;
  border: none;
}

.advanced-search_filter-dropdown {
  width: clamp(320px, 52ch, 500px);
}

.advanced-search__popover {
  display: flex;
}

.advanced-search__popover.m-popover--mobile {
  margin: auto;
}

.advanced-search__popover.m-popover--mobile .sub-search__results, .advanced-search__popover.m-popover--mobile .advanced-search__sub-search__results {
  max-height: none;
}

@media (min-width: 781px) {
  .advanced-search__popover {
    width: 36.5625em;
  }
}

.advanced-search__sub-search {
  width: 100%;
}

.advanced-search__sub-search__results {
  max-height: 300px;
  overflow: auto;
}

.advanced-search__search-input--mobile {
  grid-area: 2 / 1 / auto / -1;
}

.advanced-search__btn {
  white-space: nowrap;
}

.standard-set-filter__search-input {
  --input-border-color: transparent;
  --input-border-color-hover: transparent;
  --input-border-color-focus: transparent;
  --input-background-color: #f3f4f6;
  --input-background-color-hover: #e5e7eb;
  --input-background-color-focus: #ebf5fa;
  --searchInput-border-radius: 8px;
  border: none;
  width: clamp(280px, 40vw, 420px);
}
