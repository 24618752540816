.m-checkbox-chip__input {
  cursor: pointer;
  opacity: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  margin: 0;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.m-checkbox-chip__icon {
  margin-left: var(--space-x1);
  font-size: .75rem;
}

.m-checkbox-chip.a-chip--disabled .m-checkbox-chip__input {
  cursor: default;
}
