.active-question-wrapper {
  align-self: flex-start;
  width: 100%;
  max-width: 830px;
  margin: 20px 30px;
}

.active-question-wrapper__question {
  background: #fff;
  font-size: 14px;
  line-height: 1.2;
  box-shadow: 0 1px 2px rgba(0, 0, 0, .05);
}

.active-question-wrapper__question table {
  border-collapse: collapse;
  border-spacing: 0;
  border: 0;
  max-width: 100%;
  margin: 20px auto;
  overflow: auto;
}

.active-question-wrapper__question th {
  background-color: #fff;
  border-top: 1px solid #e2e4e6;
}

.active-question-wrapper__question tbody tr {
  background-color: #fafbfc;
}

.active-question-wrapper__question tbody tr:nth-child(2n) {
  background-color: #fff;
}

.active-question-wrapper__question tr, .active-question-wrapper__question th {
  box-shadow: inset 0 -1px #e2e4e6;
}

.active-question-wrapper__question tbody:first-child tr:first-child {
  box-shadow: inset 0 -1px #e2e4e6, inset 0 1px #e2e4e6;
}

.active-question-wrapper__question th, .active-question-wrapper__question td {
  text-align: left;
  border-right: 1px solid #e2e4e6;
  padding: 20px 20px 21px;
}

.active-question-wrapper__question th:first-child, .active-question-wrapper__question td:first-child {
  border-left: 1px solid #e2e4e6;
}

.active-question-wrapper__question th {
  white-space: nowrap;
  font-size: 1.1em;
  font-weight: bold;
}

.active-question-wrapper__question th::selection {
  background-color: rgba(0, 0, 0, 0);
}

.active-question-wrapper__question th ::selection {
  background-color: rgba(0, 0, 0, 0);
}

.active-question-wrapper__question p, .active-question-wrapper__question a, .active-question-wrapper__question ul, .active-question-wrapper__question ol, .active-question-wrapper__question li, .active-question-wrapper__question h1, .active-question-wrapper__question h2, .active-question-wrapper__question h3, .active-question-wrapper__question h4, .active-question-wrapper__question h5, .active-question-wrapper__question h6, .active-question-wrapper__question blockquote, .active-question-wrapper__question figcaption {
  -webkit-user-select: none;
  user-select: none;
}

.active-question-wrapper__question a:not([class]) {
  color: #50a9e2;
}

.active-question-wrapper__question a:not([class]):not(:hover) {
  text-decoration: none;
}

.active-question-wrapper__question a:not([class]):hover, .active-question-wrapper__question a:not([class]):visited {
  color: #1e7cb8;
}

.active-question-wrapper__question small {
  line-height: 1.2;
}

.active-question-wrapper__question img {
  max-width: 100%;
}

.active-question-wrapper__question p, .active-question-wrapper__question li {
  color: #374151;
}

@media (max-width: 600px) {
  .active-question-wrapper__question ul, .active-question-wrapper__question ol {
    margin-left: 1rem;
    padding-left: 0;
  }
}

.active-question-wrapper__question li {
  margin-bottom: .5em;
}

.active-question-wrapper__question strong {
  color: #000;
  font-family: Lato, sans-serif;
  font-weight: 800;
}

.active-question-wrapper__question blockquote {
  margin: 0 0 1em;
  padding: 1em;
}

@media (max-width: 600px) {
  .active-question-wrapper__question blockquote {
    margin: 1em 1.5em;
  }
}

.active-question-wrapper__question pre {
  color: #4b5563;
  background-color: #f9fafb;
  border-radius: .25em;
  max-width: 100%;
  padding: .5em;
  font-family: Cousine, Courier, monospace;
  line-height: 1.4;
}

.active-question-wrapper__question p code, .active-question-wrapper__question .paragraph code {
  background-color: #f3f4f6;
  border-radius: .25em;
  margin: 0 .25em;
  padding: 0 .25em;
  display: inline-block;
}

.active-question-wrapper__question pre code[class^="lang"] {
  color: #ccc;
  background: #2d2d2d;
  padding: .75em 1em;
  display: block;
  overflow-x: auto;
}

.active-question-wrapper__question hr {
  background-color: #9ca3af;
  border: 0;
  height: 1px;
}

.active-question-wrapper__question [class$="question-content"] {
  margin-bottom: 2em;
}

.active-question-wrapper__question .scroll-wrapper {
  max-width: 100%;
  overflow: auto;
}

.active-question-wrapper__question .question-prompt, .active-question-wrapper__question .question-answer-area {
  margin-bottom: 2rem;
  padding: 0 2rem;
}

@media (max-width: 900px) {
  .active-question-wrapper__question .question-prompt, .active-question-wrapper__question .question-answer-area {
    padding: 0 1rem;
  }
}

.active-question-wrapper__question .question__prompt-heading {
  margin: 20px 0;
  font-size: 1.2em;
}

.active-question-wrapper__question .MathJax {
  color: #000;
}

.active-question-wrapper__question .MathJax_Display {
  margin: 0;
}

.active-question-widgets-bar {
  background: #fff;
  border-bottom: 2px solid #d1d5db;
  flex-flow: row;
  align-items: center;
  padding: 1em;
  display: flex;
}

.active-question-content {
  flex-flow: column;
  display: flex;
}

.active-question-content__meta {
  justify-content: space-between;
  align-items: center;
  padding: 1em 1.75em;
  display: flex;
}

.active-question-content__meta-title {
  flex-grow: 1;
  margin: 0 10px 0 0;
  font-size: 1.2em;
}

.active-question-content__meta-question-sub-type {
  color: #4b5563;
  text-transform: uppercase;
  background-color: #f3f4f6;
  border-radius: 4px;
  margin: 0 .5em;
  padding: .5em .75em;
  font-size: .9em;
  font-weight: bold;
}

.active-question-content__prompt, .active-question-content__explanation {
  flex-flow: column;
  align-items: stretch;
  padding: 1em 1.75em;
  display: flex;
}

.active-question-content__explanation .question-response-stats--two-way {
  max-width: none;
}

.active-question-content__explanation .question-footer__main-content-wrapper {
  padding: 0;
}

.active-question-footer {
  justify-content: space-between;
  align-items: center;
  padding: 1em 1.3125em;
  display: flex;
}

@media (max-width: 900px) {
  .active-question-footer {
    flex-direction: column;
  }
}

.active-question-footer__item {
  display: flex;
}

@media (max-width: 900px) {
  .active-question-footer__item {
    text-align: center;
    flex-direction: column;
    width: 100%;
    margin-bottom: 15px;
  }
}

.active-question-report-issue-link {
  cursor: pointer;
  font-size: .8125rem;
  font-style: italic;
}

.active-question-report-issue-link:hover {
  color: #dc4538;
}

.active-question-button, a.active-question-button, button.active-question-button {
  color: #4b5563;
  cursor: pointer;
  background-color: #f3f4f6;
  border-radius: .15em;
  margin: 0 .25em;
  padding: .75em 2em;
  font-weight: bold;
  text-decoration: none;
  transition: background-color .25s;
}

.active-question-button:first-child, a.active-question-button:first-child, button.active-question-button:first-child {
  margin-left: 0;
}

.active-question-button:last-child, a.active-question-button:last-child, button.active-question-button:last-child {
  margin-right: 0;
}

@media (max-width: 900px) {
  .active-question-button:last-child, a.active-question-button:last-child, button.active-question-button:last-child {
    margin-bottom: 0;
  }
}

.active-question-button:hover, a.active-question-button:hover, button.active-question-button:hover {
  color: #4b5563;
}

.active-question-button:disabled, a.active-question-button:disabled, button.active-question-button:disabled {
  color: #fff;
  cursor: not-allowed;
  opacity: .5;
  background-color: #9ca3af;
}

.active-question-button:not(:disabled):hover:not(:active), .active-question-button:not(:disabled):focus:not(:active), a.active-question-button:not(:disabled):hover:not(:active), a.active-question-button:not(:disabled):focus:not(:active), button.active-question-button:not(:disabled):hover:not(:active), button.active-question-button:not(:disabled):focus:not(:active) {
  background-color: #e5e7eb;
}

.active-question-button:active, a.active-question-button:active, button.active-question-button:active {
  transition: background-color;
}

@media (max-width: 900px) {
  .active-question-button, a.active-question-button, button.active-question-button {
    margin-bottom: 10px;
    margin-left: 0;
    margin-right: 0;
  }
}

.active-question-button--green, button.active-question-button--green {
  color: #fff;
  text-align: center;
  background-color: #059669;
  text-decoration: none;
}

.active-question-button--green:hover, button.active-question-button--green:hover {
  color: #fff;
}

.active-question-button--green:not(:disabled):hover:not(:active), .active-question-button--green:not(:disabled):focus:not(:active), button.active-question-button--green:not(:disabled):hover:not(:active), button.active-question-button--green:not(:disabled):focus:not(:active) {
  background-color: #047857;
}

.active-question-button--blue-medium, button.active-question-button--blue-medium {
  color: #fff;
  text-align: center;
  background-color: #50a9e2;
  text-decoration: none;
}

.active-question-button--blue-medium:hover, button.active-question-button--blue-medium:hover {
  color: #fff;
}

.active-question-button--blue-medium:not(:disabled):hover:not(:active), .active-question-button--blue-medium:not(:disabled):focus:not(:active), button.active-question-button--blue-medium:not(:disabled):hover:not(:active), button.active-question-button--blue-medium:not(:disabled):focus:not(:active) {
  background-color: #1e7cb8;
}

.active-question-footer__nav-controls {
  margin: 0 .25em;
}

.active-question-footer__nav-controls:first-child {
  margin-left: 0;
}

.active-question-footer__nav-controls:last-child {
  margin-right: 0;
}

.active-question-footer__nav-controls .active-question-button {
  padding-left: 1em;
  padding-right: 1em;
}

.active-question-footer__nav-controls .active-question-button:first-child {
  border-right: 1px solid #6b7280;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  margin-left: 0;
  margin-right: 0;
}

.active-question-footer__nav-controls .active-question-button:first-child .fa {
  margin-right: .25em;
}

.active-question-footer__nav-controls .active-question-button:not(:first-child):not(:last-child) {
  border-right: 1px solid #6b7280;
  border-radius: 0;
  margin: 0;
}

.active-question-footer__nav-controls .active-question-button:last-child {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  margin-left: 0;
  margin-right: 0;
}

.active-question-footer__nav-controls .active-question-button:last-child .fa {
  margin-left: .25em;
}

.active-question-header {
  border-bottom: 2px solid rgba(0, 0, 0, .25);
  justify-content: flex-end;
  align-items: center;
  display: flex;
}

.active-question-header__nav-buttons-wrapper {
  display: flex;
}

.active-question-header__nav-button {
  cursor: pointer;
  background-color: #f3f4f6;
  border-left: 1px solid #9ca3af;
  align-items: center;
  height: 3em;
  padding: 0 1em;
  display: flex;
}

.active-question-header__nav-button:hover {
  background-color: #e5e7eb;
}

.active-question-header__nav-button:disabled {
  cursor: not-allowed;
  opacity: .5;
}

.active-question-submit-button__tooltip {
  text-align: center;
}

.question-interaction-handler {
  position: relative;
}

.question-interaction-handler__overlay {
  position: absolute;
}

.question-interaction-handler__overlay--active {
  z-index: 9;
  inset: 0;
}
