.content-discovery__results {
  margin: 0 32px;
}

@media (max-width: 780px) {
  .content-discovery__results {
    margin: 0 16px;
  }
}

.content-discovery__course-cards-container {
  grid-template-columns: repeat(auto-fill, minmax(296px, 1fr));
  gap: 32px;
  display: grid;
}

.content-discovery__results-container {
  border-radius: 16px;
  margin: 24px 0;
  padding: 32px;
  box-shadow: 0 1px 7px rgba(0, 0, 0, .1);
}

.content-discovery__results-header {
  align-items: center;
  margin-top: 16px;
  margin-right: 32px;
  padding-bottom: 8px;
  display: flex;
}
