.passage-correction-popover.card {
  z-index: 99;
  width: 350px;
  margin-top: 10px;
  position: fixed;
  box-shadow: 0 1px 6px rgba(0, 0, 0, .25);
}

.passage-correction-popover.card:after {
  content: "";
  background-image: linear-gradient(to bottom right, #fff 50%, rgba(0, 0, 0, 0) 0);
  width: 20px;
  height: 20px;
  display: block;
  position: absolute;
  bottom: calc(100% - 10px);
  right: 20px;
  transform: rotate(45deg);
}

.passage-correction-popover__correction-wrapper, .passage-correction-popover__original-text, .passage-correction-popover__annotation-wrapper {
  padding: 20px 15px;
}

.passage-correction-popover__correction-wrapper {
  border-bottom: 1px solid #f3f4f6;
}

.passage-correction-popover__correction-wrapper .a-form-input {
  margin-right: 0;
}

.passage-correction-popover__input {
  margin: 0 0 5px;
}

.passage-correction-popover__button-wrapper {
  text-align: right;
}

.passage-correction-popover__student-answer {
  margin-bottom: 20px;
}

.passage-correction-popover__student-answer .a-form-input__static-element {
  border: 2px solid;
}

.passage-correction-popover__student-answer--correct .a-form-input__static-element {
  border-color: #059669;
}

.passage-correction-popover__student-answer--incorrect .a-form-input__static-element {
  border-color: #dc4538;
}

.passage-correction-explanation {
  background-color: #f9fafb;
  border-radius: 10px;
  margin-bottom: 20px;
  padding: 10px;
}

.passage-correction-explanation__show-hide-toggle-wrapper {
  text-align: center;
}

button.passage-correction-explanation__show-hide-toggle.sg-button {
  font-size: 1.2em;
  font-weight: bold;
}

.passage-correction-question {
  padding-bottom: 30px;
}

.passage-correction-question__correct-passage {
  padding: 10px;
}
