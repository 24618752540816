.previous-attempts-wrapper {
  flex-flow: row;
  margin: 0 1em;
  display: flex;
}

.previous-attempts-wrapper--show-tooltip {
  cursor: pointer;
}

.previous-attempts-single-attempts-wrapper {
  flex-flow: row;
  justify-content: center;
  align-items: center;
  padding: 0 .7em 0 0;
  display: flex;
}

.previous-attempts-single-attempts-wrapper--show-tooltip:hover > .previous-attempts-single-attempt {
  transition: all .25s ease-out;
  transform: scale(1.1);
}

.previous-attempts-tooltip-attempts-wrapper h4 {
  margin: 0 0 .5em;
  font-size: 16px;
}

.previous-attempts-tooltip-attempts {
  margin: 0 0 2em;
}

.previous-attempts-tooltip-attempts:last-child {
  margin: 0;
}

.previous-attempts-tooltip-single-attempt {
  cursor: pointer;
  flex-flow: row;
  align-items: center;
  min-width: 20em;
  padding: .5em 0;
  display: flex;
}

.previous-attempts-tooltip-single-attempt:hover .previous-attempts-tooltip-single-attempt__date {
  color: #50a9e2;
  text-decoration: underline;
}

.previous-attempts-tooltip-single-attempt:hover .previous-attempts-single-attempt {
  opacity: 1;
}

@media (max-width: 600px) {
  .previous-attempts-tooltip-single-attempt {
    min-width: auto;
  }
}

.previous-attempts-tooltip-single-attempt--active {
  font-weight: bold;
}

.previous-attempts-tooltip-single-attempt--active:hover .previous-attempts-tooltip-single-attempt__date {
  color: inherit;
  text-decoration: none;
}

.previous-attempts-tooltip-single-attempt--no-hover {
  cursor: default;
}

.previous-attempts-tooltip-single-attempt--no-hover:hover .previous-attempts-tooltip-single-attempt__date {
  color: inherit;
  text-decoration: none;
}

.previous-attempts-tooltip-single-attempt__meta {
  margin: 0 0 0 .5em;
}

.previous-attempts-tooltip-single-attempt__icon {
  margin: 0 1.2em 0 0;
}
