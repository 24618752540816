.question-difficulty-label {
  background: #f3f4f6;
  border-radius: .125em;
  justify-content: center;
  padding: .5em;
  font-size: .8125rem;
  display: flex;
}

.question-difficulty-label--easy {
  color: #1e7cb8;
  background: #d3eaf8;
}

.question-difficulty-label--moderate {
  color: #059669;
  background: #d1fae5;
}

.question-difficulty-label--difficult {
  color: #dc4538;
  background: #fbefef;
}

.question-difficulty-label--short {
  width: 3.25ch;
}
