.toggle-question-set-removal {
  color: #9ca3af;
  cursor: pointer;
  text-align: center;
  width: 2ch;
  margin-left: .25em;
  padding: .5em 0;
  font-size: 1.1em;
  transition: color .15s;
}

.toggle-question-set-removal.fa-trash:hover {
  color: #dc4538;
}

.toggle-question-set-removal.fa-plus-circle:hover {
  color: #059669;
}

.question-set-pending-removal.question-list-item, .question-set-pending-removal .question-list-item {
  background-color: #f3f4f6;
}

.question-set-pending-removal.multiple-question-set {
  background-color: #d1d5db;
}

.question-set-pending-removal .multiple-question-set__name, .question-set-pending-removal .question-list-item__title {
  text-decoration: line-through;
}

.question-set-pending-removal .question-list-item__difficulty {
  color: #9ca3af;
  background-color: #fff;
}
