.questions-list-v2 {
  flex-direction: column;
  display: flex;
}

.questions-list-v2--loading {
  pointer-events: none;
}

.questions-list-v2--loading .question-sets-wrapper {
  opacity: .1;
}

.questions-list-v2__modal-button-container {
  background-color: #fff;
  border-top: 1px solid #e5e7eb;
  border-bottom: 1px solid #e5e7eb;
  border-radius: 0;
  margin-top: -1px;
  margin-bottom: -1px;
}

.questions-list-v2__modal-button {
  width: 100%;
}

.questions-list__question-sort-handler {
  border-radius: 10px;
}

.questions-list-v2__footer {
  border-top: 1px solid #e5e7eb;
  margin-top: -1px;
}

.question-set-sortable-helper {
  z-index: 1;
  background-color: #fff;
  border: 0;
  border-radius: 10px;
  box-shadow: 0 1px 7px rgba(0, 0, 0, .1);
}

.question-sets-wrapper {
  flex-grow: 1;
}

.question-sets-wrapper__virtualized-list {
  outline: 0;
}

.questions-list-v2__loading-spinner {
  font-size: 30px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%)translateY(-50%);
}
