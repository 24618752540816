.modal__wrapper.modal--upgrade-cta {
  max-width: 435px;
}

.upgrade-cta {
  font-size: 14px;
}

.upgrade-cta__header {
  text-align: center;
  font-size: 1.2em;
}

.upgrade-cta__rocket-img {
  width: 85%;
  margin: 0 auto;
  display: block;
}

.upgrade-cta__header-subject {
  color: #1e7cb8;
  font-weight: bold;
  display: block;
}

.upgrade-cta__header-tagline {
  margin: 3em 0 1.5em;
}

.upgrade-cta__selling-point-list {
  padding: 0;
}

.upgrade-cta__selling-point-wrapper {
  align-items: center;
  margin-bottom: 1.5em;
  display: flex;
}

.upgrade-cta__image-icon {
  vertical-align: middle;
  width: 3em;
  margin-right: 1em;
}

.upgrade-cta__selling-point {
  margin-bottom: 0;
  display: inline-block;
}

.upgrade-cta__selling-point--note {
  color: #9ca3af;
  font-size: 12px;
  display: block;
}

.upgrade-cta__button-wrapper {
  text-align: center;
}

.upgrade-cta__button-wrapper a {
  font-size: 1.1em;
  line-height: 1.1;
  display: block;
}

.upgrade-cta__link-wrapper {
  text-align: center;
  margin-top: 1em;
}

.upgrade-cta__link-wrapper .upgrade-cta__link {
  color: #50a9e2;
  text-decoration: none;
}
