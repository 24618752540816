html:has(.study-guide__sidebar, .study-guide-dropdown--mobile) {
  scroll-padding: 110px;
}

.study-guide-dropdown--mobile {
  width: max-content;
  height: max-content;
}

.study-guide__sidebar {
  flex-direction: column;
  flex: 0 0 245px;
  height: calc(100vh - 60px);
  display: flex;
  position: sticky;
  top: 60px;
  overflow-y: auto;
}

.study-guide__menu-item {
  color: #374151;
  text-align: left;
  justify-content: left;
}

.study-guide__dropdown .m-dropdown__target {
  width: 100%;
}

.study-guide__dropdown-list {
  z-index: 999;
  border: 1px solid #d1d5db;
  border-radius: 8px;
  width: 98%;
  max-height: 400px;
  margin: 0 auto;
  position: absolute;
  top: 78px;
  left: 0;
  right: 0;
  overflow-y: scroll;
}

.study-guide__dropdown-list .study-guide__dropdown-item.m-dropdown-item {
  border-radius: 8px;
}

.study-guide__dropdown-list .study-guide__dropdown-item.m-dropdown-item:hover {
  background: #ebf5fa;
}
