.forgot-password-wrapper {
  justify-content: center;
  display: flex;
}

.forgot-password__content {
  width: 100%;
  max-width: 440px;
}

.forgot-password__submit {
  width: 100%;
}

.forgot-password__banner {
  max-width: 504px;
}
