.report-toolbar {
  padding-left: var(--reports-table-padding-inline);
  padding-right: var(--reports-table-padding-inline);
  display: flex;
}

.report-toolbar--collapsed {
  box-shadow: var(--elevation-shadow-l1);
}

.report-toolbar__nav {
  z-index: 900;
  position: sticky;
  top: 0;
}

.report-toolbar__nav-list {
  flex-basis: 100%;
  gap: 4px;
  margin-top: 0;
  padding: 2px 0;
  font-size: 14px;
}

.report-toolbar__heading {
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 45%;
  overflow: hidden;
}

.report-toolbar__title-bar {
  padding-left: var(--reports-table-padding-inline);
  padding-right: var(--reports-table-padding-inline);
  gap: 8px;
}

.report-toolbar__title-bar-export-wrapper {
  right: 0;
}

.report-toolbar__reset-search-metrics-container {
  align-items: center;
  gap: 8px;
  padding-left: 16px;
  display: flex;
}

.report-toolbar__reset-search-metrics-container .m-search-input__wrapper .m-search-input {
  --searchInput-border-radius: 8px;
  --input-border-color: #d1d5db;
  max-width: 420px;
  max-height: 44px;
  transition: max-width .2s ease-in-out;
}

.report-toolbar__reset-search-metrics-container .m-search-input__wrapper .m-search-input:not(.a-input--focus):placeholder-shown {
  --searchInput-padding-right: 8px;
  max-width: 32px;
  transition: max-width .2s ease-in-out;
}

.report-toolbar__reset-search-metrics-container .m-search-input__wrapper .m-search-input__icon {
  color: #4b5563;
  opacity: unset;
}

.report-toolbar__reset-search-metrics-container:before {
  content: "";
  pointer-events: none;
  z-index: 1;
  background: linear-gradient(to left, #fff, rgba(0, 0, 0, 0));
  width: 72px;
  height: 100%;
  position: absolute;
  top: 0;
  left: -72px;
}
