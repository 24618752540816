.author-attribution-container {
  background-color: #e5e7eb;
  flex-direction: column;
  font-size: .8em;
  display: flex;
}

.author-attribution__info {
  justify-content: space-between;
  align-items: center;
  padding: .5em 1em;
  display: flex;
}

.author-attribution-info__button {
  font-size: .9em;
  font-weight: bold;
}

.author-attribution__info-author {
  align-items: center;
  display: flex;
}

.author-attribution__info-author-info, .author-attribution__info-author-email {
  padding: 0 1em;
}
