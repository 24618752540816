#public-questions-view {
  --questionList__viewport-spacing: 16px;
  background-color: #f3f4f6;
  flex-direction: column;
  height: calc(100vh - 60px);
  max-height: calc(100vh - 60px);
  margin-top: -60px;
  display: flex;
  overflow-y: hidden;
}

#public-questions-view .public-questions-view__loading {
  align-items: center;
  height: 100%;
}

#public-questions-view .public-questions-header {
  color: #fff;
  font-size: 20px;
}

#public-questions-view .public-questions-content {
  gap: var(--questionList__viewport-spacing);
  height: 100%;
  padding-left: var(--questionList__viewport-spacing);
  padding-right: var(--questionList__viewport-spacing);
  display: flex;
  position: relative;
}

#public-questions-view .public-questions__toolbar {
  background: #fff;
  border-bottom: 1px solid #e5e7eb;
}

#public-questions-view .public-question-list--mobile {
  width: calc(100% - var(--questionList__viewport-spacing) * 2);
  z-index: 1;
  transition: transform .2s ease-in-out;
  position: absolute;
  inset: 0;
  transform: translateX(-100%);
}

#public-questions-view .public-question-list--mobile--open {
  transform: translateX(calc(0% + var(--questionList__viewport-spacing)));
  visibility: visible;
  transition: all .2s ease-in-out;
  box-shadow: 0 5px 20px rgba(0, 0, 0, .05);
}

#public-questions-view .public-question-list {
  flex-direction: column;
  min-width: 350px;
  height: calc(100% - 164px);
  display: flex;
  overflow: hidden;
}

#public-questions-view .public-question-list .public-question-list__list-item {
  border: 1px solid #e5e7eb;
}

#public-questions-view .public-question-list .public-question-list__question {
  cursor: pointer;
  text-align: left;
  width: 100%;
}

#public-questions-view .public-question-list .public-question-list__question.selected {
  border: 2px solid #50a9e2;
}

#public-questions-view .public-question-list .public-question-list__question:hover:not(.selected) {
  border: 1px solid #c1c4c9;
}

#public-questions-view .public-question-list__questions {
  height: 100%;
  overflow-y: scroll;
}

#public-questions-view .highlighted-content-area {
  background-color: #fff;
  border: 1px solid #e5e7eb;
  border-radius: 10px;
}

#public-questions-view .public-question {
  padding-bottom: 100px;
  overflow-y: scroll;
}

#public-questions-view .public-question .public-question__header {
  border-bottom: 1px solid #e5e7eb;
  justify-content: center;
  align-items: center;
  display: flex;
}

#public-questions-view .public-question .public-question__header .public-question__question-details {
  display: flex;
}

#public-questions-view .public-question .public-question__header .question-details__question-nav {
  margin-left: auto;
}

#public-questions-view .question-content-blocker__icon--sparkles {
  color: #fff;
  opacity: .3;
  mix-blend-mode: soft-light;
  font-size: 200px;
  position: absolute;
  top: -48px;
  right: -24px;
  rotate: 12deg;
}

#public-questions-view .question-content-blocker {
  color: #fff;
  background: linear-gradient(#f59e0b, #d97706);
  border-radius: 16px;
  padding: clamp(16px, 2vw, 40px);
}

#public-questions-view .question-content-blocker .question-content-blocker__description {
  text-shadow: 0 1px 2px #b45309;
}

#public-questions-view .question-content-blocker .question-content-blocker__description ul {
  list-style-position: inside;
}

#public-questions-view .question-content-blocker .question-content-blocker__icon-wrapper {
  --question-stem-blocker_icon-wrapper: min(96px, 10vw);
  height: var(--question-stem-blocker_icon-wrapper);
  text-align: center;
  width: var(--question-stem-blocker_icon-wrapper);
  background: #fef3c7;
  border-radius: 50%;
  justify-content: center;
  display: flex;
}

#public-questions-view .question-content-blocker .question-content-blocker__lock-icon {
  color: #f59e0b;
  width: 45%;
  height: auto;
}

#public-questions-view .question-content-blocker .view-license-options {
  color: #4b5563;
  background: #fff;
  border-radius: 40px;
  width: 100%;
}
