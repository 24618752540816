.scoop-container {
  position: relative;
}

.scoop-container__bg {
  position: absolute;
  inset: 0;
}

.scoop-container__svg {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.scoop-container__svg g {
  fill: #50a9e2;
}
