.m-expandable-dropdown-item {
  background-color: rgba(0, 0, 0, 0);
  border: 0;
  width: 100%;
}

.m-expandable-dropdown-item__trigger {
  background-color: rgba(0, 0, 0, 0);
  border: 0;
  border-bottom: 1px solid #e5e7eb;
  width: 100%;
}

.m-expandable-dropdown-item__item-content-wrapper {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.m-expandable-dropdown-item__expanded-indicator {
  pointer-events: none;
  margin-top: -16px;
  margin-bottom: -16px;
  margin-right: -8px;
  transition: transform .1s;
}

.m-expandable-dropdown-item__expanded-indicator .a-icon {
  transform: translateX(1px);
}

.m-expandable-dropdown-item__expanded-indicator--expanded {
  transform: rotate(90deg);
}
