.question-translation-editor__toggle-button {
  background-color: var(--background-subtle);
  cursor: pointer;
  padding: var(--space-x1-half) var(--space-x2);
  border: none;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  display: flex;
}

.question-translation-editor__toggle-button:hover {
  background-color: var(--background-subtle-hover);
}

.question-translation-editor__toggle-button:active {
  background-color: var(--background-subtle-pressed);
  border: none;
}

.question-translation-editor__toggle-button > * {
  height: 28px;
}

.question-translation-editor__toggle-icon {
  font-size: 16px;
  transition: transform .1s;
}

.question-translation-editor__toggle-icon--rotated {
  transform: rotate(90deg);
}
