.teacher-classroom-empty-state-cta {
  width: 100%;
  max-width: 371px;
  margin: 0 auto;
  padding: 32px;
}

.teacher-classroom-empty-state-cta * {
  text-align: center;
}

.teacher-classroom-empty-state-cta__cactus {
  width: 100%;
}
