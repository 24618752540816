.footer {
  background-color: var(--background-subtle);
  text-align: center;
  font-size: 15px;
  font-weight: bold;
}

.footer__columns {
  text-align: left;
  flex-wrap: wrap;
  margin: 0 auto .1em;
  padding: 0 80px;
  display: inline-flex;
}

@media (max-width: 1020px) {
  .footer__columns {
    padding: 0 40px;
  }
}

.footer-column {
  width: 12em;
}

.footer-column__list {
  text-align: left;
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.footer-item {
  cursor: pointer;
  text-align: left;
  line-height: 1.2em;
  display: flex;
}

.footer-media {
  justify-content: center;
  display: inline;
}

@media (max-width: 780px) {
  .footer-media {
    text-align: left;
    flex-direction: column;
    padding-bottom: 8px;
    padding-left: 40px;
    display: block;
  }
}

.footer-logo-image__wrapper {
  justify-content: center;
  display: flex;
}

@media (max-width: 780px) {
  .footer-logo-image__wrapper {
    justify-content: left;
    padding-left: 40px;
  }
}

.footer-legal {
  opacity: .54;
  text-align: center;
  padding: 80px;
}

.footer-legal:not(:first-child) {
  padding-top: 0;
}

@media (max-width: 1020px) {
  .footer-legal {
    padding: 40px;
  }

  .footer-legal:not(:first-child) {
    padding-top: 0;
  }
}

@media (max-width: 780px) {
  .footer-legal__disclaimer {
    text-align: left;
  }
}
