.archived-classrooms-list {
  opacity: 1;
}

.archived-classrooms-list__heading-wrapper {
  padding: 1.5em;
}

.archived-classrooms-list__heading-wrapper h1 {
  margin-bottom: 0;
  font-size: 1.6em;
}
