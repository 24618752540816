.restricted-question-message {
  text-align: center;
}

.restricted-question-message__icon {
  color: #e29a3d;
  margin-bottom: .25em;
  font-size: 2em;
}

.restricted-question-message__message {
  max-width: 50ch;
  margin: 0 auto;
  font-size: 1.4em;
}
