:root {
  --field-label-color: #1f2937;
  --field-message-color: #4b5563;
  --field-text-size: 16px;
}

.m-field {
  flex-direction: column;
  flex-grow: 1;
  display: flex;
}

.m-field--inline {
  flex-direction: row;
  align-items: baseline;
}

.m-field__label, .m-field__input-wrapper {
  flex-grow: 1;
}

.m-field__label {
  color: var(--field-label-color);
  font-size: var(--field-text-size);
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-bottom: 4px;
  transition: color .1s ease-in-out;
  overflow: hidden;
}

.m-field__label--required:after {
  --field-label-color: #9c251c;
  content: "*";
}

.m-field__label--error {
  --field-label-color: #9c251c;
}

.m-field__label--success {
  --field-label-color: #059669;
}

.m-field__label--inline {
  flex-grow: 1;
  margin-right: 1ch;
}

.m-field__input-wrapper {
  flex-direction: column;
  display: flex;
}

.m-field__input-wrapper--inline.m-field__input-wrapper--border {
  flex-grow: 0;
  flex-shrink: 0;
}

.m-field__message {
  color: var(--field-message-color);
  margin-top: 4px;
  transition: color .1s ease-in-out;
}

.m-field__message.m-field__message--error {
  --field-message-color: #9c251c;
}

.m-field__message.m-field__message--success {
  --field-message-color: #047857;
}

.m-field__message:empty {
  display: none;
}

.m-field__label--focus {
  --field-label-color: #1a699e;
}
