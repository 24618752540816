.log-in-link-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  color: #fff;
  cursor: pointer;
  white-space: nowrap;
  background-color: rgba(0, 0, 0, 0);
  border: 0;
  border-radius: .15em;
  flex-flow: row;
  justify-content: center;
  align-items: stretch;
  padding: 0 1em;
  font-size: 1em;
  font-weight: 400;
  line-height: 60px;
  text-decoration: none;
  transition: background-color .2s cubic-bezier(.23, 1, .32, 1);
  display: flex;
}

.log-in-link-button::selection {
  background-color: rgba(0, 0, 0, 0);
}

.log-in-link-button ::selection {
  background-color: rgba(0, 0, 0, 0);
}

.log-in-link-button.disabled, .log-in-link-button.disabled:hover, .log-in-link-button:disabled, .log-in-link-button:disabled:hover {
  color: #d1d5db;
  background-color: #6b7280;
}

.log-in-link-button:not(:disabled):not(:active):hover {
  transform: translateY(-1px)scale(1.01);
  box-shadow: 0 2px 9px rgba(0, 0, 0, .2);
}

.log-in-link-button:focus, .log-in-link-button:hover {
  background: #155884;
}

.log-in-link-button:focus {
  outline: 3px solid #50a9e2;
}

.log-in-link-button:not(:disabled):not(:active):hover {
  box-shadow: none;
  transform: none;
}

@media (max-width: 1020px) {
  .log-in-link-button {
    justify-content: flex-start;
    width: 100%;
    padding: 0 20px;
  }
}
