.twq-table--full-width {
  min-width: 100%;
}

.twq-table__heading {
  text-align: center;
}

.twq-table__heading--sticky {
  z-index: 1;
  position: sticky;
  top: 120px;
}

@media screen and (max-width: 440px) {
  .twq-table__heading--sticky {
    top: 0;
  }
}

.twq-table__heading--sticky-dennis {
  z-index: 1;
  position: sticky;
  top: 144px;
}

.twq-table__heading--empty:before {
  content: "";
  background-color: #fff;
  width: calc(100% + 1px);
  height: 100%;
  display: block;
  position: absolute;
  top: -1px;
  left: -1px;
}

.twq-table-row__cell {
  text-align: center;
  outline-width: 0;
  align-items: center;
}

table td.twq-table-row__cell-option {
  cursor: pointer;
  padding: 20px 30px 19px;
}

.twq-table-row__cell-option--checked, .twq-table-row__cell-option--checked:focus, .twq-table-row__cell-option--checked:hover {
  outline-offset: -4px;
  background-color: rgba(30, 124, 184, .05);
  outline: 4px solid #1e7cb8;
}

.twq-table-row__cell-option--checked:active {
  outline-offset: -4px;
  background-color: rgba(80, 169, 226, .05);
  outline: 4px solid #50a9e2;
}

.twq-table-row__cell-option--unchecked:focus, .twq-table-row__cell-option--unchecked:hover {
  outline-offset: -4px;
  background-color: rgba(209, 213, 219, .05);
  outline: 4px solid #d1d5db;
}

.twq-table-row__cell-option--unchecked:active {
  outline-offset: -4px;
  background-color: rgba(229, 231, 235, .05);
  outline: 4px solid #e5e7eb;
}

.twq-table-row__cell--submitted {
  pointer-events: none;
}

.twq-table-row__input-text {
  text-align: center;
}

.twq-table-row__checkmark {
  background-color: rgba(0, 0, 0, 0);
  border: 3px solid rgba(0, 0, 0, 0);
  border-radius: 10px;
  margin: 0 auto;
}

.twq-table-row__checkmark:after {
  content: "";
  background-color: #fff;
  border: 2px solid #c6c9cc;
  border-radius: 4px;
  width: 20px;
  height: 20px;
  margin: 0 auto;
  display: block;
}

.twq-table-row__checkmark--checked:after {
  background-color: #399fdf;
  border-color: #399fdf;
}

.twq-table-row__correctness-indicator {
  justify-content: flex-end;
  align-items: flex-start;
  padding: 8px;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  container: correctnessIndicator / inline-size;
}

.twq-table-row__correctness-indicator-correct {
  background: #059669;
}

@container correctnessIndicator (width < 90px) {
  .twq-table-row__correctness-indicator_icon {
    display: none;
  }
}
