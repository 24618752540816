.global-nav-drawer {
  justify-content: center;
  align-items: center;
  display: flex;
}

.global-nav-drawer:focus {
  outline-width: 0;
}

.global-nav-drawer__toggle {
  cursor: pointer;
  letter-spacing: 1px;
  text-transform: uppercase;
  margin-left: 10px;
  padding: 20px 0;
  font-size: 12px;
  font-weight: bold;
}

.global-nav-drawer__links-wrapper {
  flex-direction: column;
  display: flex;
}

.global-nav-drawer__link {
  color: #fff;
  background: #1a699e;
  width: 100%;
  padding: 1em 1.2em;
  font-size: 1.2em;
  text-decoration: none;
  transition: background .25s;
}

.global-nav-drawer__link:hover {
  background: #1e7cb8;
}

.global-nav-drawer__link--active {
  background: #1a699e;
}

.global-nav-drawer__link-dropdown-wrapper .fa-caret-down {
  margin-left: 5px;
}

.global-nav-drawer__link-dropdown {
  white-space: nowrap;
  background-color: #fff;
  margin: 1em -1.2em;
  padding: 0 20px;
  display: none;
  position: relative;
}

.global-nav-drawer__link-dropdown a {
  color: #374151;
  padding: 20px 0;
  line-height: normal;
  text-decoration: none;
  display: flex;
}

.global-nav-drawer__link-dropdown--open {
  display: block;
}
