.passage-editor {
  flex-flow: wrap;
  margin: -10px;
  display: flex;
}

.passage-editor__fieldset, .passage-editor-preview__wrapper {
  flex: 280px;
  margin: 10px;
}

.passage-editor-preview__wrapper {
  overflow: hidden;
}

.passage-editor__input {
  flex-direction: column;
  margin: 0 0 20px;
  display: flex;
}

.passage-editor__input--textarea textarea {
  height: 25vh;
}

.passage-editor__input--checkbox {
  flex-direction: row;
}

.passage-editor__markdown-renderer {
  margin: 0 0 20px;
}
