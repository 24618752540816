.m-banner {
  border-color: var(--border-primary);
  color: var(--content-secondary);
  opacity: 1;
  font-size: 16px;
  transition: opacity .1s;
}

.m-banner--dismissing {
  opacity: 0;
}

.m-banner__card {
  border-radius: var(--space-x1);
  padding: var(--space-x1-quarter) var(--space-x2);
  display: flex;
}

.m-banner__card--neutral {
  background: var(--background-subtle);
  border: 1px solid var(--border-primary);
  color: var(--content-primary);
}

.m-banner__card--neutral .m-banner__btn {
  color: var(--content-primary);
}

.m-banner__card--negative {
  background: var(--background-negative-subtle);
  border: 1px solid var(--border-negative);
  color: var(--content-negative);
}

.m-banner__card--negative .m-banner__btn {
  color: var(--content-negative);
}

.m-banner__card--positive {
  background: var(--background-positive-subtle);
  border: 1px solid var(--border-positive);
  color: var(--content-positive);
}

.m-banner__card--positive .m-banner__btn {
  color: var(--content-positive);
}

.m-banner__card--info {
  background: var(--background-info-subtle);
  border: 1px solid var(--border-info);
  color: var(--content-info);
}

.m-banner__card--info .m-banner__btn {
  color: var(--content-info);
}

.m-banner__card--warning {
  background: var(--background-warning-subtle);
  border: 1px solid var(--border-warning);
  color: var(--content-warning);
}

.m-banner__card--warning .m-banner__btn {
  color: var(--content-warning);
}

.m-banner__content {
  white-space: pre-wrap;
  flex-grow: 1;
  align-content: flex-start;
  display: flex;
}

.m-banner__icon, .m-banner__btn {
  margin-bottom: calc(-1 * var(--space-x1-quarter));
  margin-top: calc(-1 * var(--space-x1-quarter));
  flex-shrink: 0;
}

.m-banner__icon {
  margin-left: calc(-1 * var(--space-x1));
  max-width: 40px;
  max-height: 40px;
  padding: var(--space-x1-quarter);
  top: var(--space-x0-quarter);
  justify-content: center;
  align-items: center;
  display: inline-flex;
}

.m-banner__btn {
  margin-left: auto;
}

.m-banner__btn ~ .m-banner__btn {
  margin-left: 0;
}
