.o-pagination {
  align-items: center;
  display: flex;
}

.o-pagination > :not(:last-child) {
  margin-right: 8px;
}

.o-pagination .a-icon {
  margin-top: 1px;
}

.o-pagination--left {
  justify-content: flex-start;
}

.o-pagination--center {
  justify-content: center;
}

.o-pagination--right {
  justify-content: flex-end;
}

.o-pagination__page-button {
  color: rgba(0, 0, 0, .54);
  min-width: 40px;
  height: 40px;
  padding: 0 12px;
}

.o-pagination__page-button:hover:not(:disabled) {
  background-color: #f9fafb;
}

.o-pagination__page-button .a-icon {
  margin-left: 8px;
  font-size: 14px;
}

.o-pagination__page, .o-pagination__divider {
  text-align: center;
  width: 40px;
  font-weight: 900;
}

.o-pagination__dropdown {
  margin: 0 8px;
}

.o-pagination__dropdown-page {
  justify-content: center;
}
