.a-menu-list-item {
  align-items: stretch;
  font-size: 15px;
  list-style-type: none;
}

.a-menu-list-item--clickable:hover {
  background-color: var(--background-primary-hover);
}

.a-menu-list-item--clickable:active {
  background-color: var(--background-primary-pressed);
}

.a-menu-list-item--clickable:focus-visible {
  outline: 3px solid #50a9e2;
}

.a-menu-list-item--disabled {
  cursor: default;
}

.a-menu-list-item--hoverable:hover {
  background-color: var(--background-primary-hover);
}

.a-menu-list-item--hoverable:active {
  background-color: var(--background-primary-pressed);
}

.a-menu-list-item--segmented .a-menu-list-item--hoverable:hover + * {
  background-color: var(--background-primary-hover);
}

.a-menu-list-item--selected:before {
  background-color: var(--content-brand);
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}

.a-menu-list-item--selected:focus-visible {
  outline: 3px solid #50a9e2;
}

.a-menu-list-item--selected:hover, .a-menu-list-item--selected:active {
  background-color: var(--background-brand-subtle-hover);
  color: var(--content-brand);
}

.a-menu-list-item__decorative-icon {
  padding: 14px 18px;
}

.a-menu-list-item__text {
  padding-top: 10px;
  padding-bottom: 10px;
}
