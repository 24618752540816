.a-anchor {
  background-color: inherit;
  color: var(--content-link);
  cursor: pointer;
  font-size: inherit;
  font-weight: inherit;
  overflow-wrap: break-word;
  text-align: left;
  border: none;
  padding: 0;
  text-decoration: none;
  display: inline;
}

.a-anchor:hover {
  color: var(--content-link-hover);
  text-decoration: underline;
}

.a-anchor:active, .a-anchor:focus {
  outline: var(--space-x0-quarter) solid var(--content-link);
}

.a-anchor--with-inherited-color, .a-anchor--with-inherited-color:hover {
  color: inherit;
}

.a-anchor--disabled, .a-anchor--disabled:hover {
  cursor: not-allowed;
  opacity: .5;
  text-decoration: none;
}

.a-anchor--underlined, .a-anchor--underlined:hover {
  text-decoration: underline;
}

.a-anchor--no-underlined, .a-anchor--no-underlined:hover {
  text-decoration: none;
}

.a-anchor__icon {
  white-space: nowrap;
  display: inline;
}

.a-anchor__icon--left {
  margin-right: var(--space-x1);
}

.a-anchor__icon--right {
  margin-left: var(--space-x1);
}
