.question-v2 {
  margin: 0;
}

.question-v2--clickable {
  cursor: pointer;
}

.question-v2--non-matching-result .question-v2__difficulty {
  font-style: italic;
}

.question-v2--active-rearrange-item {
  box-shadow: 0 5px 20px rgba(0, 0, 0, .05);
}

.question-v2__wrapper {
  color: inherit;
  padding: 16px 12px 16px 16px;
  text-decoration: none;
  display: flex;
}

.question-v2__wrapper:focus {
  outline: none;
}

.question-v2__wrapper--rearrangeable {
  padding-left: 8px;
}

.question-v2__actions {
  align-items: center;
  display: flex;
}

.question-v2__meta {
  flex-grow: 1;
  align-self: center;
}

.question-v2__meta--strikethrough {
  opacity: .32;
  text-decoration: line-through;
}

.question-v2__meta--strikethrough .tex2jax_process:before {
  content: "";
  background-color: currentColor;
  width: calc(100% + 4px);
  height: 1px;
  display: block;
  position: absolute;
  top: calc(50% + 1px);
  left: -2px;
}

.question-v2__title {
  text-decoration: none;
  display: block;
}

.question-v2__difficulty {
  margin-top: 8px;
}

.question-v2__info {
  align-items: center;
  display: flex;
}

.question-v2-drag-handle {
  color: #1f2937;
  cursor: grab;
  opacity: .5;
  margin-right: 16px;
}

.question-v2-drag-handle:hover {
  opacity: .75;
}

.question-v2-drag-handle:active {
  cursor: grabbing;
}

.question-v2-checkbox {
  margin-right: 16px;
}

.question-v2-multiple-question-indicator {
  align-self: flex-start;
  margin-right: 8px;
}

.question-v2-attempt-indicator, .question-v2-locked-indicator, .question-v2-saved-info-el {
  text-align: right;
  width: 32px;
  margin-left: 16px;
}

.clickable-indicator {
  border-radius: inherit;
  pointer-events: none;
  border: 2px solid rgba(0, 0, 0, 0);
  width: 100%;
  height: 100%;
  transition: border-color .1s;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
}

.question-v2:not(.question-v2--rearrangeable):hover .clickable-indicator:not(.clickable-indicator--active) {
  border-color: #d1d5db;
}

.question-v2__wrapper:focus ~ .clickable-indicator:not(.clickable-indicator--active) {
  border-color: #50a9e2;
}

.question-v2__wrapper:focus ~ .clickable-indicator {
  border-width: 4px;
}

.clickable-indicator--active {
  border-color: #50a9e2;
}
