.new-question-type-modal__buttons-wrapper {
  flex-direction: column;
  display: flex;
}

.sg-button.new-question-type-modal__button {
  margin-right: 0;
}

.new-question-type-modal__button:not(:last-child) {
  margin-bottom: 5px;
}
