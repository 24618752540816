.sortable-table-editor__input {
  flex-direction: column;
  margin: 0 0 20px;
  display: flex;
}

.sortable-table-editor__input textarea.a-form-input__element {
  height: 25vh;
}

.sortable-table-editor__input.sortable-table-editor__input--checkbox {
  flex-direction: row;
}

.sortable-table-editor__markdown-renderer {
  margin: 0 0 20px;
}
