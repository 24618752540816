.elimination-tool {
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
  display: flex;
}

.elimination-tool--hidden {
  display: none;
}

.elimination-tool__checkbox-toggle {
  flex-basis: auto;
}

.elimination-tool__checkbox-toggle .sg-checkbox-toggle__label {
  margin-right: 10px;
  font-weight: bold;
}

.elimination-tool__label {
  padding: .5em;
  font-weight: bold;
}

.elimination-tool__hide-show-button {
  color: #50a9e2;
  cursor: pointer;
  font-size: 14px;
}

.elimination-tool__hidden-option {
  display: none;
}

.elimination-tool__eliminator-option-wrapper {
  align-items: center;
  display: flex;
}

.elimination-tool__answer-option {
  cursor: pointer;
  padding-right: 1em;
  font-size: 21px;
  display: flex;
}

.elimination-tool__answer-option:hover {
  cursor: pointer;
}

.elimination-tool__answer-option--not-eliminated {
  color: #6b7280;
}

.elimination-tool__answer-option--not-eliminated:hover {
  color: #374151;
}

.elimination-tool__answer-option--eliminated {
  color: #f3f4f6;
}

.elimination-tool__answer-option--eliminated:hover {
  color: #6b7280;
}
