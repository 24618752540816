.modal__backdrop {
  z-index: 9998;
  background-color: rgba(0, 0, 0, .35);
  flex-direction: column;
  align-items: center;
  width: 100vw;
  height: 100vh;
  padding: 15vh 32px 32px;
  animation: .1s m-modal-backdrop-fade-in;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  overflow: auto;
}

.modal__backdrop:not(.modal__long-modal):not(.modal__full-screen) {
  padding: clamp(32px, 10vh, 320px) 32px;
}

@media (max-width: 440px) {
  .modal__backdrop, .modal__backdrop:not(.modal__long-modal):not(.modal__full-screen) {
    padding: 40px 16px 16px;
  }
}

.modal__long-modal {
  padding: 9vh 32px 32px;
}

.modal__full-screen {
  padding: 16px;
}

.modal__backdrop-fade-out {
  animation: .1s m-modal-backdrop-fade-out;
}

.modal__close-button {
  display: flex;
}

.modal__close-button:focus {
  outline: none;
}

.m-modal__content--fade-in {
  animation: .1s fadeInModal;
}

.m-modal__content {
  overflow: auto;
  box-shadow: 0 5px 20px rgba(0, 0, 0, .05);
}

.m-modal__content-close {
  animation: .1s m-modal-fade-out;
  box-shadow: 0 5px 20px rgba(0, 0, 0, .05);
}

@keyframes m-modal-fade-in {
  0% {
    transform: scale(.9);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes m-modal-fade-out {
  0% {
    transform: scale(1);
  }

  100% {
    transform: scale(.9);
  }
}

@keyframes m-modal-backdrop-fade-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes m-modal-backdrop-fade-out {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}
