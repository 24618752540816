.marketing-header {
  color: #fff;
  z-index: 1000;
  background-color: #1a699e;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 60px;
  padding: 0 8vw;
  transition: background .2s;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
}

.marketing-header .marketing-nav__menu, .marketing-header li {
  height: 100%;
}

@media (max-width: 1020px) {
  .marketing-header.marketing-header__mobile-menu--open {
    background-color: #fff;
  }

  .marketing-header li {
    border-bottom: 1px solid rgba(255, 255, 255, .2);
    width: 100%;
    height: auto;
  }
}

.marketing-nav__menu-toggle {
  margin-left: auto;
  font-size: 25px;
}

.marketing-nav {
  align-items: center;
  height: 100%;
  display: flex;
}

@media (max-width: 1020px) {
  .marketing-nav {
    background: #1a699e;
    height: calc(100vh - 60px);
    display: none;
    position: fixed;
    top: 60px;
    left: 0;
    overflow-y: scroll;
  }

  .marketing-nav.marketing-nav--open {
    display: flex;
  }

  .marketing-nav .a-nav-item {
    height: 60px;
    padding: 8px 20px;
  }
}

.marketing-nav__menu {
  align-items: center;
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
}

.marketing-nav__menu a:not(.sign-up-link-button) {
  color: inherit;
  text-decoration: none;
}

@media (max-width: 1020px) {
  .marketing-nav__menu {
    flex-direction: column;
    align-self: flex-start;
    align-items: flex-start;
    width: 100vw;
    padding: 0;
  }
}

.marketing-nav__log-in {
  margin-left: 32px;
}

@media (max-width: 1020px) {
  .marketing-nav__log-in {
    border-bottom: 1px solid rgba(255, 255, 255, .2);
    width: 100%;
    margin: 0;
  }

  .marketing-nav__sign-up {
    border-bottom: 1px solid rgba(255, 255, 255, .2);
    width: 100%;
    line-height: 60px;
  }
}

@media (min-width: 1020px) {
  .marketing-nav__has-dropdown:hover {
    background: #155884;
  }

  .marketing-nav__has-dropdown:hover .marketing-nav__dropdown {
    opacity: 1;
    animation: .2s global-nav-dropdown-fade-in;
    display: block;
  }
}

.marketing-nav__has-dropdown--open {
  background: #155884;
}

.marketing-nav__has-dropdown--open .marketing-nav__dropdown[hidden] {
  opacity: 1;
  animation: .2s global-nav-dropdown-fade-in;
  display: block;
}

.marketing-nav__dropdown {
  color: rgba(0, 0, 0, .62);
  cursor: pointer;
  white-space: nowrap;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  padding: 24px;
  position: absolute;
}

.marketing-nav__dropdown[hidden] {
  opacity: 0;
  display: none;
}

.marketing-nav__dropdown li:last-child .marketing-nav__dropdown-item {
  margin-bottom: 0;
}

.marketing-nav__dropdown .marketing-nav__dropdown-item {
  margin-bottom: 24px;
  padding: 8px;
  font-weight: 400;
  text-decoration: none;
  display: block;
}

.marketing-nav__dropdown .marketing-nav__dropdown-item a:hover, .marketing-nav__dropdown .marketing-nav__dropdown-item a:focus {
  color: #50a9e2;
}

.marketing-nav__dropdown .marketing-nav__dropdown-item a:focus {
  outline: 3px solid #50a9e2;
}

@media (max-width: 1020px) {
  .marketing-nav__dropdown {
    border-radius: 0;
    padding: 0;
    position: relative;
  }

  .marketing-nav__dropdown .marketing-nav__dropdown-item {
    margin-bottom: 0;
    padding: 0 20px;
    line-height: 60px;
  }

  .marketing-nav__dropdown .marketing-nav__dropdown-item a {
    width: 100%;
    height: 100%;
    padding: 8px;
    display: inline-block;
  }
}

@keyframes global-nav-dropdown-fade-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.marketing-nav__dropdown--with-columns {
  padding: 0;
  position: fixed;
  left: 0;
}

@media (max-width: 1020px) {
  .marketing-nav__dropdown--with-columns {
    position: relative;
  }
}

.marketing-nav__dropdown-item {
  margin-bottom: 1.5em;
  line-height: normal;
}

.marketing-nav__dropdown-item:last-child {
  margin-bottom: 0;
}

@media (max-width: 1020px) {
  .marketing-nav__dropdown-item {
    margin-bottom: 0;
    padding: 20px 0;
    line-height: 60px;
  }
}

.marketing-nav__column-dropdown {
  white-space: normal;
  width: 100vw;
  padding: 16px 80px 24px;
  list-style: none;
  display: flex;
}

.marketing-nav__column-dropdown ul {
  padding: 0;
  list-style: none;
}

@media (max-width: 1020px) {
  .marketing-nav__column-dropdown {
    flex-direction: column;
    width: 100vw;
    padding: 24px 0;
  }
}

.marketing-nav__column {
  width: 33.3333%;
  padding: 0 24px;
}

.marketing-nav__column:last-child {
  width: 66.6667%;
}

.marketing-nav__column li {
  max-width: 350px;
}

.marketing-nav__column li:last-child .marketing-nav__column-item {
  margin-bottom: 0;
}

@media (max-width: 1020px) {
  .marketing-nav__column {
    width: 100vw;
    padding: 0 24px;
  }

  .marketing-nav__column:last-child {
    width: 100vw;
    margin: 0;
  }

  .marketing-nav__column li {
    max-width: none;
  }
}

.marketing-nav__column-list {
  flex-flow: column wrap;
  max-height: 450px;
  display: flex;
}

.marketing-nav__column-list li {
  margin-bottom: 12px;
}

@media (max-width: 1020px) {
  .marketing-nav__column-list {
    flex-flow: column;
    max-height: none;
  }

  .marketing-nav__column-list li {
    padding: 0 20px;
  }
}

.marketing-nav__column-title {
  opacity: .54;
  border-bottom: 1px solid #d1d5db;
  margin: 16px 8px;
  padding: 16px 0;
}

@media (max-width: 1020px) {
  .marketing-nav__column-title {
    margin: 0 24px 16px;
  }
}

.marketing-nav__column-item {
  color: inherit;
  border-radius: 8px;
  margin-bottom: 24px;
  padding: 8px;
  line-height: normal;
  text-decoration: none;
  display: flex;
}

.marketing-nav__column-item:hover, .marketing-nav__column-item:focus {
  background: #f7f7f7;
  transition: all .2s ease-in-out;
}

.marketing-nav__column-item:focus {
  outline: 3px solid #50a9e2;
}

@media (max-width: 1020px) {
  .marketing-nav__column-item {
    margin-bottom: 24px;
    padding: 8px;
  }
}

.marketing-nav__column-item-title {
  color: #1a699e;
  white-space: nowrap;
  margin-bottom: 8px;
  font-size: 20px;
  font-weight: 900;
}

.marketing-nav__column-item-description {
  color: rgba(0, 0, 0, .54);
  font-size: 15px;
  font-weight: bold;
  line-height: 22px;
}
