.a-radio {
  --radio-background-color--checked: var(--background-brand);
  --radio-background-color--unchecked: var(--background-subtle);
  --radio-background-color--hover-checked: var(--background-brand-hover);
  --radio-background-color--hover-unchecked: var(--background-subtle-hover);
  --radio-background-color--active-checked: var(--background-brand-pressed);
  --radio-background-color--active-unchecked: var(--background-subtle-pressed);
  --radio-background-color--disabled-checked: var(--color-brand-50);
  --radio-border-color--unchecked: var(--border-primary);
  --radio-border-color--checked: var(--radio-background-color--checked);
  --radio-border-color--hover-checked: var(--border-brand);
  --radio-border-color--hover-unchecked: var(--border-primary);
  --radio-border-color--active-checked: var(--radio-background-color--active-checked);
  --radio-border-color--active-unchecked: var(--radio-background-color--unchecked);
  --radio-border-color--disabled-checked: var(--radio-background-color--disabled-checked);
  --radio-border-color--disabled-unchecked: var(--color-neutral-200);
  --radio-icon-color--unchecked: transparent;
  --radio-icon-color--checked: var(--content-primary-inverse-static);
  --radio-icon-color--disabled-checked: var(--brand-300);
  --radio-border-radius: calc(var(--radio-size) * 2);
  --radio-size: 32px;
  --radio-icon-font-size: 10px;
  display: inline-block;
}

.a-radio__input {
  cursor: pointer;
  opacity: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  margin: 0;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.a-radio__display {
  background-color: var(--radio-background-color--unchecked);
  border: 1px solid var(--radio-border-color--unchecked);
  border-radius: var(--radio-border-radius);
  color: var(--radio-icon-color--unchecked);
  height: var(--radio-size);
  width: var(--radio-size);
  justify-content: center;
  align-items: center;
  display: inline-flex;
}

.a-radio__icon {
  font-size: var(--radio-icon-font-size);
}

.a-radio__input:not(:checked) + .a-radio__display > svg {
  visibility: hidden;
}

.a-radio__input:checked ~ .a-radio__display {
  background-color: var(--radio-background-color--checked);
  border-color: var(--radio-border-color--checked);
  color: var(--radio-icon-color--checked);
}

.a-radio:hover .a-radio__input:not(:checked):not(:disabled) ~ .a-radio__display {
  background-color: var(--radio-background-color--hover-unchecked);
  border-color: var(--radio-border-color--hover-unchecked);
}

.a-radio:hover .a-radio__input:not(:disabled), .a-radio:hover .a-radio__input:checked:not(:disabled) ~ .a-radio__display {
  background-color: var(--radio-background-color--hover-checked);
}

.a-radio:active .a-radio__input:not(:checked) ~ .a-radio__display {
  background-color: var(--radio-background-color--active-unchecked);
  border-color: var(--radio-border-color--active-unchecked);
  transform: scale(.9);
}

.a-radio:active .a-radio__input:not(:disabled), .a-radio:active .a-radio__input:checked:not(:disabled) ~ .a-radio__display {
  background-color: var(--radio-background-color--active-checked);
}

.a-radio--disabled {
  cursor: not-allowed;
}

.a-radio--disabled * {
  cursor: inherit;
}

.a-radio--disabled .a-radio__input ~ .a-radio__display {
  background-color: var(--radio-background-color--disabled-unchecked);
  border-color: var(--radio-border-color--disabled-unchecked);
}

.a-radio--disabled .a-radio__input, .a-radio--disabled .a-radio__input:checked ~ .a-radio__display {
  background-color: var(--radio-background-color--disabled-checked);
  border-color: var(--radio-border-color--disabled-checked);
}

.a-radio__input:not(:disabled):focus ~ .a-radio__display {
  outline: 3px solid #50a9e2;
}

.a-radio__input:not(:disabled):focus:not(:focus-visible) ~ .a-radio__display {
  outline: none;
}

.a-radio__input:checked ~ .a-radio__display .a-radio__icon {
  animation: .15s cubic-bezier(.25, .4, .65, 1.9) cubic;
}

@keyframes cubic {
  0%, 20% {
    transform: scale(0);
  }

  80%, 100% {
    transform: scale(1);
  }
}
