.summary-stats-other-stats {
  align-items: start;
  gap: var(--space-x2);
  flex-wrap: wrap;
  display: flex;
}

.summary-stats-other-stats__pair {
  border: 1px solid var(--border-subtle);
  border-radius: var(--space-x1);
  min-width: fit-content;
  padding: var(--space-x1-quarter);
  flex: 1;
}
