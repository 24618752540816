.u-mar_0 {
  margin: 0;
}

.u-mar-t_0 {
  margin-top: 0;
}

.u-mar-r_0 {
  margin-right: 0;
}

.u-mar-b_0 {
  margin-bottom: 0;
}

.u-mar-l_0 {
  margin-left: 0;
}

.u-mar-lr_0 {
  margin-left: 0;
  margin-right: 0;
}

.u-mar-tb_0 {
  margin-top: 0;
  margin-bottom: 0;
}

.u-mar_1 {
  margin: 8px;
}

.u-mar-t_1 {
  margin-top: 8px;
}

.u-mar-r_1 {
  margin-right: 8px;
}

.u-mar-b_1 {
  margin-bottom: 8px;
}

.u-mar-l_1 {
  margin-left: 8px;
}

.u-mar-lr_1 {
  margin-left: 8px;
  margin-right: 8px;
}

.u-mar-tb_1 {
  margin-top: 8px;
  margin-bottom: 8px;
}

.u-mar_2 {
  margin: 16px;
}

.u-mar-t_2 {
  margin-top: 16px;
}

.u-mar-r_2 {
  margin-right: 16px;
}

.u-mar-b_2 {
  margin-bottom: 16px;
}

.u-mar-l_2 {
  margin-left: 16px;
}

.u-mar-lr_2 {
  margin-left: 16px;
  margin-right: 16px;
}

.u-mar-tb_2 {
  margin-top: 16px;
  margin-bottom: 16px;
}

.u-mar_3 {
  margin: 24px;
}

.u-mar-t_3 {
  margin-top: 24px;
}

.u-mar-r_3 {
  margin-right: 24px;
}

.u-mar-b_3 {
  margin-bottom: 24px;
}

.u-mar-l_3 {
  margin-left: 24px;
}

.u-mar-lr_3 {
  margin-left: 24px;
  margin-right: 24px;
}

.u-mar-tb_3 {
  margin-top: 24px;
  margin-bottom: 24px;
}

.u-mar_4 {
  margin: 32px;
}

.u-mar-t_4 {
  margin-top: 32px;
}

.u-mar-r_4 {
  margin-right: 32px;
}

.u-mar-b_4 {
  margin-bottom: 32px;
}

.u-mar-l_4 {
  margin-left: 32px;
}

.u-mar-lr_4 {
  margin-left: 32px;
  margin-right: 32px;
}

.u-mar-tb_4 {
  margin-top: 32px;
  margin-bottom: 32px;
}

.u-mar_5 {
  margin: 40px;
}

.u-mar-t_5 {
  margin-top: 40px;
}

.u-mar-r_5 {
  margin-right: 40px;
}

.u-mar-b_5 {
  margin-bottom: 40px;
}

.u-mar-l_5 {
  margin-left: 40px;
}

.u-mar-lr_5 {
  margin-left: 40px;
  margin-right: 40px;
}

.u-mar-tb_5 {
  margin-top: 40px;
  margin-bottom: 40px;
}

.u-mar_6 {
  margin: 48px;
}

.u-mar-t_6 {
  margin-top: 48px;
}

.u-mar-r_6 {
  margin-right: 48px;
}

.u-mar-b_6 {
  margin-bottom: 48px;
}

.u-mar-l_6 {
  margin-left: 48px;
}

.u-mar-lr_6 {
  margin-left: 48px;
  margin-right: 48px;
}

.u-mar-tb_6 {
  margin-top: 48px;
  margin-bottom: 48px;
}

.u-mar_7 {
  margin: 56px;
}

.u-mar-t_7 {
  margin-top: 56px;
}

.u-mar-r_7 {
  margin-right: 56px;
}

.u-mar-b_7 {
  margin-bottom: 56px;
}

.u-mar-l_7 {
  margin-left: 56px;
}

.u-mar-lr_7 {
  margin-left: 56px;
  margin-right: 56px;
}

.u-mar-tb_7 {
  margin-top: 56px;
  margin-bottom: 56px;
}

.u-mar_8 {
  margin: 64px;
}

.u-mar-t_8 {
  margin-top: 64px;
}

.u-mar-r_8 {
  margin-right: 64px;
}

.u-mar-b_8 {
  margin-bottom: 64px;
}

.u-mar-l_8 {
  margin-left: 64px;
}

.u-mar-lr_8 {
  margin-left: 64px;
  margin-right: 64px;
}

.u-mar-tb_8 {
  margin-top: 64px;
  margin-bottom: 64px;
}

.u-mar_9 {
  margin: 72px;
}

.u-mar-t_9 {
  margin-top: 72px;
}

.u-mar-r_9 {
  margin-right: 72px;
}

.u-mar-b_9 {
  margin-bottom: 72px;
}

.u-mar-l_9 {
  margin-left: 72px;
}

.u-mar-lr_9 {
  margin-left: 72px;
  margin-right: 72px;
}

.u-mar-tb_9 {
  margin-top: 72px;
  margin-bottom: 72px;
}

.u-mar_10 {
  margin: 80px;
}

.u-mar-t_10 {
  margin-top: 80px;
}

.u-mar-r_10 {
  margin-right: 80px;
}

.u-mar-b_10 {
  margin-bottom: 80px;
}

.u-mar-l_10 {
  margin-left: 80px;
}

.u-mar-lr_10 {
  margin-left: 80px;
  margin-right: 80px;
}

.u-mar-tb_10 {
  margin-top: 80px;
  margin-bottom: 80px;
}

.u-mar_11 {
  margin: 88px;
}

.u-mar-t_11 {
  margin-top: 88px;
}

.u-mar-r_11 {
  margin-right: 88px;
}

.u-mar-b_11 {
  margin-bottom: 88px;
}

.u-mar-l_11 {
  margin-left: 88px;
}

.u-mar-lr_11 {
  margin-left: 88px;
  margin-right: 88px;
}

.u-mar-tb_11 {
  margin-top: 88px;
  margin-bottom: 88px;
}

.u-mar_12 {
  margin: 96px;
}

.u-mar-t_12 {
  margin-top: 96px;
}

.u-mar-r_12 {
  margin-right: 96px;
}

.u-mar-b_12 {
  margin-bottom: 96px;
}

.u-mar-l_12 {
  margin-left: 96px;
}

.u-mar-lr_12 {
  margin-left: 96px;
  margin-right: 96px;
}

.u-mar-tb_12 {
  margin-top: 96px;
  margin-bottom: 96px;
}

@media screen and (max-width: 1400px) {
  .u-mar_0\@xl {
    margin: 0;
  }

  .u-mar-t_0\@xl {
    margin-top: 0;
  }

  .u-mar-r_0\@xl {
    margin-right: 0;
  }

  .u-mar-b_0\@xl {
    margin-bottom: 0;
  }

  .u-mar-l_0\@xl {
    margin-left: 0;
  }

  .u-mar-lr_0\@xl {
    margin-left: 0;
    margin-right: 0;
  }

  .u-mar-tb_0\@xl {
    margin-top: 0;
    margin-bottom: 0;
  }
}

@media screen and (max-width: 1280px) {
  .u-mar_0\@l {
    margin: 0;
  }

  .u-mar-t_0\@l {
    margin-top: 0;
  }

  .u-mar-r_0\@l {
    margin-right: 0;
  }

  .u-mar-b_0\@l {
    margin-bottom: 0;
  }

  .u-mar-l_0\@l {
    margin-left: 0;
  }

  .u-mar-lr_0\@l {
    margin-left: 0;
    margin-right: 0;
  }

  .u-mar-tb_0\@l {
    margin-top: 0;
    margin-bottom: 0;
  }
}

@media screen and (max-width: 1020px) {
  .u-mar_0\@m {
    margin: 0;
  }

  .u-mar-t_0\@m {
    margin-top: 0;
  }

  .u-mar-r_0\@m {
    margin-right: 0;
  }

  .u-mar-b_0\@m {
    margin-bottom: 0;
  }

  .u-mar-l_0\@m {
    margin-left: 0;
  }

  .u-mar-lr_0\@m {
    margin-left: 0;
    margin-right: 0;
  }

  .u-mar-tb_0\@m {
    margin-top: 0;
    margin-bottom: 0;
  }
}

@media screen and (max-width: 780px) {
  .u-mar_0\@s {
    margin: 0;
  }

  .u-mar-t_0\@s {
    margin-top: 0;
  }

  .u-mar-r_0\@s {
    margin-right: 0;
  }

  .u-mar-b_0\@s {
    margin-bottom: 0;
  }

  .u-mar-l_0\@s {
    margin-left: 0;
  }

  .u-mar-lr_0\@s {
    margin-left: 0;
    margin-right: 0;
  }

  .u-mar-tb_0\@s {
    margin-top: 0;
    margin-bottom: 0;
  }
}

@media screen and (max-width: 440px) {
  .u-mar_0\@xs {
    margin: 0;
  }

  .u-mar-t_0\@xs {
    margin-top: 0;
  }

  .u-mar-r_0\@xs {
    margin-right: 0;
  }

  .u-mar-b_0\@xs {
    margin-bottom: 0;
  }

  .u-mar-l_0\@xs {
    margin-left: 0;
  }

  .u-mar-lr_0\@xs {
    margin-left: 0;
    margin-right: 0;
  }

  .u-mar-tb_0\@xs {
    margin-top: 0;
    margin-bottom: 0;
  }
}

@media screen and (max-width: 380px) {
  .u-mar_0\@xxs {
    margin: 0;
  }

  .u-mar-t_0\@xxs {
    margin-top: 0;
  }

  .u-mar-r_0\@xxs {
    margin-right: 0;
  }

  .u-mar-b_0\@xxs {
    margin-bottom: 0;
  }

  .u-mar-l_0\@xxs {
    margin-left: 0;
  }

  .u-mar-lr_0\@xxs {
    margin-left: 0;
    margin-right: 0;
  }

  .u-mar-tb_0\@xxs {
    margin-top: 0;
    margin-bottom: 0;
  }
}

@media screen and (max-width: 1400px) {
  .u-mar_1\@xl {
    margin: 8px;
  }

  .u-mar-t_1\@xl {
    margin-top: 8px;
  }

  .u-mar-r_1\@xl {
    margin-right: 8px;
  }

  .u-mar-b_1\@xl {
    margin-bottom: 8px;
  }

  .u-mar-l_1\@xl {
    margin-left: 8px;
  }

  .u-mar-lr_1\@xl {
    margin-left: 8px;
    margin-right: 8px;
  }

  .u-mar-tb_1\@xl {
    margin-top: 8px;
    margin-bottom: 8px;
  }
}

@media screen and (max-width: 1280px) {
  .u-mar_1\@l {
    margin: 8px;
  }

  .u-mar-t_1\@l {
    margin-top: 8px;
  }

  .u-mar-r_1\@l {
    margin-right: 8px;
  }

  .u-mar-b_1\@l {
    margin-bottom: 8px;
  }

  .u-mar-l_1\@l {
    margin-left: 8px;
  }

  .u-mar-lr_1\@l {
    margin-left: 8px;
    margin-right: 8px;
  }

  .u-mar-tb_1\@l {
    margin-top: 8px;
    margin-bottom: 8px;
  }
}

@media screen and (max-width: 1020px) {
  .u-mar_1\@m {
    margin: 8px;
  }

  .u-mar-t_1\@m {
    margin-top: 8px;
  }

  .u-mar-r_1\@m {
    margin-right: 8px;
  }

  .u-mar-b_1\@m {
    margin-bottom: 8px;
  }

  .u-mar-l_1\@m {
    margin-left: 8px;
  }

  .u-mar-lr_1\@m {
    margin-left: 8px;
    margin-right: 8px;
  }

  .u-mar-tb_1\@m {
    margin-top: 8px;
    margin-bottom: 8px;
  }
}

@media screen and (max-width: 780px) {
  .u-mar_1\@s {
    margin: 8px;
  }

  .u-mar-t_1\@s {
    margin-top: 8px;
  }

  .u-mar-r_1\@s {
    margin-right: 8px;
  }

  .u-mar-b_1\@s {
    margin-bottom: 8px;
  }

  .u-mar-l_1\@s {
    margin-left: 8px;
  }

  .u-mar-lr_1\@s {
    margin-left: 8px;
    margin-right: 8px;
  }

  .u-mar-tb_1\@s {
    margin-top: 8px;
    margin-bottom: 8px;
  }
}

@media screen and (max-width: 440px) {
  .u-mar_1\@xs {
    margin: 8px;
  }

  .u-mar-t_1\@xs {
    margin-top: 8px;
  }

  .u-mar-r_1\@xs {
    margin-right: 8px;
  }

  .u-mar-b_1\@xs {
    margin-bottom: 8px;
  }

  .u-mar-l_1\@xs {
    margin-left: 8px;
  }

  .u-mar-lr_1\@xs {
    margin-left: 8px;
    margin-right: 8px;
  }

  .u-mar-tb_1\@xs {
    margin-top: 8px;
    margin-bottom: 8px;
  }
}

@media screen and (max-width: 380px) {
  .u-mar_1\@xxs {
    margin: 8px;
  }

  .u-mar-t_1\@xxs {
    margin-top: 8px;
  }

  .u-mar-r_1\@xxs {
    margin-right: 8px;
  }

  .u-mar-b_1\@xxs {
    margin-bottom: 8px;
  }

  .u-mar-l_1\@xxs {
    margin-left: 8px;
  }

  .u-mar-lr_1\@xxs {
    margin-left: 8px;
    margin-right: 8px;
  }

  .u-mar-tb_1\@xxs {
    margin-top: 8px;
    margin-bottom: 8px;
  }
}

@media screen and (max-width: 1400px) {
  .u-mar_2\@xl {
    margin: 16px;
  }

  .u-mar-t_2\@xl {
    margin-top: 16px;
  }

  .u-mar-r_2\@xl {
    margin-right: 16px;
  }

  .u-mar-b_2\@xl {
    margin-bottom: 16px;
  }

  .u-mar-l_2\@xl {
    margin-left: 16px;
  }

  .u-mar-lr_2\@xl {
    margin-left: 16px;
    margin-right: 16px;
  }

  .u-mar-tb_2\@xl {
    margin-top: 16px;
    margin-bottom: 16px;
  }
}

@media screen and (max-width: 1280px) {
  .u-mar_2\@l {
    margin: 16px;
  }

  .u-mar-t_2\@l {
    margin-top: 16px;
  }

  .u-mar-r_2\@l {
    margin-right: 16px;
  }

  .u-mar-b_2\@l {
    margin-bottom: 16px;
  }

  .u-mar-l_2\@l {
    margin-left: 16px;
  }

  .u-mar-lr_2\@l {
    margin-left: 16px;
    margin-right: 16px;
  }

  .u-mar-tb_2\@l {
    margin-top: 16px;
    margin-bottom: 16px;
  }
}

@media screen and (max-width: 1020px) {
  .u-mar_2\@m {
    margin: 16px;
  }

  .u-mar-t_2\@m {
    margin-top: 16px;
  }

  .u-mar-r_2\@m {
    margin-right: 16px;
  }

  .u-mar-b_2\@m {
    margin-bottom: 16px;
  }

  .u-mar-l_2\@m {
    margin-left: 16px;
  }

  .u-mar-lr_2\@m {
    margin-left: 16px;
    margin-right: 16px;
  }

  .u-mar-tb_2\@m {
    margin-top: 16px;
    margin-bottom: 16px;
  }
}

@media screen and (max-width: 780px) {
  .u-mar_2\@s {
    margin: 16px;
  }

  .u-mar-t_2\@s {
    margin-top: 16px;
  }

  .u-mar-r_2\@s {
    margin-right: 16px;
  }

  .u-mar-b_2\@s {
    margin-bottom: 16px;
  }

  .u-mar-l_2\@s {
    margin-left: 16px;
  }

  .u-mar-lr_2\@s {
    margin-left: 16px;
    margin-right: 16px;
  }

  .u-mar-tb_2\@s {
    margin-top: 16px;
    margin-bottom: 16px;
  }
}

@media screen and (max-width: 440px) {
  .u-mar_2\@xs {
    margin: 16px;
  }

  .u-mar-t_2\@xs {
    margin-top: 16px;
  }

  .u-mar-r_2\@xs {
    margin-right: 16px;
  }

  .u-mar-b_2\@xs {
    margin-bottom: 16px;
  }

  .u-mar-l_2\@xs {
    margin-left: 16px;
  }

  .u-mar-lr_2\@xs {
    margin-left: 16px;
    margin-right: 16px;
  }

  .u-mar-tb_2\@xs {
    margin-top: 16px;
    margin-bottom: 16px;
  }
}

@media screen and (max-width: 380px) {
  .u-mar_2\@xxs {
    margin: 16px;
  }

  .u-mar-t_2\@xxs {
    margin-top: 16px;
  }

  .u-mar-r_2\@xxs {
    margin-right: 16px;
  }

  .u-mar-b_2\@xxs {
    margin-bottom: 16px;
  }

  .u-mar-l_2\@xxs {
    margin-left: 16px;
  }

  .u-mar-lr_2\@xxs {
    margin-left: 16px;
    margin-right: 16px;
  }

  .u-mar-tb_2\@xxs {
    margin-top: 16px;
    margin-bottom: 16px;
  }
}

@media screen and (max-width: 1400px) {
  .u-mar_3\@xl {
    margin: 24px;
  }

  .u-mar-t_3\@xl {
    margin-top: 24px;
  }

  .u-mar-r_3\@xl {
    margin-right: 24px;
  }

  .u-mar-b_3\@xl {
    margin-bottom: 24px;
  }

  .u-mar-l_3\@xl {
    margin-left: 24px;
  }

  .u-mar-lr_3\@xl {
    margin-left: 24px;
    margin-right: 24px;
  }

  .u-mar-tb_3\@xl {
    margin-top: 24px;
    margin-bottom: 24px;
  }
}

@media screen and (max-width: 1280px) {
  .u-mar_3\@l {
    margin: 24px;
  }

  .u-mar-t_3\@l {
    margin-top: 24px;
  }

  .u-mar-r_3\@l {
    margin-right: 24px;
  }

  .u-mar-b_3\@l {
    margin-bottom: 24px;
  }

  .u-mar-l_3\@l {
    margin-left: 24px;
  }

  .u-mar-lr_3\@l {
    margin-left: 24px;
    margin-right: 24px;
  }

  .u-mar-tb_3\@l {
    margin-top: 24px;
    margin-bottom: 24px;
  }
}

@media screen and (max-width: 1020px) {
  .u-mar_3\@m {
    margin: 24px;
  }

  .u-mar-t_3\@m {
    margin-top: 24px;
  }

  .u-mar-r_3\@m {
    margin-right: 24px;
  }

  .u-mar-b_3\@m {
    margin-bottom: 24px;
  }

  .u-mar-l_3\@m {
    margin-left: 24px;
  }

  .u-mar-lr_3\@m {
    margin-left: 24px;
    margin-right: 24px;
  }

  .u-mar-tb_3\@m {
    margin-top: 24px;
    margin-bottom: 24px;
  }
}

@media screen and (max-width: 780px) {
  .u-mar_3\@s {
    margin: 24px;
  }

  .u-mar-t_3\@s {
    margin-top: 24px;
  }

  .u-mar-r_3\@s {
    margin-right: 24px;
  }

  .u-mar-b_3\@s {
    margin-bottom: 24px;
  }

  .u-mar-l_3\@s {
    margin-left: 24px;
  }

  .u-mar-lr_3\@s {
    margin-left: 24px;
    margin-right: 24px;
  }

  .u-mar-tb_3\@s {
    margin-top: 24px;
    margin-bottom: 24px;
  }
}

@media screen and (max-width: 440px) {
  .u-mar_3\@xs {
    margin: 24px;
  }

  .u-mar-t_3\@xs {
    margin-top: 24px;
  }

  .u-mar-r_3\@xs {
    margin-right: 24px;
  }

  .u-mar-b_3\@xs {
    margin-bottom: 24px;
  }

  .u-mar-l_3\@xs {
    margin-left: 24px;
  }

  .u-mar-lr_3\@xs {
    margin-left: 24px;
    margin-right: 24px;
  }

  .u-mar-tb_3\@xs {
    margin-top: 24px;
    margin-bottom: 24px;
  }
}

@media screen and (max-width: 380px) {
  .u-mar_3\@xxs {
    margin: 24px;
  }

  .u-mar-t_3\@xxs {
    margin-top: 24px;
  }

  .u-mar-r_3\@xxs {
    margin-right: 24px;
  }

  .u-mar-b_3\@xxs {
    margin-bottom: 24px;
  }

  .u-mar-l_3\@xxs {
    margin-left: 24px;
  }

  .u-mar-lr_3\@xxs {
    margin-left: 24px;
    margin-right: 24px;
  }

  .u-mar-tb_3\@xxs {
    margin-top: 24px;
    margin-bottom: 24px;
  }
}

@media screen and (max-width: 1400px) {
  .u-mar_4\@xl {
    margin: 32px;
  }

  .u-mar-t_4\@xl {
    margin-top: 32px;
  }

  .u-mar-r_4\@xl {
    margin-right: 32px;
  }

  .u-mar-b_4\@xl {
    margin-bottom: 32px;
  }

  .u-mar-l_4\@xl {
    margin-left: 32px;
  }

  .u-mar-lr_4\@xl {
    margin-left: 32px;
    margin-right: 32px;
  }

  .u-mar-tb_4\@xl {
    margin-top: 32px;
    margin-bottom: 32px;
  }
}

@media screen and (max-width: 1280px) {
  .u-mar_4\@l {
    margin: 32px;
  }

  .u-mar-t_4\@l {
    margin-top: 32px;
  }

  .u-mar-r_4\@l {
    margin-right: 32px;
  }

  .u-mar-b_4\@l {
    margin-bottom: 32px;
  }

  .u-mar-l_4\@l {
    margin-left: 32px;
  }

  .u-mar-lr_4\@l {
    margin-left: 32px;
    margin-right: 32px;
  }

  .u-mar-tb_4\@l {
    margin-top: 32px;
    margin-bottom: 32px;
  }
}

@media screen and (max-width: 1020px) {
  .u-mar_4\@m {
    margin: 32px;
  }

  .u-mar-t_4\@m {
    margin-top: 32px;
  }

  .u-mar-r_4\@m {
    margin-right: 32px;
  }

  .u-mar-b_4\@m {
    margin-bottom: 32px;
  }

  .u-mar-l_4\@m {
    margin-left: 32px;
  }

  .u-mar-lr_4\@m {
    margin-left: 32px;
    margin-right: 32px;
  }

  .u-mar-tb_4\@m {
    margin-top: 32px;
    margin-bottom: 32px;
  }
}

@media screen and (max-width: 780px) {
  .u-mar_4\@s {
    margin: 32px;
  }

  .u-mar-t_4\@s {
    margin-top: 32px;
  }

  .u-mar-r_4\@s {
    margin-right: 32px;
  }

  .u-mar-b_4\@s {
    margin-bottom: 32px;
  }

  .u-mar-l_4\@s {
    margin-left: 32px;
  }

  .u-mar-lr_4\@s {
    margin-left: 32px;
    margin-right: 32px;
  }

  .u-mar-tb_4\@s {
    margin-top: 32px;
    margin-bottom: 32px;
  }
}

@media screen and (max-width: 440px) {
  .u-mar_4\@xs {
    margin: 32px;
  }

  .u-mar-t_4\@xs {
    margin-top: 32px;
  }

  .u-mar-r_4\@xs {
    margin-right: 32px;
  }

  .u-mar-b_4\@xs {
    margin-bottom: 32px;
  }

  .u-mar-l_4\@xs {
    margin-left: 32px;
  }

  .u-mar-lr_4\@xs {
    margin-left: 32px;
    margin-right: 32px;
  }

  .u-mar-tb_4\@xs {
    margin-top: 32px;
    margin-bottom: 32px;
  }
}

@media screen and (max-width: 380px) {
  .u-mar_4\@xxs {
    margin: 32px;
  }

  .u-mar-t_4\@xxs {
    margin-top: 32px;
  }

  .u-mar-r_4\@xxs {
    margin-right: 32px;
  }

  .u-mar-b_4\@xxs {
    margin-bottom: 32px;
  }

  .u-mar-l_4\@xxs {
    margin-left: 32px;
  }

  .u-mar-lr_4\@xxs {
    margin-left: 32px;
    margin-right: 32px;
  }

  .u-mar-tb_4\@xxs {
    margin-top: 32px;
    margin-bottom: 32px;
  }
}

@media screen and (max-width: 1400px) {
  .u-mar_5\@xl {
    margin: 40px;
  }

  .u-mar-t_5\@xl {
    margin-top: 40px;
  }

  .u-mar-r_5\@xl {
    margin-right: 40px;
  }

  .u-mar-b_5\@xl {
    margin-bottom: 40px;
  }

  .u-mar-l_5\@xl {
    margin-left: 40px;
  }

  .u-mar-lr_5\@xl {
    margin-left: 40px;
    margin-right: 40px;
  }

  .u-mar-tb_5\@xl {
    margin-top: 40px;
    margin-bottom: 40px;
  }
}

@media screen and (max-width: 1280px) {
  .u-mar_5\@l {
    margin: 40px;
  }

  .u-mar-t_5\@l {
    margin-top: 40px;
  }

  .u-mar-r_5\@l {
    margin-right: 40px;
  }

  .u-mar-b_5\@l {
    margin-bottom: 40px;
  }

  .u-mar-l_5\@l {
    margin-left: 40px;
  }

  .u-mar-lr_5\@l {
    margin-left: 40px;
    margin-right: 40px;
  }

  .u-mar-tb_5\@l {
    margin-top: 40px;
    margin-bottom: 40px;
  }
}

@media screen and (max-width: 1020px) {
  .u-mar_5\@m {
    margin: 40px;
  }

  .u-mar-t_5\@m {
    margin-top: 40px;
  }

  .u-mar-r_5\@m {
    margin-right: 40px;
  }

  .u-mar-b_5\@m {
    margin-bottom: 40px;
  }

  .u-mar-l_5\@m {
    margin-left: 40px;
  }

  .u-mar-lr_5\@m {
    margin-left: 40px;
    margin-right: 40px;
  }

  .u-mar-tb_5\@m {
    margin-top: 40px;
    margin-bottom: 40px;
  }
}

@media screen and (max-width: 780px) {
  .u-mar_5\@s {
    margin: 40px;
  }

  .u-mar-t_5\@s {
    margin-top: 40px;
  }

  .u-mar-r_5\@s {
    margin-right: 40px;
  }

  .u-mar-b_5\@s {
    margin-bottom: 40px;
  }

  .u-mar-l_5\@s {
    margin-left: 40px;
  }

  .u-mar-lr_5\@s {
    margin-left: 40px;
    margin-right: 40px;
  }

  .u-mar-tb_5\@s {
    margin-top: 40px;
    margin-bottom: 40px;
  }
}

@media screen and (max-width: 440px) {
  .u-mar_5\@xs {
    margin: 40px;
  }

  .u-mar-t_5\@xs {
    margin-top: 40px;
  }

  .u-mar-r_5\@xs {
    margin-right: 40px;
  }

  .u-mar-b_5\@xs {
    margin-bottom: 40px;
  }

  .u-mar-l_5\@xs {
    margin-left: 40px;
  }

  .u-mar-lr_5\@xs {
    margin-left: 40px;
    margin-right: 40px;
  }

  .u-mar-tb_5\@xs {
    margin-top: 40px;
    margin-bottom: 40px;
  }
}

@media screen and (max-width: 380px) {
  .u-mar_5\@xxs {
    margin: 40px;
  }

  .u-mar-t_5\@xxs {
    margin-top: 40px;
  }

  .u-mar-r_5\@xxs {
    margin-right: 40px;
  }

  .u-mar-b_5\@xxs {
    margin-bottom: 40px;
  }

  .u-mar-l_5\@xxs {
    margin-left: 40px;
  }

  .u-mar-lr_5\@xxs {
    margin-left: 40px;
    margin-right: 40px;
  }

  .u-mar-tb_5\@xxs {
    margin-top: 40px;
    margin-bottom: 40px;
  }
}

@media screen and (max-width: 1400px) {
  .u-mar_6\@xl {
    margin: 48px;
  }

  .u-mar-t_6\@xl {
    margin-top: 48px;
  }

  .u-mar-r_6\@xl {
    margin-right: 48px;
  }

  .u-mar-b_6\@xl {
    margin-bottom: 48px;
  }

  .u-mar-l_6\@xl {
    margin-left: 48px;
  }

  .u-mar-lr_6\@xl {
    margin-left: 48px;
    margin-right: 48px;
  }

  .u-mar-tb_6\@xl {
    margin-top: 48px;
    margin-bottom: 48px;
  }
}

@media screen and (max-width: 1280px) {
  .u-mar_6\@l {
    margin: 48px;
  }

  .u-mar-t_6\@l {
    margin-top: 48px;
  }

  .u-mar-r_6\@l {
    margin-right: 48px;
  }

  .u-mar-b_6\@l {
    margin-bottom: 48px;
  }

  .u-mar-l_6\@l {
    margin-left: 48px;
  }

  .u-mar-lr_6\@l {
    margin-left: 48px;
    margin-right: 48px;
  }

  .u-mar-tb_6\@l {
    margin-top: 48px;
    margin-bottom: 48px;
  }
}

@media screen and (max-width: 1020px) {
  .u-mar_6\@m {
    margin: 48px;
  }

  .u-mar-t_6\@m {
    margin-top: 48px;
  }

  .u-mar-r_6\@m {
    margin-right: 48px;
  }

  .u-mar-b_6\@m {
    margin-bottom: 48px;
  }

  .u-mar-l_6\@m {
    margin-left: 48px;
  }

  .u-mar-lr_6\@m {
    margin-left: 48px;
    margin-right: 48px;
  }

  .u-mar-tb_6\@m {
    margin-top: 48px;
    margin-bottom: 48px;
  }
}

@media screen and (max-width: 780px) {
  .u-mar_6\@s {
    margin: 48px;
  }

  .u-mar-t_6\@s {
    margin-top: 48px;
  }

  .u-mar-r_6\@s {
    margin-right: 48px;
  }

  .u-mar-b_6\@s {
    margin-bottom: 48px;
  }

  .u-mar-l_6\@s {
    margin-left: 48px;
  }

  .u-mar-lr_6\@s {
    margin-left: 48px;
    margin-right: 48px;
  }

  .u-mar-tb_6\@s {
    margin-top: 48px;
    margin-bottom: 48px;
  }
}

@media screen and (max-width: 440px) {
  .u-mar_6\@xs {
    margin: 48px;
  }

  .u-mar-t_6\@xs {
    margin-top: 48px;
  }

  .u-mar-r_6\@xs {
    margin-right: 48px;
  }

  .u-mar-b_6\@xs {
    margin-bottom: 48px;
  }

  .u-mar-l_6\@xs {
    margin-left: 48px;
  }

  .u-mar-lr_6\@xs {
    margin-left: 48px;
    margin-right: 48px;
  }

  .u-mar-tb_6\@xs {
    margin-top: 48px;
    margin-bottom: 48px;
  }
}

@media screen and (max-width: 380px) {
  .u-mar_6\@xxs {
    margin: 48px;
  }

  .u-mar-t_6\@xxs {
    margin-top: 48px;
  }

  .u-mar-r_6\@xxs {
    margin-right: 48px;
  }

  .u-mar-b_6\@xxs {
    margin-bottom: 48px;
  }

  .u-mar-l_6\@xxs {
    margin-left: 48px;
  }

  .u-mar-lr_6\@xxs {
    margin-left: 48px;
    margin-right: 48px;
  }

  .u-mar-tb_6\@xxs {
    margin-top: 48px;
    margin-bottom: 48px;
  }
}

@media screen and (max-width: 1400px) {
  .u-mar_7\@xl {
    margin: 56px;
  }

  .u-mar-t_7\@xl {
    margin-top: 56px;
  }

  .u-mar-r_7\@xl {
    margin-right: 56px;
  }

  .u-mar-b_7\@xl {
    margin-bottom: 56px;
  }

  .u-mar-l_7\@xl {
    margin-left: 56px;
  }

  .u-mar-lr_7\@xl {
    margin-left: 56px;
    margin-right: 56px;
  }

  .u-mar-tb_7\@xl {
    margin-top: 56px;
    margin-bottom: 56px;
  }
}

@media screen and (max-width: 1280px) {
  .u-mar_7\@l {
    margin: 56px;
  }

  .u-mar-t_7\@l {
    margin-top: 56px;
  }

  .u-mar-r_7\@l {
    margin-right: 56px;
  }

  .u-mar-b_7\@l {
    margin-bottom: 56px;
  }

  .u-mar-l_7\@l {
    margin-left: 56px;
  }

  .u-mar-lr_7\@l {
    margin-left: 56px;
    margin-right: 56px;
  }

  .u-mar-tb_7\@l {
    margin-top: 56px;
    margin-bottom: 56px;
  }
}

@media screen and (max-width: 1020px) {
  .u-mar_7\@m {
    margin: 56px;
  }

  .u-mar-t_7\@m {
    margin-top: 56px;
  }

  .u-mar-r_7\@m {
    margin-right: 56px;
  }

  .u-mar-b_7\@m {
    margin-bottom: 56px;
  }

  .u-mar-l_7\@m {
    margin-left: 56px;
  }

  .u-mar-lr_7\@m {
    margin-left: 56px;
    margin-right: 56px;
  }

  .u-mar-tb_7\@m {
    margin-top: 56px;
    margin-bottom: 56px;
  }
}

@media screen and (max-width: 780px) {
  .u-mar_7\@s {
    margin: 56px;
  }

  .u-mar-t_7\@s {
    margin-top: 56px;
  }

  .u-mar-r_7\@s {
    margin-right: 56px;
  }

  .u-mar-b_7\@s {
    margin-bottom: 56px;
  }

  .u-mar-l_7\@s {
    margin-left: 56px;
  }

  .u-mar-lr_7\@s {
    margin-left: 56px;
    margin-right: 56px;
  }

  .u-mar-tb_7\@s {
    margin-top: 56px;
    margin-bottom: 56px;
  }
}

@media screen and (max-width: 440px) {
  .u-mar_7\@xs {
    margin: 56px;
  }

  .u-mar-t_7\@xs {
    margin-top: 56px;
  }

  .u-mar-r_7\@xs {
    margin-right: 56px;
  }

  .u-mar-b_7\@xs {
    margin-bottom: 56px;
  }

  .u-mar-l_7\@xs {
    margin-left: 56px;
  }

  .u-mar-lr_7\@xs {
    margin-left: 56px;
    margin-right: 56px;
  }

  .u-mar-tb_7\@xs {
    margin-top: 56px;
    margin-bottom: 56px;
  }
}

@media screen and (max-width: 380px) {
  .u-mar_7\@xxs {
    margin: 56px;
  }

  .u-mar-t_7\@xxs {
    margin-top: 56px;
  }

  .u-mar-r_7\@xxs {
    margin-right: 56px;
  }

  .u-mar-b_7\@xxs {
    margin-bottom: 56px;
  }

  .u-mar-l_7\@xxs {
    margin-left: 56px;
  }

  .u-mar-lr_7\@xxs {
    margin-left: 56px;
    margin-right: 56px;
  }

  .u-mar-tb_7\@xxs {
    margin-top: 56px;
    margin-bottom: 56px;
  }
}

@media screen and (max-width: 1400px) {
  .u-mar_8\@xl {
    margin: 64px;
  }

  .u-mar-t_8\@xl {
    margin-top: 64px;
  }

  .u-mar-r_8\@xl {
    margin-right: 64px;
  }

  .u-mar-b_8\@xl {
    margin-bottom: 64px;
  }

  .u-mar-l_8\@xl {
    margin-left: 64px;
  }

  .u-mar-lr_8\@xl {
    margin-left: 64px;
    margin-right: 64px;
  }

  .u-mar-tb_8\@xl {
    margin-top: 64px;
    margin-bottom: 64px;
  }
}

@media screen and (max-width: 1280px) {
  .u-mar_8\@l {
    margin: 64px;
  }

  .u-mar-t_8\@l {
    margin-top: 64px;
  }

  .u-mar-r_8\@l {
    margin-right: 64px;
  }

  .u-mar-b_8\@l {
    margin-bottom: 64px;
  }

  .u-mar-l_8\@l {
    margin-left: 64px;
  }

  .u-mar-lr_8\@l {
    margin-left: 64px;
    margin-right: 64px;
  }

  .u-mar-tb_8\@l {
    margin-top: 64px;
    margin-bottom: 64px;
  }
}

@media screen and (max-width: 1020px) {
  .u-mar_8\@m {
    margin: 64px;
  }

  .u-mar-t_8\@m {
    margin-top: 64px;
  }

  .u-mar-r_8\@m {
    margin-right: 64px;
  }

  .u-mar-b_8\@m {
    margin-bottom: 64px;
  }

  .u-mar-l_8\@m {
    margin-left: 64px;
  }

  .u-mar-lr_8\@m {
    margin-left: 64px;
    margin-right: 64px;
  }

  .u-mar-tb_8\@m {
    margin-top: 64px;
    margin-bottom: 64px;
  }
}

@media screen and (max-width: 780px) {
  .u-mar_8\@s {
    margin: 64px;
  }

  .u-mar-t_8\@s {
    margin-top: 64px;
  }

  .u-mar-r_8\@s {
    margin-right: 64px;
  }

  .u-mar-b_8\@s {
    margin-bottom: 64px;
  }

  .u-mar-l_8\@s {
    margin-left: 64px;
  }

  .u-mar-lr_8\@s {
    margin-left: 64px;
    margin-right: 64px;
  }

  .u-mar-tb_8\@s {
    margin-top: 64px;
    margin-bottom: 64px;
  }
}

@media screen and (max-width: 440px) {
  .u-mar_8\@xs {
    margin: 64px;
  }

  .u-mar-t_8\@xs {
    margin-top: 64px;
  }

  .u-mar-r_8\@xs {
    margin-right: 64px;
  }

  .u-mar-b_8\@xs {
    margin-bottom: 64px;
  }

  .u-mar-l_8\@xs {
    margin-left: 64px;
  }

  .u-mar-lr_8\@xs {
    margin-left: 64px;
    margin-right: 64px;
  }

  .u-mar-tb_8\@xs {
    margin-top: 64px;
    margin-bottom: 64px;
  }
}

@media screen and (max-width: 380px) {
  .u-mar_8\@xxs {
    margin: 64px;
  }

  .u-mar-t_8\@xxs {
    margin-top: 64px;
  }

  .u-mar-r_8\@xxs {
    margin-right: 64px;
  }

  .u-mar-b_8\@xxs {
    margin-bottom: 64px;
  }

  .u-mar-l_8\@xxs {
    margin-left: 64px;
  }

  .u-mar-lr_8\@xxs {
    margin-left: 64px;
    margin-right: 64px;
  }

  .u-mar-tb_8\@xxs {
    margin-top: 64px;
    margin-bottom: 64px;
  }
}

@media screen and (max-width: 1400px) {
  .u-mar_9\@xl {
    margin: 72px;
  }

  .u-mar-t_9\@xl {
    margin-top: 72px;
  }

  .u-mar-r_9\@xl {
    margin-right: 72px;
  }

  .u-mar-b_9\@xl {
    margin-bottom: 72px;
  }

  .u-mar-l_9\@xl {
    margin-left: 72px;
  }

  .u-mar-lr_9\@xl {
    margin-left: 72px;
    margin-right: 72px;
  }

  .u-mar-tb_9\@xl {
    margin-top: 72px;
    margin-bottom: 72px;
  }
}

@media screen and (max-width: 1280px) {
  .u-mar_9\@l {
    margin: 72px;
  }

  .u-mar-t_9\@l {
    margin-top: 72px;
  }

  .u-mar-r_9\@l {
    margin-right: 72px;
  }

  .u-mar-b_9\@l {
    margin-bottom: 72px;
  }

  .u-mar-l_9\@l {
    margin-left: 72px;
  }

  .u-mar-lr_9\@l {
    margin-left: 72px;
    margin-right: 72px;
  }

  .u-mar-tb_9\@l {
    margin-top: 72px;
    margin-bottom: 72px;
  }
}

@media screen and (max-width: 1020px) {
  .u-mar_9\@m {
    margin: 72px;
  }

  .u-mar-t_9\@m {
    margin-top: 72px;
  }

  .u-mar-r_9\@m {
    margin-right: 72px;
  }

  .u-mar-b_9\@m {
    margin-bottom: 72px;
  }

  .u-mar-l_9\@m {
    margin-left: 72px;
  }

  .u-mar-lr_9\@m {
    margin-left: 72px;
    margin-right: 72px;
  }

  .u-mar-tb_9\@m {
    margin-top: 72px;
    margin-bottom: 72px;
  }
}

@media screen and (max-width: 780px) {
  .u-mar_9\@s {
    margin: 72px;
  }

  .u-mar-t_9\@s {
    margin-top: 72px;
  }

  .u-mar-r_9\@s {
    margin-right: 72px;
  }

  .u-mar-b_9\@s {
    margin-bottom: 72px;
  }

  .u-mar-l_9\@s {
    margin-left: 72px;
  }

  .u-mar-lr_9\@s {
    margin-left: 72px;
    margin-right: 72px;
  }

  .u-mar-tb_9\@s {
    margin-top: 72px;
    margin-bottom: 72px;
  }
}

@media screen and (max-width: 440px) {
  .u-mar_9\@xs {
    margin: 72px;
  }

  .u-mar-t_9\@xs {
    margin-top: 72px;
  }

  .u-mar-r_9\@xs {
    margin-right: 72px;
  }

  .u-mar-b_9\@xs {
    margin-bottom: 72px;
  }

  .u-mar-l_9\@xs {
    margin-left: 72px;
  }

  .u-mar-lr_9\@xs {
    margin-left: 72px;
    margin-right: 72px;
  }

  .u-mar-tb_9\@xs {
    margin-top: 72px;
    margin-bottom: 72px;
  }
}

@media screen and (max-width: 380px) {
  .u-mar_9\@xxs {
    margin: 72px;
  }

  .u-mar-t_9\@xxs {
    margin-top: 72px;
  }

  .u-mar-r_9\@xxs {
    margin-right: 72px;
  }

  .u-mar-b_9\@xxs {
    margin-bottom: 72px;
  }

  .u-mar-l_9\@xxs {
    margin-left: 72px;
  }

  .u-mar-lr_9\@xxs {
    margin-left: 72px;
    margin-right: 72px;
  }

  .u-mar-tb_9\@xxs {
    margin-top: 72px;
    margin-bottom: 72px;
  }
}

@media screen and (max-width: 1400px) {
  .u-mar_10\@xl {
    margin: 80px;
  }

  .u-mar-t_10\@xl {
    margin-top: 80px;
  }

  .u-mar-r_10\@xl {
    margin-right: 80px;
  }

  .u-mar-b_10\@xl {
    margin-bottom: 80px;
  }

  .u-mar-l_10\@xl {
    margin-left: 80px;
  }

  .u-mar-lr_10\@xl {
    margin-left: 80px;
    margin-right: 80px;
  }

  .u-mar-tb_10\@xl {
    margin-top: 80px;
    margin-bottom: 80px;
  }
}

@media screen and (max-width: 1280px) {
  .u-mar_10\@l {
    margin: 80px;
  }

  .u-mar-t_10\@l {
    margin-top: 80px;
  }

  .u-mar-r_10\@l {
    margin-right: 80px;
  }

  .u-mar-b_10\@l {
    margin-bottom: 80px;
  }

  .u-mar-l_10\@l {
    margin-left: 80px;
  }

  .u-mar-lr_10\@l {
    margin-left: 80px;
    margin-right: 80px;
  }

  .u-mar-tb_10\@l {
    margin-top: 80px;
    margin-bottom: 80px;
  }
}

@media screen and (max-width: 1020px) {
  .u-mar_10\@m {
    margin: 80px;
  }

  .u-mar-t_10\@m {
    margin-top: 80px;
  }

  .u-mar-r_10\@m {
    margin-right: 80px;
  }

  .u-mar-b_10\@m {
    margin-bottom: 80px;
  }

  .u-mar-l_10\@m {
    margin-left: 80px;
  }

  .u-mar-lr_10\@m {
    margin-left: 80px;
    margin-right: 80px;
  }

  .u-mar-tb_10\@m {
    margin-top: 80px;
    margin-bottom: 80px;
  }
}

@media screen and (max-width: 780px) {
  .u-mar_10\@s {
    margin: 80px;
  }

  .u-mar-t_10\@s {
    margin-top: 80px;
  }

  .u-mar-r_10\@s {
    margin-right: 80px;
  }

  .u-mar-b_10\@s {
    margin-bottom: 80px;
  }

  .u-mar-l_10\@s {
    margin-left: 80px;
  }

  .u-mar-lr_10\@s {
    margin-left: 80px;
    margin-right: 80px;
  }

  .u-mar-tb_10\@s {
    margin-top: 80px;
    margin-bottom: 80px;
  }
}

@media screen and (max-width: 440px) {
  .u-mar_10\@xs {
    margin: 80px;
  }

  .u-mar-t_10\@xs {
    margin-top: 80px;
  }

  .u-mar-r_10\@xs {
    margin-right: 80px;
  }

  .u-mar-b_10\@xs {
    margin-bottom: 80px;
  }

  .u-mar-l_10\@xs {
    margin-left: 80px;
  }

  .u-mar-lr_10\@xs {
    margin-left: 80px;
    margin-right: 80px;
  }

  .u-mar-tb_10\@xs {
    margin-top: 80px;
    margin-bottom: 80px;
  }
}

@media screen and (max-width: 380px) {
  .u-mar_10\@xxs {
    margin: 80px;
  }

  .u-mar-t_10\@xxs {
    margin-top: 80px;
  }

  .u-mar-r_10\@xxs {
    margin-right: 80px;
  }

  .u-mar-b_10\@xxs {
    margin-bottom: 80px;
  }

  .u-mar-l_10\@xxs {
    margin-left: 80px;
  }

  .u-mar-lr_10\@xxs {
    margin-left: 80px;
    margin-right: 80px;
  }

  .u-mar-tb_10\@xxs {
    margin-top: 80px;
    margin-bottom: 80px;
  }
}

@media screen and (max-width: 1400px) {
  .u-mar_11\@xl {
    margin: 88px;
  }

  .u-mar-t_11\@xl {
    margin-top: 88px;
  }

  .u-mar-r_11\@xl {
    margin-right: 88px;
  }

  .u-mar-b_11\@xl {
    margin-bottom: 88px;
  }

  .u-mar-l_11\@xl {
    margin-left: 88px;
  }

  .u-mar-lr_11\@xl {
    margin-left: 88px;
    margin-right: 88px;
  }

  .u-mar-tb_11\@xl {
    margin-top: 88px;
    margin-bottom: 88px;
  }
}

@media screen and (max-width: 1280px) {
  .u-mar_11\@l {
    margin: 88px;
  }

  .u-mar-t_11\@l {
    margin-top: 88px;
  }

  .u-mar-r_11\@l {
    margin-right: 88px;
  }

  .u-mar-b_11\@l {
    margin-bottom: 88px;
  }

  .u-mar-l_11\@l {
    margin-left: 88px;
  }

  .u-mar-lr_11\@l {
    margin-left: 88px;
    margin-right: 88px;
  }

  .u-mar-tb_11\@l {
    margin-top: 88px;
    margin-bottom: 88px;
  }
}

@media screen and (max-width: 1020px) {
  .u-mar_11\@m {
    margin: 88px;
  }

  .u-mar-t_11\@m {
    margin-top: 88px;
  }

  .u-mar-r_11\@m {
    margin-right: 88px;
  }

  .u-mar-b_11\@m {
    margin-bottom: 88px;
  }

  .u-mar-l_11\@m {
    margin-left: 88px;
  }

  .u-mar-lr_11\@m {
    margin-left: 88px;
    margin-right: 88px;
  }

  .u-mar-tb_11\@m {
    margin-top: 88px;
    margin-bottom: 88px;
  }
}

@media screen and (max-width: 780px) {
  .u-mar_11\@s {
    margin: 88px;
  }

  .u-mar-t_11\@s {
    margin-top: 88px;
  }

  .u-mar-r_11\@s {
    margin-right: 88px;
  }

  .u-mar-b_11\@s {
    margin-bottom: 88px;
  }

  .u-mar-l_11\@s {
    margin-left: 88px;
  }

  .u-mar-lr_11\@s {
    margin-left: 88px;
    margin-right: 88px;
  }

  .u-mar-tb_11\@s {
    margin-top: 88px;
    margin-bottom: 88px;
  }
}

@media screen and (max-width: 440px) {
  .u-mar_11\@xs {
    margin: 88px;
  }

  .u-mar-t_11\@xs {
    margin-top: 88px;
  }

  .u-mar-r_11\@xs {
    margin-right: 88px;
  }

  .u-mar-b_11\@xs {
    margin-bottom: 88px;
  }

  .u-mar-l_11\@xs {
    margin-left: 88px;
  }

  .u-mar-lr_11\@xs {
    margin-left: 88px;
    margin-right: 88px;
  }

  .u-mar-tb_11\@xs {
    margin-top: 88px;
    margin-bottom: 88px;
  }
}

@media screen and (max-width: 380px) {
  .u-mar_11\@xxs {
    margin: 88px;
  }

  .u-mar-t_11\@xxs {
    margin-top: 88px;
  }

  .u-mar-r_11\@xxs {
    margin-right: 88px;
  }

  .u-mar-b_11\@xxs {
    margin-bottom: 88px;
  }

  .u-mar-l_11\@xxs {
    margin-left: 88px;
  }

  .u-mar-lr_11\@xxs {
    margin-left: 88px;
    margin-right: 88px;
  }

  .u-mar-tb_11\@xxs {
    margin-top: 88px;
    margin-bottom: 88px;
  }
}

@media screen and (max-width: 1400px) {
  .u-mar_12\@xl {
    margin: 96px;
  }

  .u-mar-t_12\@xl {
    margin-top: 96px;
  }

  .u-mar-r_12\@xl {
    margin-right: 96px;
  }

  .u-mar-b_12\@xl {
    margin-bottom: 96px;
  }

  .u-mar-l_12\@xl {
    margin-left: 96px;
  }

  .u-mar-lr_12\@xl {
    margin-left: 96px;
    margin-right: 96px;
  }

  .u-mar-tb_12\@xl {
    margin-top: 96px;
    margin-bottom: 96px;
  }
}

@media screen and (max-width: 1280px) {
  .u-mar_12\@l {
    margin: 96px;
  }

  .u-mar-t_12\@l {
    margin-top: 96px;
  }

  .u-mar-r_12\@l {
    margin-right: 96px;
  }

  .u-mar-b_12\@l {
    margin-bottom: 96px;
  }

  .u-mar-l_12\@l {
    margin-left: 96px;
  }

  .u-mar-lr_12\@l {
    margin-left: 96px;
    margin-right: 96px;
  }

  .u-mar-tb_12\@l {
    margin-top: 96px;
    margin-bottom: 96px;
  }
}

@media screen and (max-width: 1020px) {
  .u-mar_12\@m {
    margin: 96px;
  }

  .u-mar-t_12\@m {
    margin-top: 96px;
  }

  .u-mar-r_12\@m {
    margin-right: 96px;
  }

  .u-mar-b_12\@m {
    margin-bottom: 96px;
  }

  .u-mar-l_12\@m {
    margin-left: 96px;
  }

  .u-mar-lr_12\@m {
    margin-left: 96px;
    margin-right: 96px;
  }

  .u-mar-tb_12\@m {
    margin-top: 96px;
    margin-bottom: 96px;
  }
}

@media screen and (max-width: 780px) {
  .u-mar_12\@s {
    margin: 96px;
  }

  .u-mar-t_12\@s {
    margin-top: 96px;
  }

  .u-mar-r_12\@s {
    margin-right: 96px;
  }

  .u-mar-b_12\@s {
    margin-bottom: 96px;
  }

  .u-mar-l_12\@s {
    margin-left: 96px;
  }

  .u-mar-lr_12\@s {
    margin-left: 96px;
    margin-right: 96px;
  }

  .u-mar-tb_12\@s {
    margin-top: 96px;
    margin-bottom: 96px;
  }
}

@media screen and (max-width: 440px) {
  .u-mar_12\@xs {
    margin: 96px;
  }

  .u-mar-t_12\@xs {
    margin-top: 96px;
  }

  .u-mar-r_12\@xs {
    margin-right: 96px;
  }

  .u-mar-b_12\@xs {
    margin-bottom: 96px;
  }

  .u-mar-l_12\@xs {
    margin-left: 96px;
  }

  .u-mar-lr_12\@xs {
    margin-left: 96px;
    margin-right: 96px;
  }

  .u-mar-tb_12\@xs {
    margin-top: 96px;
    margin-bottom: 96px;
  }
}

@media screen and (max-width: 380px) {
  .u-mar_12\@xxs {
    margin: 96px;
  }

  .u-mar-t_12\@xxs {
    margin-top: 96px;
  }

  .u-mar-r_12\@xxs {
    margin-right: 96px;
  }

  .u-mar-b_12\@xxs {
    margin-bottom: 96px;
  }

  .u-mar-l_12\@xxs {
    margin-left: 96px;
  }

  .u-mar-lr_12\@xxs {
    margin-left: 96px;
    margin-right: 96px;
  }

  .u-mar-tb_12\@xxs {
    margin-top: 96px;
    margin-bottom: 96px;
  }
}

.u-pad_0 {
  padding: 0;
}

.u-pad-t_0 {
  padding-top: 0;
}

.u-pad-r_0 {
  padding-right: 0;
}

.u-pad-b_0 {
  padding-bottom: 0;
}

.u-pad-l_0 {
  padding-left: 0;
}

.u-pad-lr_0 {
  padding-left: 0;
  padding-right: 0;
}

.u-pad-tb_0 {
  padding-top: 0;
  padding-bottom: 0;
}

.u-pad_1 {
  padding: 8px;
}

.u-pad-t_1 {
  padding-top: 8px;
}

.u-pad-r_1 {
  padding-right: 8px;
}

.u-pad-b_1 {
  padding-bottom: 8px;
}

.u-pad-l_1 {
  padding-left: 8px;
}

.u-pad-lr_1 {
  padding-left: 8px;
  padding-right: 8px;
}

.u-pad-tb_1 {
  padding-top: 8px;
  padding-bottom: 8px;
}

.u-pad_2 {
  padding: 16px;
}

.u-pad-t_2 {
  padding-top: 16px;
}

.u-pad-r_2 {
  padding-right: 16px;
}

.u-pad-b_2 {
  padding-bottom: 16px;
}

.u-pad-l_2 {
  padding-left: 16px;
}

.u-pad-lr_2 {
  padding-left: 16px;
  padding-right: 16px;
}

.u-pad-tb_2 {
  padding-top: 16px;
  padding-bottom: 16px;
}

.u-pad_3 {
  padding: 24px;
}

.u-pad-t_3 {
  padding-top: 24px;
}

.u-pad-r_3 {
  padding-right: 24px;
}

.u-pad-b_3 {
  padding-bottom: 24px;
}

.u-pad-l_3 {
  padding-left: 24px;
}

.u-pad-lr_3 {
  padding-left: 24px;
  padding-right: 24px;
}

.u-pad-tb_3 {
  padding-top: 24px;
  padding-bottom: 24px;
}

.u-pad_4 {
  padding: 32px;
}

.u-pad-t_4 {
  padding-top: 32px;
}

.u-pad-r_4 {
  padding-right: 32px;
}

.u-pad-b_4 {
  padding-bottom: 32px;
}

.u-pad-l_4 {
  padding-left: 32px;
}

.u-pad-lr_4 {
  padding-left: 32px;
  padding-right: 32px;
}

.u-pad-tb_4 {
  padding-top: 32px;
  padding-bottom: 32px;
}

.u-pad_5 {
  padding: 40px;
}

.u-pad-t_5 {
  padding-top: 40px;
}

.u-pad-r_5 {
  padding-right: 40px;
}

.u-pad-b_5 {
  padding-bottom: 40px;
}

.u-pad-l_5 {
  padding-left: 40px;
}

.u-pad-lr_5 {
  padding-left: 40px;
  padding-right: 40px;
}

.u-pad-tb_5 {
  padding-top: 40px;
  padding-bottom: 40px;
}

.u-pad_6 {
  padding: 48px;
}

.u-pad-t_6 {
  padding-top: 48px;
}

.u-pad-r_6 {
  padding-right: 48px;
}

.u-pad-b_6 {
  padding-bottom: 48px;
}

.u-pad-l_6 {
  padding-left: 48px;
}

.u-pad-lr_6 {
  padding-left: 48px;
  padding-right: 48px;
}

.u-pad-tb_6 {
  padding-top: 48px;
  padding-bottom: 48px;
}

.u-pad_7 {
  padding: 56px;
}

.u-pad-t_7 {
  padding-top: 56px;
}

.u-pad-r_7 {
  padding-right: 56px;
}

.u-pad-b_7 {
  padding-bottom: 56px;
}

.u-pad-l_7 {
  padding-left: 56px;
}

.u-pad-lr_7 {
  padding-left: 56px;
  padding-right: 56px;
}

.u-pad-tb_7 {
  padding-top: 56px;
  padding-bottom: 56px;
}

.u-pad_8 {
  padding: 64px;
}

.u-pad-t_8 {
  padding-top: 64px;
}

.u-pad-r_8 {
  padding-right: 64px;
}

.u-pad-b_8 {
  padding-bottom: 64px;
}

.u-pad-l_8 {
  padding-left: 64px;
}

.u-pad-lr_8 {
  padding-left: 64px;
  padding-right: 64px;
}

.u-pad-tb_8 {
  padding-top: 64px;
  padding-bottom: 64px;
}

.u-pad_9 {
  padding: 72px;
}

.u-pad-t_9 {
  padding-top: 72px;
}

.u-pad-r_9 {
  padding-right: 72px;
}

.u-pad-b_9 {
  padding-bottom: 72px;
}

.u-pad-l_9 {
  padding-left: 72px;
}

.u-pad-lr_9 {
  padding-left: 72px;
  padding-right: 72px;
}

.u-pad-tb_9 {
  padding-top: 72px;
  padding-bottom: 72px;
}

.u-pad_10 {
  padding: 80px;
}

.u-pad-t_10 {
  padding-top: 80px;
}

.u-pad-r_10 {
  padding-right: 80px;
}

.u-pad-b_10 {
  padding-bottom: 80px;
}

.u-pad-l_10 {
  padding-left: 80px;
}

.u-pad-lr_10 {
  padding-left: 80px;
  padding-right: 80px;
}

.u-pad-tb_10 {
  padding-top: 80px;
  padding-bottom: 80px;
}

.u-pad_11 {
  padding: 88px;
}

.u-pad-t_11 {
  padding-top: 88px;
}

.u-pad-r_11 {
  padding-right: 88px;
}

.u-pad-b_11 {
  padding-bottom: 88px;
}

.u-pad-l_11 {
  padding-left: 88px;
}

.u-pad-lr_11 {
  padding-left: 88px;
  padding-right: 88px;
}

.u-pad-tb_11 {
  padding-top: 88px;
  padding-bottom: 88px;
}

.u-pad_12 {
  padding: 96px;
}

.u-pad-t_12 {
  padding-top: 96px;
}

.u-pad-r_12 {
  padding-right: 96px;
}

.u-pad-b_12 {
  padding-bottom: 96px;
}

.u-pad-l_12 {
  padding-left: 96px;
}

.u-pad-lr_12 {
  padding-left: 96px;
  padding-right: 96px;
}

.u-pad-tb_12 {
  padding-top: 96px;
  padding-bottom: 96px;
}

@media screen and (max-width: 1400px) {
  .u-pad_0\@xl {
    padding: 0;
  }

  .u-pad-t_0\@xl {
    padding-top: 0;
  }

  .u-pad-r_0\@xl {
    padding-right: 0;
  }

  .u-pad-b_0\@xl {
    padding-bottom: 0;
  }

  .u-pad-l_0\@xl {
    padding-left: 0;
  }

  .u-pad-lr_0\@xl {
    padding-left: 0;
    padding-right: 0;
  }

  .u-pad-tb_0\@xl {
    padding-top: 0;
    padding-bottom: 0;
  }
}

@media screen and (max-width: 1280px) {
  .u-pad_0\@l {
    padding: 0;
  }

  .u-pad-t_0\@l {
    padding-top: 0;
  }

  .u-pad-r_0\@l {
    padding-right: 0;
  }

  .u-pad-b_0\@l {
    padding-bottom: 0;
  }

  .u-pad-l_0\@l {
    padding-left: 0;
  }

  .u-pad-lr_0\@l {
    padding-left: 0;
    padding-right: 0;
  }

  .u-pad-tb_0\@l {
    padding-top: 0;
    padding-bottom: 0;
  }
}

@media screen and (max-width: 1020px) {
  .u-pad_0\@m {
    padding: 0;
  }

  .u-pad-t_0\@m {
    padding-top: 0;
  }

  .u-pad-r_0\@m {
    padding-right: 0;
  }

  .u-pad-b_0\@m {
    padding-bottom: 0;
  }

  .u-pad-l_0\@m {
    padding-left: 0;
  }

  .u-pad-lr_0\@m {
    padding-left: 0;
    padding-right: 0;
  }

  .u-pad-tb_0\@m {
    padding-top: 0;
    padding-bottom: 0;
  }
}

@media screen and (max-width: 780px) {
  .u-pad_0\@s {
    padding: 0;
  }

  .u-pad-t_0\@s {
    padding-top: 0;
  }

  .u-pad-r_0\@s {
    padding-right: 0;
  }

  .u-pad-b_0\@s {
    padding-bottom: 0;
  }

  .u-pad-l_0\@s {
    padding-left: 0;
  }

  .u-pad-lr_0\@s {
    padding-left: 0;
    padding-right: 0;
  }

  .u-pad-tb_0\@s {
    padding-top: 0;
    padding-bottom: 0;
  }
}

@media screen and (max-width: 440px) {
  .u-pad_0\@xs {
    padding: 0;
  }

  .u-pad-t_0\@xs {
    padding-top: 0;
  }

  .u-pad-r_0\@xs {
    padding-right: 0;
  }

  .u-pad-b_0\@xs {
    padding-bottom: 0;
  }

  .u-pad-l_0\@xs {
    padding-left: 0;
  }

  .u-pad-lr_0\@xs {
    padding-left: 0;
    padding-right: 0;
  }

  .u-pad-tb_0\@xs {
    padding-top: 0;
    padding-bottom: 0;
  }
}

@media screen and (max-width: 380px) {
  .u-pad_0\@xxs {
    padding: 0;
  }

  .u-pad-t_0\@xxs {
    padding-top: 0;
  }

  .u-pad-r_0\@xxs {
    padding-right: 0;
  }

  .u-pad-b_0\@xxs {
    padding-bottom: 0;
  }

  .u-pad-l_0\@xxs {
    padding-left: 0;
  }

  .u-pad-lr_0\@xxs {
    padding-left: 0;
    padding-right: 0;
  }

  .u-pad-tb_0\@xxs {
    padding-top: 0;
    padding-bottom: 0;
  }
}

@media screen and (max-width: 1400px) {
  .u-pad_1\@xl {
    padding: 8px;
  }

  .u-pad-t_1\@xl {
    padding-top: 8px;
  }

  .u-pad-r_1\@xl {
    padding-right: 8px;
  }

  .u-pad-b_1\@xl {
    padding-bottom: 8px;
  }

  .u-pad-l_1\@xl {
    padding-left: 8px;
  }

  .u-pad-lr_1\@xl {
    padding-left: 8px;
    padding-right: 8px;
  }

  .u-pad-tb_1\@xl {
    padding-top: 8px;
    padding-bottom: 8px;
  }
}

@media screen and (max-width: 1280px) {
  .u-pad_1\@l {
    padding: 8px;
  }

  .u-pad-t_1\@l {
    padding-top: 8px;
  }

  .u-pad-r_1\@l {
    padding-right: 8px;
  }

  .u-pad-b_1\@l {
    padding-bottom: 8px;
  }

  .u-pad-l_1\@l {
    padding-left: 8px;
  }

  .u-pad-lr_1\@l {
    padding-left: 8px;
    padding-right: 8px;
  }

  .u-pad-tb_1\@l {
    padding-top: 8px;
    padding-bottom: 8px;
  }
}

@media screen and (max-width: 1020px) {
  .u-pad_1\@m {
    padding: 8px;
  }

  .u-pad-t_1\@m {
    padding-top: 8px;
  }

  .u-pad-r_1\@m {
    padding-right: 8px;
  }

  .u-pad-b_1\@m {
    padding-bottom: 8px;
  }

  .u-pad-l_1\@m {
    padding-left: 8px;
  }

  .u-pad-lr_1\@m {
    padding-left: 8px;
    padding-right: 8px;
  }

  .u-pad-tb_1\@m {
    padding-top: 8px;
    padding-bottom: 8px;
  }
}

@media screen and (max-width: 780px) {
  .u-pad_1\@s {
    padding: 8px;
  }

  .u-pad-t_1\@s {
    padding-top: 8px;
  }

  .u-pad-r_1\@s {
    padding-right: 8px;
  }

  .u-pad-b_1\@s {
    padding-bottom: 8px;
  }

  .u-pad-l_1\@s {
    padding-left: 8px;
  }

  .u-pad-lr_1\@s {
    padding-left: 8px;
    padding-right: 8px;
  }

  .u-pad-tb_1\@s {
    padding-top: 8px;
    padding-bottom: 8px;
  }
}

@media screen and (max-width: 440px) {
  .u-pad_1\@xs {
    padding: 8px;
  }

  .u-pad-t_1\@xs {
    padding-top: 8px;
  }

  .u-pad-r_1\@xs {
    padding-right: 8px;
  }

  .u-pad-b_1\@xs {
    padding-bottom: 8px;
  }

  .u-pad-l_1\@xs {
    padding-left: 8px;
  }

  .u-pad-lr_1\@xs {
    padding-left: 8px;
    padding-right: 8px;
  }

  .u-pad-tb_1\@xs {
    padding-top: 8px;
    padding-bottom: 8px;
  }
}

@media screen and (max-width: 380px) {
  .u-pad_1\@xxs {
    padding: 8px;
  }

  .u-pad-t_1\@xxs {
    padding-top: 8px;
  }

  .u-pad-r_1\@xxs {
    padding-right: 8px;
  }

  .u-pad-b_1\@xxs {
    padding-bottom: 8px;
  }

  .u-pad-l_1\@xxs {
    padding-left: 8px;
  }

  .u-pad-lr_1\@xxs {
    padding-left: 8px;
    padding-right: 8px;
  }

  .u-pad-tb_1\@xxs {
    padding-top: 8px;
    padding-bottom: 8px;
  }
}

@media screen and (max-width: 1400px) {
  .u-pad_2\@xl {
    padding: 16px;
  }

  .u-pad-t_2\@xl {
    padding-top: 16px;
  }

  .u-pad-r_2\@xl {
    padding-right: 16px;
  }

  .u-pad-b_2\@xl {
    padding-bottom: 16px;
  }

  .u-pad-l_2\@xl {
    padding-left: 16px;
  }

  .u-pad-lr_2\@xl {
    padding-left: 16px;
    padding-right: 16px;
  }

  .u-pad-tb_2\@xl {
    padding-top: 16px;
    padding-bottom: 16px;
  }
}

@media screen and (max-width: 1280px) {
  .u-pad_2\@l {
    padding: 16px;
  }

  .u-pad-t_2\@l {
    padding-top: 16px;
  }

  .u-pad-r_2\@l {
    padding-right: 16px;
  }

  .u-pad-b_2\@l {
    padding-bottom: 16px;
  }

  .u-pad-l_2\@l {
    padding-left: 16px;
  }

  .u-pad-lr_2\@l {
    padding-left: 16px;
    padding-right: 16px;
  }

  .u-pad-tb_2\@l {
    padding-top: 16px;
    padding-bottom: 16px;
  }
}

@media screen and (max-width: 1020px) {
  .u-pad_2\@m {
    padding: 16px;
  }

  .u-pad-t_2\@m {
    padding-top: 16px;
  }

  .u-pad-r_2\@m {
    padding-right: 16px;
  }

  .u-pad-b_2\@m {
    padding-bottom: 16px;
  }

  .u-pad-l_2\@m {
    padding-left: 16px;
  }

  .u-pad-lr_2\@m {
    padding-left: 16px;
    padding-right: 16px;
  }

  .u-pad-tb_2\@m {
    padding-top: 16px;
    padding-bottom: 16px;
  }
}

@media screen and (max-width: 780px) {
  .u-pad_2\@s {
    padding: 16px;
  }

  .u-pad-t_2\@s {
    padding-top: 16px;
  }

  .u-pad-r_2\@s {
    padding-right: 16px;
  }

  .u-pad-b_2\@s {
    padding-bottom: 16px;
  }

  .u-pad-l_2\@s {
    padding-left: 16px;
  }

  .u-pad-lr_2\@s {
    padding-left: 16px;
    padding-right: 16px;
  }

  .u-pad-tb_2\@s {
    padding-top: 16px;
    padding-bottom: 16px;
  }
}

@media screen and (max-width: 440px) {
  .u-pad_2\@xs {
    padding: 16px;
  }

  .u-pad-t_2\@xs {
    padding-top: 16px;
  }

  .u-pad-r_2\@xs {
    padding-right: 16px;
  }

  .u-pad-b_2\@xs {
    padding-bottom: 16px;
  }

  .u-pad-l_2\@xs {
    padding-left: 16px;
  }

  .u-pad-lr_2\@xs {
    padding-left: 16px;
    padding-right: 16px;
  }

  .u-pad-tb_2\@xs {
    padding-top: 16px;
    padding-bottom: 16px;
  }
}

@media screen and (max-width: 380px) {
  .u-pad_2\@xxs {
    padding: 16px;
  }

  .u-pad-t_2\@xxs {
    padding-top: 16px;
  }

  .u-pad-r_2\@xxs {
    padding-right: 16px;
  }

  .u-pad-b_2\@xxs {
    padding-bottom: 16px;
  }

  .u-pad-l_2\@xxs {
    padding-left: 16px;
  }

  .u-pad-lr_2\@xxs {
    padding-left: 16px;
    padding-right: 16px;
  }

  .u-pad-tb_2\@xxs {
    padding-top: 16px;
    padding-bottom: 16px;
  }
}

@media screen and (max-width: 1400px) {
  .u-pad_3\@xl {
    padding: 24px;
  }

  .u-pad-t_3\@xl {
    padding-top: 24px;
  }

  .u-pad-r_3\@xl {
    padding-right: 24px;
  }

  .u-pad-b_3\@xl {
    padding-bottom: 24px;
  }

  .u-pad-l_3\@xl {
    padding-left: 24px;
  }

  .u-pad-lr_3\@xl {
    padding-left: 24px;
    padding-right: 24px;
  }

  .u-pad-tb_3\@xl {
    padding-top: 24px;
    padding-bottom: 24px;
  }
}

@media screen and (max-width: 1280px) {
  .u-pad_3\@l {
    padding: 24px;
  }

  .u-pad-t_3\@l {
    padding-top: 24px;
  }

  .u-pad-r_3\@l {
    padding-right: 24px;
  }

  .u-pad-b_3\@l {
    padding-bottom: 24px;
  }

  .u-pad-l_3\@l {
    padding-left: 24px;
  }

  .u-pad-lr_3\@l {
    padding-left: 24px;
    padding-right: 24px;
  }

  .u-pad-tb_3\@l {
    padding-top: 24px;
    padding-bottom: 24px;
  }
}

@media screen and (max-width: 1020px) {
  .u-pad_3\@m {
    padding: 24px;
  }

  .u-pad-t_3\@m {
    padding-top: 24px;
  }

  .u-pad-r_3\@m {
    padding-right: 24px;
  }

  .u-pad-b_3\@m {
    padding-bottom: 24px;
  }

  .u-pad-l_3\@m {
    padding-left: 24px;
  }

  .u-pad-lr_3\@m {
    padding-left: 24px;
    padding-right: 24px;
  }

  .u-pad-tb_3\@m {
    padding-top: 24px;
    padding-bottom: 24px;
  }
}

@media screen and (max-width: 780px) {
  .u-pad_3\@s {
    padding: 24px;
  }

  .u-pad-t_3\@s {
    padding-top: 24px;
  }

  .u-pad-r_3\@s {
    padding-right: 24px;
  }

  .u-pad-b_3\@s {
    padding-bottom: 24px;
  }

  .u-pad-l_3\@s {
    padding-left: 24px;
  }

  .u-pad-lr_3\@s {
    padding-left: 24px;
    padding-right: 24px;
  }

  .u-pad-tb_3\@s {
    padding-top: 24px;
    padding-bottom: 24px;
  }
}

@media screen and (max-width: 440px) {
  .u-pad_3\@xs {
    padding: 24px;
  }

  .u-pad-t_3\@xs {
    padding-top: 24px;
  }

  .u-pad-r_3\@xs {
    padding-right: 24px;
  }

  .u-pad-b_3\@xs {
    padding-bottom: 24px;
  }

  .u-pad-l_3\@xs {
    padding-left: 24px;
  }

  .u-pad-lr_3\@xs {
    padding-left: 24px;
    padding-right: 24px;
  }

  .u-pad-tb_3\@xs {
    padding-top: 24px;
    padding-bottom: 24px;
  }
}

@media screen and (max-width: 380px) {
  .u-pad_3\@xxs {
    padding: 24px;
  }

  .u-pad-t_3\@xxs {
    padding-top: 24px;
  }

  .u-pad-r_3\@xxs {
    padding-right: 24px;
  }

  .u-pad-b_3\@xxs {
    padding-bottom: 24px;
  }

  .u-pad-l_3\@xxs {
    padding-left: 24px;
  }

  .u-pad-lr_3\@xxs {
    padding-left: 24px;
    padding-right: 24px;
  }

  .u-pad-tb_3\@xxs {
    padding-top: 24px;
    padding-bottom: 24px;
  }
}

@media screen and (max-width: 1400px) {
  .u-pad_4\@xl {
    padding: 32px;
  }

  .u-pad-t_4\@xl {
    padding-top: 32px;
  }

  .u-pad-r_4\@xl {
    padding-right: 32px;
  }

  .u-pad-b_4\@xl {
    padding-bottom: 32px;
  }

  .u-pad-l_4\@xl {
    padding-left: 32px;
  }

  .u-pad-lr_4\@xl {
    padding-left: 32px;
    padding-right: 32px;
  }

  .u-pad-tb_4\@xl {
    padding-top: 32px;
    padding-bottom: 32px;
  }
}

@media screen and (max-width: 1280px) {
  .u-pad_4\@l {
    padding: 32px;
  }

  .u-pad-t_4\@l {
    padding-top: 32px;
  }

  .u-pad-r_4\@l {
    padding-right: 32px;
  }

  .u-pad-b_4\@l {
    padding-bottom: 32px;
  }

  .u-pad-l_4\@l {
    padding-left: 32px;
  }

  .u-pad-lr_4\@l {
    padding-left: 32px;
    padding-right: 32px;
  }

  .u-pad-tb_4\@l {
    padding-top: 32px;
    padding-bottom: 32px;
  }
}

@media screen and (max-width: 1020px) {
  .u-pad_4\@m {
    padding: 32px;
  }

  .u-pad-t_4\@m {
    padding-top: 32px;
  }

  .u-pad-r_4\@m {
    padding-right: 32px;
  }

  .u-pad-b_4\@m {
    padding-bottom: 32px;
  }

  .u-pad-l_4\@m {
    padding-left: 32px;
  }

  .u-pad-lr_4\@m {
    padding-left: 32px;
    padding-right: 32px;
  }

  .u-pad-tb_4\@m {
    padding-top: 32px;
    padding-bottom: 32px;
  }
}

@media screen and (max-width: 780px) {
  .u-pad_4\@s {
    padding: 32px;
  }

  .u-pad-t_4\@s {
    padding-top: 32px;
  }

  .u-pad-r_4\@s {
    padding-right: 32px;
  }

  .u-pad-b_4\@s {
    padding-bottom: 32px;
  }

  .u-pad-l_4\@s {
    padding-left: 32px;
  }

  .u-pad-lr_4\@s {
    padding-left: 32px;
    padding-right: 32px;
  }

  .u-pad-tb_4\@s {
    padding-top: 32px;
    padding-bottom: 32px;
  }
}

@media screen and (max-width: 440px) {
  .u-pad_4\@xs {
    padding: 32px;
  }

  .u-pad-t_4\@xs {
    padding-top: 32px;
  }

  .u-pad-r_4\@xs {
    padding-right: 32px;
  }

  .u-pad-b_4\@xs {
    padding-bottom: 32px;
  }

  .u-pad-l_4\@xs {
    padding-left: 32px;
  }

  .u-pad-lr_4\@xs {
    padding-left: 32px;
    padding-right: 32px;
  }

  .u-pad-tb_4\@xs {
    padding-top: 32px;
    padding-bottom: 32px;
  }
}

@media screen and (max-width: 380px) {
  .u-pad_4\@xxs {
    padding: 32px;
  }

  .u-pad-t_4\@xxs {
    padding-top: 32px;
  }

  .u-pad-r_4\@xxs {
    padding-right: 32px;
  }

  .u-pad-b_4\@xxs {
    padding-bottom: 32px;
  }

  .u-pad-l_4\@xxs {
    padding-left: 32px;
  }

  .u-pad-lr_4\@xxs {
    padding-left: 32px;
    padding-right: 32px;
  }

  .u-pad-tb_4\@xxs {
    padding-top: 32px;
    padding-bottom: 32px;
  }
}

@media screen and (max-width: 1400px) {
  .u-pad_5\@xl {
    padding: 40px;
  }

  .u-pad-t_5\@xl {
    padding-top: 40px;
  }

  .u-pad-r_5\@xl {
    padding-right: 40px;
  }

  .u-pad-b_5\@xl {
    padding-bottom: 40px;
  }

  .u-pad-l_5\@xl {
    padding-left: 40px;
  }

  .u-pad-lr_5\@xl {
    padding-left: 40px;
    padding-right: 40px;
  }

  .u-pad-tb_5\@xl {
    padding-top: 40px;
    padding-bottom: 40px;
  }
}

@media screen and (max-width: 1280px) {
  .u-pad_5\@l {
    padding: 40px;
  }

  .u-pad-t_5\@l {
    padding-top: 40px;
  }

  .u-pad-r_5\@l {
    padding-right: 40px;
  }

  .u-pad-b_5\@l {
    padding-bottom: 40px;
  }

  .u-pad-l_5\@l {
    padding-left: 40px;
  }

  .u-pad-lr_5\@l {
    padding-left: 40px;
    padding-right: 40px;
  }

  .u-pad-tb_5\@l {
    padding-top: 40px;
    padding-bottom: 40px;
  }
}

@media screen and (max-width: 1020px) {
  .u-pad_5\@m {
    padding: 40px;
  }

  .u-pad-t_5\@m {
    padding-top: 40px;
  }

  .u-pad-r_5\@m {
    padding-right: 40px;
  }

  .u-pad-b_5\@m {
    padding-bottom: 40px;
  }

  .u-pad-l_5\@m {
    padding-left: 40px;
  }

  .u-pad-lr_5\@m {
    padding-left: 40px;
    padding-right: 40px;
  }

  .u-pad-tb_5\@m {
    padding-top: 40px;
    padding-bottom: 40px;
  }
}

@media screen and (max-width: 780px) {
  .u-pad_5\@s {
    padding: 40px;
  }

  .u-pad-t_5\@s {
    padding-top: 40px;
  }

  .u-pad-r_5\@s {
    padding-right: 40px;
  }

  .u-pad-b_5\@s {
    padding-bottom: 40px;
  }

  .u-pad-l_5\@s {
    padding-left: 40px;
  }

  .u-pad-lr_5\@s {
    padding-left: 40px;
    padding-right: 40px;
  }

  .u-pad-tb_5\@s {
    padding-top: 40px;
    padding-bottom: 40px;
  }
}

@media screen and (max-width: 440px) {
  .u-pad_5\@xs {
    padding: 40px;
  }

  .u-pad-t_5\@xs {
    padding-top: 40px;
  }

  .u-pad-r_5\@xs {
    padding-right: 40px;
  }

  .u-pad-b_5\@xs {
    padding-bottom: 40px;
  }

  .u-pad-l_5\@xs {
    padding-left: 40px;
  }

  .u-pad-lr_5\@xs {
    padding-left: 40px;
    padding-right: 40px;
  }

  .u-pad-tb_5\@xs {
    padding-top: 40px;
    padding-bottom: 40px;
  }
}

@media screen and (max-width: 380px) {
  .u-pad_5\@xxs {
    padding: 40px;
  }

  .u-pad-t_5\@xxs {
    padding-top: 40px;
  }

  .u-pad-r_5\@xxs {
    padding-right: 40px;
  }

  .u-pad-b_5\@xxs {
    padding-bottom: 40px;
  }

  .u-pad-l_5\@xxs {
    padding-left: 40px;
  }

  .u-pad-lr_5\@xxs {
    padding-left: 40px;
    padding-right: 40px;
  }

  .u-pad-tb_5\@xxs {
    padding-top: 40px;
    padding-bottom: 40px;
  }
}

@media screen and (max-width: 1400px) {
  .u-pad_6\@xl {
    padding: 48px;
  }

  .u-pad-t_6\@xl {
    padding-top: 48px;
  }

  .u-pad-r_6\@xl {
    padding-right: 48px;
  }

  .u-pad-b_6\@xl {
    padding-bottom: 48px;
  }

  .u-pad-l_6\@xl {
    padding-left: 48px;
  }

  .u-pad-lr_6\@xl {
    padding-left: 48px;
    padding-right: 48px;
  }

  .u-pad-tb_6\@xl {
    padding-top: 48px;
    padding-bottom: 48px;
  }
}

@media screen and (max-width: 1280px) {
  .u-pad_6\@l {
    padding: 48px;
  }

  .u-pad-t_6\@l {
    padding-top: 48px;
  }

  .u-pad-r_6\@l {
    padding-right: 48px;
  }

  .u-pad-b_6\@l {
    padding-bottom: 48px;
  }

  .u-pad-l_6\@l {
    padding-left: 48px;
  }

  .u-pad-lr_6\@l {
    padding-left: 48px;
    padding-right: 48px;
  }

  .u-pad-tb_6\@l {
    padding-top: 48px;
    padding-bottom: 48px;
  }
}

@media screen and (max-width: 1020px) {
  .u-pad_6\@m {
    padding: 48px;
  }

  .u-pad-t_6\@m {
    padding-top: 48px;
  }

  .u-pad-r_6\@m {
    padding-right: 48px;
  }

  .u-pad-b_6\@m {
    padding-bottom: 48px;
  }

  .u-pad-l_6\@m {
    padding-left: 48px;
  }

  .u-pad-lr_6\@m {
    padding-left: 48px;
    padding-right: 48px;
  }

  .u-pad-tb_6\@m {
    padding-top: 48px;
    padding-bottom: 48px;
  }
}

@media screen and (max-width: 780px) {
  .u-pad_6\@s {
    padding: 48px;
  }

  .u-pad-t_6\@s {
    padding-top: 48px;
  }

  .u-pad-r_6\@s {
    padding-right: 48px;
  }

  .u-pad-b_6\@s {
    padding-bottom: 48px;
  }

  .u-pad-l_6\@s {
    padding-left: 48px;
  }

  .u-pad-lr_6\@s {
    padding-left: 48px;
    padding-right: 48px;
  }

  .u-pad-tb_6\@s {
    padding-top: 48px;
    padding-bottom: 48px;
  }
}

@media screen and (max-width: 440px) {
  .u-pad_6\@xs {
    padding: 48px;
  }

  .u-pad-t_6\@xs {
    padding-top: 48px;
  }

  .u-pad-r_6\@xs {
    padding-right: 48px;
  }

  .u-pad-b_6\@xs {
    padding-bottom: 48px;
  }

  .u-pad-l_6\@xs {
    padding-left: 48px;
  }

  .u-pad-lr_6\@xs {
    padding-left: 48px;
    padding-right: 48px;
  }

  .u-pad-tb_6\@xs {
    padding-top: 48px;
    padding-bottom: 48px;
  }
}

@media screen and (max-width: 380px) {
  .u-pad_6\@xxs {
    padding: 48px;
  }

  .u-pad-t_6\@xxs {
    padding-top: 48px;
  }

  .u-pad-r_6\@xxs {
    padding-right: 48px;
  }

  .u-pad-b_6\@xxs {
    padding-bottom: 48px;
  }

  .u-pad-l_6\@xxs {
    padding-left: 48px;
  }

  .u-pad-lr_6\@xxs {
    padding-left: 48px;
    padding-right: 48px;
  }

  .u-pad-tb_6\@xxs {
    padding-top: 48px;
    padding-bottom: 48px;
  }
}

@media screen and (max-width: 1400px) {
  .u-pad_7\@xl {
    padding: 56px;
  }

  .u-pad-t_7\@xl {
    padding-top: 56px;
  }

  .u-pad-r_7\@xl {
    padding-right: 56px;
  }

  .u-pad-b_7\@xl {
    padding-bottom: 56px;
  }

  .u-pad-l_7\@xl {
    padding-left: 56px;
  }

  .u-pad-lr_7\@xl {
    padding-left: 56px;
    padding-right: 56px;
  }

  .u-pad-tb_7\@xl {
    padding-top: 56px;
    padding-bottom: 56px;
  }
}

@media screen and (max-width: 1280px) {
  .u-pad_7\@l {
    padding: 56px;
  }

  .u-pad-t_7\@l {
    padding-top: 56px;
  }

  .u-pad-r_7\@l {
    padding-right: 56px;
  }

  .u-pad-b_7\@l {
    padding-bottom: 56px;
  }

  .u-pad-l_7\@l {
    padding-left: 56px;
  }

  .u-pad-lr_7\@l {
    padding-left: 56px;
    padding-right: 56px;
  }

  .u-pad-tb_7\@l {
    padding-top: 56px;
    padding-bottom: 56px;
  }
}

@media screen and (max-width: 1020px) {
  .u-pad_7\@m {
    padding: 56px;
  }

  .u-pad-t_7\@m {
    padding-top: 56px;
  }

  .u-pad-r_7\@m {
    padding-right: 56px;
  }

  .u-pad-b_7\@m {
    padding-bottom: 56px;
  }

  .u-pad-l_7\@m {
    padding-left: 56px;
  }

  .u-pad-lr_7\@m {
    padding-left: 56px;
    padding-right: 56px;
  }

  .u-pad-tb_7\@m {
    padding-top: 56px;
    padding-bottom: 56px;
  }
}

@media screen and (max-width: 780px) {
  .u-pad_7\@s {
    padding: 56px;
  }

  .u-pad-t_7\@s {
    padding-top: 56px;
  }

  .u-pad-r_7\@s {
    padding-right: 56px;
  }

  .u-pad-b_7\@s {
    padding-bottom: 56px;
  }

  .u-pad-l_7\@s {
    padding-left: 56px;
  }

  .u-pad-lr_7\@s {
    padding-left: 56px;
    padding-right: 56px;
  }

  .u-pad-tb_7\@s {
    padding-top: 56px;
    padding-bottom: 56px;
  }
}

@media screen and (max-width: 440px) {
  .u-pad_7\@xs {
    padding: 56px;
  }

  .u-pad-t_7\@xs {
    padding-top: 56px;
  }

  .u-pad-r_7\@xs {
    padding-right: 56px;
  }

  .u-pad-b_7\@xs {
    padding-bottom: 56px;
  }

  .u-pad-l_7\@xs {
    padding-left: 56px;
  }

  .u-pad-lr_7\@xs {
    padding-left: 56px;
    padding-right: 56px;
  }

  .u-pad-tb_7\@xs {
    padding-top: 56px;
    padding-bottom: 56px;
  }
}

@media screen and (max-width: 380px) {
  .u-pad_7\@xxs {
    padding: 56px;
  }

  .u-pad-t_7\@xxs {
    padding-top: 56px;
  }

  .u-pad-r_7\@xxs {
    padding-right: 56px;
  }

  .u-pad-b_7\@xxs {
    padding-bottom: 56px;
  }

  .u-pad-l_7\@xxs {
    padding-left: 56px;
  }

  .u-pad-lr_7\@xxs {
    padding-left: 56px;
    padding-right: 56px;
  }

  .u-pad-tb_7\@xxs {
    padding-top: 56px;
    padding-bottom: 56px;
  }
}

@media screen and (max-width: 1400px) {
  .u-pad_8\@xl {
    padding: 64px;
  }

  .u-pad-t_8\@xl {
    padding-top: 64px;
  }

  .u-pad-r_8\@xl {
    padding-right: 64px;
  }

  .u-pad-b_8\@xl {
    padding-bottom: 64px;
  }

  .u-pad-l_8\@xl {
    padding-left: 64px;
  }

  .u-pad-lr_8\@xl {
    padding-left: 64px;
    padding-right: 64px;
  }

  .u-pad-tb_8\@xl {
    padding-top: 64px;
    padding-bottom: 64px;
  }
}

@media screen and (max-width: 1280px) {
  .u-pad_8\@l {
    padding: 64px;
  }

  .u-pad-t_8\@l {
    padding-top: 64px;
  }

  .u-pad-r_8\@l {
    padding-right: 64px;
  }

  .u-pad-b_8\@l {
    padding-bottom: 64px;
  }

  .u-pad-l_8\@l {
    padding-left: 64px;
  }

  .u-pad-lr_8\@l {
    padding-left: 64px;
    padding-right: 64px;
  }

  .u-pad-tb_8\@l {
    padding-top: 64px;
    padding-bottom: 64px;
  }
}

@media screen and (max-width: 1020px) {
  .u-pad_8\@m {
    padding: 64px;
  }

  .u-pad-t_8\@m {
    padding-top: 64px;
  }

  .u-pad-r_8\@m {
    padding-right: 64px;
  }

  .u-pad-b_8\@m {
    padding-bottom: 64px;
  }

  .u-pad-l_8\@m {
    padding-left: 64px;
  }

  .u-pad-lr_8\@m {
    padding-left: 64px;
    padding-right: 64px;
  }

  .u-pad-tb_8\@m {
    padding-top: 64px;
    padding-bottom: 64px;
  }
}

@media screen and (max-width: 780px) {
  .u-pad_8\@s {
    padding: 64px;
  }

  .u-pad-t_8\@s {
    padding-top: 64px;
  }

  .u-pad-r_8\@s {
    padding-right: 64px;
  }

  .u-pad-b_8\@s {
    padding-bottom: 64px;
  }

  .u-pad-l_8\@s {
    padding-left: 64px;
  }

  .u-pad-lr_8\@s {
    padding-left: 64px;
    padding-right: 64px;
  }

  .u-pad-tb_8\@s {
    padding-top: 64px;
    padding-bottom: 64px;
  }
}

@media screen and (max-width: 440px) {
  .u-pad_8\@xs {
    padding: 64px;
  }

  .u-pad-t_8\@xs {
    padding-top: 64px;
  }

  .u-pad-r_8\@xs {
    padding-right: 64px;
  }

  .u-pad-b_8\@xs {
    padding-bottom: 64px;
  }

  .u-pad-l_8\@xs {
    padding-left: 64px;
  }

  .u-pad-lr_8\@xs {
    padding-left: 64px;
    padding-right: 64px;
  }

  .u-pad-tb_8\@xs {
    padding-top: 64px;
    padding-bottom: 64px;
  }
}

@media screen and (max-width: 380px) {
  .u-pad_8\@xxs {
    padding: 64px;
  }

  .u-pad-t_8\@xxs {
    padding-top: 64px;
  }

  .u-pad-r_8\@xxs {
    padding-right: 64px;
  }

  .u-pad-b_8\@xxs {
    padding-bottom: 64px;
  }

  .u-pad-l_8\@xxs {
    padding-left: 64px;
  }

  .u-pad-lr_8\@xxs {
    padding-left: 64px;
    padding-right: 64px;
  }

  .u-pad-tb_8\@xxs {
    padding-top: 64px;
    padding-bottom: 64px;
  }
}

@media screen and (max-width: 1400px) {
  .u-pad_9\@xl {
    padding: 72px;
  }

  .u-pad-t_9\@xl {
    padding-top: 72px;
  }

  .u-pad-r_9\@xl {
    padding-right: 72px;
  }

  .u-pad-b_9\@xl {
    padding-bottom: 72px;
  }

  .u-pad-l_9\@xl {
    padding-left: 72px;
  }

  .u-pad-lr_9\@xl {
    padding-left: 72px;
    padding-right: 72px;
  }

  .u-pad-tb_9\@xl {
    padding-top: 72px;
    padding-bottom: 72px;
  }
}

@media screen and (max-width: 1280px) {
  .u-pad_9\@l {
    padding: 72px;
  }

  .u-pad-t_9\@l {
    padding-top: 72px;
  }

  .u-pad-r_9\@l {
    padding-right: 72px;
  }

  .u-pad-b_9\@l {
    padding-bottom: 72px;
  }

  .u-pad-l_9\@l {
    padding-left: 72px;
  }

  .u-pad-lr_9\@l {
    padding-left: 72px;
    padding-right: 72px;
  }

  .u-pad-tb_9\@l {
    padding-top: 72px;
    padding-bottom: 72px;
  }
}

@media screen and (max-width: 1020px) {
  .u-pad_9\@m {
    padding: 72px;
  }

  .u-pad-t_9\@m {
    padding-top: 72px;
  }

  .u-pad-r_9\@m {
    padding-right: 72px;
  }

  .u-pad-b_9\@m {
    padding-bottom: 72px;
  }

  .u-pad-l_9\@m {
    padding-left: 72px;
  }

  .u-pad-lr_9\@m {
    padding-left: 72px;
    padding-right: 72px;
  }

  .u-pad-tb_9\@m {
    padding-top: 72px;
    padding-bottom: 72px;
  }
}

@media screen and (max-width: 780px) {
  .u-pad_9\@s {
    padding: 72px;
  }

  .u-pad-t_9\@s {
    padding-top: 72px;
  }

  .u-pad-r_9\@s {
    padding-right: 72px;
  }

  .u-pad-b_9\@s {
    padding-bottom: 72px;
  }

  .u-pad-l_9\@s {
    padding-left: 72px;
  }

  .u-pad-lr_9\@s {
    padding-left: 72px;
    padding-right: 72px;
  }

  .u-pad-tb_9\@s {
    padding-top: 72px;
    padding-bottom: 72px;
  }
}

@media screen and (max-width: 440px) {
  .u-pad_9\@xs {
    padding: 72px;
  }

  .u-pad-t_9\@xs {
    padding-top: 72px;
  }

  .u-pad-r_9\@xs {
    padding-right: 72px;
  }

  .u-pad-b_9\@xs {
    padding-bottom: 72px;
  }

  .u-pad-l_9\@xs {
    padding-left: 72px;
  }

  .u-pad-lr_9\@xs {
    padding-left: 72px;
    padding-right: 72px;
  }

  .u-pad-tb_9\@xs {
    padding-top: 72px;
    padding-bottom: 72px;
  }
}

@media screen and (max-width: 380px) {
  .u-pad_9\@xxs {
    padding: 72px;
  }

  .u-pad-t_9\@xxs {
    padding-top: 72px;
  }

  .u-pad-r_9\@xxs {
    padding-right: 72px;
  }

  .u-pad-b_9\@xxs {
    padding-bottom: 72px;
  }

  .u-pad-l_9\@xxs {
    padding-left: 72px;
  }

  .u-pad-lr_9\@xxs {
    padding-left: 72px;
    padding-right: 72px;
  }

  .u-pad-tb_9\@xxs {
    padding-top: 72px;
    padding-bottom: 72px;
  }
}

@media screen and (max-width: 1400px) {
  .u-pad_10\@xl {
    padding: 80px;
  }

  .u-pad-t_10\@xl {
    padding-top: 80px;
  }

  .u-pad-r_10\@xl {
    padding-right: 80px;
  }

  .u-pad-b_10\@xl {
    padding-bottom: 80px;
  }

  .u-pad-l_10\@xl {
    padding-left: 80px;
  }

  .u-pad-lr_10\@xl {
    padding-left: 80px;
    padding-right: 80px;
  }

  .u-pad-tb_10\@xl {
    padding-top: 80px;
    padding-bottom: 80px;
  }
}

@media screen and (max-width: 1280px) {
  .u-pad_10\@l {
    padding: 80px;
  }

  .u-pad-t_10\@l {
    padding-top: 80px;
  }

  .u-pad-r_10\@l {
    padding-right: 80px;
  }

  .u-pad-b_10\@l {
    padding-bottom: 80px;
  }

  .u-pad-l_10\@l {
    padding-left: 80px;
  }

  .u-pad-lr_10\@l {
    padding-left: 80px;
    padding-right: 80px;
  }

  .u-pad-tb_10\@l {
    padding-top: 80px;
    padding-bottom: 80px;
  }
}

@media screen and (max-width: 1020px) {
  .u-pad_10\@m {
    padding: 80px;
  }

  .u-pad-t_10\@m {
    padding-top: 80px;
  }

  .u-pad-r_10\@m {
    padding-right: 80px;
  }

  .u-pad-b_10\@m {
    padding-bottom: 80px;
  }

  .u-pad-l_10\@m {
    padding-left: 80px;
  }

  .u-pad-lr_10\@m {
    padding-left: 80px;
    padding-right: 80px;
  }

  .u-pad-tb_10\@m {
    padding-top: 80px;
    padding-bottom: 80px;
  }
}

@media screen and (max-width: 780px) {
  .u-pad_10\@s {
    padding: 80px;
  }

  .u-pad-t_10\@s {
    padding-top: 80px;
  }

  .u-pad-r_10\@s {
    padding-right: 80px;
  }

  .u-pad-b_10\@s {
    padding-bottom: 80px;
  }

  .u-pad-l_10\@s {
    padding-left: 80px;
  }

  .u-pad-lr_10\@s {
    padding-left: 80px;
    padding-right: 80px;
  }

  .u-pad-tb_10\@s {
    padding-top: 80px;
    padding-bottom: 80px;
  }
}

@media screen and (max-width: 440px) {
  .u-pad_10\@xs {
    padding: 80px;
  }

  .u-pad-t_10\@xs {
    padding-top: 80px;
  }

  .u-pad-r_10\@xs {
    padding-right: 80px;
  }

  .u-pad-b_10\@xs {
    padding-bottom: 80px;
  }

  .u-pad-l_10\@xs {
    padding-left: 80px;
  }

  .u-pad-lr_10\@xs {
    padding-left: 80px;
    padding-right: 80px;
  }

  .u-pad-tb_10\@xs {
    padding-top: 80px;
    padding-bottom: 80px;
  }
}

@media screen and (max-width: 380px) {
  .u-pad_10\@xxs {
    padding: 80px;
  }

  .u-pad-t_10\@xxs {
    padding-top: 80px;
  }

  .u-pad-r_10\@xxs {
    padding-right: 80px;
  }

  .u-pad-b_10\@xxs {
    padding-bottom: 80px;
  }

  .u-pad-l_10\@xxs {
    padding-left: 80px;
  }

  .u-pad-lr_10\@xxs {
    padding-left: 80px;
    padding-right: 80px;
  }

  .u-pad-tb_10\@xxs {
    padding-top: 80px;
    padding-bottom: 80px;
  }
}

@media screen and (max-width: 1400px) {
  .u-pad_11\@xl {
    padding: 88px;
  }

  .u-pad-t_11\@xl {
    padding-top: 88px;
  }

  .u-pad-r_11\@xl {
    padding-right: 88px;
  }

  .u-pad-b_11\@xl {
    padding-bottom: 88px;
  }

  .u-pad-l_11\@xl {
    padding-left: 88px;
  }

  .u-pad-lr_11\@xl {
    padding-left: 88px;
    padding-right: 88px;
  }

  .u-pad-tb_11\@xl {
    padding-top: 88px;
    padding-bottom: 88px;
  }
}

@media screen and (max-width: 1280px) {
  .u-pad_11\@l {
    padding: 88px;
  }

  .u-pad-t_11\@l {
    padding-top: 88px;
  }

  .u-pad-r_11\@l {
    padding-right: 88px;
  }

  .u-pad-b_11\@l {
    padding-bottom: 88px;
  }

  .u-pad-l_11\@l {
    padding-left: 88px;
  }

  .u-pad-lr_11\@l {
    padding-left: 88px;
    padding-right: 88px;
  }

  .u-pad-tb_11\@l {
    padding-top: 88px;
    padding-bottom: 88px;
  }
}

@media screen and (max-width: 1020px) {
  .u-pad_11\@m {
    padding: 88px;
  }

  .u-pad-t_11\@m {
    padding-top: 88px;
  }

  .u-pad-r_11\@m {
    padding-right: 88px;
  }

  .u-pad-b_11\@m {
    padding-bottom: 88px;
  }

  .u-pad-l_11\@m {
    padding-left: 88px;
  }

  .u-pad-lr_11\@m {
    padding-left: 88px;
    padding-right: 88px;
  }

  .u-pad-tb_11\@m {
    padding-top: 88px;
    padding-bottom: 88px;
  }
}

@media screen and (max-width: 780px) {
  .u-pad_11\@s {
    padding: 88px;
  }

  .u-pad-t_11\@s {
    padding-top: 88px;
  }

  .u-pad-r_11\@s {
    padding-right: 88px;
  }

  .u-pad-b_11\@s {
    padding-bottom: 88px;
  }

  .u-pad-l_11\@s {
    padding-left: 88px;
  }

  .u-pad-lr_11\@s {
    padding-left: 88px;
    padding-right: 88px;
  }

  .u-pad-tb_11\@s {
    padding-top: 88px;
    padding-bottom: 88px;
  }
}

@media screen and (max-width: 440px) {
  .u-pad_11\@xs {
    padding: 88px;
  }

  .u-pad-t_11\@xs {
    padding-top: 88px;
  }

  .u-pad-r_11\@xs {
    padding-right: 88px;
  }

  .u-pad-b_11\@xs {
    padding-bottom: 88px;
  }

  .u-pad-l_11\@xs {
    padding-left: 88px;
  }

  .u-pad-lr_11\@xs {
    padding-left: 88px;
    padding-right: 88px;
  }

  .u-pad-tb_11\@xs {
    padding-top: 88px;
    padding-bottom: 88px;
  }
}

@media screen and (max-width: 380px) {
  .u-pad_11\@xxs {
    padding: 88px;
  }

  .u-pad-t_11\@xxs {
    padding-top: 88px;
  }

  .u-pad-r_11\@xxs {
    padding-right: 88px;
  }

  .u-pad-b_11\@xxs {
    padding-bottom: 88px;
  }

  .u-pad-l_11\@xxs {
    padding-left: 88px;
  }

  .u-pad-lr_11\@xxs {
    padding-left: 88px;
    padding-right: 88px;
  }

  .u-pad-tb_11\@xxs {
    padding-top: 88px;
    padding-bottom: 88px;
  }
}

@media screen and (max-width: 1400px) {
  .u-pad_12\@xl {
    padding: 96px;
  }

  .u-pad-t_12\@xl {
    padding-top: 96px;
  }

  .u-pad-r_12\@xl {
    padding-right: 96px;
  }

  .u-pad-b_12\@xl {
    padding-bottom: 96px;
  }

  .u-pad-l_12\@xl {
    padding-left: 96px;
  }

  .u-pad-lr_12\@xl {
    padding-left: 96px;
    padding-right: 96px;
  }

  .u-pad-tb_12\@xl {
    padding-top: 96px;
    padding-bottom: 96px;
  }
}

@media screen and (max-width: 1280px) {
  .u-pad_12\@l {
    padding: 96px;
  }

  .u-pad-t_12\@l {
    padding-top: 96px;
  }

  .u-pad-r_12\@l {
    padding-right: 96px;
  }

  .u-pad-b_12\@l {
    padding-bottom: 96px;
  }

  .u-pad-l_12\@l {
    padding-left: 96px;
  }

  .u-pad-lr_12\@l {
    padding-left: 96px;
    padding-right: 96px;
  }

  .u-pad-tb_12\@l {
    padding-top: 96px;
    padding-bottom: 96px;
  }
}

@media screen and (max-width: 1020px) {
  .u-pad_12\@m {
    padding: 96px;
  }

  .u-pad-t_12\@m {
    padding-top: 96px;
  }

  .u-pad-r_12\@m {
    padding-right: 96px;
  }

  .u-pad-b_12\@m {
    padding-bottom: 96px;
  }

  .u-pad-l_12\@m {
    padding-left: 96px;
  }

  .u-pad-lr_12\@m {
    padding-left: 96px;
    padding-right: 96px;
  }

  .u-pad-tb_12\@m {
    padding-top: 96px;
    padding-bottom: 96px;
  }
}

@media screen and (max-width: 780px) {
  .u-pad_12\@s {
    padding: 96px;
  }

  .u-pad-t_12\@s {
    padding-top: 96px;
  }

  .u-pad-r_12\@s {
    padding-right: 96px;
  }

  .u-pad-b_12\@s {
    padding-bottom: 96px;
  }

  .u-pad-l_12\@s {
    padding-left: 96px;
  }

  .u-pad-lr_12\@s {
    padding-left: 96px;
    padding-right: 96px;
  }

  .u-pad-tb_12\@s {
    padding-top: 96px;
    padding-bottom: 96px;
  }
}

@media screen and (max-width: 440px) {
  .u-pad_12\@xs {
    padding: 96px;
  }

  .u-pad-t_12\@xs {
    padding-top: 96px;
  }

  .u-pad-r_12\@xs {
    padding-right: 96px;
  }

  .u-pad-b_12\@xs {
    padding-bottom: 96px;
  }

  .u-pad-l_12\@xs {
    padding-left: 96px;
  }

  .u-pad-lr_12\@xs {
    padding-left: 96px;
    padding-right: 96px;
  }

  .u-pad-tb_12\@xs {
    padding-top: 96px;
    padding-bottom: 96px;
  }
}

@media screen and (max-width: 380px) {
  .u-pad_12\@xxs {
    padding: 96px;
  }

  .u-pad-t_12\@xxs {
    padding-top: 96px;
  }

  .u-pad-r_12\@xxs {
    padding-right: 96px;
  }

  .u-pad-b_12\@xxs {
    padding-bottom: 96px;
  }

  .u-pad-l_12\@xxs {
    padding-left: 96px;
  }

  .u-pad-lr_12\@xxs {
    padding-left: 96px;
    padding-right: 96px;
  }

  .u-pad-tb_12\@xxs {
    padding-top: 96px;
    padding-bottom: 96px;
  }
}

.u-mar_auto {
  margin: auto;
}

@media screen and (max-width: 1400px) {
  .u-mar_auto\@xl {
    margin: auto;
  }
}

@media screen and (max-width: 1280px) {
  .u-mar_auto\@l {
    margin: auto;
  }
}

@media screen and (max-width: 1020px) {
  .u-mar_auto\@m {
    margin: auto;
  }
}

@media screen and (max-width: 780px) {
  .u-mar_auto\@s {
    margin: auto;
  }
}

@media screen and (max-width: 440px) {
  .u-mar_auto\@xs {
    margin: auto;
  }
}

@media screen and (max-width: 380px) {
  .u-mar_auto\@xxs {
    margin: auto;
  }
}

.u-mar-t_auto {
  margin-top: auto;
}

@media screen and (max-width: 1400px) {
  .u-mar-t_auto\@xl {
    margin-top: auto;
  }
}

@media screen and (max-width: 1280px) {
  .u-mar-t_auto\@l {
    margin-top: auto;
  }
}

@media screen and (max-width: 1020px) {
  .u-mar-t_auto\@m {
    margin-top: auto;
  }
}

@media screen and (max-width: 780px) {
  .u-mar-t_auto\@s {
    margin-top: auto;
  }
}

@media screen and (max-width: 440px) {
  .u-mar-t_auto\@xs {
    margin-top: auto;
  }
}

@media screen and (max-width: 380px) {
  .u-mar-t_auto\@xxs {
    margin-top: auto;
  }
}

.u-mar-r_auto {
  margin-right: auto;
}

@media screen and (max-width: 1400px) {
  .u-mar-r_auto\@xl {
    margin-right: auto;
  }
}

@media screen and (max-width: 1280px) {
  .u-mar-r_auto\@l {
    margin-right: auto;
  }
}

@media screen and (max-width: 1020px) {
  .u-mar-r_auto\@m {
    margin-right: auto;
  }
}

@media screen and (max-width: 780px) {
  .u-mar-r_auto\@s {
    margin-right: auto;
  }
}

@media screen and (max-width: 440px) {
  .u-mar-r_auto\@xs {
    margin-right: auto;
  }
}

@media screen and (max-width: 380px) {
  .u-mar-r_auto\@xxs {
    margin-right: auto;
  }
}

.u-mar-b_auto {
  margin-bottom: auto;
}

@media screen and (max-width: 1400px) {
  .u-mar-b_auto\@xl {
    margin-bottom: auto;
  }
}

@media screen and (max-width: 1280px) {
  .u-mar-b_auto\@l {
    margin-bottom: auto;
  }
}

@media screen and (max-width: 1020px) {
  .u-mar-b_auto\@m {
    margin-bottom: auto;
  }
}

@media screen and (max-width: 780px) {
  .u-mar-b_auto\@s {
    margin-bottom: auto;
  }
}

@media screen and (max-width: 440px) {
  .u-mar-b_auto\@xs {
    margin-bottom: auto;
  }
}

@media screen and (max-width: 380px) {
  .u-mar-b_auto\@xxs {
    margin-bottom: auto;
  }
}

.u-mar-l_auto {
  margin-left: auto;
}

@media screen and (max-width: 1400px) {
  .u-mar-l_auto\@xl {
    margin-left: auto;
  }
}

@media screen and (max-width: 1280px) {
  .u-mar-l_auto\@l {
    margin-left: auto;
  }
}

@media screen and (max-width: 1020px) {
  .u-mar-l_auto\@m {
    margin-left: auto;
  }
}

@media screen and (max-width: 780px) {
  .u-mar-l_auto\@s {
    margin-left: auto;
  }
}

@media screen and (max-width: 440px) {
  .u-mar-l_auto\@xs {
    margin-left: auto;
  }
}

@media screen and (max-width: 380px) {
  .u-mar-l_auto\@xxs {
    margin-left: auto;
  }
}

.u-mar-lr_auto {
  margin-left: auto;
  margin-right: auto;
}

@media screen and (max-width: 1400px) {
  .u-mar-lr_auto\@xl {
    margin-left: auto;
    margin-right: auto;
  }
}

@media screen and (max-width: 1280px) {
  .u-mar-lr_auto\@l {
    margin-left: auto;
    margin-right: auto;
  }
}

@media screen and (max-width: 1020px) {
  .u-mar-lr_auto\@m {
    margin-left: auto;
    margin-right: auto;
  }
}

@media screen and (max-width: 780px) {
  .u-mar-lr_auto\@s {
    margin-left: auto;
    margin-right: auto;
  }
}

@media screen and (max-width: 440px) {
  .u-mar-lr_auto\@xs {
    margin-left: auto;
    margin-right: auto;
  }
}

@media screen and (max-width: 380px) {
  .u-mar-lr_auto\@xxs {
    margin-left: auto;
    margin-right: auto;
  }
}

.u-mar-tb_auto {
  margin-top: auto;
  margin-bottom: auto;
}

@media screen and (max-width: 1400px) {
  .u-mar-tb_auto\@xl {
    margin-top: auto;
    margin-bottom: auto;
  }
}

@media screen and (max-width: 1280px) {
  .u-mar-tb_auto\@l {
    margin-top: auto;
    margin-bottom: auto;
  }
}

@media screen and (max-width: 1020px) {
  .u-mar-tb_auto\@m {
    margin-top: auto;
    margin-bottom: auto;
  }
}

@media screen and (max-width: 780px) {
  .u-mar-tb_auto\@s {
    margin-top: auto;
    margin-bottom: auto;
  }
}

@media screen and (max-width: 440px) {
  .u-mar-tb_auto\@xs {
    margin-top: auto;
    margin-bottom: auto;
  }
}

@media screen and (max-width: 380px) {
  .u-mar-tb_auto\@xxs {
    margin-top: auto;
    margin-bottom: auto;
  }
}

.u-color_blue-050 {
  color: #ebf5fa;
}

.u-color_blue-100 {
  color: #d3eaf8;
}

.u-color_blue-200 {
  color: #a8d4f0;
}

.u-color_blue-300 {
  color: #7cbfe9;
}

.u-color_blue-400 {
  color: #50a9e2;
}

.u-color_blue-500 {
  color: #1e7cb8;
}

.u-color_blue-600 {
  color: #1a699e;
}

.u-color_blue-700 {
  color: #155884;
}

.u-color_blue-800 {
  color: #104060;
}

.u-color_blue-900 {
  color: #0b2f46;
}

.u-color_slate-050 {
  color: #f9fafb;
}

.u-color_slate-100 {
  color: #f3f4f6;
}

.u-color_slate-200 {
  color: #e5e7eb;
}

.u-color_slate-300 {
  color: #d1d5db;
}

.u-color_slate-400 {
  color: #9ca3af;
}

.u-color_slate-500 {
  color: #6b7280;
}

.u-color_slate-600 {
  color: #4b5563;
}

.u-color_slate-700 {
  color: #374151;
}

.u-color_slate-800 {
  color: #1f2937;
}

.u-color_slate-900 {
  color: #111827;
}

.u-color_green-050 {
  color: #ecfdf5;
}

.u-color_green-100 {
  color: #d1fae5;
}

.u-color_green-200 {
  color: #a7f3d0;
}

.u-color_green-300 {
  color: #6ee7b7;
}

.u-color_green-400 {
  color: #34d399;
}

.u-color_green-500 {
  color: #10b981;
}

.u-color_green-600 {
  color: #059669;
}

.u-color_green-700 {
  color: #047857;
}

.u-color_green-800 {
  color: #065f46;
}

.u-color_green-900 {
  color: #064e3b;
}

.u-color_yellow-050 {
  color: #fffbeb;
}

.u-color_yellow-100 {
  color: #fef3c7;
}

.u-color_yellow-200 {
  color: #fde68a;
}

.u-color_yellow-300 {
  color: #fcd34d;
}

.u-color_yellow-400 {
  color: #fbbf24;
}

.u-color_yellow-500 {
  color: #f59e0b;
}

.u-color_yellow-600 {
  color: #d97706;
}

.u-color_yellow-700 {
  color: #b45309;
}

.u-color_yellow-800 {
  color: #92400e;
}

.u-color_yellow-900 {
  color: #78350f;
}

.u-color_red-050 {
  color: #fbefef;
}

.u-color_red-100 {
  color: #f7d7d4;
}

.u-color_red-200 {
  color: #eb968e;
}

.u-color_red-300 {
  color: #e36e63;
}

.u-color_red-400 {
  color: #dc4538;
}

.u-color_red-500 {
  color: #cc3224;
}

.u-color_red-600 {
  color: #9c251c;
}

.u-color_red-700 {
  color: #821f17;
}

.u-color_red-800 {
  color: #5f1711;
}

.u-color_red-900 {
  color: #45110c;
}

.u-color_purple-050 {
  color: #f8f3fb;
}

.u-color_purple-075 {
  color: #f5effa;
}

.u-color_purple-100 {
  color: #e7d8f3;
}

.u-color_purple-200 {
  color: #b68adb;
}

.u-color_purple-300 {
  color: #ceb1e7;
}

.u-color_purple-400 {
  color: #9d63cf;
}

.u-color_purple-500 {
  color: #7d39b7;
}

.u-color_purple-600 {
  color: #652e94;
}

.u-color_purple-700 {
  color: #502475;
}

.u-color_purple-800 {
  color: #3a1b56;
}

.u-color_purple-900 {
  color: #2b133e;
}

.u-color_white {
  color: #fff;
}

.u-color_black {
  color: #000;
}

.u-color_transparent {
  color: rgba(0, 0, 0, 0);
}

.u-bgc_blue-050 {
  background-color: #ebf5fa;
}

.u-bgc_blue-100 {
  background-color: #d3eaf8;
}

.u-bgc_blue-200 {
  background-color: #a8d4f0;
}

.u-bgc_blue-300 {
  background-color: #7cbfe9;
}

.u-bgc_blue-400 {
  background-color: #50a9e2;
}

.u-bgc_blue-500 {
  background-color: #1e7cb8;
}

.u-bgc_blue-600 {
  background-color: #1a699e;
}

.u-bgc_blue-700 {
  background-color: #155884;
}

.u-bgc_blue-800 {
  background-color: #104060;
}

.u-bgc_blue-900 {
  background-color: #0b2f46;
}

.u-bgc_slate-050 {
  background-color: #f9fafb;
}

.u-bgc_slate-100 {
  background-color: #f3f4f6;
}

.u-bgc_slate-200 {
  background-color: #e5e7eb;
}

.u-bgc_slate-300 {
  background-color: #d1d5db;
}

.u-bgc_slate-400 {
  background-color: #9ca3af;
}

.u-bgc_slate-500 {
  background-color: #6b7280;
}

.u-bgc_slate-600 {
  background-color: #4b5563;
}

.u-bgc_slate-700 {
  background-color: #374151;
}

.u-bgc_slate-800 {
  background-color: #1f2937;
}

.u-bgc_slate-900 {
  background-color: #111827;
}

.u-bgc_green-050 {
  background-color: #ecfdf5;
}

.u-bgc_green-100 {
  background-color: #d1fae5;
}

.u-bgc_green-200 {
  background-color: #a7f3d0;
}

.u-bgc_green-300 {
  background-color: #6ee7b7;
}

.u-bgc_green-400 {
  background-color: #34d399;
}

.u-bgc_green-500 {
  background-color: #10b981;
}

.u-bgc_green-600 {
  background-color: #059669;
}

.u-bgc_green-700 {
  background-color: #047857;
}

.u-bgc_green-800 {
  background-color: #065f46;
}

.u-bgc_green-900 {
  background-color: #064e3b;
}

.u-bgc_yellow-050 {
  background-color: #fffbeb;
}

.u-bgc_yellow-100 {
  background-color: #fef3c7;
}

.u-bgc_yellow-200 {
  background-color: #fde68a;
}

.u-bgc_yellow-300 {
  background-color: #fcd34d;
}

.u-bgc_yellow-400 {
  background-color: #fbbf24;
}

.u-bgc_yellow-500 {
  background-color: #f59e0b;
}

.u-bgc_yellow-600 {
  background-color: #d97706;
}

.u-bgc_yellow-700 {
  background-color: #b45309;
}

.u-bgc_yellow-800 {
  background-color: #92400e;
}

.u-bgc_yellow-900 {
  background-color: #78350f;
}

.u-bgc_red-050 {
  background-color: #fbefef;
}

.u-bgc_red-100 {
  background-color: #f7d7d4;
}

.u-bgc_red-200 {
  background-color: #eb968e;
}

.u-bgc_red-300 {
  background-color: #e36e63;
}

.u-bgc_red-400 {
  background-color: #dc4538;
}

.u-bgc_red-500 {
  background-color: #cc3224;
}

.u-bgc_red-600 {
  background-color: #9c251c;
}

.u-bgc_red-700 {
  background-color: #821f17;
}

.u-bgc_red-800 {
  background-color: #5f1711;
}

.u-bgc_red-900 {
  background-color: #45110c;
}

.u-bgc_purple-050 {
  background-color: #f8f3fb;
}

.u-bgc_purple-075 {
  background-color: #f5effa;
}

.u-bgc_purple-100 {
  background-color: #e7d8f3;
}

.u-bgc_purple-200 {
  background-color: #b68adb;
}

.u-bgc_purple-300 {
  background-color: #ceb1e7;
}

.u-bgc_purple-400 {
  background-color: #9d63cf;
}

.u-bgc_purple-500 {
  background-color: #7d39b7;
}

.u-bgc_purple-600 {
  background-color: #652e94;
}

.u-bgc_purple-700 {
  background-color: #502475;
}

.u-bgc_purple-800 {
  background-color: #3a1b56;
}

.u-bgc_purple-900 {
  background-color: #2b133e;
}

.u-bgc_white {
  background-color: #fff;
}

.u-bgc_black {
  background-color: #000;
}

.u-bgc_transparent {
  background-color: rgba(0, 0, 0, 0);
}

.u-border-color_blue-050 {
  border-color: #ebf5fa;
}

.u-border-color_blue-100 {
  border-color: #d3eaf8;
}

.u-border-color_blue-200 {
  border-color: #a8d4f0;
}

.u-border-color_blue-300 {
  border-color: #7cbfe9;
}

.u-border-color_blue-400 {
  border-color: #50a9e2;
}

.u-border-color_blue-500 {
  border-color: #1e7cb8;
}

.u-border-color_blue-600 {
  border-color: #1a699e;
}

.u-border-color_blue-700 {
  border-color: #155884;
}

.u-border-color_blue-800 {
  border-color: #104060;
}

.u-border-color_blue-900 {
  border-color: #0b2f46;
}

.u-border-color_slate-050 {
  border-color: #f9fafb;
}

.u-border-color_slate-100 {
  border-color: #f3f4f6;
}

.u-border-color_slate-200 {
  border-color: #e5e7eb;
}

.u-border-color_slate-300 {
  border-color: #d1d5db;
}

.u-border-color_slate-400 {
  border-color: #9ca3af;
}

.u-border-color_slate-500 {
  border-color: #6b7280;
}

.u-border-color_slate-600 {
  border-color: #4b5563;
}

.u-border-color_slate-700 {
  border-color: #374151;
}

.u-border-color_slate-800 {
  border-color: #1f2937;
}

.u-border-color_slate-900 {
  border-color: #111827;
}

.u-border-color_green-050 {
  border-color: #ecfdf5;
}

.u-border-color_green-100 {
  border-color: #d1fae5;
}

.u-border-color_green-200 {
  border-color: #a7f3d0;
}

.u-border-color_green-300 {
  border-color: #6ee7b7;
}

.u-border-color_green-400 {
  border-color: #34d399;
}

.u-border-color_green-500 {
  border-color: #10b981;
}

.u-border-color_green-600 {
  border-color: #059669;
}

.u-border-color_green-700 {
  border-color: #047857;
}

.u-border-color_green-800 {
  border-color: #065f46;
}

.u-border-color_green-900 {
  border-color: #064e3b;
}

.u-border-color_yellow-050 {
  border-color: #fffbeb;
}

.u-border-color_yellow-100 {
  border-color: #fef3c7;
}

.u-border-color_yellow-200 {
  border-color: #fde68a;
}

.u-border-color_yellow-300 {
  border-color: #fcd34d;
}

.u-border-color_yellow-400 {
  border-color: #fbbf24;
}

.u-border-color_yellow-500 {
  border-color: #f59e0b;
}

.u-border-color_yellow-600 {
  border-color: #d97706;
}

.u-border-color_yellow-700 {
  border-color: #b45309;
}

.u-border-color_yellow-800 {
  border-color: #92400e;
}

.u-border-color_yellow-900 {
  border-color: #78350f;
}

.u-border-color_red-050 {
  border-color: #fbefef;
}

.u-border-color_red-100 {
  border-color: #f7d7d4;
}

.u-border-color_red-200 {
  border-color: #eb968e;
}

.u-border-color_red-300 {
  border-color: #e36e63;
}

.u-border-color_red-400 {
  border-color: #dc4538;
}

.u-border-color_red-500 {
  border-color: #cc3224;
}

.u-border-color_red-600 {
  border-color: #9c251c;
}

.u-border-color_red-700 {
  border-color: #821f17;
}

.u-border-color_red-800 {
  border-color: #5f1711;
}

.u-border-color_red-900 {
  border-color: #45110c;
}

.u-border-color_purple-050 {
  border-color: #f8f3fb;
}

.u-border-color_purple-075 {
  border-color: #f5effa;
}

.u-border-color_purple-100 {
  border-color: #e7d8f3;
}

.u-border-color_purple-200 {
  border-color: #b68adb;
}

.u-border-color_purple-300 {
  border-color: #ceb1e7;
}

.u-border-color_purple-400 {
  border-color: #9d63cf;
}

.u-border-color_purple-500 {
  border-color: #7d39b7;
}

.u-border-color_purple-600 {
  border-color: #652e94;
}

.u-border-color_purple-700 {
  border-color: #502475;
}

.u-border-color_purple-800 {
  border-color: #3a1b56;
}

.u-border-color_purple-900 {
  border-color: #2b133e;
}

.u-border-color_white {
  border-color: #fff;
}

.u-border-color_black {
  border-color: #000;
}

.u-border-color_transparent {
  border-color: rgba(0, 0, 0, 0);
}

.u-border_none {
  border: none;
}

.u-cursor_auto {
  cursor: auto;
}

.u-cursor_default {
  cursor: default;
}

.u-cursor_pointer {
  cursor: pointer;
}

.u-cursor_not-allowed {
  cursor: not-allowed;
}

.u-display_block {
  display: block;
}

.u-display_inline-block {
  display: inline-block;
}

.u-display_flex {
  display: flex;
}

.u-display_inline-flex {
  display: inline-flex;
}

.u-display_inline {
  display: inline;
}

.u-display_grid {
  display: grid;
}

.u-display_none {
  display: none;
}

.u-flex-direction_column {
  flex-direction: column;
}

.u-flex-direction_row {
  flex-direction: row;
}

.u-flex-direction_row-reverse {
  flex-direction: row-reverse;
}

.u-flex-wrap_wrap {
  flex-wrap: wrap;
}

.u-flex-wrap_nowrap {
  flex-wrap: nowrap;
}

.u-align-items_baseline {
  align-items: baseline;
}

.u-align-items_center {
  align-items: center;
}

.u-align-items_flex-end {
  align-items: flex-end;
}

.u-align-items_flex-start {
  align-items: flex-start;
}

.u-align-items_start {
  align-items: start;
}

.u-align-items_inherit {
  align-items: inherit;
}

.u-align-items_initial {
  align-items: initial;
}

.u-align-items_unset {
  align-items: unset;
}

.u-align-self_auto {
  align-self: auto;
}

.u-align-self_stretch {
  align-self: stretch;
}

.u-align-self_center {
  align-self: center;
}

.u-align-self_flex-start {
  align-self: flex-start;
}

.u-align-self_flex-end {
  align-self: flex-end;
}

.u-align-self_baseline {
  align-self: baseline;
}

.u-align-self_inial {
  align-self: inial;
}

.u-align-self_inherit {
  align-self: inherit;
}

.u-justify-content_center {
  justify-content: center;
}

.u-justify-content_flex-end {
  justify-content: flex-end;
}

.u-justify-content_flex-start {
  justify-content: flex-start;
}

.u-justify-content_initial {
  justify-content: initial;
}

.u-justify-content_inherit {
  justify-content: inherit;
}

.u-justify-content_space-around {
  justify-content: space-around;
}

.u-justify-content_space-between {
  justify-content: space-between;
}

.u-justify-self_center {
  justify-self: center;
}

.u-flex-grow_1 {
  flex-grow: 1;
}

.u-font-style_normal {
  font-style: normal;
}

.u-font-style_italic {
  font-style: italic;
}

.u-font-size_inherit {
  font-size: inherit;
}

.u-list-style_none {
  list-style: none;
}

.u-position_absolute {
  position: absolute;
}

.u-position_relative {
  position: relative;
}

.u-overflow_auto {
  overflow: auto;
}

.u-overflow_hidden {
  overflow: hidden;
}

.u-overflow_visible {
  overflow: visible;
}

.u-text-align_center {
  text-align: center;
}

.u-text-align_left {
  text-align: left;
}

.u-text-align_right {
  text-align: right;
}

.u-text-decoration_none {
  text-decoration: none;
}

.u-text-decoration_underline {
  text-decoration: underline;
}

.u-text-overflow_ellipsis {
  text-overflow: ellipsis;
}

.u-text-transform_uppercase {
  text-transform: uppercase;
}

.u-text-transform_capitalize {
  text-transform: capitalize;
}

.u-text-wrap_nowrap {
  text-wrap: nowrap;
}

.u-text-wrap_pretty {
  text-wrap: pretty;
}

.u-font-weight_normal {
  font-weight: normal;
}

.u-font-weight_bold {
  font-weight: bold;
}

.u-font-weight_700 {
  font-weight: 700;
}

.u-font-weight_900 {
  font-weight: 900;
}

.u-white-space_normal {
  white-space: normal;
}

.u-white-space_pre {
  white-space: pre;
}

.u-white-space_nowrap {
  white-space: nowrap;
}

.u-float_right {
  float: right;
}

.u-float_left {
  float: left;
}

.u-pointer-events_auto {
  pointer-events: auto;
}

.u-pointer-events_initial {
  pointer-events: initial;
}

.u-pointer-events_none {
  pointer-events: none;
}

.u-pointer-events_unset {
  pointer-events: unset;
}

.u-visibility_hidden {
  visibility: hidden;
}

.u-word-break_break-all {
  word-break: break-all;
}

.u-word-break_break-word {
  word-break: break-word;
}

.u-width_max-content {
  width: max-content;
}

.u-width_fit-content {
  width: fit-content;
}

.u-height_fit-content {
  height: fit-content;
}

.u-flex_1 {
  flex: 1;
}

.u-flex-shrink_0 {
  flex-shrink: 0;
}

.u-flex-shrink_1 {
  flex-shrink: 1;
}

.u-flex-shrink_10 {
  flex-shrink: 10;
}

.u-max-width_default {
  max-width: 1280px;
}

.u-max-width_legacy {
  max-width: 1088px;
}

.u-max-width_sm {
  max-width: 976px;
}

.u-max-width_inherit {
  max-width: inherit;
}

.u-max-width_448 {
  max-width: 488px;
}

.u-max-width_500 {
  max-width: 500px;
}

.u-max-width_text {
  max-width: 70ch;
}

.u-max-width_480 {
  max-width: 480px;
}

.u-max-width_240 {
  max-width: 240px;
}

.scroll-lock {
  overflow: hidden !important;
}

.sr-only {
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  white-space: nowrap;
  width: 1px;
  height: 1px;
  position: absolute;
  overflow: hidden;
}

.u-outline_focus {
  outline: 3px solid #50a9e2;
}

.u-width_100pc {
  width: 100%;
}

.u-width_50pc {
  width: 50%;
}

.u-width_inherit {
  width: inherit;
}

.u-height_screen {
  height: 100vh;
}

.u-height_100pc {
  height: 100%;
}

.u-line-height_140pc {
  line-height: 140%;
}

.u-line-height_150pc {
  line-height: 150%;
}

.u-border-radius_0 {
  border-radius: 0;
}

.u-border-radius_0-half {
  border-radius: 4px;
}

.u-border-radius_1 {
  border-radius: 8px;
}

.u-border-radius_2 {
  border-radius: 16px;
}

.u-border-radius_circle {
  border-radius: 100%;
}

.u-border-radius_pill {
  border-radius: 1000px;
}

.u-border {
  border-style: solid;
  border-width: 1px;
}

.u-border-b {
  border-bottom-style: solid;
  border-bottom-width: 1px;
}

.u-border-t {
  border-top-style: solid;
  border-top-width: 1px;
}

.u-border-l {
  border-left-style: solid;
  border-left-width: 1px;
}

.u-border-r {
  border-right-style: solid;
  border-right-width: 1px;
}

.u-overflow-y_scroll {
  overflow-y: scroll;
}

.u-overflow-y_auto {
  overflow-y: auto;
}

.u-overflow-x_scroll {
  overflow-x: scroll;
}

.u-overflow-x_hidden {
  overflow-x: hidden;
}

.u-overflow-x_auto {
  overflow-x: auto;
}

.u-overscroll-behavior_contain {
  overscroll-behavior: contain;
}

.u-opacity_full {
  opacity: 1;
}

.u-opacity_0 {
  opacity: 0;
}

.u-truncate {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.u-box-shadow_default {
  box-shadow: 0 5px 20px rgba(0, 0, 0, .05);
}

.u-fill_blue-400 {
  fill: #50a9e2;
}

.u-color-inherit {
  color: inherit;
}

.u-min-width_0 {
  min-width: 0;
}
