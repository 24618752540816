.frq-item--guide-level {
  cursor: default;
}

.frq-item__body {
  display: block;
}

.frq-item__body--question {
  opacity: 1;
}

.frq-item__body--question .collapsible-list__list {
  font-size: .9em;
  line-height: 1.6;
}

.frq-item__body--question .collapsible-list__list--inline {
  list-style: none;
}

.frq-item__body--question .collapsible-list__list-item {
  text-transform: uppercase;
  font-weight: bold;
}

.frq-item__body--question .collapsible-list__list-item--is-expanded {
  opacity: .6;
}

.frq-item__metadata {
  flex-basis: 0;
  margin-left: auto;
}

.frq-item__metadata--question {
  opacity: 1;
}
