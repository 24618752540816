:export {
  drawerModeBreakpoint: 1040px;
  longCardBreakpoint: 580px;
}

.cl-sidebar-menu {
  background-color: var(--elevation-background-l1);
  border-right: 1px solid var(--border-subtle);
  z-index: 2;
  width: 320px;
  min-width: 320px;
  min-height: 100%;
  max-height: calc(100vh - 60px);
  padding: 16px;
  position: sticky;
  top: 60px;
  overflow-y: auto;
  box-shadow: 0 1px 7px rgba(0, 0, 0, .1);
}

.cl-sidebar-menu__category-list {
  margin: 0;
  padding-left: 0;
  list-style-type: none;
}

.cl-sidebar-menu__category-wrapper {
  color: #a7adb0;
  text-transform: uppercase;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 20px 0;
  font-size: 13px;
  font-weight: bold;
  display: flex;
}

.cl-sidebar-menu__category-toggle {
  font-size: 0;
}

.cl-sidebar-menu__category-toggle .sg-button__icon {
  font-size: 13px;
}

.cl-sidebar-menu__items-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.cl-sidebar-menu__dropdown {
  justify-content: space-between;
  align-items: center;
  margin: 0 10px;
  display: flex;
}

.cl-sidebar-menu__dropdown-text {
  flex-direction: column;
  display: flex;
}

.cl-sidebar-menu__item-wrapper {
  margin-bottom: 10px;
}

.cl-sidebar-menu__item {
  color: #707679;
  text-align: left;
  width: 100%;
  padding: 10px 0;
  font-weight: bold;
  text-decoration: none;
  display: block;
}

.cl-sidebar-menu__item:hover, .cl-sidebar-menu__item.cl-sidebar-menu__item--active {
  color: #50a9e2;
}

.cl-sidebar-menu__item:last-child {
  margin-bottom: 0;
}

.cl-sidebar-menu__item--category {
  text-transform: uppercase;
}

.cl-sidebar-menu__item--category:not(:hover):not(.cl-sidebar-menu__item--active) {
  color: #a7adb0;
}

.cl-sidebar-menu__drawer-heading {
  border-bottom: 1px solid rgba(0, 0, 0, .15);
  justify-content: space-between;
  align-items: center;
  height: 80px;
  padding: 0 40px;
  font-size: 1.25em;
  font-weight: 600;
  display: flex;
}

.cl-sidebar-menu__drawer-label {
  margin: 0;
}

.sg-button.cl-sidebar-menu__drawer-close-btn {
  font-weight: inherit;
  padding-right: 0;
}
