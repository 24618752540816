.school-search-grouped-inputs {
  flex-direction: column;
  flex: auto;
  display: flex;
}

.school-search-grouped-inputs__row {
  flex: auto;
  display: flex;
}

.school-search-grouped-inputs__input--wide {
  width: 100%;
}
