.topic-summary {
  flex-direction: column;
  width: calc(100% - 4rem);
  max-width: 830px;
  margin: 20px 30px;
  padding: 1.25rem 3rem 1.25rem 2rem;
  display: flex;
}

.topic-summary__summary-wrapper {
  justify-content: space-between;
  display: flex;
}

.topic-summary__image {
  width: 84px;
  height: 84px;
}

.topic-summary__text-wrapper {
  width: calc(100% - 84px - 2rem);
}

.topic-summary__title {
  margin-bottom: .5em;
  line-height: 1.25;
}

.topic-summary__summary {
  font-size: 1em;
  line-height: 1.5;
}

.topic-summary__button {
  align-self: flex-end;
  margin-top: 1em;
}

.topic-summary__button .fa {
  margin-left: .5em;
}

.interaction-engine-v2--mobile .topic-summary {
  width: 100%;
  min-height: calc(100vh - 120px);
  margin: 0;
}

.interaction-engine-v2--mobile .topic-summary__image {
  display: none;
}

.interaction-engine-v2--mobile .topic-summary__text-wrapper {
  width: 100%;
}
