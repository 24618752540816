.sg-button {
  color: #fff;
  cursor: pointer;
  text-align: center;
  background-color: #50a9e2;
  border: 0;
  border-radius: 2px;
  outline-width: 0;
  padding: 10px 20px;
  font-size: 15.4px;
  font-weight: bold;
  text-decoration: none;
  display: inline-block;
}

.sg-button:not(:last-child) {
  margin-right: 7px;
}

.sg-button:not(.sg-button--link-button):not(.unbutton):not(:disabled):not(:active):hover {
  transition: box-shadow .1s ease-in-out, transform .1s ease-in-out;
  transform: translateY(-1px)scale(1.01);
  box-shadow: 0 2px 9px rgba(0, 0, 0, .2);
}

.sg-button:not(.sg-button--link-button):not(.unbutton):disabled, .sg-button:not(.sg-button--link-button).sg-button--disabled {
  color: rgba(255, 255, 255, .5);
  cursor: not-allowed;
  background-color: rgba(80, 169, 226, .5);
}

.sg-button:not(.sg-button--link-button).sg-button--disabled {
  pointer-events: none;
}

.sg-button.unbutton:disabled {
  cursor: not-allowed;
}

.sg-button__icon {
  margin-left: 5px;
  display: inline-block;
}

.sg-button--green {
  background-color: #059669;
}

.sg-button--green:not(.sg-button--link-button):disabled, .sg-button--green:not(.sg-button--link-button).sg-button--disabled {
  background-color: rgba(5, 150, 105, .5);
}

.sg-button--purple {
  background-color: #9d63cf;
}

.sg-button--purple:not(.sg-button--link-button):disabled, .sg-button--purple:not(.sg-button--link-button).sg-button--disabled {
  background-color: rgba(157, 99, 207, .5);
}

.sg-button--red {
  background-color: #dc4538;
}

.sg-button--red:not(.sg-button--link-button):disabled, .sg-button--red:not(.sg-button--link-button).sg-button--disabled {
  background-color: rgba(220, 69, 56, .5);
}

.sg-button--gray {
  color: #374151;
  background-color: #f3f4f6;
}

.sg-button--gray:not(.sg-button--link-button):disabled, .sg-button--gray:not(.sg-button--link-button).sg-button--disabled {
  color: rgba(107, 114, 128, .5);
  background-color: rgba(243, 244, 246, .5);
}

.sg-button--white {
  color: #50a9e2;
  background-color: #fff;
  box-shadow: 0 1px 1px #d1d5db;
}

.sg-button.sg-button--link-button {
  color: #50a9e2;
  background-color: rgba(0, 0, 0, 0);
  padding: 10px 14px;
}

.sg-button.sg-button--link-button:hover {
  color: #50a9e2;
  text-decoration: underline;
}

.sg-button.sg-button--link-button.sg-button--green {
  color: #059669;
}

.sg-button.sg-button--link-button.sg-button--green:disabled {
  color: rgba(5, 150, 105, .5);
}

.sg-button.sg-button--link-button.sg-button--purple {
  color: #9d63cf;
}

.sg-button.sg-button--link-button.sg-button--purple:disabled {
  color: rgba(157, 99, 207, .5);
}

.sg-button.sg-button--link-button.sg-button--red {
  color: #dc4538;
}

.sg-button.sg-button--link-button.sg-button--red:disabled {
  color: rgba(220, 69, 56, .5);
}

.sg-button.sg-button--link-button.sg-button--gray {
  color: #9ca3af;
}

.sg-button.sg-button--link-button.sg-button--gray:disabled {
  color: rgba(156, 163, 175, .5);
}

.sg-button.sg-button--link-button:disabled {
  cursor: not-allowed;
  background-color: rgba(0, 0, 0, 0);
  text-decoration: none;
}

.sg-button.sg-button--text-button {
  color: #374151;
  background-color: rgba(0, 0, 0, 0);
  padding: 10px 14px;
}

.sg-button.sg-button--text-button:hover {
  color: #374151;
}

.sg-button.sg-button--text-button:disabled {
  color: rgba(55, 65, 81, .5);
  cursor: not-allowed;
  background-color: rgba(0, 0, 0, 0);
  text-decoration: none;
}

.sg-button.sg-button--no-padding {
  padding: 0;
}

.sg-button--outline {
  color: #50a9e2;
  background-color: #fff;
  box-shadow: inset 0 0 0 2px;
}

.sg-button--outline:not(:disabled):not(:active):hover {
  box-shadow: inset 0 0 0 2px, 0 2px 9px rgba(0, 0, 0, .2);
}

.sg-button--green.sg-button--outline {
  color: #059669;
}

.sg-button--purple.sg-button--outline {
  color: #9d63cf;
}

.sg-button--red.sg-button--outline {
  color: #dc4538;
}

.sg-button--gray.sg-button--outline {
  color: #374151;
}

.sg-button--no-border {
  box-shadow: 0 1px 1px 1px rgba(0, 0, 0, .1);
}

.sg-button--white-bg {
  box-shadow: 0 1px 3px rgba(0, 0, 0, .2);
}

.sg-button--white-bg:not(:disabled):not(:active):hover {
  box-shadow: 0 2px 9px rgba(0, 0, 0, .2);
}

.sg-button--small {
  padding: 7px;
  font-size: 12.6px;
}

.sg-button--large {
  border-radius: 3px;
  padding: 21px 39px;
  font-size: 18.2px;
}

.sg-button__disabled-event-helper {
  position: absolute;
  inset: 0;
}
