.o-wall__info-wrapper {
  text-align: center;
  max-width: 420px;
}

.o-wall__curve {
  z-index: 1;
}

.o-wall__img {
  height: 50px;
  margin-bottom: var(--space-x2);
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: contain;
  width: 50px;
}

.o-wall__info-wrapper > :last-child {
  margin-bottom: 0;
}

@media screen and (max-width: 780px) {
  .o-wall__curve {
    padding: var(--space-x2) var(--space-x2) var(--space-x3);
  }

  .o-wall__img {
    margin-bottom: var(--space-x1);
  }
}
