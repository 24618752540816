.queue-search-panel__guide-selector {
  z-index: 10;
}

.queue-search-panel__guide-selector--filters {
  justify-content: space-between;
  align-items: center;
  margin: 8px 8px 0;
  display: flex;
}

.queue-search-panel__guide-selector--dropdown-trigger {
  text-align: left;
  width: 264px;
}
