.guide-level-selector {
  width: 640px;
}

.guide-level-selector__empty-state {
  justify-content: center;
  align-items: center;
  height: 100px;
  display: flex;
}

.guide-level-selector__body {
  max-height: 400px;
  padding: 0;
  overflow: auto;
}

.guide-level-selector__list-item {
  justify-content: space-between;
  align-items: center;
  padding-left: 8px;
  display: flex;
}

.guide-level-selector__list-item-no-children {
  margin-left: 36px;
}

.guide-level-selector__list-item-icon {
  margin-right: 4px;
}

.guide-level-selector__list-item--right {
  align-items: center;
  display: flex;
}

.guide-level-selector__published {
  color: #10b981;
}

.guide-level-selector__list-item--1-level {
  padding-left: 8px;
}

.guide-level-selector__list-item--2-level {
  padding-left: 24px;
}

.guide-level-selector__list-item--3-level {
  padding-left: 52px;
}
