.template-list-wrapper {
  flex-flow: column;
  font-size: .875rem;
  display: flex;
}

.template-list-wrapper .a-form {
  width: 100%;
}

.template-list-header__learn-more {
  padding-bottom: 2.5em;
  font-size: .875rem;
  font-style: italic;
}

.template-list-templates-wrapper {
  overflow-x: auto;
}

.template-list-templates__item {
  cursor: pointer;
  background: #fff;
  border: 2px solid #f3f4f6;
  border-radius: .25em;
  outline: 0;
  flex-flow: column;
  margin: .5em 0;
  padding: 1em;
  display: flex;
  box-shadow: 0 1px 2px rgba(0, 0, 0, .1);
}

.template-list-templates__item:hover, .template-list-templates__item:focus {
  border-color: #1e7cb8;
}

.template-list-templates__item--selected {
  border: 2px solid #50a9e2;
}

.template-list-templates__item--invalid {
  cursor: default;
  background-color: rgba(255, 255, 255, .5);
}

.template-list-templates__item--invalid:hover, .template-list-templates__item--invalid:focus {
  border-color: #f3f4f6;
}

.template-list-templates__item--invalid .template-list-templates__item-title, .template-list-templates__item--invalid .template-list-templates__item-content {
  opacity: .5;
}

.template-list-templates__item-title {
  margin: 0 0 .25em;
  font-weight: bold;
}

.template-list-templates__item-checkbox-wrapper {
  color: #fff;
  cursor: pointer;
  width: 1.56em;
  min-width: 1.56em;
  height: 1.56em;
  min-height: 1.56em;
  font-weight: bold;
  display: inline-block;
  position: absolute;
  top: 50%;
  bottom: 0;
  right: 1em;
  transform: translateY(-50%)translateX(-15%);
}

.template-list-templates__item-checkbox-wrapper .checkbox-checkmark, .template-list-templates__item-checkbox-wrapper .checkbox-checkmark:after {
  position: absolute;
  inset: 0;
}

.template-list-templates__item-checkbox-wrapper input[type="checkbox"] {
  position: absolute;
  inset: 0;
}

.template-list-templates__item-checkbox-wrapper .checkbox-checkmark {
  content: "";
  will-change: background-color;
  background-color: #fff;
  border-radius: .2em;
}

.template-list-templates__item-checkbox-wrapper input[type="checkbox"] {
  cursor: pointer;
  opacity: 0;
  z-index: 99;
  background-color: rgba(0, 0, 0, 0);
  border: 0;
  outline-width: 0;
  width: 0;
  height: 0;
  margin: 0;
  padding: 0;
}

.template-list-templates__item-checkbox-wrapper input[type="checkbox"]:not(:disabled):hover ~ .checkbox-checkmark {
  background-color: #f2f2f2;
  transition: background-color .2s cubic-bezier(.23, 1, .32, 1);
}

.template-list-templates__item-checkbox-wrapper input[type="checkbox"]:not(:disabled):focus ~ .checkbox-checkmark {
  background-color: #f2f2f2;
  transition: background-color .2s cubic-bezier(.23, 1, .32, 1);
}

.template-list-templates__item-checkbox-wrapper input[type="checkbox"]:not(:disabled):checked:hover ~ .checkbox-checkmark {
  background-color: #3a9ede;
  transition: background-color .2s cubic-bezier(.23, 1, .32, 1);
}

.template-list-templates__item-checkbox-wrapper input[type="checkbox"]:not(:disabled):checked:focus ~ .checkbox-checkmark {
  background-color: #3a9ede;
  transition: background-color .2s cubic-bezier(.23, 1, .32, 1);
}

.template-list-templates__item-checkbox-wrapper input[type="checkbox"]:not(:disabled):focus ~ .checkbox-checkmark {
  box-shadow: 0 0 1px 3px #d1d5db;
}

.template-list-templates__item-checkbox-wrapper input[type="checkbox"]:not(:disabled):checked:focus ~ .checkbox-checkmark {
  box-shadow: 0 0 1px 3px #d1d5db;
}

.template-list-templates__item-checkbox-wrapper input[type="checkbox"]:checked ~ .checkbox-checkmark {
  background-color: #50a9e2;
}

.template-list-templates__item-checkbox-wrapper input[type="checkbox"]:checked ~ .checkbox-checkmark:after {
  color: #fff;
  content: "";
  text-align: center;
  font-family: "Font Awesome 6 Pro";
  font-size: .9em;
}

.template-list-templates__item-checkbox-wrapper input[type="checkbox"]:checked:disabled ~ .checkbox-checkmark {
  background-color: #9ca3af;
  transition: all cubic-bezier(.23, 1, .32, 1);
}

.template-list-templates__item-checkbox-wrapper .checkbox-checkmark {
  border: 2px solid #d1d5db;
  border-radius: 50%;
}

.template-list-templates__item-checkbox-wrapper input[type="checkbox"] {
  border: 2px solid #d1d5db;
  border-radius: 50%;
}

.template-list-templates__item-checkbox-wrapper .checkbox-checkmark--selected {
  border-color: #50a9e2;
}

.template-list-templates__item-invalid-wrapper {
  width: 100%;
  position: relative;
}

.template-list-templates__item-input-invalid {
  color: #e29a3d;
  font-size: 1.2em;
  font-weight: bold;
  position: absolute;
  bottom: 50%;
  right: 0;
  transform: translateY(-50%);
}

.template-list-templates__item-input-invalid:hover .template-list-templates__item-input-invalid-hover-text {
  visibility: visible;
}

.template-list-templates__item-input-invalid-hover-text {
  color: #fff;
  opacity: 1;
  visibility: hidden;
  z-index: 999;
  background: #374151;
  border-radius: .25em;
  width: 23.625em;
  padding: .4em;
  font-family: Lato, Helvetica, Arial, sans-serif;
  font-size: 12.6px;
  position: absolute;
  top: 112%;
  right: 0;
}

.template-list-actions__create-assignment-button {
  color: #1e7cb8;
  cursor: pointer;
  padding: 0 0 1em;
}

.template-list-actions {
  margin: 1em 0 0;
  position: sticky;
  bottom: 0;
}

.template-list-actions__button {
  -webkit-appearance: none;
  -moz-appearance: none;
  color: #fff;
  cursor: pointer;
  background-color: #34d399;
  border: 0;
  border-radius: .15em;
  justify-content: center;
  align-items: stretch;
  width: 100%;
  margin: 0 0 1em;
  padding: .85em 1em .8em;
  font-size: 1em;
  font-weight: bold;
  text-decoration: none;
  transition: box-shadow .1s ease-in-out, transform .1s ease-in-out;
  display: flex;
}

.template-list-actions__button::selection {
  background-color: rgba(0, 0, 0, 0);
}

.template-list-actions__button ::selection {
  background-color: rgba(0, 0, 0, 0);
}

.template-list-actions__button.disabled, .template-list-actions__button.disabled:hover, .template-list-actions__button:disabled, .template-list-actions__button:disabled:hover {
  color: #d1d5db;
  background-color: #6b7280;
}

.template-list-actions__button:not(:disabled):not(:active):hover {
  transform: translateY(-1px)scale(1.01);
  box-shadow: 0 2px 9px rgba(0, 0, 0, .2);
}

.template-list-actions__button:only-child {
  margin: 0;
}

.template-list-form-wrapper {
  margin: 0 0 1em;
}
