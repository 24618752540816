.masquerade-panel {
  z-index: 10000;
  background: #1f2937;
  justify-content: space-between;
  align-items: center;
  height: 60px;
  transition: top .25s ease-out;
  display: flex;
  position: fixed;
  top: -60px;
  left: 0;
  right: 0;
}

.masquerade-panel--visible {
  top: 0;
}

.masquerade-panel__logo {
  margin: 0 16px;
}

.masquerade-panel__logo img {
  height: 40px;
}

@media (max-width: 780px) {
  .masquerade-panel__logo {
    margin: 0 8px;
  }
}

.masquerade-panel__spinner {
  margin: 16px;
}
