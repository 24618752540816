.a-button {
  --button-background-color: var(--background-brand);
  --button-background-color--hover: var(--background-brand-hover);
  --button-background-color--active: var(--background-brand-pressed);
  --button-text-color: white;
  --button-border-color: transparent;
  --button-border-color--hover: transparent;
  --button-min-height: 44px;
  --button-min-width: 44px;
  --button-padding: 12px 16px;
  --button-border-radius: 8px;
  --button-font-size: .9375rem;
  background-color: var(--button-background-color);
  border-radius: var(--button-border-radius);
  box-shadow: 0 0 0 1px var(--button-border-color) inset;
  color: var(--button-text-color);
  cursor: pointer;
  font-size: var(--button-font-size);
  min-height: var(--button-min-height);
  min-width: var(--button-min-width);
  padding: var(--button-padding);
  border: none;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  text-decoration: none;
  display: inline-flex;
}

.a-button:hover:not(:disabled) {
  background-color: var(--button-background-color--hover);
  box-shadow: 0 0 0 2px var(--button-border-color--hover) inset;
}

.a-button:active:not(:disabled) {
  background-color: var(--button-background-color--active);
  box-shadow: 0 0 0 2px var(--button-border-color) inset;
}

.a-button:not(:active) {
  transition-property: background-color, box-shadow;
  transition-duration: .1s;
}

.a-button:focus-visible {
  outline: 3px solid #50a9e2;
}

.a-button-text--primary, .a-button-outlined--primary {
  --button-text-color: var(--content-brand);
  --button-background-color: transparent;
  --button-background-color--hover: var(--background-brand-subtle-hover);
  --button-background-color--active: var(--background-brand-subtle-pressed);
}

.a-button-outlined--primary {
  --button-border-color: var(--border-primary);
  --button-border-color--hover: var(--border-brand);
  --button-border-color--active: var(--border-brand);
}

.a-button-faded--primary {
  --button-text-color: var(--content-brand);
  --button-background-color: var(--background-brand-subtle);
  --button-background-color--hover: var(--background-brand-subtle-hover);
  --button-background-color--active: var(--background-subtle-pressed);
}

.a-button-solid--secondary {
  --button-text-color: var(--content-secondary);
  --button-background-color: var(--background-subtle);
  --button-background-color--hover: var(--background-subtle-hover);
  --button-background-color--active: var(--background-subtle-pressed);
}

.a-button-text--secondary {
  --button-text-color: var(--content-secondary);
  --button-background-color: transparent;
  --button-background-color--hover: var(--background-subtle-hover);
  --button-background-color--active: var(--background-subtle-pressed);
}

.a-button-outlined--secondary {
  --button-text-color: var(--content-secondary);
  --button-background-color: var(--background-primary);
  --button-background-color--hover: var(--background-subtle-hover);
  --button-background-color--active: var(--background-subtle-pressed);
  --button-border-color: var(--border-primary);
  --button-border-color--hover: var(--border-primary);
  --button-border-color--active: var(--border-primary);
}

.a-button-faded--secondary {
  --button-text-color: var(--content-secondary);
  --button-background-color: var(--background-subtle);
  --button-background-color--hover: var(--background-subtle-hover);
  --button-background-color--active: var(--background-subtle-pressed);
}

.a-button-solid--negative {
  --button-background-color: var(--background-negative);
  --button-background-color--hover: var(--background-negative-hover);
  --button-background-color--active: var(--background-negative-pressed);
}

.a-button-text--negative {
  --button-background-color: transparent;
  --button-text-color: var(--content-negative);
  --button-background-color--hover: var(--background-negative-subtle-hover);
  --button-background-color--active: var(--background-negative-subtle-pressed);
}

.a-button-outlined--negative {
  --button-background-color: var(--background-primary);
  --button-text-color: var(--content-negative);
  --button-background-color--hover: var(--background-negative-subtle-hover);
  --button-background-color--active: var(--background-negative-subtle-pressed);
  --button-border-color: var(--border-primary);
  --button-border-color--hover: var(--border-negative);
  --button-border-color--active: var(--border-negative);
}

.a-button-faded--negative {
  --button-text-color: var(--content-negative);
  --button-background-color: var(--background-negative-subtle);
  --button-background-color--hover: var(--background-negative-subtle-hover);
  --button-background-color--active: var(--background-negative-subtle-pressed);
}

.a-button-solid--positive {
  --button-background-color: var(--background-positive);
  --button-background-color--hover: var(--background-positive-hover);
  --button-background-color--active: var(--background-positive-pressed);
}

.a-button-text--positive {
  --button-text-color: var(--content-positive);
  --button-background-color: transparent;
  --button-background-color--hover: var(--background-positive-subtle-hover);
  --button-background-color--active: var(--background-positive-subtle-pressed);
}

.a-button-outlined--positive {
  --button-text-color: var(--content-positive);
  --button-background-color: var(--background-primary);
  --button-background-color--hover: var(--background-positive-subtle-hover);
  --button-background-color--active: var(--background-positive-subtle-pressed);
  --button-border-color: var(--border-primary);
  --button-border-color--hover: var(--border-positive);
  --button-border-color--active: var(--border-positive);
}

.a-button-faded--positive {
  --button-text-color: var(--content-positive);
  --button-background-color: var(--background-positive-subtle);
  --button-background-color--hover: var(--background-positive-subtle-hover);
  --button-background-color--active: var(--background-positive-subtle-pressed);
}

.a-button-solid--vendor-google {
  --button-background-color: #4285f4;
  --button-background-color--hover: #0e61eb;
}

.a-button-solid--vendor-clever {
  --button-background-color: #4274f6;
  --button-background-color--hover: #0b4bef;
}

.a-button--s {
  --button-min-height: 32px;
  --button-min-width: 32px;
  --button-padding: 6px 10px;
}

.a-button--l {
  --button-min-height: 64px;
  --button-min-width: 64px;
  --button-border-radius: 12px;
  --button-padding: 24px 32px;
  --button-font-size: 1rem;
}

.a-button--pill {
  --button-border-radius: 1000px;
}

.a-button--circle {
  height: var(--button-min-height);
  width: var(--button-min-height);
  --button-border-radius: var(--button-min-height);
}

.a-button--square {
  --button-border-radius: 8px;
}

.a-button-outlined--vendor-clever {
  --button-border-color: #d1d5db;
  --button-border-color--hover: #4274f6;
}

.a-button--inherit-with-transparent-bg {
  color: inherit;
  background-color: rgba(0, 0, 0, 0);
}

.a-button--inherit-with-transparent-bg:hover:not(:disabled) {
  background-color: rgba(0, 0, 0, .04);
}

.a-button--inherit-with-transparent-bg:active:not(:disabled) {
  background-color: rgba(0, 0, 0, 0);
}

.a-button--disabled {
  cursor: not-allowed;
  opacity: .5;
  pointer-events: none;
}
