.sg-tooltip {
  opacity: 1;
  z-index: 999;
  border-radius: 4px;
  min-width: 200px;
  max-width: 320px;
  font-size: 14px;
  transition: opacity .25s;
  position: absolute;
  box-shadow: 0 2px 10px rgba(0, 0, 0, .2);
}

.sg-tooltip:before {
  content: "";
  width: .9em;
  height: .9em;
  display: block;
  position: absolute;
  transform: rotate(45deg);
  box-shadow: 0 2px 10px rgba(0, 0, 0, .2);
}

.sg-tooltip__body {
  border-radius: 4px;
  max-height: 50vh;
  padding: 1em;
  overflow: auto;
}

.sg-tooltip--hidden {
  opacity: 0;
  width: 0;
  min-width: 0;
  height: 0;
  padding: 0;
  transition: opacity;
  overflow: hidden;
}

.sg-tooltip--centered .sg-tooltip__body {
  text-align: center;
}

.sg-tooltip--light .sg-tooltip__body {
  color: #374151;
  background-color: #fff;
}

.sg-tooltip--light:before {
  background-color: #fff;
}

.sg-tooltip--dark .sg-tooltip__body {
  color: #fff;
  background-color: #374151;
  padding: 5px 10px 6px;
}

.sg-tooltip--dark:before {
  background-color: #374151;
}

.sg-tooltip--top:before, .sg-tooltip--bottom:before {
  left: calc(50% - .45em);
}

.sg-tooltip--top:before {
  top: calc(100% - .45em);
}

.sg-tooltip--bottom:before {
  bottom: calc(100% - .45em);
}

.sg-tooltip--left:before, .sg-tooltip--right:before {
  top: calc(50% - .45em);
}

.sg-tooltip--left:before {
  left: calc(100% - .45em);
}

.sg-tooltip--right:before {
  right: calc(100% - .45em);
}

.sg-tooltip--absolute.sg-tooltip--top, .sg-tooltip--absolute.sg-tooltip--bottom {
  left: 50%;
  transform: translateX(-50%);
}

.sg-tooltip--absolute.sg-tooltip--top:before, .sg-tooltip--absolute.sg-tooltip--bottom:before {
  left: calc(50% - .45em);
}

.sg-tooltip--absolute.sg-tooltip--top {
  bottom: calc(100% + 1em);
}

.sg-tooltip--absolute.sg-tooltip--top:before {
  top: calc(100% - .45em);
}

.sg-tooltip--absolute.sg-tooltip--bottom {
  top: calc(100% + 1em);
}

.sg-tooltip--absolute.sg-tooltip--bottom:before {
  bottom: calc(100% - .45em);
}

.sg-tooltip--absolute.sg-tooltip--left, .sg-tooltip--absolute.sg-tooltip--right {
  top: 50%;
  transform: translateY(-50%);
}

.sg-tooltip--absolute.sg-tooltip--left:before, .sg-tooltip--absolute.sg-tooltip--right:before {
  top: calc(50% - .45em);
}

.sg-tooltip--absolute.sg-tooltip--left {
  right: calc(100% + 1em);
}

.sg-tooltip--absolute.sg-tooltip--left:before {
  left: calc(100% - .45em);
}

.sg-tooltip--absolute.sg-tooltip--right {
  left: calc(100% + 1em);
}

.sg-tooltip--absolute.sg-tooltip--right:before {
  right: calc(100% - .45em);
}

.sg-tooltip-wrapper:not(:hover) .sg-tooltip {
  opacity: 0;
  width: 0;
  min-width: 0;
  height: 0;
  padding: 0;
  transition: opacity;
  overflow: hidden;
}
