.date-range {
  justify-content: space-between;
  font-size: 14px;
  display: flex;
}

.date-range__item {
  flex-grow: 1;
  margin-right: 1em;
}

.date-range__item:last-child {
  margin-right: 0;
}

.date-range__item div.blocky-date-picker__label {
  font-weight: bold;
}

.date-range-wrapper--simple-dropdown .blocky-date-picker__input {
  border: 2px solid #f3f4f6;
  border-radius: .25em;
  transition: border-color .1s;
}

.date-range-wrapper--simple-dropdown .blocky-date-picker__input:hover {
  border-color: #a8d4f0;
  transition: border-color 50ms;
}
