.timer {
  color: #fff;
  white-space: nowrap;
  background: #50a9e2;
  border-radius: 3px;
  padding: .4em .55em;
  font-size: 1.1em;
  font-weight: bold;
}

.timer .fa {
  opacity: .5;
  margin-right: .4em;
}

.timer--almost-out {
  background: #e36e63;
  transition: background .25s;
}
