.a-icon {
  display: inline-block;
}

.a-icon--size-inherit {
  font-size: 1em;
}

.a-icon--size-standalone {
  font-size: 18px;
}

.a-icon--emphasis-high {
  opacity: .78;
}

.a-icon--emphasis-medium {
  opacity: .7;
}

.a-icon--emphasis-low {
  opacity: .62;
}

.a-icon--emphasis-lowest {
  opacity: .54;
}

.a-icon--emphasis-disabled {
  opacity: .32;
}
