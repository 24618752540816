.vanilla-ie-no-questions-found-message {
  text-align: center;
  padding: 2em;
  font-size: 1.1em;
  font-weight: 600;
  position: absolute;
  bottom: 0;
}

.vanilla-ie-no-questions-found-message__text {
  color: #6b7280;
}

.vanilla-ie-no-questions-found-message__reset-button {
  color: #50a9e2;
  cursor: pointer;
  font-weight: 600;
}

.vanilla-ie-no-questions-found-message__reset-button:hover {
  color: #1e7cb8;
}
