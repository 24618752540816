.classrooms-secondary-header-left-content {
  margin: 0 1.5em 0 0;
}

@media (max-width: 600px) {
  .classrooms-secondary-header-left-content {
    margin: 0 1em 0 0;
  }
}

.classrooms-secondary-header-left-content--has-archived-classes .simple-dropdown__option:last-child {
  color: #1e7cb8;
  text-align: right;
  background-color: #f3f4f6;
}
