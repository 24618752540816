.o-search-select__content-wrapper {
  z-index: 10;
  width: 100%;
  margin-top: 8px;
}

.o-search-select__content {
  max-height: 400px;
  overflow: auto;
  box-shadow: 0 5px 20px rgba(0, 0, 0, .05);
}

.o-search-select__content > :not(:last-child) {
  border-bottom: 1px solid #e5e7eb;
}

.o-search-select__content .o-search-select__empty-results {
  padding: 16px;
}

.o-search-select__search-input {
  width: 100%;
}

.o-search-select__message {
  color: #4b5563;
  margin-top: 4px;
  transition: color .1s ease-in-out;
}

.o-search-select__message--error {
  color: #9c251c;
}
