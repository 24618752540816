.a-tooltip {
  border: 1px solid var(--border-primary);
  border-radius: var(--space-x0-half);
  box-shadow: var(--elevation-shadow-l1);
  z-index: 1;
  max-width: 50ch;
  padding: 6px 10px;
  animation: .1s ease-in-out fade-in;
  display: inline-block;
}

.a-tooltip--theme-dark {
  background-color: var(--background-primary-inverse);
}

.a-tooltip--theme-light {
  background-color: var(--elevation-background-l1);
}

[data-placement^="top"] > .a-tooltip {
  margin-bottom: var(--space-x1);
}

[data-placement^="right"] > .a-tooltip {
  margin-left: var(--space-x1);
}

[data-placement^="bottom"] > .a-tooltip {
  margin-top: var(--space-x1);
}

[data-placement^="left"] > .a-tooltip {
  margin-right: var(--space-x1);
}
