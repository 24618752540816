.study-guide-content {
  flex-direction: column;
  padding-inline: 1rem;
  display: flex;
}

.study-guide-content__split-pane {
  flex-direction: row;
  gap: 48px;
  display: flex;
}

.study-guide-cards {
  flex-flow: column;
  display: flex;
}

.study-guide-cards-cta {
  color: #fff;
  background-color: #50a9e2;
  border-radius: 4px;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1em;
  padding: 1em 2em;
  display: flex;
}

.study-guide-cards-cta__upgrade-wrapper {
  align-items: center;
  display: flex;
}

.study-guide-cards-cta__p {
  margin: 0 0 0 .5em;
}

.study-guide-actions__accuracy-badge {
  margin-top: -.5em;
}

.study-guide-access-label {
  top: 1px;
}

.study-guide-heading-wrapper {
  align-items: center;
  display: flex;
}

.study-guide__search-result {
  color: #92400e;
  opacity: 1;
  background-color: #fef3c7;
  border-radius: 8px;
  padding: 8px;
  font-weight: bold;
  display: inline-block;
}

.disabled.sgi:hover {
  cursor: default;
  background-color: #fff;
}

.study-guide__sticky-search {
  z-index: 999;
  box-sizing: content-box;
  background: #fff;
  grid-template-columns: repeat(2, 1fr);
  justify-content: end;
  row-gap: 1rem;
  padding: 1.5rem 1rem;
  display: grid;
  position: sticky;
  top: 60px;
}

.study-guide__sticky-search--zIndex {
  z-index: 1000;
}

.study-guide__sticky-search--is-sticky {
  border-bottom: 1px solid var(--border-border-primary, #d1d5db);
  box-shadow: 0 2px 2px -2px rgba(35, 42, 47, .06), 0 4px 6px rgba(35, 42, 47, .06);
}

.study-guide__sticky-search button {
  box-sizing: border-box;
}

@media (min-width: 780px) {
  .study-guide__sticky-search {
    position: relative;
    top: 0;
  }

  .study-guide__sticky-search:after {
    display: none;
  }
}
