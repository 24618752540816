.m-selection-label {
  display: flex;
}

.m-selection-label__text-block {
  margin: auto 0 auto 16px;
}

.m-selection-label__label {
  cursor: default;
}
