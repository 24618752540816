.multiplechoice-editor .simple-dropdown {
  z-index: 100;
  margin-bottom: 1em;
}

.multiplechoice-editor .multiple-choice-editor__multiple-answers {
  margin-bottom: 2em;
}

.multiplechoice-editor .a-form-input__error {
  font-weight: normal;
}

.multiplechoice-editor .select-correct-answer + .form-input-error {
  margin-top: -.5em;
  margin-bottom: .5em;
}

.multiplechoice-editor .multiple-choice-editor-answer-option {
  margin-bottom: 2em;
  position: relative;
}

.multiplechoice-editor .multiple-choice-editor-answer-option b {
  margin-top: .5em;
}

.multiplechoice-editor .multiple-choice-editor-answer-option .multiple-choice-editor-answer-option-checkbox {
  align-items: center;
  width: 10em;
  margin-bottom: 1em;
  margin-right: auto;
  display: inline-flex;
  left: auto;
}

.multiplechoice-editor .multiple-choice-editor-answer-option .multiple-choice-editor-answer-option-checkbox .a-form-checkbox-wrapper {
  margin-right: 8px;
}

.multiplechoice-editor .multiple-choice-editor-answer-option .multiple-choice-editor-answer-option-text-input {
  width: 100%;
  padding-left: 1.75em;
  padding-right: 2.5em;
  position: relative;
}

.multiplechoice-editor .multiple-choice-editor-answer-option .multiple-choice-editor-answer-option-suplement-button {
  justify-content: flex-end;
  display: flex;
}

.multiplechoice-editor .multiple-choice-editor-answer-option .multiple-choice-editor-answer-option-suplement-button .add-supplement-btn {
  text-decoration: underline;
}

.multiplechoice-editor .multiple-choice-editor-answer-option .multiple-choice-editor-answer-option-suplement-button .add-supplement-btn:after {
  content: normal;
}

.multiplechoice-editor .multiple-choice-editor-option-selector {
  cursor: pointer;
}

.multiplechoice-editor .multiple-choice-editor-option-selector.not-selected {
  background: #d1d5db;
  border: 0;
  border-radius: 50%;
  width: 1.25em;
  height: 1.25em;
}

.multiplechoice-editor .multiple-choice-editor-option-selector.selected {
  background: #059669;
  border: 0;
  border-radius: 50%;
  width: 1.25em;
  height: 1.25em;
  position: relative;
}

.multiplechoice-editor .multiple-choice-editor-option-selector.selected:before {
  color: #fff;
  content: "";
  font-family: "Font Awesome 6 Pro";
  font-size: .8em;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%)translateY(-50%);
}

.multiplechoice-editor .multiplechoice-editor .question-prompt textarea, .multiplechoice-editor .multiplechoice-editor .explanation-solution textarea {
  padding: 15px;
}
