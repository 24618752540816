.subject-selector__breadcrumb {
  display: inherit;
  margin: 0;
  padding-left: 0;
  list-style: none;
}

.subject-selector__item-name, .subject-selector__item-name > .paragraph {
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 16ch;
  overflow: hidden;
}

.subject-selector_guide-list {
  max-height: 280px;
  overflow: auto;
}
