[data-placement^="top"] > .m-popover {
  margin-bottom: var(--space-x1);
}

[data-placement^="right"] > .m-popover {
  margin-left: var(--space-x1);
}

[data-placement^="bottom"] > .m-popover {
  margin-top: var(--space-x1);
}

[data-placement^="left"] > .m-popover {
  margin-right: var(--space-x1);
}

.m-popover {
  animation: fade-in var(--duration-fast) ease-in-out;
  min-width: 15rem;
  max-width: 36.5625rem;
}

.m-popover--mobile {
  z-index: 9999;
  max-width: calc(100vw - 2em);
  height: 100%;
}

.m-popover__backdrop {
  background-color: var(--overlay-35);
  z-index: 9;
  position: fixed;
  inset: 0;
}

.m-popover__close-btn {
  box-shadow: var(--elevation-shadow-l3);
  margin: 0 0 .25em auto;
}
