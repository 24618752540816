.subject-split-card button:focus:not(:focus-visible) {
  outline: 0;
}

.subject-split-card a:focus:not(:focus-visible) {
  outline: 0;
}

.subject-split-card:focus {
  outline: 0;
}

.subject-split-card:focus-within {
  outline: 0;
}

.subject-split-card--action-bar-in-view .subject-split-card__details, .subject-split-card--description-in-view .subject-split-card__details {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.subject-split-card__action-bar {
  border-radius: 0 0 24px 24px;
  min-height: 44px;
  box-shadow: inset 0 -1px #d1d5db, inset -1px 0 #d1d5db, inset 1px 0 #d1d5db;
}

.subject-split-card__action-bar:hover, .subject-split-card__action-bar:focus {
  cursor: pointer;
  background-color: #f3f4f6;
  text-decoration: none;
}

.subject-split-card__action-bar:active {
  background-color: #e5e7eb;
}

.subject-split-card__action-bar button:hover {
  text-decoration: none;
}

.subject-split-card__description {
  max-height: 0;
  padding-bottom: unset;
  padding-top: unset;
  border: none;
  border-bottom-right-radius: 24px;
  border-bottom-left-radius: 24px;
  transition: max-height .32s cubic-bezier(.075, .82, .165, 1), padding .32s cubic-bezier(.075, .82, .165, 1);
}

.subject-split-card--description-in-view .subject-split-card__description {
  max-height: unset;
  overflow: unset;
  padding-top: 16px;
  padding-bottom: 16px;
  box-shadow: inset 0 -1px #d1d5db, inset -1px 0 #d1d5db, inset 1px 0 #d1d5db;
}

.subject-split-card__details {
  cursor: pointer;
  border-bottom: none;
  border-radius: 24px;
  min-height: 120px;
  padding-right: 84px;
  transition: border-radius .25s cubic-bezier(.075, .82, .165, 1);
}

.subject-split-card__details:focus {
  outline: none;
}

.subject-split-card__details:hover {
  background-image: linear-gradient(rgba(0, 0, 0, .18) 0 0);
}

.subject-split-card__details:active {
  background-image: unset;
}

.subject-split-card__thumbnail {
  position: absolute;
  top: 0;
  right: 0;
}
