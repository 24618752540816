@media (min-width: 441px) {
  .o-mandark-table__mobile-search-toggle {
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
    white-space: nowrap;
    visibility: hidden;
    width: 1px;
    height: 1px;
    position: absolute;
    overflow: hidden;
  }
}

@media (max-width: 440px) {
  .o-mandark-table__search--hidden {
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
    white-space: nowrap;
    visibility: hidden;
    width: 1px;
    height: 1px;
    position: absolute;
    overflow: hidden;
  }
}
