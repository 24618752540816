.assignment-v2-ie-content-wrapper {
  width: 100%;
  max-width: 830px;
}

.assignment-v2-ie-subheader-right-content {
  display: flex;
}

.assignment-v2-ie-subheader-right-content__timer {
  align-items: center;
  margin-right: 10px;
  display: flex;
}
