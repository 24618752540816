.passage-correction-editor-uncorrected-text {
  margin-bottom: 20px;
}

.passage-correction-editor-uncorrected-text__input .a-form-input__element {
  height: 12em;
}

.passage-correction-editor-define-corrections__label {
  margin: 0 0 5px;
  font-weight: bold;
}

.passage-correction-editor-define-corrections__aside {
  color: #9ca3af;
  font-weight: bold;
}

.passage-correction-editor-define-corrections__corrections {
  cursor: default;
  border: 1px solid #d1d5db;
  border-radius: .25em;
  padding: 20px;
  font-size: 1.1em;
  line-height: 1.6;
}

.passage-correction-editor-define-corrections__buttons {
  justify-content: flex-end;
  margin-top: 2em;
  display: flex;
}

.passage-correction-editor-define-corrections__corrections--is-selecting-region .passage-correction-word:not(.passage-correction-word--active) {
  background-color: rgba(0, 0, 0, 0);
}

.passage-correction-editor-popover.card {
  z-index: 99;
  width: 350px;
  margin-top: 10px;
  position: fixed;
  box-shadow: 0 1px 6px rgba(0, 0, 0, .25);
}

.passage-correction-editor-popover.card:after {
  content: "";
  background-image: linear-gradient(to bottom right, #fff 50%, rgba(0, 0, 0, 0) 0);
  width: 20px;
  height: 20px;
  display: block;
  position: absolute;
  bottom: calc(100% - 10px);
  right: 20px;
  transform: rotate(45deg);
}

.passage-correction-editor-popover.card.passage-correction-editor-popover {
  box-shadow: 0 2px 10px rgba(0, 0, 0, .18);
}

.passage-correction-editor-popover__section {
  padding: 20px;
}

.passage-correction-editor-popover__section:not(:last-child) {
  border-bottom: 1px solid #f3f4f6;
}

.passage-correction-editor-popover__section .a-form-input {
  margin-right: 0;
}

.passage-correction-editor-popover__alt-correction-wrapper {
  align-items: center;
  margin-top: 10px;
  display: flex;
}

.passage-correction-editor-popover__add-alt-correction-wrapper {
  justify-content: flex-end;
  display: flex;
}

.passage-correction-editor-popover__actions {
  margin-top: 20px;
  display: flex;
}

.passage-correction-editor-popover__actions > * {
  flex-grow: 1;
}
