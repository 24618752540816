.status-selector {
  list-style: none;
}

.status-selector ul {
  flex-direction: column;
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
}

.status-selector__item {
  cursor: pointer;
  background-color: #ebf5fa;
  padding: 1em;
  display: flex;
}

.status-selector__item--active {
  color: #fff;
  background-color: #50a9e2;
}
