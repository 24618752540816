.o-table__row {
  flex-direction: row;
  flex-grow: 1;
  display: flex;
}

.o-table__row:hover .o-table__cell {
  background: #f3f4f6;
}

.o-table__row.o-table__row--selected .o-table__cell {
  background: #eef6fc;
}

.o-table__row.o-table__row--link:hover .o-table__cell {
  cursor: pointer;
  background: #eef6fc;
}

.o-table__row.o-table__row--link:active .o-table__cell {
  background: #cbe5f6;
}

.o-table--data .o-table__row:not(:last-child) {
  border-bottom: 1px solid #d1d5db;
}

.o-table--list thead .o-table__row .o-table__header {
  background: #fff;
}

.o-table--list tbody .o-table__row {
  flex-wrap: wrap;
  margin-top: -1px;
}

.o-table--list tbody .o-table__row:not(:last-child):after {
  content: "";
  width: calc(100% - 26px);
  height: 1px;
  margin: 0 auto;
  box-shadow: inset 0 -1px #d1d5db;
}

.o-table--list .o-table__row.o-table__row--selected:not(:last-child):after, .o-table--list .o-table__row:not(:last-child):hover:after {
  box-shadow: none;
}

.o-table--basic .o-table__row:not(:last-child) {
  border-bottom: 1px solid #d1d5db;
}

.o-table--basic:hover .o-table__cell {
  background: inherit;
}
