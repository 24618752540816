.tooltip__wrapper {
  font-family: Lato, Helvetica, Arial, sans-serif;
  position: static;
}

@media screen and (max-width: 600px) {
  .tooltip__wrapper {
    display: none;
  }
}

.tooltip__wrapper .tooltip .tooltip__markdown-renderer p {
  color: #f3f4f6;
  text-transform: none;
  margin-bottom: .25em;
  font-weight: normal;
}

.tooltip__wrapper .tooltip--white .tooltip__markdown-renderer p {
  color: #000;
}

.tooltip {
  color: #fff;
  opacity: 0;
  visibility: hidden;
  white-space: normal;
  z-index: 99;
  background-color: #1a699e;
  border-radius: .5em;
  width: 25vw;
  max-width: 300px;
  padding: .75em 1em;
  transition: visibility, opacity .25s;
  position: absolute;
  bottom: calc(100% + 1.5em);
  left: 50%;
  transform: translateX(-50%);
}

.tooltip:after, .tooltip:before {
  content: "";
  background-image: linear-gradient(45deg, #1a699e 50%, rgba(0, 0, 0, 0) 0);
  width: 1.5em;
  height: 1.5em;
  display: block;
  position: absolute;
  bottom: -1.5em;
  left: 50%;
  transform: translateY(-55%)translateX(-50%)rotate(-45deg);
}

.tooltip--right {
  bottom: 50%;
  left: calc(100% + 1.5em);
  transform: translateY(40%);
}

.tooltip--right:after, .tooltip--right:before {
  top: 50%;
  bottom: auto;
  left: 0;
  transform: translateY(-55%)translateX(-50%)rotate(45deg);
}

.tooltip--left {
  bottom: 50%;
  right: calc(100% + 1.5em);
  transform: translateY(40%);
}

.tooltip--left:after, .tooltip--left:before {
  top: 50%;
  bottom: auto;
  left: 90%;
  transform: translateY(-55%)translateX(50%)rotate(225deg);
}

.tooltip--bottom {
  top: calc(100% + 1.5em);
  bottom: auto;
}

.tooltip--bottom:after, .tooltip--bottom:before {
  top: .25em;
  bottom: auto;
  transform: translateY(-55%)translateX(-50%)rotate(135deg);
}

.tooltip--white {
  color: #374151;
  background-color: #fff;
  border: 2px solid #d1d5db;
}

.tooltip--white:after {
  background-image: linear-gradient(45deg, #fff 50%, rgba(0, 0, 0, 0) 0);
  bottom: -1.45em;
}

.tooltip--white:before {
  background-image: linear-gradient(45deg, #d1d5db 50%, rgba(0, 0, 0, 0) 0);
  bottom: -1.7em;
}

.tooltip--white.tooltip--right:after {
  bottom: auto;
  left: .25em;
}

.tooltip--white.tooltip--right:before {
  bottom: auto;
}
