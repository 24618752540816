.pagi {
  width: 100%;
  max-width: 100%;
  font-size: 14px;
}

.pagi__actions-wrapper {
  align-items: stretch;
  display: flex;
}

.pagi__caret {
  text-align: center;
  z-index: 1;
  justify-content: center;
  align-items: center;
  width: 20px;
  font-size: 1.5em;
  display: flex;
}

.pagi__caret, .pagi__caret:hover {
  color: #50a9e2;
}

.pagi__caret:disabled {
  color: #d1d5db;
  cursor: not-allowed;
}

.pagi__caret:before {
  content: "";
  width: 5px;
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
}

.pagi__caret:first-child:before {
  background-image: linear-gradient(to right, #fff, rgba(0, 0, 0, 0));
  left: 100%;
}

.pagi__caret:last-child:before {
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0), #fff);
  right: 100%;
}

ul.pagi__pages {
  max-width: calc(100% - 50px);
  text-align: center;
  white-space: nowrap;
  flex-grow: 1;
  max-width: calc(100% - 40px);
  margin: 0;
  padding: 0 5px;
  overflow: auto;
}

li.pagi__page {
  list-style-type: none;
  display: inline-block;
}

.pagi__page-btn {
  border-radius: .15em;
  margin: 0 .25em;
  padding: .35em .5em calc(.35em + 1px);
}

.pagi__page-btn--active {
  color: #fff;
  background-color: #50a9e2;
}

.pagi__page-btn--active:hover {
  color: #fff;
}
