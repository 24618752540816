.standard-search__standard-list {
  margin-top: 20px;
}

.question-footer__label-item .fa {
  cursor: pointer;
  margin-left: 5px;
}

.standard-select-modal__body {
  max-height: 50vh;
  padding: 0;
  overflow: auto;
}

.selected-items__chips {
  flex-wrap: nowrap;
  max-height: 50px;
  display: flex;
  overflow-x: scroll;
}
