.o-dialogue__content {
  width: 100%;
  display: inline-block;
}

.o-dialogue--size-full {
  width: 100%;
}

.o-dialogue--size-xl {
  max-width: 720px;
}

.o-dialogue--size-l {
  max-width: 600px;
}

.o-dialogue--size-m {
  max-width: 480px;
}

.o-dialogue--size-s {
  max-width: 320px;
}

.o-dialogue__header {
  display: flex;
  position: static;
}

.o-dialogue__title {
  flex-grow: 1;
  max-width: 70ch;
}

.o-dialogue__title:not(.o-dialogue__title--stretch) {
  padding-right: 64px;
}

.o-dialogue__title:not(.o-dialogue__title--group) {
  margin-bottom: 24px;
}

.o-dialogue__title--group {
  margin-bottom: 8px;
}

.o-dialogue__title--align-center {
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}

.o-dialogue__title--align-center:not(.o-dialogue__title--stretch) {
  padding-left: 64px;
}

.o-dialogue__close-button {
  z-index: 1;
  position: absolute;
  top: 0;
  right: 0;
}

.o-dialogue__body--align-center {
  text-align: center;
  max-width: 420px;
  margin-left: auto;
  margin-right: auto;
}

.o-dialogue__btn-group {
  margin-top: 40px;
}

.o-dialogue__btn-group--align-center {
  margin-left: auto;
  margin-right: auto;
}
