.a-form-checkbox {
  cursor: pointer;
  font-weight: normal;
  display: flex;
}

.a-form-checkbox--disabled {
  cursor: not-allowed;
  opacity: .5;
}

.a-form-checkbox-wrapper {
  color: #fff;
  cursor: pointer;
  width: 1.2em;
  min-width: 1.2em;
  height: 1.2em;
  min-height: 1.2em;
  margin-right: 1em;
  display: inline-block;
  position: relative;
}

.a-form-checkbox-wrapper .checkbox-checkmark, .a-form-checkbox-wrapper .checkbox-checkmark:after {
  position: absolute;
  inset: 0;
}

.a-form-checkbox-wrapper input[type="checkbox"] {
  position: absolute;
  inset: 0;
}

.a-form-checkbox-wrapper .checkbox-checkmark {
  content: "";
  will-change: background-color;
  background-color: #d1d5db;
  border-radius: .2em;
}

.a-form-checkbox-wrapper input[type="checkbox"] {
  cursor: pointer;
  opacity: 0;
  z-index: 99;
  background-color: rgba(0, 0, 0, 0);
  border: 0;
  outline-width: 0;
  width: 0;
  height: 0;
  margin: 0;
  padding: 0;
}

.a-form-checkbox-wrapper input[type="checkbox"]:not(:disabled):hover ~ .checkbox-checkmark {
  background-color: #c3c8d0;
  transition: background-color .2s cubic-bezier(.23, 1, .32, 1);
}

.a-form-checkbox-wrapper input[type="checkbox"]:not(:disabled):focus ~ .checkbox-checkmark {
  background-color: #c3c8d0;
  transition: background-color .2s cubic-bezier(.23, 1, .32, 1);
}

.a-form-checkbox-wrapper input[type="checkbox"]:not(:disabled):checked:hover ~ .checkbox-checkmark {
  background-color: #047d58;
  transition: background-color .2s cubic-bezier(.23, 1, .32, 1);
}

.a-form-checkbox-wrapper input[type="checkbox"]:not(:disabled):checked:focus ~ .checkbox-checkmark {
  background-color: #047d58;
  transition: background-color .2s cubic-bezier(.23, 1, .32, 1);
}

.a-form-checkbox-wrapper input[type="checkbox"]:not(:disabled):focus ~ .checkbox-checkmark {
  box-shadow: 0 0 1px 3px #d1d5db;
}

.a-form-checkbox-wrapper input[type="checkbox"]:not(:disabled):checked:focus ~ .checkbox-checkmark {
  box-shadow: 0 0 1px 3px #d1d5db;
}

.a-form-checkbox-wrapper input[type="checkbox"]:checked ~ .checkbox-checkmark {
  background-color: #059669;
}

.a-form-checkbox-wrapper input[type="checkbox"]:checked ~ .checkbox-checkmark:after {
  color: #fff;
  content: "";
  text-align: center;
  font-family: "Font Awesome 6 Pro";
  font-size: .9em;
}

.a-form-checkbox-wrapper input[type="checkbox"]:checked:disabled ~ .checkbox-checkmark {
  background-color: #9ca3af;
  transition: all cubic-bezier(.23, 1, .32, 1);
}

.a-form-checkbox-label-wrapper {
  flex-direction: column;
  display: flex;
}

.a-form-checkbox--has-error .a-form-input__label {
  margin-bottom: .25em;
}
