.accuracy-badge__wrapper {
  font-size: .75em;
  font-weight: bold;
  display: inline-flex;
}

@media (min-width: 420px) {
  .accuracy-badge__wrapper {
    font-size: .9em;
  }
}

.accuracy-badge {
  color: #fff;
  background-color: #f3f4f6;
}

.accuracy-badge--status {
  display: none;
}

.accuracy-badge--status.accuracy-badge--not-started {
  text-align: center;
  white-space: nowrap;
  border-radius: 5em;
  padding: .5rem .75rem;
  display: block;
}

.accuracy-badge--percentage {
  border-radius: 5em;
  padding: .5rem .75rem;
}

@media (min-width: 900px) {
  .accuracy-badge--status {
    border-right: .025em solid #fff;
    border-radius: 5rem 0 0 5rem;
    padding: .5rem .5rem .5rem .75rem;
    display: block;
  }

  .accuracy-badge--percentage {
    border-radius: 0 5rem 5rem 0;
    padding: .5rem .75rem .5rem .5rem;
  }
}

.accuracy-badge--mastery {
  background-color: #9d63cf;
}

.accuracy-badge--excelling {
  background-color: #50a9e2;
}

.accuracy-badge--passing {
  background-color: #f0bf6c;
}

.accuracy-badge--proficient {
  background-color: #10b981;
}

.accuracy-badge--struggling {
  background-color: #e36e63;
}

.accuracy-badge--learning, .accuracy-badge--started {
  background-color: #9ca3af;
}

.accuracy-badge--not-started {
  color: #4b5563;
  background-color: #f9fafb;
}
