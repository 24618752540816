@media (max-width: 440px) {
  .invite-banner__inner {
    flex-wrap: wrap;
  }

  .invite-banner__btn {
    width: 100%;
    margin-top: 8px;
  }
}
