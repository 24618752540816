.feedback-dialogue__container {
  max-width: 448px;
}

.feedback-dialogue__letters-outer-container {
  display: flex;
}

.feedback-dialogue__letters-outer-container > div {
  outline: none;
  margin-right: 8px;
}

@media (max-width: 780px) {
  .feedback-dialogue__letters-outer-container > div {
    margin: 0;
  }

  .feedback-dialogue__letters-outer-container > div > svg {
    width: 50px;
  }
}

.feedback-dialogue__letter-container {
  overflow: visible;
}

.feedback-dialogue__letter-container:hover {
  cursor: pointer;
}

.feedback-dialogue__letter-container:hover .feedback-dialogue__letter {
  fill: #50a9e2;
  opacity: 1;
}

.feedback-dialogue__letter-container:active .feedback-dialogue__letter {
  fill: #1e7cb8;
}

.feedback-dialogue__letter {
  fill: #6b7280;
  opacity: .5;
  transform: scale(.35)translate(17px, 8px);
}

.feedback-dialogue__circle {
  transform-origin: center;
  stroke-dasharray: 150;
  stroke-dashoffset: 100px;
  animation: .4s ease-out forwards feedback-dialogue-dash;
  transform: scale(1.2);
}

@keyframes feedback-dialogue-dash {
  to {
    stroke-dashoffset: 0;
  }
}

.feedback-dialogue__circle--selected {
  display: none;
}

.feedback-dialogue__selected-letter svg .feedback-dialogue__letter {
  fill: #50a9e2;
  opacity: 1;
}

.feedback-dialogue__selected-letter svg .feedback-dialogue__circle--selected {
  display: block;
}
