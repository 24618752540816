.question-preview {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  width: 830px;
  margin: 0 auto 20px;
  font-size: 14px;
  line-height: 1.2;
}

.question-preview table {
  border-collapse: collapse;
  border-spacing: 0;
  border: 0;
  max-width: 100%;
  margin: 20px auto;
  overflow: auto;
}

.question-preview th {
  background-color: #fff;
  border-top: 1px solid #e2e4e6;
}

.question-preview tbody tr {
  background-color: #fafbfc;
}

.question-preview tbody tr:nth-child(2n) {
  background-color: #fff;
}

.question-preview tr, .question-preview th {
  box-shadow: inset 0 -1px #e2e4e6;
}

.question-preview tbody:first-child tr:first-child {
  box-shadow: inset 0 -1px #e2e4e6, inset 0 1px #e2e4e6;
}

.question-preview th, .question-preview td {
  text-align: left;
  border-right: 1px solid #e2e4e6;
  padding: 20px 20px 21px;
}

.question-preview th:first-child, .question-preview td:first-child {
  border-left: 1px solid #e2e4e6;
}

.question-preview th {
  white-space: nowrap;
  font-size: 1.1em;
  font-weight: bold;
}

.question-preview th::selection {
  background-color: rgba(0, 0, 0, 0);
}

.question-preview th ::selection {
  background-color: rgba(0, 0, 0, 0);
}

.question-preview p, .question-preview a, .question-preview ul, .question-preview ol, .question-preview li, .question-preview h1, .question-preview h2, .question-preview h3, .question-preview h4, .question-preview h5, .question-preview h6, .question-preview blockquote, .question-preview figcaption {
  -webkit-user-select: none;
  user-select: none;
}

.question-preview a:not([class]) {
  color: #50a9e2;
}

.question-preview a:not([class]):not(:hover) {
  text-decoration: none;
}

.question-preview a:not([class]):hover, .question-preview a:not([class]):visited {
  color: #1e7cb8;
}

.question-preview small {
  line-height: 1.2;
}

.question-preview img {
  max-width: 100%;
}

.question-preview p, .question-preview li {
  color: #374151;
}

@media (max-width: 600px) {
  .question-preview ul, .question-preview ol {
    margin-left: 1rem;
    padding-left: 0;
  }
}

.question-preview li {
  margin-bottom: .5em;
}

.question-preview strong {
  color: #000;
  font-family: Lato, sans-serif;
  font-weight: 800;
}

.question-preview blockquote {
  margin: 0 0 1em;
  padding: 1em;
}

@media (max-width: 600px) {
  .question-preview blockquote {
    margin: 1em 1.5em;
  }
}

.question-preview pre {
  color: #4b5563;
  background-color: #f9fafb;
  border-radius: .25em;
  max-width: 100%;
  padding: .5em;
  font-family: Cousine, Courier, monospace;
  line-height: 1.4;
}

.question-preview p code, .question-preview .paragraph code {
  background-color: #f3f4f6;
  border-radius: .25em;
  margin: 0 .25em;
  padding: 0 .25em;
  display: inline-block;
}

.question-preview pre code[class^="lang"] {
  color: #ccc;
  background: #2d2d2d;
  padding: .75em 1em;
  display: block;
  overflow-x: auto;
}

.question-preview hr {
  background-color: #9ca3af;
  border: 0;
  height: 1px;
}

.question-preview [class$="question-content"] {
  margin-bottom: 2em;
}

.question-preview .scroll-wrapper {
  max-width: 100%;
  overflow: auto;
}

.question-preview .question-prompt, .question-preview .question-answer-area {
  margin-bottom: 2rem;
  padding: 0 2rem;
}

@media (max-width: 900px) {
  .question-preview .question-prompt, .question-preview .question-answer-area {
    padding: 0 1rem;
  }
}

.question-preview .question__prompt-heading {
  margin: 20px 0;
  font-size: 1.2em;
}

.question-preview .MathJax {
  color: #000;
}

.question-preview .MathJax_Display {
  margin: 0;
}

.question-preview__header {
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1em;
  display: flex;
}

.question-preview__header h1 {
  flex-grow: 1;
  margin: 0;
  font-size: 1.2em;
}
