.a-chip {
  border-radius: var(--space-x0-half);
  margin: var(--space-x0) var(--space-x1) var(--space-x1) var(--space-x0);
  padding: var(--space-x1) var(--space-x1-half);
  white-space: nowrap;
  border: none;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  font-weight: bold;
  text-decoration: none;
  display: inline-flex;
}

.a-chip--small {
  font-size: var(--text-s);
  padding: var(--space-x0-half) var(--space-x1);
}

.a-chip--neutral {
  background-color: var(--background-subtle);
  color: var(--content-primary);
}

.a-chip--neutral i {
  color: var(--content-primary);
}

.a-chip--neutral.a-chip--hover:not(.a-chip--disabled):hover {
  background-color: var(--background-subtle-hover);
}

.a-chip--neutral.a-chip--hover:not(.a-chip--disabled):hover:active, .a-chip--neutral:active:not(.a-chip--disabled) {
  background-color: var(--background-subtle);
}

.a-chip--neutral.a-chip--focus, .a-chip--neutral:focus {
  box-shadow: 0 0 0 2px var(--background-subtle-pressed);
  outline: none;
}

.a-chip--brand {
  background-color: var(--background-brand-subtle);
  color: var(--content-info);
}

.a-chip--brand i {
  color: var(--content-info);
}

.a-chip--brand.a-chip--hover:not(.a-chip--disabled):hover {
  background-color: var(--background-brand-subtle-hover);
}

.a-chip--brand.a-chip--hover:not(.a-chip--disabled):hover:active, .a-chip--brand:active:not(.a-chip--disabled) {
  background-color: var(--background-brand-subtle);
}

.a-chip--brand.a-chip--focus, .a-chip--brand:focus {
  box-shadow: 0 0 0 2px var(--background-brand-subtle-pressed);
  outline: none;
}

.a-chip--negative {
  background-color: var(--background-negative-subtle);
  color: var(--content-negative);
}

.a-chip--negative i {
  color: var(--content-negative);
}

.a-chip--negative.a-chip--hover:not(.a-chip--disabled):hover {
  background-color: var(--background-negative-subtle-hover);
}

.a-chip--negative.a-chip--hover:not(.a-chip--disabled):hover:active, .a-chip--negative:active:not(.a-chip--disabled) {
  background-color: var(--background-negative-subtle);
}

.a-chip--negative.a-chip--focus, .a-chip--negative:focus {
  box-shadow: 0 0 0 2px var(--background-negative-subtle-pressed);
  outline: none;
}

a.a-chip {
  text-decoration: none;
}

.a-chip--disabled {
  opacity: .5;
}

.a-chip--disabled:hover {
  color: inherit;
}

.a-chip--rounded {
  border-radius: var(--space-x2);
}
