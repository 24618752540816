.o-wall-back-button {
  color: inherit;
  opacity: .75;
  text-shadow: 0 1px 5px rgba(0, 0, 0, .25);
  background-color: rgba(0, 0, 0, 0);
  border: none;
  outline: none;
  align-items: center;
  text-decoration: none;
  transition: opacity .1s;
  display: flex;
  position: absolute;
  top: 40px;
  left: 40px;
}

.o-wall-back-button:focus, .o-wall-back-button:hover {
  opacity: 1;
}

.o-wall-back-button:focus:not(:focus-visible) {
  opacity: .75;
}

.o-wall-back-button .fa {
  margin-right: 8px;
}

@media screen and (max-width: 780px) {
  .o-wall-back-button {
    margin-bottom: 16px;
    position: relative;
    top: auto;
    left: auto;
  }
}
