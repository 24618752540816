.audio-player__container {
  border: 1px solid var(--border-primary);
  margin: inherit;
  width: inherit;
  border-radius: 16px;
  flex-wrap: wrap;
  flex-shrink: 0;
  place-content: center;
  align-self: stretch;
  align-items: center;
  gap: 24px;
  padding: 24px;
  display: flex;
}

.audio-player__title-track {
  flex-direction: column;
  flex: 1 0 0;
  align-items: center;
  gap: 16px;
  min-width: 280px;
  display: flex;
}

.audio-player__track {
  appearance: none;
  background: var(--content-tertiary-inverse);
  cursor: pointer;
  border-radius: 24px;
  width: 100%;
  height: 8px;
  margin: 12px;
}

.audio-player__track:hover {
  background: linear-gradient(to right, var(--content-secondary) var(--hover-percentage, 0%), var(--content-tertiary-inverse) var(--hover-percentage, 0%));
}

.audio-player__track::-webkit-slider-thumb {
  appearance: none;
  box-shadow: inset 0 0 0 4px var(--content-tertiary-inverse);
  cursor: pointer;
  background: #fff;
  border-radius: 50%;
  width: 24px;
  height: 24px;
}

.audio-player__track::-webkit-slider-thumb:hover {
  transform: scale(1.2);
}

.audio-player__track::-moz-range-thumb {
  box-shadow: inset 0 0 0 4px var(--content-tertiary-inverse);
  cursor: pointer;
  background: #fff;
  border-radius: 50%;
  width: 24px;
  height: 24px;
}

.audio-player__track::-moz-range-thumb:hover {
  transform: scale(1.2);
}

.audio-player__track--playing {
  background: var(--background-info);
}

.audio-player__track--playing:hover {
  background: linear-gradient(to right, var(--content-info) var(--hover-percentage, 0%), var(--background-info) var(--hover-percentage, 0%));
}

.audio-player__track--playing::-webkit-slider-thumb {
  box-shadow: inset 0 0 0 4px var(--background-info);
}

.audio-player__track--playing::-moz-range-thumb {
  box-shadow: inset 0 0 0 4px var(--background-info);
}

.audio-player__buttons-group {
  align-items: center;
  gap: 16px;
  display: flex;
}

.audio-player__title {
  text-align: center;
  max-width: 380px;
}

.audio-player__track-wrapper {
  flex-direction: column;
  align-self: stretch;
  align-items: center;
  display: flex;
}

.audio-player__timestamp-wrapper {
  justify-content: space-between;
  align-self: stretch;
  align-items: flex-start;
  gap: 8px;
  display: flex;
}

.audio-player__button {
  border: none;
  border-radius: 50%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.audio-player__button.type-1 {
  background: var(--background-info-subtle);
  width: 52px;
  height: 52px;
}

.audio-player__button.type-1:hover {
  background: var(--background-brand-subtle-hover);
}

.audio-player__button.type-2 {
  background: var(--background-info);
  width: 72px;
  height: 72px;
}

.audio-player__button.type-2:hover {
  background: var(--background-brand-hover);
}

.audio-player__button-icon-play-pause {
  color: #fff;
  font-size: 32px;
}

.audio-player__button-icon {
  color: var(--background-info);
  font-size: 17px;
}
