.m-modal__content.log-in-form {
  width: 100%;
  max-width: 480px;
}

.log-in-form-sso {
  display: flex;
}

@media (max-width: 1020px) {
  .log-in-form-sso {
    flex-direction: column;
  }
}

.log-in-form-sso__button {
  width: 100%;
}

.log-in-form-sso__button:last-child {
  margin-left: 8px;
}

@media (max-width: 1020px) {
  .log-in-form-sso__button:last-child {
    margin: 8px 0;
  }
}
