.a-card {
  border: 1px solid;
  list-style-type: none;
}

.a-card--roundness-none {
  border-radius: 0;
}

.a-card--roundness-small {
  border-radius: var(--space-x1);
}

.a-card--roundness-medium {
  border-radius: var(--space-x2);
}

.a-card--roundness-large {
  border-radius: var(--space-x3);
}

.a-card--roundness-pill {
  border-radius: 1000px;
}

.a-card--border-none {
  border: 0;
}

.a-card--border-regular {
  border-color: var(--border-primary);
}

.a-card--border-darker {
  border-color: var(--border-bold);
}

.a-card--border-success {
  border-color: var(--border-positive);
}

.a-card--shadow-closest {
  box-shadow: var(--elevation-shadow-l1);
}

.a-card--shadow-close {
  box-shadow: var(--elevation-shadow-l2);
}

.a-card--shadow-default {
  box-shadow: var(--elevation-shadow-l3);
}

.a-card--shadow-far {
  box-shadow: var(--elevation-shadow-l4);
}

.a-card--shadow-farthest {
  box-shadow: var(--elevation-shadow-l5);
}
