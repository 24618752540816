.o-find-and-apply__header {
  display: flex;
}

.o-find-and-apply__search {
  flex-grow: 4;
}

.o-find-and-apply__search:not(:last-child) {
  margin-right: 1em;
}

.o-find-and-apply__btn-group {
  justify-content: flex-end;
  display: flex;
}

.o-find-and-apply__btn-group > :not(:only-child) {
  margin-left: 8px;
}

.o-find-and-apply__msg {
  max-width: 70ch;
}

@media (max-width: 440px) {
  .o-find-and-apply {
    flex-direction: column;
    height: 100%;
    display: flex;
  }

  .o-find-and-apply__body {
    flex: 5em;
    overflow: auto;
  }

  .o-find-and-apply__footer {
    min-height: 5em;
  }
}
