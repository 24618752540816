.u-margin-block_space-x0 {
  margin-block: var(--space-x0);
}

.u-margin-block_space-x0-quarter {
  margin-block: var(--space-x0-quarter);
}

.u-margin-block_space-x0-half {
  margin-block: var(--space-x0-half);
}

.u-margin-block_space-x1 {
  margin-block: var(--space-x1);
}

.u-margin-block_space-x1-half {
  margin-block: var(--space-x1-half);
}

.u-margin-block_space-x2 {
  margin-block: var(--space-x2);
}

.u-margin-block_space-x3 {
  margin-block: var(--space-x3);
}

.u-margin-block_space-x4 {
  margin-block: var(--space-x4);
}

.u-margin-block_space-x5 {
  margin-block: var(--space-x5);
}

.u-margin-block_space-x6 {
  margin-block: var(--space-x6);
}

.u-margin-block_space-x7 {
  margin-block: var(--space-x7);
}

.u-margin-block_space-x8 {
  margin-block: var(--space-x8);
}

.u-margin-block_space-x9 {
  margin-block: var(--space-x9);
}

.u-margin-block_space-x10 {
  margin-block: var(--space-x10);
}

.u-margin-inline_space-x0 {
  margin-inline: var(--space-x0);
}

.u-margin-inline_space-x0-quarter {
  margin-inline: var(--space-x0-quarter);
}

.u-margin-inline_space-x0-half {
  margin-inline: var(--space-x0-half);
}

.u-margin-inline_space-x1 {
  margin-inline: var(--space-x1);
}

.u-margin-inline_space-x1-half {
  margin-inline: var(--space-x1-half);
}

.u-margin-inline_space-x2 {
  margin-inline: var(--space-x2);
}

.u-margin-inline_space-x3 {
  margin-inline: var(--space-x3);
}

.u-margin-inline_space-x4 {
  margin-inline: var(--space-x4);
}

.u-margin-inline_space-x5 {
  margin-inline: var(--space-x5);
}

.u-margin-inline_space-x6 {
  margin-inline: var(--space-x6);
}

.u-margin-inline_space-x7 {
  margin-inline: var(--space-x7);
}

.u-margin-inline_space-x8 {
  margin-inline: var(--space-x8);
}

.u-margin-inline_space-x9 {
  margin-inline: var(--space-x9);
}

.u-margin-inline_space-x10 {
  margin-inline: var(--space-x10);
}

.u-margin-block-start_space-x0 {
  margin-block-start: var(--space-x0);
}

.u-margin-block-start_space-x0-quarter {
  margin-block-start: var(--space-x0-quarter);
}

.u-margin-block-start_space-x0-half {
  margin-block-start: var(--space-x0-half);
}

.u-margin-block-start_space-x1 {
  margin-block-start: var(--space-x1);
}

.u-margin-block-start_space-x1-half {
  margin-block-start: var(--space-x1-half);
}

.u-margin-block-start_space-x2 {
  margin-block-start: var(--space-x2);
}

.u-margin-block-start_space-x3 {
  margin-block-start: var(--space-x3);
}

.u-margin-block-start_space-x4 {
  margin-block-start: var(--space-x4);
}

.u-margin-block-start_space-x5 {
  margin-block-start: var(--space-x5);
}

.u-margin-block-start_space-x6 {
  margin-block-start: var(--space-x6);
}

.u-margin-block-start_space-x7 {
  margin-block-start: var(--space-x7);
}

.u-margin-block-start_space-x8 {
  margin-block-start: var(--space-x8);
}

.u-margin-block-start_space-x9 {
  margin-block-start: var(--space-x9);
}

.u-margin-block-start_space-x10 {
  margin-block-start: var(--space-x10);
}

.u-margin-block-end_space-x0 {
  margin-block-end: var(--space-x0);
}

.u-margin-block-end_space-x0-quarter {
  margin-block-end: var(--space-x0-quarter);
}

.u-margin-block-end_space-x0-half {
  margin-block-end: var(--space-x0-half);
}

.u-margin-block-end_space-x1 {
  margin-block-end: var(--space-x1);
}

.u-margin-block-end_space-x1-half {
  margin-block-end: var(--space-x1-half);
}

.u-margin-block-end_space-x2 {
  margin-block-end: var(--space-x2);
}

.u-margin-block-end_space-x3 {
  margin-block-end: var(--space-x3);
}

.u-margin-block-end_space-x4 {
  margin-block-end: var(--space-x4);
}

.u-margin-block-end_space-x5 {
  margin-block-end: var(--space-x5);
}

.u-margin-block-end_space-x6 {
  margin-block-end: var(--space-x6);
}

.u-margin-block-end_space-x7 {
  margin-block-end: var(--space-x7);
}

.u-margin-block-end_space-x8 {
  margin-block-end: var(--space-x8);
}

.u-margin-block-end_space-x9 {
  margin-block-end: var(--space-x9);
}

.u-margin-block-end_space-x10 {
  margin-block-end: var(--space-x10);
}

.u-margin-inline-end_space-x0 {
  margin-inline-end: var(--space-x0);
}

.u-margin-inline-end_space-x0-quarter {
  margin-inline-end: var(--space-x0-quarter);
}

.u-margin-inline-end_space-x0-half {
  margin-inline-end: var(--space-x0-half);
}

.u-margin-inline-end_space-x1 {
  margin-inline-end: var(--space-x1);
}

.u-margin-inline-end_space-x1-half {
  margin-inline-end: var(--space-x1-half);
}

.u-margin-inline-end_space-x2 {
  margin-inline-end: var(--space-x2);
}

.u-margin-inline-end_space-x3 {
  margin-inline-end: var(--space-x3);
}

.u-margin-inline-end_space-x4 {
  margin-inline-end: var(--space-x4);
}

.u-margin-inline-end_space-x5 {
  margin-inline-end: var(--space-x5);
}

.u-margin-inline-end_space-x6 {
  margin-inline-end: var(--space-x6);
}

.u-margin-inline-end_space-x7 {
  margin-inline-end: var(--space-x7);
}

.u-margin-inline-end_space-x8 {
  margin-inline-end: var(--space-x8);
}

.u-margin-inline-end_space-x9 {
  margin-inline-end: var(--space-x9);
}

.u-margin-inline-end_space-x10 {
  margin-inline-end: var(--space-x10);
}

.u-margin-inline-start_space-x0 {
  margin-inline-start: var(--space-x0);
}

.u-margin-inline-start_space-x0-quarter {
  margin-inline-start: var(--space-x0-quarter);
}

.u-margin-inline-start_space-x0-half {
  margin-inline-start: var(--space-x0-half);
}

.u-margin-inline-start_space-x1 {
  margin-inline-start: var(--space-x1);
}

.u-margin-inline-start_space-x1-half {
  margin-inline-start: var(--space-x1-half);
}

.u-margin-inline-start_space-x2 {
  margin-inline-start: var(--space-x2);
}

.u-margin-inline-start_space-x3 {
  margin-inline-start: var(--space-x3);
}

.u-margin-inline-start_space-x4 {
  margin-inline-start: var(--space-x4);
}

.u-margin-inline-start_space-x5 {
  margin-inline-start: var(--space-x5);
}

.u-margin-inline-start_space-x6 {
  margin-inline-start: var(--space-x6);
}

.u-margin-inline-start_space-x7 {
  margin-inline-start: var(--space-x7);
}

.u-margin-inline-start_space-x8 {
  margin-inline-start: var(--space-x8);
}

.u-margin-inline-start_space-x9 {
  margin-inline-start: var(--space-x9);
}

.u-margin-inline-start_space-x10 {
  margin-inline-start: var(--space-x10);
}

.u-padding-block_space-x0 {
  padding-block: var(--space-x0);
}

.u-padding-block_space-x0-quarter {
  padding-block: var(--space-x0-quarter);
}

.u-padding-block_space-x0-half {
  padding-block: var(--space-x0-half);
}

.u-padding-block_space-x1 {
  padding-block: var(--space-x1);
}

.u-padding-block_space-x1-half {
  padding-block: var(--space-x1-half);
}

.u-padding-block_space-x2 {
  padding-block: var(--space-x2);
}

.u-padding-block_space-x3 {
  padding-block: var(--space-x3);
}

.u-padding-block_space-x4 {
  padding-block: var(--space-x4);
}

.u-padding-block_space-x5 {
  padding-block: var(--space-x5);
}

.u-padding-block_space-x6 {
  padding-block: var(--space-x6);
}

.u-padding-block_space-x7 {
  padding-block: var(--space-x7);
}

.u-padding-block_space-x8 {
  padding-block: var(--space-x8);
}

.u-padding-block_space-x9 {
  padding-block: var(--space-x9);
}

.u-padding-block_space-x10 {
  padding-block: var(--space-x10);
}

.u-padding-inline_space-x0 {
  padding-inline: var(--space-x0);
}

.u-padding-inline_space-x0-quarter {
  padding-inline: var(--space-x0-quarter);
}

.u-padding-inline_space-x0-half {
  padding-inline: var(--space-x0-half);
}

.u-padding-inline_space-x1 {
  padding-inline: var(--space-x1);
}

.u-padding-inline_space-x1-half {
  padding-inline: var(--space-x1-half);
}

.u-padding-inline_space-x2 {
  padding-inline: var(--space-x2);
}

.u-padding-inline_space-x3 {
  padding-inline: var(--space-x3);
}

.u-padding-inline_space-x4 {
  padding-inline: var(--space-x4);
}

.u-padding-inline_space-x5 {
  padding-inline: var(--space-x5);
}

.u-padding-inline_space-x6 {
  padding-inline: var(--space-x6);
}

.u-padding-inline_space-x7 {
  padding-inline: var(--space-x7);
}

.u-padding-inline_space-x8 {
  padding-inline: var(--space-x8);
}

.u-padding-inline_space-x9 {
  padding-inline: var(--space-x9);
}

.u-padding-inline_space-x10 {
  padding-inline: var(--space-x10);
}

.u-padding-block-start_space-x0 {
  padding-block-start: var(--space-x0);
}

.u-padding-block-start_space-x0-quarter {
  padding-block-start: var(--space-x0-quarter);
}

.u-padding-block-start_space-x0-half {
  padding-block-start: var(--space-x0-half);
}

.u-padding-block-start_space-x1 {
  padding-block-start: var(--space-x1);
}

.u-padding-block-start_space-x1-half {
  padding-block-start: var(--space-x1-half);
}

.u-padding-block-start_space-x2 {
  padding-block-start: var(--space-x2);
}

.u-padding-block-start_space-x3 {
  padding-block-start: var(--space-x3);
}

.u-padding-block-start_space-x4 {
  padding-block-start: var(--space-x4);
}

.u-padding-block-start_space-x5 {
  padding-block-start: var(--space-x5);
}

.u-padding-block-start_space-x6 {
  padding-block-start: var(--space-x6);
}

.u-padding-block-start_space-x7 {
  padding-block-start: var(--space-x7);
}

.u-padding-block-start_space-x8 {
  padding-block-start: var(--space-x8);
}

.u-padding-block-start_space-x9 {
  padding-block-start: var(--space-x9);
}

.u-padding-block-start_space-x10 {
  padding-block-start: var(--space-x10);
}

.u-padding-inline-end_space-x0 {
  padding-inline-end: var(--space-x0);
}

.u-padding-inline-end_space-x0-quarter {
  padding-inline-end: var(--space-x0-quarter);
}

.u-padding-inline-end_space-x0-half {
  padding-inline-end: var(--space-x0-half);
}

.u-padding-inline-end_space-x1 {
  padding-inline-end: var(--space-x1);
}

.u-padding-inline-end_space-x1-half {
  padding-inline-end: var(--space-x1-half);
}

.u-padding-inline-end_space-x2 {
  padding-inline-end: var(--space-x2);
}

.u-padding-inline-end_space-x3 {
  padding-inline-end: var(--space-x3);
}

.u-padding-inline-end_space-x4 {
  padding-inline-end: var(--space-x4);
}

.u-padding-inline-end_space-x5 {
  padding-inline-end: var(--space-x5);
}

.u-padding-inline-end_space-x6 {
  padding-inline-end: var(--space-x6);
}

.u-padding-inline-end_space-x7 {
  padding-inline-end: var(--space-x7);
}

.u-padding-inline-end_space-x8 {
  padding-inline-end: var(--space-x8);
}

.u-padding-inline-end_space-x9 {
  padding-inline-end: var(--space-x9);
}

.u-padding-inline-end_space-x10 {
  padding-inline-end: var(--space-x10);
}

.u-padding-block-end_space-x0 {
  padding-block-end: var(--space-x0);
}

.u-padding-block-end_space-x0-quarter {
  padding-block-end: var(--space-x0-quarter);
}

.u-padding-block-end_space-x0-half {
  padding-block-end: var(--space-x0-half);
}

.u-padding-block-end_space-x1 {
  padding-block-end: var(--space-x1);
}

.u-padding-block-end_space-x1-half {
  padding-block-end: var(--space-x1-half);
}

.u-padding-block-end_space-x2 {
  padding-block-end: var(--space-x2);
}

.u-padding-block-end_space-x3 {
  padding-block-end: var(--space-x3);
}

.u-padding-block-end_space-x4 {
  padding-block-end: var(--space-x4);
}

.u-padding-block-end_space-x5 {
  padding-block-end: var(--space-x5);
}

.u-padding-block-end_space-x6 {
  padding-block-end: var(--space-x6);
}

.u-padding-block-end_space-x7 {
  padding-block-end: var(--space-x7);
}

.u-padding-block-end_space-x8 {
  padding-block-end: var(--space-x8);
}

.u-padding-block-end_space-x9 {
  padding-block-end: var(--space-x9);
}

.u-padding-block-end_space-x10 {
  padding-block-end: var(--space-x10);
}

.u-padding-inline-start_space-x0 {
  padding-inline-start: var(--space-x0);
}

.u-padding-inline-start_space-x0-quarter {
  padding-inline-start: var(--space-x0-quarter);
}

.u-padding-inline-start_space-x0-half {
  padding-inline-start: var(--space-x0-half);
}

.u-padding-inline-start_space-x1 {
  padding-inline-start: var(--space-x1);
}

.u-padding-inline-start_space-x1-half {
  padding-inline-start: var(--space-x1-half);
}

.u-padding-inline-start_space-x2 {
  padding-inline-start: var(--space-x2);
}

.u-padding-inline-start_space-x3 {
  padding-inline-start: var(--space-x3);
}

.u-padding-inline-start_space-x4 {
  padding-inline-start: var(--space-x4);
}

.u-padding-inline-start_space-x5 {
  padding-inline-start: var(--space-x5);
}

.u-padding-inline-start_space-x6 {
  padding-inline-start: var(--space-x6);
}

.u-padding-inline-start_space-x7 {
  padding-inline-start: var(--space-x7);
}

.u-padding-inline-start_space-x8 {
  padding-inline-start: var(--space-x8);
}

.u-padding-inline-start_space-x9 {
  padding-inline-start: var(--space-x9);
}

.u-padding-inline-start_space-x10 {
  padding-inline-start: var(--space-x10);
}

.u-gap_space-x0 {
  gap: var(--space-x0);
}

.u-gap_space-x0-half {
  gap: var(--space-x0-half);
}

.u-gap_space-x1 {
  gap: var(--space-x1);
}

.u-gap_space-x1-half {
  gap: var(--space-x1-half);
}

.u-gap_space-x2 {
  gap: var(--space-x2);
}

.u-gap_space-x3 {
  gap: var(--space-x3);
}

.u-gap_space-x4 {
  gap: var(--space-x4);
}

.u-gap_space-x5 {
  gap: var(--space-x5);
}

.u-gap_space-x6 {
  gap: var(--space-x6);
}

.u-gap_space-x7 {
  gap: var(--space-x7);
}

.u-gap_space-x8 {
  gap: var(--space-x8);
}

.u-gap_space-x9 {
  gap: var(--space-x9);
}

.u-gap_space-x10 {
  gap: var(--space-x10);
}
