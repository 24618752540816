.a-curve {
  color: var(--content-primary-inverse-static);
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.a-curve__bg--pos-top:after, .a-curve__bg--pos-bottom:after {
  left: 50%;
  transform: translateX(-50%);
}

.a-curve__bg--pos-left:after, .a-curve__bg--pos-right:after {
  top: 50%;
  transform: translateY(-50%);
}

.a-curve__bg--pos-top:after {
  top: 0;
}

.a-curve__bg--pos-bottom:after {
  bottom: 0;
}

.a-curve__bg--pos-left:after {
  left: 0;
}

.a-curve__bg--pos-right:after {
  right: 0;
}

.a-curve--depth-xl .a-curve__bg--pos-top:before {
  top: 80px;
}

.a-curve--depth-xl .a-curve__bg--pos-bottom:before {
  bottom: 80px;
}

.a-curve--depth-xl .a-curve__bg--pos-left:before {
  left: 80px;
}

.a-curve--depth-xl .a-curve__bg--pos-right:before {
  right: 80px;
}

.a-curve--depth-xl .a-curve__bg--pos-top:after, .a-curve--depth-xl .a-curve__bg--pos-bottom:after {
  width: 120%;
  height: 160px;
}

.a-curve--depth-xl .a-curve__bg--pos-left:after, .a-curve--depth-xl .a-curve__bg--pos-right:after {
  width: 160px;
  height: 120%;
}

.a-curve--depth-l .a-curve__bg--pos-top:before {
  top: 60px;
}

.a-curve--depth-l .a-curve__bg--pos-bottom:before {
  bottom: 60px;
}

.a-curve--depth-l .a-curve__bg--pos-left:before {
  left: 60px;
}

.a-curve--depth-l .a-curve__bg--pos-right:before {
  right: 60px;
}

.a-curve--depth-l .a-curve__bg--pos-top:after, .a-curve--depth-l .a-curve__bg--pos-bottom:after {
  width: 120%;
  height: 120px;
}

.a-curve--depth-l .a-curve__bg--pos-left:after, .a-curve--depth-l .a-curve__bg--pos-right:after {
  width: 120px;
  height: 120%;
}

.a-curve--depth-m .a-curve__bg--pos-top:before {
  top: 40px;
}

.a-curve--depth-m .a-curve__bg--pos-bottom:before {
  bottom: 40px;
}

.a-curve--depth-m .a-curve__bg--pos-left:before {
  left: 40px;
}

.a-curve--depth-m .a-curve__bg--pos-right:before {
  right: 40px;
}

.a-curve--depth-m .a-curve__bg--pos-top:after, .a-curve--depth-m .a-curve__bg--pos-bottom:after {
  width: 120%;
  height: 80px;
}

.a-curve--depth-m .a-curve__bg--pos-left:after, .a-curve--depth-m .a-curve__bg--pos-right:after {
  width: 80px;
  height: 120%;
}

.a-curve--depth-s .a-curve__bg--pos-top:before {
  top: 20px;
}

.a-curve--depth-s .a-curve__bg--pos-bottom:before {
  bottom: 20px;
}

.a-curve--depth-s .a-curve__bg--pos-left:before {
  left: 20px;
}

.a-curve--depth-s .a-curve__bg--pos-right:before {
  right: 20px;
}

.a-curve--depth-s .a-curve__bg--pos-top:after, .a-curve--depth-s .a-curve__bg--pos-bottom:after {
  width: 120%;
  height: 40px;
}

.a-curve--depth-s .a-curve__bg--pos-left:after, .a-curve--depth-s .a-curve__bg--pos-right:after {
  width: 40px;
  height: 120%;
}

.a-curve--depth-xs .a-curve__bg--pos-top:before {
  top: 16px;
}

.a-curve--depth-xs .a-curve__bg--pos-bottom:before {
  bottom: 16px;
}

.a-curve--depth-xs .a-curve__bg--pos-left:before {
  left: 16px;
}

.a-curve--depth-xs .a-curve__bg--pos-right:before {
  right: 16px;
}

.a-curve--depth-xs .a-curve__bg--pos-top:after, .a-curve--depth-xs .a-curve__bg--pos-bottom:after {
  width: 120%;
  height: 32px;
}

.a-curve--depth-xs .a-curve__bg--pos-left:after, .a-curve--depth-xs .a-curve__bg--pos-right:after {
  width: 32px;
  height: 120%;
}

.a-curve__bg {
  color: var(--background-brand);
  position: absolute;
  inset: 0;
  overflow: hidden;
}

.a-curve__bg:before {
  content: "";
  background-color: currentColor;
  display: block;
  position: absolute;
  inset: 0;
}

.a-curve__bg:after {
  content: "";
  background-color: currentColor;
  border-radius: 50%;
  display: block;
  position: absolute;
}
