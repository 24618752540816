.a-heading {
  font-weight: 900;
}

.a-heading--with-margin {
  margin-bottom: 1.5em;
}

.a-heading--2xl {
  font-size: 2.5rem;
}

.a-heading--xl {
  font-size: 2rem;
}

.a-heading--l {
  font-size: 1.75rem;
}

.a-heading--m {
  font-size: 1.5rem;
}

.a-heading--s {
  font-size: 1.25rem;
}

.a-heading--xs {
  font-size: 1rem;
}

.a-heading--2xs {
  letter-spacing: .5px;
  text-transform: uppercase;
  font-size: .875rem;
}

.a-heading--uppercase {
  text-transform: uppercase;
}

@media screen and (max-width: 580px) {
  .a-heading--2xl {
    font-size: 2.375rem;
  }

  .a-heading--xl {
    font-size: 1.875rem;
  }

  .a-heading--l {
    font-size: 1.625rem;
  }

  .a-heading--m {
    font-size: 1.375rem;
  }

  .a-heading--s {
    font-size: 1.125rem;
  }

  .a-heading--xs {
    font-size: .8125rem;
  }

  .a-heading--2xs {
    font-size: .75rem;
  }
}
