.sign-up-link-button {
  color: #50a9e2;
  text-transform: uppercase;
  white-space: nowrap;
  background-color: #fff;
  border-radius: 8px;
  padding: 0 16px;
  font-size: .8em;
  font-weight: 700;
  line-height: 40px;
  text-decoration: none;
  transition: all .2s ease-in-out;
}

.sign-up-link-button:focus, .sign-up-link-button:hover {
  background: #f9fafb;
  box-shadow: 0 2px 10px rgba(0, 0, 0, .1);
}
