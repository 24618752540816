.marketing-banner {
  background-color: #155884;
  padding: 64px 56px;
}

@media (min-width: 440px) {
  .marketing-banner {
    background-image: url("./backpack-laptop.svg");
    background-position: 100% 100%;
    background-repeat: no-repeat;
    background-size: 30%;
  }
}

.marketing-banner_text-block {
  max-width: 70%;
}

.marketing-banner_text-block--text {
  opacity: .7;
  text-shadow: 0 0 4px #155884, 0 0 8px #155884, 0 0 12px #155884;
}

.marketing-banner_text-block--heading {
  text-shadow: 0 0 4px #155884, 0 0 8px #155884, 0 0 12px #155884;
}

@media (max-width: 440px) {
  .marketing-banner {
    padding: 32px;
  }

  .marketing-banner_text-block {
    max-width: 100%;
  }

  .marketing-banner_text-block--button {
    width: 100%;
  }
}
