.sub-search__results {
  max-height: 300px;
  margin: 0;
  padding: 0;
  overflow: auto;
}

@media (max-width: 440px) {
  .sub-search__results {
    max-height: none;
  }
}

.sub-search__result {
  display: flex;
}

.sub-search__result--description-hidden {
  align-items: center;
}

.sub-search__result-label {
  display: block;
}

.sub-search__result-description--hidden {
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: inset(100%);
  white-space: nowrap;
  border: 0;
  width: 1px;
  height: 1px;
  padding: 0;
  position: absolute;
  overflow: hidden;
}
