.audio-editor__fieldset, .audio-editor__input {
  flex-direction: column;
  margin: 0 0 20px;
  display: flex;
}

.audio-editor__input--upload-manager {
  align-items: left;
  margin: 0 auto 10px;
}

.audio-editor__button {
  -webkit-appearance: none;
  -moz-appearance: none;
  color: #374151;
  cursor: pointer;
  background-color: #d1d5db;
  border: 0;
  border-radius: .15em;
  padding: .85em 1em .8em;
  font-size: 1em;
  font-weight: bold;
  text-decoration: none;
  transition: box-shadow .1s ease-in-out, transform .1s ease-in-out;
}

.audio-editor__button::selection {
  background-color: rgba(0, 0, 0, 0);
}

.audio-editor__button ::selection {
  background-color: rgba(0, 0, 0, 0);
}

.audio-editor__button.disabled, .audio-editor__button.disabled:hover, .audio-editor__button:disabled, .audio-editor__button:disabled:hover {
  color: #d1d5db;
  background-color: #6b7280;
}

.audio-editor__button:not(:disabled):not(:active):hover {
  transform: translateY(-1px)scale(1.01);
  box-shadow: 0 2px 9px rgba(0, 0, 0, .2);
}

input[type="file"].audio-editor__button {
  display: none;
}

.audio-editor__button--disabled {
  cursor: not-allowed;
  opacity: .5;
}
