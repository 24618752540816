.assignment-status-banner {
  color: #fff;
  background-color: #059669;
  border-radius: 5px;
  align-items: center;
  margin-bottom: 10px;
  padding: 15px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, .1);
}

.assignment-status-banner--red {
  background-color: #dc4538;
}
