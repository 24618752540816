.access-label__breadcrumb-wrapper, .access-label__subject-view-wrapper {
  align-self: center;
  margin-left: auto;
  margin-right: .5em;
  display: flex;
}

.access-label {
  opacity: .25;
  margin: 0 1ch;
  font-size: 1em;
}

.access-label--subscription {
  color: #374151;
  cursor: pointer;
  opacity: 1;
  white-space: nowrap;
  background: #f3f4f6;
  border-radius: 2em;
  width: auto;
  height: 2em;
  margin: 0 1em;
  padding: .4em 1em;
  font-size: 14px;
  font-weight: bold;
  display: inline-block;
}

.access-label--subscription, .access-label--subscription a {
  text-decoration: none;
}

.access-label--subscription:empty:not([type="button"]) {
  display: none;
}

.access-label--subscription::selection {
  background-color: rgba(0, 0, 0, 0);
}

.access-label--subscription ::selection {
  background-color: rgba(0, 0, 0, 0);
}

.access-label--subscription:hover, .access-label--subscription:focus {
  background-color: #3a9ede;
  outline-width: 0;
  transition: background-color .25s;
}

@media (max-width: 600px) {
  .access-label--subscription:hover, .access-label--subscription:focus {
    background-color: #50a9e2;
    outline-width: 0;
    transition: background-color .25s;
  }
}

.access-label--subscription:hover {
  background: #f3f4f6;
}

.access-label--subscription .fa {
  margin-right: .5em;
}

.access-label--subscription .tooltip {
  text-align: center;
  width: 10vw;
  padding: .5em .7em;
}

.access-label--subscription .tooltip p {
  margin: 0 0 .1em;
}

@media (max-width: 600px) {
  .access-label--subscription {
    margin-top: .25em;
  }
}
