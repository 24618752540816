.global-nav {
  color: #fff;
  z-index: 1000;
  background-color: #1a699e;
  width: 100%;
  height: 60px;
  position: fixed;
  top: 0;
  left: 0;
}

.global-nav-not-sticky {
  position: relative;
}

.global-nav__content {
  flex-flow: row;
  justify-content: space-between;
  align-items: stretch;
  width: 100%;
  max-width: 2880px;
  height: 100%;
  display: flex;
}

.global-nav__content nav, .global-nav__content ul:not(.global-nav__main-link-dropdown), .global-nav__content li {
  height: 100%;
}

.global-nav__title .a-logo {
  align-items: center;
  display: flex;
}

.global-nav__title .global-nav__dennis-logo {
  width: 96px;
}

.global-nav__main {
  margin-right: auto;
}

.global-nav__main-list, .global-nav__account-list {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
}

.global-nav__main-link {
  align-items: center;
  padding: 0 1em;
  display: flex;
}

@media (max-width: 780px) {
  .global-nav__main-link:not(.global-nav__main-link-dropdown-wrapper) {
    padding: .5em;
  }
}

a.global-nav__main-link--active {
  color: #fff;
  background: #1e7cb8;
}

a.global-nav__main-link--active:hover {
  background: #1e7cb8;
}

.global-nav__has-dropdown:hover {
  background: #155884;
}

.global-nav__has-dropdown:hover .global-nav__main-link-dropdown {
  opacity: 1;
  animation: .2s global-nav-dropdown-fade-in;
  display: block;
}

.global-nav__beta-badge {
  letter-spacing: .03125rem;
  background: #0b2f46;
  padding: 2px 6px;
  font-weight: 900;
}

.global-nav__beta-badge .a-text {
  font-size: 10px;
}

.global-nav__main-link-dropdown {
  cursor: pointer;
  white-space: nowrap;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  padding: 16px;
  position: absolute;
}

.global-nav__main-link-dropdown[hidden] {
  opacity: 0;
  display: none;
}

.global-nav__main-link-dropdown li:last-child .global-nav__dropdown-item {
  margin-bottom: 0;
}

.global-nav__main-link-dropdown .global-nav__dropdown-item {
  color: rgba(0, 0, 0, .7);
  margin-bottom: 24px;
  padding: 8px;
  text-decoration: none;
  display: block;
}

.global-nav__main-link-dropdown .global-nav__dropdown-item:hover, .global-nav__main-link-dropdown .global-nav__dropdown-item:focus {
  color: #1e7cb8;
}

.global-nav__main-link-dropdown .a-menu-list-item {
  justify-content: left;
}

@keyframes global-nav-dropdown-fade-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@media (max-width: 780px) {
  .global-nav__rating-icon-container {
    display: none;
  }
}

.global-nav__rating-icon svg circle {
  display: none;
}

.global-nav__rating-icon-pulsing svg circle {
  transform-origin: center;
  animation: 3s ease-in-out infinite both global-nav-heartbeat;
}

@keyframes global-nav-heartbeat {
  from {
    transform-origin: center;
    transform: scale(1);
  }

  10% {
    transform-origin: center;
    transform: scale(.91);
  }

  17% {
    transform-origin: center;
    transform: scale(.98);
  }

  33% {
    transform-origin: center;
    transform: scale(.87);
  }

  45% {
    transform-origin: center;
    transform: scale(1);
  }
}

@media (max-width: 780px) {
  .global-nav__folders svg {
    display: none;
  }

  .global-nav__folders {
    padding: 1em 0;
  }
}

.global-nav__folders-active {
  background: #1e7cb8;
}

.global-nav__dropdown-username {
  border-bottom: 1px solid #f3f4f6;
  align-items: center;
  padding: 1em 1.5em;
  display: flex;
}

.global-nav__username {
  flex-flow: row;
  align-items: center;
  margin: 0 0 0 10px;
  font-size: .875em;
  display: flex;
}

.global-nav__dropdown-drawer {
  padding: 0;
}

.global-nav__dropdown {
  flex-flow: column;
  width: 100%;
  font-size: 1.05rem;
  display: flex;
}

.global-nav__dropdown a {
  color: #374151;
  font-weight: bold;
  text-decoration: none;
}

.global-nav__dropdown a:hover {
  background: #e5e7eb;
  transition: background .15s ease-out;
}

.global-nav__dropdown-link {
  color: #374151;
  cursor: pointer;
  padding: 16px 24px;
  font-weight: bold;
}

.global-nav__dropdown-link:hover {
  color: #374151;
  background: #e5e7eb;
  text-decoration: none;
  transition: background .15s ease-out;
}

.global-nav__beta-button {
  background-color: #9d63cf;
  position: relative;
}

.global-nav__beta-button .global-nav__beta-badge {
  align-items: flex-start;
  gap: var(--spacing-spacing-1, 8px);
  background-color: #f59e0b;
  padding: 4px;
  display: flex;
  position: absolute;
  top: -4.273px;
  right: -6.721px;
  transform: rotate(15deg);
}

:export {
  mobileBreakpoint: 1020px;
  tabletBreakpoint: 1020px;
  globalNavHeight: 60px;
}
