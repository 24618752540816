.previous-attempts-single-attempt {
  color: #fff;
  opacity: .5;
  background: #9ca3af;
  border-radius: .7143em;
  justify-content: center;
  align-items: center;
  width: 1.4286em;
  height: 1.4286em;
  margin: 0 -.7em 0 0;
  display: flex;
}

.previous-attempts-single-attempt--active {
  opacity: 1;
  z-index: 9;
}

.previous-attempts-single-attempt--correct {
  background: #34d399;
}

.previous-attempts-single-attempt--incorrect {
  background: #dc4538;
}

.previous-attempts-single-attempt--used {
  background: #50a9e2;
}

.previous-attempts-single-attempt--not-used {
  background: #fff;
  border: 2px solid #9ca3af;
}

.previous-attempts-single-attempt__icon {
  font-size: .9em;
}
