.summary-stats-donut-chart__text-container {
  position: absolute;
}

.summary-stats-donut-chart__legend {
  align-content: start;
  column-gap: var(--space-x1-quarter);
  row-gap: var(--space-x1);
  padding-left: var(--space-x2);
  grid-template-columns: auto 1fr auto auto;
  list-style-type: none;
  display: grid;
}

.summary-stats-donut-chart__legend-item {
  grid-column: 1 / -1;
  grid-template-columns: subgrid;
  display: grid;
}

.summary-stats-donut-chart__legend-item > * {
  align-self: center;
}

.summary-stats-donut-chart__legend-square {
  border-radius: var(--space-x1);
  height: var(--space-x2);
  width: var(--space-x1);
  background-color: gray;
}

.summary-stats-donut-chart__tooltip-wrapper {
  top: 0;
  left: 0;
}

.summary-stats-donut-chart__tooltip-content {
  max-width: calc(40 * var(--space-x1));
}

.summary-stats-donut-chart__svg {
  overflow: visible;
}
