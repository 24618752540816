.question-tag-editor__tag-list {
  margin-top: 5px;
}

.tag-item .fa {
  margin-left: 5px;
}

.question-tag-editor .sg-dropdown__bottom-message {
  pointer-events: all;
}
