.label-table {
  margin-bottom: 2em;
}

.label-table__name {
  font-weight: bold;
}

.label-table__category-name {
  text-align: center;
}
