.mcq-option-accessible-wrapper {
  width: 100%;
}

.mcq-option {
  border-radius: 8px;
  align-items: center;
  width: 100%;
  margin-bottom: 8px;
  padding: 12px;
  display: flex;
  position: relative;
}

.mcq-option:before {
  content: "";
  pointer-events: none;
  border: 1px solid #e1e4e6;
  border-radius: 80px;
  display: block;
  position: absolute;
  inset: 0;
}

.mcq-option:hover .mcq-option__correctness-indicator {
  opacity: 1;
}

.mcq-option--checkbox, .mcq-option--checkbox:before {
  border-radius: 8px;
}

.mcq-option__letter-wrapper {
  color: #979da1;
  background-color: #fff;
  border-radius: 50%;
  width: 44px;
  min-width: 44px;
  height: 44px;
  min-height: 44px;
  margin-right: 12px;
  font-size: 21px;
}

.mcq-option__letter-wrapper--checkbox {
  border-radius: 8px;
}

.mcq-option__letter {
  font-weight: bold;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%)translateY(calc(-50% - 1px));
}

.mcq-option__content {
  flex-grow: 1;
  max-width: calc(100% - 48px);
}

.mcq-option--pre-submission {
  cursor: pointer;
}

.mcq-option--pre-submission:hover:before {
  border: 3px solid #c6c9cc;
}

.mcq-option__hidden-input {
  appearance: none;
  width: 0;
  height: 0;
  position: absolute;
  overflow: hidden;
}

.mcq-option__hidden-input:focus + .mcq-option--pre-submission {
  opacity: 1;
}

.mcq-option__hidden-input:focus + .mcq-option--pre-submission:not(.mcq-option--selected):before {
  border: 3px solid #c6c9cc;
}

.mcq-option--post-submission {
  cursor: default;
}

.mcq-option--selected:hover:before, .mcq-option--selected:before {
  border: 3px solid #50a9e2;
}

.mcq-option--selected .mcq-option__letter-wrapper {
  color: #fff;
  background-color: #50a9e2;
  border-color: #50a9e2;
}

.mcq-option--eliminated {
  opacity: .5;
  pointer-events: none;
}

.mcq-option--eliminated .mcq-option__content {
  text-decoration: line-through;
}

.interaction-engine-v2--mobile .mcq__options-wrapper {
  padding: 0;
}

.interaction-engine-v2--mobile .mcq-option {
  padding: 12px;
}

.interaction-engine-v2--mobile .mcq-option__content {
  max-width: calc(100% - 33px);
}

.interaction-engine-v2--mobile .mcq-option__letter-wrapper {
  width: 40px;
  min-width: 40px;
  height: 40px;
  min-height: 40px;
  font-size: 18px;
}
