.upgrade-subject-modal {
  max-width: 450px;
}

.upgrade-subject-modal__title {
  text-align: center;
  margin: 0 40px;
}

.upgrade-subject-modal__img {
  max-width: 240px;
  margin: 24px auto;
  display: block;
}

.upgrade-subject-modal__selling-point-list {
  margin-bottom: 40px;
  padding: 0;
}

.upgrade-subject-modal__selling-point-wrapper {
  align-items: center;
  margin-bottom: 24px;
  display: flex;
}

.upgrade-subject-modal__image-icon {
  vertical-align: middle;
  width: 3em;
  margin-right: 1em;
}

.upgrade-subject-modal__selling-point {
  margin-bottom: 0;
  margin-left: 24px;
  display: inline-block;
}

.upgrade-subject-modal__button {
  text-align: center;
}
