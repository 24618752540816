.upload-button__wrapper {
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.upload-button__button {
  flex-grow: 0;
  flex-shrink: 0;
  order: -1;
  align-self: flex-start;
  margin-right: 1em;
}

.upload-button__file-list {
  flex-direction: column;
  flex-grow: 1;
  flex-basis: calc(100% - 122px);
  padding: 0;
  list-style: none;
  display: flex;
  overflow: hidden;
}

.upload-button__file-list:empty {
  margin: 0;
}

.upload-button__file-list-item {
  justify-content: space-between;
  display: flex;
}

.upload-button__file-list-item-name, .upload-button__file-list-item-size {
  text-overflow: ellipsis;
  white-space: nowrap;
  flex-basis: 50%;
  overflow: hidden;
}

.upload-button__file-list-item-name:last-child, .upload-button__file-list-item-size:last-child {
  text-align: right;
}

.upload-button__error-container {
  flex: 1 0 100%;
}
