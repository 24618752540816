.ie-subheader {
  z-index: 100;
  background-color: #1e7cb8;
  width: 100%;
  height: 60px;
  padding: 0 1em;
  font-weight: bold;
  display: flex;
  position: fixed;
  top: 60px;
}

.ie-subheader .loading-indicator {
  align-items: center;
  height: 100%;
  display: flex;
}

.ie-subheader .loading-indicator__circle {
  background-color: #fff;
}

.ie-subheader__item {
  color: #fff;
  white-space: nowrap;
  align-items: center;
  padding: 0 2em 0 1em;
  text-decoration: none;
  display: flex;
  overflow: hidden;
}

.ie-subheader__item:first-child {
  min-width: 10em;
}

.ie-subheader__item:not(:only-child):after {
  content: "";
  background: linear-gradient(to top right, rgba(0, 0, 0, 0) calc(50% - 1px), rgba(255, 255, 255, .25) 0 50%, rgba(0, 0, 0, 0) 0) top / 100% 50% no-repeat, linear-gradient(to bottom right, rgba(0, 0, 0, 0) calc(50% - 1px), rgba(255, 255, 255, .25) 0 50%, rgba(0, 0, 0, 0) 0) bottom / 100% 50% no-repeat;
  width: 1em;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
}

.ie-subheader__item-name--hoverable {
  cursor: pointer;
}

.ie-subheader__item-name--hoverable:hover .ie-subheader__item-hover-text {
  opacity: .6;
  transform: translateY(-.6em)translateY(-1px);
}

.ie-subheader__item-name--hoverable:hover .ie-subheader__item-label {
  transform: translateY(.4em);
}

.ie-subheader__item-name {
  white-space: nowrap;
  margin-right: .75em;
}

.ie-subheader__item-name:only-child {
  margin-right: 0;
}

.ie-subheader__item-hover-text {
  opacity: 0;
  font-size: .9em;
  font-style: italic;
  transition: opacity .25s, transform .25s;
  position: absolute;
  transform: translateY(0);
}

.ie-subheader__item-label {
  text-overflow: ellipsis;
  min-width: 7em;
  transition: transform .25s;
  display: block;
  overflow: hidden;
  transform: translateY(0);
}

.ie-subheader__access-label {
  margin: 0 0 0 .75em;
}

.ie-subheader__right-content {
  align-items: center;
  margin-left: auto;
  display: flex;
}
