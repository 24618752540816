.content-discovery-survey__container {
  max-width: 864px;
  margin: 32px 64px 0;
}

@media (max-width: 780px) {
  .content-discovery-survey__container {
    margin: 0 16px;
  }
}

.content-discovery-survey__options {
  grid-gap: 16px;
  grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
  display: grid;
}
