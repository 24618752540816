.supplement-editor__wrapper {
  margin-top: -60px;
}

.supplement-editor__wrapper--in-modal {
  margin-top: 0;
}

.supplement-editor {
  padding: var(--space-x4);
  background: #fff;
  border-radius: 4px;
  flex-direction: column;
  max-width: 1088px;
  margin: 0 auto;
  display: flex;
}

.supplement-editor__header-section {
  box-shadow: var(--elevation-shadow-l1);
  z-index: 1;
  position: sticky;
  top: 120px;
}

.supplement-editor__header-section--in-modal {
  top: 0;
}

.supplement-editor__header {
  background-color: var(--background-primary);
  border-bottom: 1px solid var(--border-primary);
  padding: var(--space-x2) var(--space-x4);
  justify-content: space-between;
  align-items: center;
  gap: var(--space-x2);
  display: flex;
}

.supplement-editor__input {
  flex-direction: column;
  margin: 0 0 20px;
  display: flex;
}

.supplement-editor__input--error .simple-dropdown__input-wrapper .simple-dropdown__input:not(.simple-dropdown__input--bare) {
  border-color: #dc4538;
}

.supplement-editor__btn-wrapper {
  justify-content: flex-end;
  display: flex;
}

.supplement-editor__btn {
  align-self: flex-end;
}

.supplement-editor__code-input {
  width: 50%;
  margin-bottom: 2rem;
}

.supplement-editor__code-copy-btn {
  width: 50%;
  margin-left: 1rem;
}
