.a-toggle {
  --toggle-padding: 8px;
  --toggle-control-size: 20px;
  --toggle-control-color: var(--content-tertiary);
  --toggle-track-color: var(--background-subtle);
  --toggle-border-color: var(--border-primary);
  display: inline-block;
}

.a-toggle__input {
  cursor: pointer;
  opacity: 0;
  z-index: 2;
  width: 100%;
  height: 100%;
  margin: 0;
  position: absolute;
  top: 0;
  left: 0;
}

.a-toggle__track {
  background-color: var(--toggle-track-color);
  border: 1px solid var(--toggle-border-color);
  min-height: calc(var(--toggle-control-size)  + var(--toggle-padding) * 2);
  min-width: calc(var(--toggle-control-size) * 2 + var(--toggle-padding) * 3);
  border-radius: 1000px;
  display: flex;
}

.a-toggle__control {
  background-color: var(--toggle-control-color);
  left: var(--toggle-padding);
  min-height: var(--toggle-control-size);
  min-width: var(--toggle-control-size);
  top: calc(var(--toggle-padding)  - 1px);
  border-radius: 1000px;
  transition: all .1s ease-in-out;
  position: absolute;
}

.a-toggle__input:not(:checked):hover ~ .a-toggle__track {
  --toggle-track-color: var(--background-subtle-hover);
}

.a-toggle__input:not(:checked):active ~ .a-toggle__track {
  --toggle-track-color: var(--background-subtle-pressed);
}

.a-toggle__input:not(:checked):active ~ .a-toggle__track .a-toggle__control {
  transform: scale(.85);
}

.a-toggle__input:checked ~ .a-toggle__track {
  --toggle-track-color: var(--background-brand);
  --toggle-control-color: var(--content-primary-inverse-static);
  --toggle-border-color: var(--border-brand);
}

.a-toggle__input:checked ~ .a-toggle__track .a-toggle__control {
  left: calc(100% - var(--toggle-control-size)  - var(--toggle-padding));
}

.a-toggle__input:checked:hover ~ .a-toggle__track {
  --toggle-track-color: var(--background-brand-hover);
}

.a-toggle__input:checked:active ~ .a-toggle__track {
  --toggle-track-color: var(--background-brand-pressed);
}

.a-toggle__input:checked:active ~ .a-toggle__track .a-toggle__control {
  transform: scale(.85);
}

.a-toggle__input:disabled {
  cursor: not-allowed;
}

.a-toggle__input:disabled ~ .a-toggle__track {
  opacity: .65;
}

.a-toggle__input:focus ~ .a-toggle__track .a-toggle__control {
  outline: var(--border-selected);
}

.a-toggle__input:focus:not(:focus-visible) ~ .a-toggle__track .a-toggle__control {
  outline: none;
}

.a-toggle--size-small {
  --toggle-control-size: 16px;
  --toggle-padding: 6px;
}

.a-toggle--size-extra-small {
  --toggle-control-size: 14px;
  --toggle-padding: 4px;
}
