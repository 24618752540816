.update-account-wrapper {
  justify-content: center;
  font-size: .875rem;
  display: flex;
  position: relative;
}

.update-account-wrapper h1 {
  font-size: 1.125rem;
}

.update-account-wrapper h2 {
  margin: 0;
  font-size: 1.125rem;
}

.update-account-wrapper h3 {
  margin: 0;
  font-size: .9375rem;
}

.update-account-wrapper h4 {
  margin: 0;
  font-size: .875rem;
}

.update-account-content {
  color: #374151;
  flex-flow: column;
  flex: auto;
  width: 100%;
  max-width: 1088px;
  padding: 1em;
  display: flex;
  position: relative;
}

.update-account-meta {
  flex-flow: column;
  align-items: center;
  display: flex;
}

.update-account-meta-title {
  text-align: center;
  justify-content: center;
  display: flex;
}

.update-account-meta-description {
  text-align: center;
  justify-content: center;
  font-size: 1rem;
  display: flex;
}

.update-account-meta-img {
  height: 7em;
  display: flex;
}

.update-account-form-wrapper .search-schools-by-name__text-input {
  -webkit-appearance: none;
  -moz-appearance: none;
  background-color: #fff;
  border: 2px solid #e5e7eb;
  border-radius: 3px;
  height: 2.5rem;
  padding: 0 .5em;
  font-family: Lato, Helvetica, Arial, sans-serif;
  font-size: 1em;
  transition: border 50ms ease-in-out;
}

.update-account-form-wrapper .search-schools-by-name__text-input:focus, .update-account-form-wrapper .search-schools-by-name__text-input:hover {
  transition: border 50ms ease-in-out;
}

.update-account-form-wrapper .search-schools-by-name__text-input:focus {
  border-color: #a8d4f0;
  outline-width: 0;
}

.update-account-form-wrapper .search-schools-by-name__text-input:hover {
  border-color: #a8d4f0;
}

.update-account-form-wrapper .search-schools-by-name__text-input:disabled {
  cursor: not-allowed;
  opacity: .5;
}

.update-account-form-wrapper .search-schools-by-name__text-input:disabled:hover {
  border-color: #e5e7eb;
}

.update-account-form-wrapper .search-schools-by-name__text-input:disabled {
  opacity: .5;
}

.update-account-form-change-account-type {
  flex: auto;
  justify-content: center;
  align-items: center;
  margin: 0 0 .75em;
  font-size: 1rem;
  display: flex;
}

.update-account-form-link--change-account-type, .update-account-form-link--no-school {
  margin: 0 0 0 .25em;
}

@media (max-width: 600px) {
  .update-account-form-link--no-school {
    margin: .25em 0 0;
  }
}
