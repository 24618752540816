:root {
  --iconInput-icon-offset: 14px;
  --iconInput-padding-offset: 40px;
  --iconInput-icon-size: 14px;
}

.m-icon-input__wrapper {
  display: inline-flex;
  position: relative;
}

.m-icon-input__wrapper--fill {
  width: 100%;
}

.m-icon-input__icon {
  font-size: var(--iconInput-icon-size);
  pointer-events: none;
  align-self: center;
  position: absolute;
}

.m-icon-input__icon--left {
  left: var(--iconInput-icon-offset);
}

.m-icon-input__icon--right {
  right: var(--iconInput-icon-offset);
}

.m-icon-input__icon--clickable {
  pointer-events: auto;
}

.m-icon-input.m-icon-input--with-icon-left {
  padding-left: var(--iconInput-padding-offset);
}

.m-icon-input.m-icon-input--with-icon-right {
  padding-right: var(--iconInput-padding-offset);
}

.m-icon-input.m-icon-input--with-icon-right.m-icon-input--with-icon-left {
  padding-left: var(--iconInput-padding-offset);
  padding-right: var(--iconInput-padding-offset);
}

.m-icon-input.a-input--s {
  --iconInput-padding-offset: 28px;
}

.m-icon-input.a-input--s ~ .m-icon-input__icon {
  --iconInput-icon-offset: 10px;
  --iconInput-icon-size: 12px;
}
