.graphing-input__board-overflow-container {
  text-align: center;
  z-index: 0;
  width: 100%;
  overflow: auto;
}

.graphing-input__board-wrapper {
  text-align: initial;
  display: inline-block;
}

.graphing-input__scroll-helper {
  z-index: 1;
  background-color: rgba(0, 0, 0, 0);
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.graphing-input__board {
  border: 2px solid rgba(0, 0, 0, 0);
  outline: 0;
  margin: 10px;
}

.graphing-input__board:focus {
  border: 2px solid #00f;
}

.graphing-input__board-row {
  display: flex;
}

.graphing-input__board-label {
  text-align: center;
}

.graphing-input__board-label--vertical {
  writing-mode: vertical-rl;
}

.graphing-input__board-label--vertical:first-child {
  transform: rotate(180deg);
}

.graphing-input-a11y-hidden {
  clip-path: inset(50%);
  clip: rect(0 0 0 0);
  white-space: nowrap;
  width: 1px;
  height: 1px;
  position: absolute;
  overflow: hidden;
}

.graphing-input-described-by {
  display: none;
}
