.m-dropdown__target {
  display: inline-block;
}

.m-dropdown__target * {
  white-space: nowrap;
}

.m-dropdown__target--fill {
  width: 100%;
}

.m-dropdown__content-wrapper {
  opacity: 0;
  pointer-events: none;
  transition: opacity var(--duration-fast);
  z-index: 1;
}

.m-dropdown__content-wrapper--show {
  opacity: 1;
  pointer-events: auto;
}

.m-dropdown__content {
  border: 1px solid var(--border-primary);
  box-shadow: var(--elevation-shadow-l3);
  max-width: 448px;
  padding: var(--space-x1) var(--space-x0);
  border-radius: 8px;
  width: 100%;
  overflow: auto;
}

.m-dropdown__content--border-bottom {
  border-bottom: 1px solid var(--border-primary);
}

.m-dropdown__content--min {
  min-width: 160px;
}

.m-dropdown__content--max {
  max-height: 448px;
  overflow: auto;
  transform: translate3d(0, 0, 0);
}

.m-dropdown__content-wrapper[data-placement^="top"] {
  margin-bottom: var(--space-x1);
}

.m-dropdown__content-wrapper[data-placement^="bottom"] {
  margin-top: var(--space-x1);
}

.m-dropdown__content-wrapper[data-placement^="right"] {
  margin-left: var(--space-x1);
}

.m-dropdown__content-wrapper[data-placement^="left"] {
  margin-right: var(--space-x1);
}

@media (max-width: 780px) {
  .m-dropdown__content-wrapper {
    transition: opacity var(--duration-fast), bottom var(--duration-medium);
    width: 100%;
    bottom: -25%;
  }

  .m-dropdown__content-wrapper--show {
    bottom: 0;
  }

  .m-dropdown__content-wrapper--show:before {
    background: var(--overlay-35);
    content: "";
    z-index: 9;
    width: 100vw;
    height: 100vh;
    position: absolute;
    bottom: 0;
    left: 0;
  }

  .m-dropdown__content {
    z-index: 9999;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
    max-width: none;
    max-height: 70vh;
    overflow: auto;
  }

  .m-dropdown__content-wrapper[data-placement^="top"] {
    margin-bottom: 0;
  }

  .m-dropdown__mobile-close {
    box-shadow: var(--elevation-shadow-l3);
    float: right;
    z-index: 9999;
    margin: 0 16px 16px auto;
  }
}
