.app-portal, .app-portal--z-tooltip, .app-portal--z-popover {
  z-index: 9999;
}

.app-portal--z-toast {
  z-index: 10000;
}

.app-portal--z-tooltip-in-navbar {
  z-index: 107;
}

.app-portal--z-modal {
  z-index: 9998;
}
