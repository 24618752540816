.sg-icon-tooltip {
  margin: 0 .25em;
  display: inline-block;
}

.sg-icon-tooltip__icon {
  color: #d1d5db;
  cursor: pointer;
  transition: color .1s ease-in-out;
}

.sg-icon-tooltip__icon:hover {
  color: inherit;
}
