.sortable-element__moved {
  animation: .5s ease-out fade-in;
}

.sortable-element__hover-area {
  cursor: pointer;
  z-index: 2;
  background: linear-gradient(#50a9e2, #50a9e2) center / 4px 4px repeat-x;
  border: none;
  width: 100%;
  position: absolute;
  left: 0;
}

.sortable-element__hover-area:hover, .sortable-element__hover-area:focus {
  opacity: 1;
  outline: none;
  bottom: 0;
}

.sortable-element__hover-area:hover:before, .sortable-element__hover-area:focus:before {
  opacity: 1;
  padding: 6px 10px;
  transition: all .1s ease-in-out;
}

.sortable-element__hover-area:before {
  color: #fff;
  content: "Click to place";
  background-color: #50a9e2;
  border: 2px solid #50a9e2;
  border-radius: 16px;
  padding: 4px 8px;
  font-weight: 600;
  transition: all .1s ease-in-out;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.sortable-element__hover-area__above {
  top: 0;
  transform: translateY(-50%);
}

.sortable-element__hover-area__below {
  bottom: 0;
  transform: translateY(50%);
}
