.subject-view-wrapper {
  color: #374151;
  font-size: 14px;
  position: relative;
  top: -60px;
}

.subject-view-wrapper--study-guide {
  flex-direction: column;
  display: flex;
}

.subject-view-content {
  width: 100%;
}
