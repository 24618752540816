.a-grid__row {
  box-sizing: border-box;
  flex-wrap: wrap;
  margin-top: 0;
  margin-bottom: 0;
  display: flex;
}

.a-grid__row--row-gap-1:last-child {
  margin-bottom: -8px;
}

.a-grid__row--row-gap-2:last-child {
  margin-bottom: -16px;
}

.a-grid__row--row-gap-3:last-child {
  margin-bottom: -24px;
}

.a-grid__row--row-gap-4:last-child {
  margin-bottom: -32px;
}

.a-grid__row--row-gap-5:last-child {
  margin-bottom: -40px;
}

.a-grid__row--row-gap-6:last-child {
  margin-bottom: -48px;
}

.a-grid__row--row-gap-7:last-child {
  margin-bottom: -56px;
}

.a-grid__row--row-gap-8:last-child {
  margin-bottom: -64px;
}

.a-grid__row--row-gap-9:last-child {
  margin-bottom: -72px;
}

.a-grid__row--row-gap-10:last-child {
  margin-bottom: -80px;
}

.a-grid__row--col-gap-1 {
  width: calc(100% + 8px);
  margin-left: -4px;
  margin-right: -4px;
}

.a-grid__row--col-gap-2 {
  width: calc(100% + 16px);
  margin-left: -8px;
  margin-right: -8px;
}

.a-grid__row--col-gap-3 {
  width: calc(100% + 24px);
  margin-left: -12px;
  margin-right: -12px;
}

.a-grid__row--col-gap-4 {
  width: calc(100% + 32px);
  margin-left: -16px;
  margin-right: -16px;
}

.a-grid__row--col-gap-5 {
  width: calc(100% + 40px);
  margin-left: -20px;
  margin-right: -20px;
}

.a-grid__row--col-gap-6 {
  width: calc(100% + 48px);
  margin-left: -24px;
  margin-right: -24px;
}

.a-grid__row--col-gap-7 {
  width: calc(100% + 56px);
  margin-left: -28px;
  margin-right: -28px;
}

.a-grid__row--col-gap-8 {
  width: calc(100% + 64px);
  margin-left: -32px;
  margin-right: -32px;
}

.a-grid__row--col-gap-9 {
  width: calc(100% + 72px);
  margin-left: -36px;
  margin-right: -36px;
}

.a-grid__row--col-gap-10 {
  width: calc(100% + 80px);
  margin-left: -40px;
  margin-right: -40px;
}

@media screen and (max-width: 1400px) {
  .a-grid__row--col-gap-xl-1 {
    width: calc(100% + 8px);
    margin-left: -4px;
    margin-right: -4px;
  }

  .a-grid__row--col-gap-xl-2 {
    width: calc(100% + 16px);
    margin-left: -8px;
    margin-right: -8px;
  }

  .a-grid__row--col-gap-xl-3 {
    width: calc(100% + 24px);
    margin-left: -12px;
    margin-right: -12px;
  }

  .a-grid__row--col-gap-xl-4 {
    width: calc(100% + 32px);
    margin-left: -16px;
    margin-right: -16px;
  }

  .a-grid__row--col-gap-xl-5 {
    width: calc(100% + 40px);
    margin-left: -20px;
    margin-right: -20px;
  }

  .a-grid__row--col-gap-xl-6 {
    width: calc(100% + 48px);
    margin-left: -24px;
    margin-right: -24px;
  }

  .a-grid__row--col-gap-xl-7 {
    width: calc(100% + 56px);
    margin-left: -28px;
    margin-right: -28px;
  }

  .a-grid__row--col-gap-xl-8 {
    width: calc(100% + 64px);
    margin-left: -32px;
    margin-right: -32px;
  }

  .a-grid__row--col-gap-xl-9 {
    width: calc(100% + 72px);
    margin-left: -36px;
    margin-right: -36px;
  }

  .a-grid__row--col-gap-xl-10 {
    width: calc(100% + 80px);
    margin-left: -40px;
    margin-right: -40px;
  }
}

@media screen and (max-width: 1280px) {
  .a-grid__row--col-gap-l-1 {
    width: calc(100% + 8px);
    margin-left: -4px;
    margin-right: -4px;
  }

  .a-grid__row--col-gap-l-2 {
    width: calc(100% + 16px);
    margin-left: -8px;
    margin-right: -8px;
  }

  .a-grid__row--col-gap-l-3 {
    width: calc(100% + 24px);
    margin-left: -12px;
    margin-right: -12px;
  }

  .a-grid__row--col-gap-l-4 {
    width: calc(100% + 32px);
    margin-left: -16px;
    margin-right: -16px;
  }

  .a-grid__row--col-gap-l-5 {
    width: calc(100% + 40px);
    margin-left: -20px;
    margin-right: -20px;
  }

  .a-grid__row--col-gap-l-6 {
    width: calc(100% + 48px);
    margin-left: -24px;
    margin-right: -24px;
  }

  .a-grid__row--col-gap-l-7 {
    width: calc(100% + 56px);
    margin-left: -28px;
    margin-right: -28px;
  }

  .a-grid__row--col-gap-l-8 {
    width: calc(100% + 64px);
    margin-left: -32px;
    margin-right: -32px;
  }

  .a-grid__row--col-gap-l-9 {
    width: calc(100% + 72px);
    margin-left: -36px;
    margin-right: -36px;
  }

  .a-grid__row--col-gap-l-10 {
    width: calc(100% + 80px);
    margin-left: -40px;
    margin-right: -40px;
  }
}

@media screen and (max-width: 1020px) {
  .a-grid__row--col-gap-m-1 {
    width: calc(100% + 8px);
    margin-left: -4px;
    margin-right: -4px;
  }

  .a-grid__row--col-gap-m-2 {
    width: calc(100% + 16px);
    margin-left: -8px;
    margin-right: -8px;
  }

  .a-grid__row--col-gap-m-3 {
    width: calc(100% + 24px);
    margin-left: -12px;
    margin-right: -12px;
  }

  .a-grid__row--col-gap-m-4 {
    width: calc(100% + 32px);
    margin-left: -16px;
    margin-right: -16px;
  }

  .a-grid__row--col-gap-m-5 {
    width: calc(100% + 40px);
    margin-left: -20px;
    margin-right: -20px;
  }

  .a-grid__row--col-gap-m-6 {
    width: calc(100% + 48px);
    margin-left: -24px;
    margin-right: -24px;
  }

  .a-grid__row--col-gap-m-7 {
    width: calc(100% + 56px);
    margin-left: -28px;
    margin-right: -28px;
  }

  .a-grid__row--col-gap-m-8 {
    width: calc(100% + 64px);
    margin-left: -32px;
    margin-right: -32px;
  }

  .a-grid__row--col-gap-m-9 {
    width: calc(100% + 72px);
    margin-left: -36px;
    margin-right: -36px;
  }

  .a-grid__row--col-gap-m-10 {
    width: calc(100% + 80px);
    margin-left: -40px;
    margin-right: -40px;
  }
}

@media screen and (max-width: 780px) {
  .a-grid__row--col-gap-s-1 {
    width: calc(100% + 8px);
    margin-left: -4px;
    margin-right: -4px;
  }

  .a-grid__row--col-gap-s-2 {
    width: calc(100% + 16px);
    margin-left: -8px;
    margin-right: -8px;
  }

  .a-grid__row--col-gap-s-3 {
    width: calc(100% + 24px);
    margin-left: -12px;
    margin-right: -12px;
  }

  .a-grid__row--col-gap-s-4 {
    width: calc(100% + 32px);
    margin-left: -16px;
    margin-right: -16px;
  }

  .a-grid__row--col-gap-s-5 {
    width: calc(100% + 40px);
    margin-left: -20px;
    margin-right: -20px;
  }

  .a-grid__row--col-gap-s-6 {
    width: calc(100% + 48px);
    margin-left: -24px;
    margin-right: -24px;
  }

  .a-grid__row--col-gap-s-7 {
    width: calc(100% + 56px);
    margin-left: -28px;
    margin-right: -28px;
  }

  .a-grid__row--col-gap-s-8 {
    width: calc(100% + 64px);
    margin-left: -32px;
    margin-right: -32px;
  }

  .a-grid__row--col-gap-s-9 {
    width: calc(100% + 72px);
    margin-left: -36px;
    margin-right: -36px;
  }

  .a-grid__row--col-gap-s-10 {
    width: calc(100% + 80px);
    margin-left: -40px;
    margin-right: -40px;
  }
}

@media screen and (max-width: 440px) {
  .a-grid__row--col-gap-xs-1 {
    width: calc(100% + 8px);
    margin-left: -4px;
    margin-right: -4px;
  }

  .a-grid__row--col-gap-xs-2 {
    width: calc(100% + 16px);
    margin-left: -8px;
    margin-right: -8px;
  }

  .a-grid__row--col-gap-xs-3 {
    width: calc(100% + 24px);
    margin-left: -12px;
    margin-right: -12px;
  }

  .a-grid__row--col-gap-xs-4 {
    width: calc(100% + 32px);
    margin-left: -16px;
    margin-right: -16px;
  }

  .a-grid__row--col-gap-xs-5 {
    width: calc(100% + 40px);
    margin-left: -20px;
    margin-right: -20px;
  }

  .a-grid__row--col-gap-xs-6 {
    width: calc(100% + 48px);
    margin-left: -24px;
    margin-right: -24px;
  }

  .a-grid__row--col-gap-xs-7 {
    width: calc(100% + 56px);
    margin-left: -28px;
    margin-right: -28px;
  }

  .a-grid__row--col-gap-xs-8 {
    width: calc(100% + 64px);
    margin-left: -32px;
    margin-right: -32px;
  }

  .a-grid__row--col-gap-xs-9 {
    width: calc(100% + 72px);
    margin-left: -36px;
    margin-right: -36px;
  }

  .a-grid__row--col-gap-xs-10 {
    width: calc(100% + 80px);
    margin-left: -40px;
    margin-right: -40px;
  }
}

@media screen and (max-width: 380px) {
  .a-grid__row--col-gap-xxs-1 {
    width: calc(100% + 8px);
    margin-left: -4px;
    margin-right: -4px;
  }

  .a-grid__row--col-gap-xxs-2 {
    width: calc(100% + 16px);
    margin-left: -8px;
    margin-right: -8px;
  }

  .a-grid__row--col-gap-xxs-3 {
    width: calc(100% + 24px);
    margin-left: -12px;
    margin-right: -12px;
  }

  .a-grid__row--col-gap-xxs-4 {
    width: calc(100% + 32px);
    margin-left: -16px;
    margin-right: -16px;
  }

  .a-grid__row--col-gap-xxs-5 {
    width: calc(100% + 40px);
    margin-left: -20px;
    margin-right: -20px;
  }

  .a-grid__row--col-gap-xxs-6 {
    width: calc(100% + 48px);
    margin-left: -24px;
    margin-right: -24px;
  }

  .a-grid__row--col-gap-xxs-7 {
    width: calc(100% + 56px);
    margin-left: -28px;
    margin-right: -28px;
  }

  .a-grid__row--col-gap-xxs-8 {
    width: calc(100% + 64px);
    margin-left: -32px;
    margin-right: -32px;
  }

  .a-grid__row--col-gap-xxs-9 {
    width: calc(100% + 72px);
    margin-left: -36px;
    margin-right: -36px;
  }

  .a-grid__row--col-gap-xxs-10 {
    width: calc(100% + 80px);
    margin-left: -40px;
    margin-right: -40px;
  }
}

.a-grid__row--debug {
  background-color: rgba(204, 50, 36, .4);
}
