:export {
  titleBarHeight: 80px;
  mobileToolbarHeight: 53px;
}

.practice-view-toolbar {
  background-color: #fff;
  border-bottom: 1px solid #e5e7eb;
  width: 100%;
  padding: 4px 0;
}

.practice-view-toolbar__content {
  -webkit-overflow-scrolling: touch;
  align-items: center;
  padding: 0 16px;
  display: flex;
}

.practice-view-toolbar__content--align-end {
  justify-content: flex-end;
}

.practice-view-toolbar__divider {
  background-color: #e5e7eb;
  width: 1px;
  height: 16px;
  margin: 4px;
  display: inline-block;
}
