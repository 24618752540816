.passage-correction-word, .passage-correction-whitespace {
  cursor: default;
  display: inline-block;
}

.passage-correction-word {
  background-color: inherit;
  color: inherit;
  font-size: inherit;
  border: 0;
  border-radius: 5px;
  padding: 0 .15em;
}

.passage-correction-word--selectable:hover {
  cursor: pointer;
  background-color: #fcd34d;
}

.passage-correction-word--corrected:after {
  content: "";
  background-color: #1e7cb8;
  width: 100%;
  height: 2px;
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
}

.passage-correction-word--active {
  background-color: #fcd34d;
  border-radius: 0;
}

.passage-correction-word--correct {
  background-color: rgba(0, 208, 0, .15);
  border-radius: 0;
}

.passage-correction-word--incorrect {
  background-color: rgba(255, 0, 32, .15);
  border-radius: 0;
}

.passage-correction-word--range-start {
  border-top-left-radius: .25em;
  border-bottom-left-radius: .25em;
}

.passage-correction-word--range-end {
  border-top-right-radius: .25em;
  border-bottom-right-radius: .25em;
}
