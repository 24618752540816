.abandon-modal {
  max-width: 34.15em;
}

.abandon-modal__warning {
  color: #f0bf6c;
  justify-content: center;
  padding: 1.5em;
  display: flex;
}

.abandon-modal__warning:before {
  font-size: 64px;
}

.abandon-modal__wrapper {
  flex-direction: column;
  display: flex;
}

.abandon-modal__message-wrapper, .abandon-modal__action-wrapper {
  max-width: 18em;
  margin: 0 auto 1.5em;
}

.abandon-modal__action-wrapper {
  flex-direction: column;
  justify-content: center;
  display: flex;
}

.abandon-modal__message-wrapper {
  text-align: center;
}

.abandon-modal__action {
  width: 100%;
}

.abandon-modal__action--show-questions {
  -webkit-appearance: none;
  -moz-appearance: none;
  color: #fff;
  cursor: pointer;
  background-color: #059669;
  border: 0;
  border-radius: .15em;
  margin: 0 auto .5em;
  padding: .85em 1em .8em;
  font-size: 1em;
  font-weight: bold;
  text-decoration: none;
  transition: box-shadow .1s ease-in-out, transform .1s ease-in-out;
}

.abandon-modal__action--show-questions::selection {
  background-color: rgba(0, 0, 0, 0);
}

.abandon-modal__action--show-questions ::selection {
  background-color: rgba(0, 0, 0, 0);
}

.abandon-modal__action--show-questions.disabled, .abandon-modal__action--show-questions.disabled:hover, .abandon-modal__action--show-questions:disabled, .abandon-modal__action--show-questions:disabled:hover {
  color: #d1d5db;
  background-color: #6b7280;
}

.abandon-modal__action--show-questions:not(:disabled):not(:active):hover {
  transform: translateY(-1px)scale(1.01);
  box-shadow: 0 2px 9px rgba(0, 0, 0, .2);
}

.abandon-modal__action--submit {
  -webkit-appearance: none;
  -moz-appearance: none;
  color: #fff;
  cursor: pointer;
  background-color: #50a9e2;
  border: 0;
  border-radius: .15em;
  padding: .85em 1em .8em;
  font-size: 1em;
  font-weight: bold;
  text-decoration: none;
  transition: box-shadow .1s ease-in-out, transform .1s ease-in-out;
}

.abandon-modal__action--submit::selection {
  background-color: rgba(0, 0, 0, 0);
}

.abandon-modal__action--submit ::selection {
  background-color: rgba(0, 0, 0, 0);
}

.abandon-modal__action--submit.disabled, .abandon-modal__action--submit.disabled:hover, .abandon-modal__action--submit:disabled, .abandon-modal__action--submit:disabled:hover {
  color: #d1d5db;
  background-color: #6b7280;
}

.abandon-modal__action--submit:not(:disabled):not(:active):hover {
  transform: translateY(-1px)scale(1.01);
  box-shadow: 0 2px 9px rgba(0, 0, 0, .2);
}

.abandon-modal__link {
  color: #50a9e2;
  cursor: pointer;
  text-align: center;
  text-decoration: none;
}

.abandon-modal__link:hover, .abandon-modal__link:focus {
  color: #1a699e;
}
