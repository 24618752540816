.drilldown-selector {
  box-shadow: var(--elevation-shadow-l1);
  padding-left: var(--reports-table-padding-inline);
  padding-right: var(--reports-table-padding-inline);
}

.drilldown-selector__tab-content {
  z-index: 20;
  align-items: center;
  gap: 8px;
  display: flex;
}
