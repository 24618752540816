.assignment-stage {
  flex-flow: column;
  width: 100%;
  max-width: 900px;
  margin: 20px 30px;
  padding: 1.25em 5em;
  display: flex;
}

@media (max-width: 900px) {
  .assignment-stage {
    background-color: #f3f4f6;
    margin: 0;
    padding: 1.25em .5em;
  }
}

.assignment-stage__main-content {
  display: flex;
}

@media (max-width: 900px) {
  .assignment-stage__main-content {
    flex-direction: column;
  }
}

.assignment-stage__footer-content {
  justify-content: flex-end;
  margin-top: 1em;
  display: flex;
}

.assignment-stage__image-wrapper {
  background: #3a9ede url("./assets/library-blue.svg") center no-repeat;
  min-width: 200px;
  margin-right: 1em;
}

@media (max-width: 900px) {
  .assignment-stage__image-wrapper {
    width: 100%;
    height: 200px;
    margin-bottom: .5em;
  }
}

.assignment-stage__image {
  border-radius: .25em;
  height: 100%;
}

.assignment-stage-card {
  width: 100%;
}

.assignment-stage-card__header {
  justify-content: space-between;
  align-items: center;
  margin-bottom: .5em;
  display: flex;
}

.assignment-stage-card__header h1 {
  margin-bottom: 0;
  margin-right: 1em;
}

.assignment-stage-card__subheader {
  color: #9ca3af;
  margin-bottom: 2.25em;
  font-size: .9rem;
  display: block;
}

.assignment-stage-card__submission-status {
  color: #fff;
  white-space: nowrap;
  border-radius: 2em;
  align-self: flex-start;
  height: 2.25em;
  padding: .5em 1em;
  font-size: .8rem;
  font-weight: bold;
}

.assignment-stage-card__submission-status .fa {
  margin-right: .5em;
}

.assignment-stage-card__submission-status--red {
  background: #dc4538;
}

.assignment-stage-card__submission-status--orange {
  background: #f0bf6c;
}

.assignment-stage-card__submission-status--green {
  background: #059669;
}

.assignment-stage__summary-wrapper hr {
  border: 0;
  border-top: 2px solid #f3f4f6;
  margin: 2em -1.25em;
  display: block;
}

.assignment-stage__details, .assignment-stage__summary {
  justify-content: space-between;
  display: flex;
}

@media (max-width: 900px) {
  .assignment-stage__details, .assignment-stage__summary {
    flex-direction: column;
  }
}

.assignment-stage__detail-card, .assignment-stage__summary-card {
  width: 33.3333%;
  margin-right: 1em;
  box-shadow: 0 1px 2px rgba(0, 0, 0, .15);
}

.assignment-stage__detail-card h4, .assignment-stage__summary-card h4 {
  color: #9ca3af;
  text-transform: uppercase;
  font-size: .9em;
}

.assignment-stage__detail-card:last-child, .assignment-stage__summary-card:last-child {
  margin-right: 0;
}

@media (max-width: 900px) {
  .assignment-stage__detail-card, .assignment-stage__summary-card {
    width: 100%;
    margin-bottom: .5em;
  }
}

.assignment-stage__detail-card--full-width {
  width: 100%;
}

.assignment-stage__summary-card {
  width: 50%;
}

@media (max-width: 900px) {
  .assignment-stage__summary-card {
    width: 100%;
  }
}

.assignment-stage__detail-content, .assignment-stage__summary-content {
  white-space: nowrap;
  margin: .75em 0 .25em;
  font-size: 1.6em;
  font-weight: bold;
  display: block;
}

.assignment-stage__detail-footer, .assignment-stage__summary-footer {
  color: #9ca3af;
  white-space: nowrap;
  font-size: .9em;
  font-weight: bold;
}

.assignment-stage__time-tooltip {
  white-space: normal;
  font-weight: normal;
}

.assignment-stage__explanation-setting {
  color: #6b7280;
  text-align: center;
  margin: 2em 1em;
  font-weight: bold;
}

.assignment-stage__message {
  background: #f9fafb;
  line-height: 1.67;
  box-shadow: 0 1px 2px rgba(0, 0, 0, .15);
}
