.image-supplement__resizable {
  border: 1px solid var(--border-subtle);
  border-radius: .5rem;
  margin-bottom: 1.5rem;
}

.image-supplement__figure {
  background: var(--background-subtle);
  border-radius: .5rem;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  margin: 0;
  display: flex;
  position: relative;
}

@media (min-width: 780px) {
  .image-supplement__figure:hover .image-supplement__controls {
    display: flex;
  }
}

.image-supplement__image-container {
  border-bottom: 1px solid var(--border-primary);
  box-sizing: content-box;
  border-radius: 0;
  width: 100%;
  height: 100%;
  min-height: 152px;
  max-height: 560px;
  padding: 0;
  position: relative;
}

@media (min-width: 780px) {
  .image-supplement__image-container {
    cursor: default;
  }
}

.image-supplement__image-container:hover:not(:disabled) {
  background-color: inherit;
}

.image-supplement__image {
  object-fit: contain;
  width: 100%;
  height: 100%;
  min-height: 152px;
  max-height: 560px;
}

.image-supplement__controls {
  z-index: 9;
  gap: .5rem;
  display: flex;
  position: absolute;
  top: .5rem;
  right: .5rem;
}

@media (min-width: 780px) {
  .image-supplement__controls {
    display: none;
  }
}

.image-supplement__controls-button {
  cursor: pointer;
  box-sizing: border-box;
  border: 1px solid var(--border-primary);
  width: 32px;
  height: 32px;
  box-shadow: var(--elevation-shadow-l4);
  background-color: #fff;
  border-radius: 2.5rem;
}

.image-supplement__controls-button:hover:not(:disabled) {
  border: 2px solid var(--border-primary);
  box-shadow: var(--elevation-shadow-l4);
}

.image-supplement__controls--fullscreen {
  display: flex;
}

.image-supplement__controls-zoom {
  border-radius: 2.5rem;
  width: 44px;
  transition: width .2s ease-out;
  overflow: hidden;
}

@media (min-width: 780px) {
  .image-supplement__controls-zoom {
    width: 32px;
  }
}

.image-supplement__controls-zoom--expanded {
  border: 1px solid var(--border-primary);
  box-sizing: border-box;
  width: 172px;
}

@media (min-width: 780px) {
  .image-supplement__controls-zoom--expanded {
    width: 135px;
  }
}

.image-supplement__controls-zoom-options {
  background-color: #fff;
  align-items: center;
  display: flex;
}

.image-supplement__controls-zoom-options .image-supplement__controls-button, .image-supplement__controls-zoom-options .image-supplement__controls-button:hover:not(:disabled) {
  box-shadow: none;
  border: none;
}

.image-supplement__hidden {
  margin-bottom: 1.5rem;
}

.image-supplement__hidden-icon {
  border-radius: .5rem;
  padding: .5rem;
}

.image-supplement__hidden-button {
  background-color: var(--background-bold);
  color: var(--content-primary);
  border-radius: .5rem;
  width: max-content;
  padding: .5rem;
  display: flex;
}

.image-supplement__hidden-button:hover:not(:disabled) {
  background-color: var(--background-bold);
}

.image-supplement__hidden-button:hover:not(:disabled) .image-supplement__hidden-icon:nth-child(2) {
  background: var(--background-brand-subtle-hover);
}

.image-supplement__modal-backdrop {
  background-color: var(--background-subtle);
  padding: 0;
}

.image-supplement__modal-content {
  flex-direction: column;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  margin: 0;
  display: flex;
  position: relative;
  overflow: hidden;
}

.image-supplement__modal-caption {
  border-top: 1px solid var(--border-primary);
  color: var(--content-secondary);
  text-align: center;
  padding: 1rem;
}

.image-supplement__modal-image {
  object-fit: contain;
  width: 100%;
  height: 100%;
}

.image-supplement__resize-handle {
  flex-direction: column;
  align-items: center;
  display: flex;
  position: absolute;
  bottom: .25rem;
  right: .125rem;
  transform: rotate(-45deg);
}

.image-supplement__resize-handle div {
  border-bottom: 1px solid var(--border-bold);
  margin-bottom: 1px;
}

.image-supplement__resize-handle div:first-child {
  width: 9px;
}

.image-supplement__resize-handle div:nth-child(2) {
  width: 6px;
}

.image-supplement__resize-handle div:nth-child(3) {
  width: 3px;
  margin-bottom: 0;
}
