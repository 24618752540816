.copy-to-clipboard--inline .copy-to-clipboard__label, .copy-to-clipboard--inline .copy-to-clipboard__display {
  display: inline-block;
}

.copy-to-clipboard--flex {
  align-items: stretch;
  display: flex;
}

.copy-to-clipboard__label {
  margin-bottom: 5px;
  font-weight: bold;
  display: block;
}

.copy-to-clipboard__display {
  margin: 0 5px 0 0;
}

.copy-to-clipboard__button {
  padding: 10px 15px;
}
