.a-logo svg {
  width: 100%;
  height: 100%;
}

.a-logo svg path {
  fill: #1a699e;
}

.a-logo--light svg path {
  fill: #fff;
}
