.email-verification-notice {
  max-width: 426.667px;
  margin: auto;
}

.email-verification-notice .a-button {
  width: 100%;
}

.email-verification-notice__instructions {
  text-align: left;
}

.email-verification-notice__instructions li {
  line-height: 1.5;
}

.email-verification-modal {
  max-width: 344px;
}
