.a-grid__col--debug {
  background-color: rgba(204, 50, 36, .4);
}

.a-grid__col {
  box-sizing: border-box;
  width: 100%;
  display: block;
}

.a-grid__col--1--col-gap-1 {
  width: calc(8.33333% - 8.08333px);
  margin-left: 4px;
  margin-right: 4px;
}

.a-grid__col--2--col-gap-1 {
  width: calc(16.6667% - 8.16667px);
  margin-left: 4px;
  margin-right: 4px;
}

.a-grid__col--3--col-gap-1 {
  width: calc(25% - 8.25px);
  margin-left: 4px;
  margin-right: 4px;
}

.a-grid__col--4--col-gap-1 {
  width: calc(33.3333% - 8.33333px);
  margin-left: 4px;
  margin-right: 4px;
}

.a-grid__col--5--col-gap-1 {
  width: calc(41.6667% - 8.41667px);
  margin-left: 4px;
  margin-right: 4px;
}

.a-grid__col--6--col-gap-1 {
  width: calc(50% - 8.5px);
  margin-left: 4px;
  margin-right: 4px;
}

.a-grid__col--7--col-gap-1 {
  width: calc(58.3333% - 8.58333px);
  margin-left: 4px;
  margin-right: 4px;
}

.a-grid__col--8--col-gap-1 {
  width: calc(66.6667% - 8.66667px);
  margin-left: 4px;
  margin-right: 4px;
}

.a-grid__col--9--col-gap-1 {
  width: calc(75% - 8.75px);
  margin-left: 4px;
  margin-right: 4px;
}

.a-grid__col--10--col-gap-1 {
  width: calc(83.3333% - 8.83333px);
  margin-left: 4px;
  margin-right: 4px;
}

.a-grid__col--11--col-gap-1 {
  width: calc(91.6667% - 8.91667px);
  margin-left: 4px;
  margin-right: 4px;
}

.a-grid__col--12--col-gap-1 {
  width: calc(100% - 9px);
  margin-left: 4px;
  margin-right: 4px;
}

.a-grid__col--1--col-gap-2 {
  width: calc(8.33333% - 16.0833px);
  margin-left: 8px;
  margin-right: 8px;
}

.a-grid__col--2--col-gap-2 {
  width: calc(16.6667% - 16.1667px);
  margin-left: 8px;
  margin-right: 8px;
}

.a-grid__col--3--col-gap-2 {
  width: calc(25% - 16.25px);
  margin-left: 8px;
  margin-right: 8px;
}

.a-grid__col--4--col-gap-2 {
  width: calc(33.3333% - 16.3333px);
  margin-left: 8px;
  margin-right: 8px;
}

.a-grid__col--5--col-gap-2 {
  width: calc(41.6667% - 16.4167px);
  margin-left: 8px;
  margin-right: 8px;
}

.a-grid__col--6--col-gap-2 {
  width: calc(50% - 16.5px);
  margin-left: 8px;
  margin-right: 8px;
}

.a-grid__col--7--col-gap-2 {
  width: calc(58.3333% - 16.5833px);
  margin-left: 8px;
  margin-right: 8px;
}

.a-grid__col--8--col-gap-2 {
  width: calc(66.6667% - 16.6667px);
  margin-left: 8px;
  margin-right: 8px;
}

.a-grid__col--9--col-gap-2 {
  width: calc(75% - 16.75px);
  margin-left: 8px;
  margin-right: 8px;
}

.a-grid__col--10--col-gap-2 {
  width: calc(83.3333% - 16.8333px);
  margin-left: 8px;
  margin-right: 8px;
}

.a-grid__col--11--col-gap-2 {
  width: calc(91.6667% - 16.9167px);
  margin-left: 8px;
  margin-right: 8px;
}

.a-grid__col--12--col-gap-2 {
  width: calc(100% - 17px);
  margin-left: 8px;
  margin-right: 8px;
}

.a-grid__col--1--col-gap-3 {
  width: calc(8.33333% - 24.0833px);
  margin-left: 12px;
  margin-right: 12px;
}

.a-grid__col--2--col-gap-3 {
  width: calc(16.6667% - 24.1667px);
  margin-left: 12px;
  margin-right: 12px;
}

.a-grid__col--3--col-gap-3 {
  width: calc(25% - 24.25px);
  margin-left: 12px;
  margin-right: 12px;
}

.a-grid__col--4--col-gap-3 {
  width: calc(33.3333% - 24.3333px);
  margin-left: 12px;
  margin-right: 12px;
}

.a-grid__col--5--col-gap-3 {
  width: calc(41.6667% - 24.4167px);
  margin-left: 12px;
  margin-right: 12px;
}

.a-grid__col--6--col-gap-3 {
  width: calc(50% - 24.5px);
  margin-left: 12px;
  margin-right: 12px;
}

.a-grid__col--7--col-gap-3 {
  width: calc(58.3333% - 24.5833px);
  margin-left: 12px;
  margin-right: 12px;
}

.a-grid__col--8--col-gap-3 {
  width: calc(66.6667% - 24.6667px);
  margin-left: 12px;
  margin-right: 12px;
}

.a-grid__col--9--col-gap-3 {
  width: calc(75% - 24.75px);
  margin-left: 12px;
  margin-right: 12px;
}

.a-grid__col--10--col-gap-3 {
  width: calc(83.3333% - 24.8333px);
  margin-left: 12px;
  margin-right: 12px;
}

.a-grid__col--11--col-gap-3 {
  width: calc(91.6667% - 24.9167px);
  margin-left: 12px;
  margin-right: 12px;
}

.a-grid__col--12--col-gap-3 {
  width: calc(100% - 25px);
  margin-left: 12px;
  margin-right: 12px;
}

.a-grid__col--1--col-gap-4 {
  width: calc(8.33333% - 32.0833px);
  margin-left: 16px;
  margin-right: 16px;
}

.a-grid__col--2--col-gap-4 {
  width: calc(16.6667% - 32.1667px);
  margin-left: 16px;
  margin-right: 16px;
}

.a-grid__col--3--col-gap-4 {
  width: calc(25% - 32.25px);
  margin-left: 16px;
  margin-right: 16px;
}

.a-grid__col--4--col-gap-4 {
  width: calc(33.3333% - 32.3333px);
  margin-left: 16px;
  margin-right: 16px;
}

.a-grid__col--5--col-gap-4 {
  width: calc(41.6667% - 32.4167px);
  margin-left: 16px;
  margin-right: 16px;
}

.a-grid__col--6--col-gap-4 {
  width: calc(50% - 32.5px);
  margin-left: 16px;
  margin-right: 16px;
}

.a-grid__col--7--col-gap-4 {
  width: calc(58.3333% - 32.5833px);
  margin-left: 16px;
  margin-right: 16px;
}

.a-grid__col--8--col-gap-4 {
  width: calc(66.6667% - 32.6667px);
  margin-left: 16px;
  margin-right: 16px;
}

.a-grid__col--9--col-gap-4 {
  width: calc(75% - 32.75px);
  margin-left: 16px;
  margin-right: 16px;
}

.a-grid__col--10--col-gap-4 {
  width: calc(83.3333% - 32.8333px);
  margin-left: 16px;
  margin-right: 16px;
}

.a-grid__col--11--col-gap-4 {
  width: calc(91.6667% - 32.9167px);
  margin-left: 16px;
  margin-right: 16px;
}

.a-grid__col--12--col-gap-4 {
  width: calc(100% - 33px);
  margin-left: 16px;
  margin-right: 16px;
}

.a-grid__col--1--col-gap-5 {
  width: calc(8.33333% - 40.0833px);
  margin-left: 20px;
  margin-right: 20px;
}

.a-grid__col--2--col-gap-5 {
  width: calc(16.6667% - 40.1667px);
  margin-left: 20px;
  margin-right: 20px;
}

.a-grid__col--3--col-gap-5 {
  width: calc(25% - 40.25px);
  margin-left: 20px;
  margin-right: 20px;
}

.a-grid__col--4--col-gap-5 {
  width: calc(33.3333% - 40.3333px);
  margin-left: 20px;
  margin-right: 20px;
}

.a-grid__col--5--col-gap-5 {
  width: calc(41.6667% - 40.4167px);
  margin-left: 20px;
  margin-right: 20px;
}

.a-grid__col--6--col-gap-5 {
  width: calc(50% - 40.5px);
  margin-left: 20px;
  margin-right: 20px;
}

.a-grid__col--7--col-gap-5 {
  width: calc(58.3333% - 40.5833px);
  margin-left: 20px;
  margin-right: 20px;
}

.a-grid__col--8--col-gap-5 {
  width: calc(66.6667% - 40.6667px);
  margin-left: 20px;
  margin-right: 20px;
}

.a-grid__col--9--col-gap-5 {
  width: calc(75% - 40.75px);
  margin-left: 20px;
  margin-right: 20px;
}

.a-grid__col--10--col-gap-5 {
  width: calc(83.3333% - 40.8333px);
  margin-left: 20px;
  margin-right: 20px;
}

.a-grid__col--11--col-gap-5 {
  width: calc(91.6667% - 40.9167px);
  margin-left: 20px;
  margin-right: 20px;
}

.a-grid__col--12--col-gap-5 {
  width: calc(100% - 41px);
  margin-left: 20px;
  margin-right: 20px;
}

.a-grid__col--1--col-gap-6 {
  width: calc(8.33333% - 48.0833px);
  margin-left: 24px;
  margin-right: 24px;
}

.a-grid__col--2--col-gap-6 {
  width: calc(16.6667% - 48.1667px);
  margin-left: 24px;
  margin-right: 24px;
}

.a-grid__col--3--col-gap-6 {
  width: calc(25% - 48.25px);
  margin-left: 24px;
  margin-right: 24px;
}

.a-grid__col--4--col-gap-6 {
  width: calc(33.3333% - 48.3333px);
  margin-left: 24px;
  margin-right: 24px;
}

.a-grid__col--5--col-gap-6 {
  width: calc(41.6667% - 48.4167px);
  margin-left: 24px;
  margin-right: 24px;
}

.a-grid__col--6--col-gap-6 {
  width: calc(50% - 48.5px);
  margin-left: 24px;
  margin-right: 24px;
}

.a-grid__col--7--col-gap-6 {
  width: calc(58.3333% - 48.5833px);
  margin-left: 24px;
  margin-right: 24px;
}

.a-grid__col--8--col-gap-6 {
  width: calc(66.6667% - 48.6667px);
  margin-left: 24px;
  margin-right: 24px;
}

.a-grid__col--9--col-gap-6 {
  width: calc(75% - 48.75px);
  margin-left: 24px;
  margin-right: 24px;
}

.a-grid__col--10--col-gap-6 {
  width: calc(83.3333% - 48.8333px);
  margin-left: 24px;
  margin-right: 24px;
}

.a-grid__col--11--col-gap-6 {
  width: calc(91.6667% - 48.9167px);
  margin-left: 24px;
  margin-right: 24px;
}

.a-grid__col--12--col-gap-6 {
  width: calc(100% - 49px);
  margin-left: 24px;
  margin-right: 24px;
}

.a-grid__col--1--col-gap-7 {
  width: calc(8.33333% - 56.0833px);
  margin-left: 28px;
  margin-right: 28px;
}

.a-grid__col--2--col-gap-7 {
  width: calc(16.6667% - 56.1667px);
  margin-left: 28px;
  margin-right: 28px;
}

.a-grid__col--3--col-gap-7 {
  width: calc(25% - 56.25px);
  margin-left: 28px;
  margin-right: 28px;
}

.a-grid__col--4--col-gap-7 {
  width: calc(33.3333% - 56.3333px);
  margin-left: 28px;
  margin-right: 28px;
}

.a-grid__col--5--col-gap-7 {
  width: calc(41.6667% - 56.4167px);
  margin-left: 28px;
  margin-right: 28px;
}

.a-grid__col--6--col-gap-7 {
  width: calc(50% - 56.5px);
  margin-left: 28px;
  margin-right: 28px;
}

.a-grid__col--7--col-gap-7 {
  width: calc(58.3333% - 56.5833px);
  margin-left: 28px;
  margin-right: 28px;
}

.a-grid__col--8--col-gap-7 {
  width: calc(66.6667% - 56.6667px);
  margin-left: 28px;
  margin-right: 28px;
}

.a-grid__col--9--col-gap-7 {
  width: calc(75% - 56.75px);
  margin-left: 28px;
  margin-right: 28px;
}

.a-grid__col--10--col-gap-7 {
  width: calc(83.3333% - 56.8333px);
  margin-left: 28px;
  margin-right: 28px;
}

.a-grid__col--11--col-gap-7 {
  width: calc(91.6667% - 56.9167px);
  margin-left: 28px;
  margin-right: 28px;
}

.a-grid__col--12--col-gap-7 {
  width: calc(100% - 57px);
  margin-left: 28px;
  margin-right: 28px;
}

.a-grid__col--1--col-gap-8 {
  width: calc(8.33333% - 64.0833px);
  margin-left: 32px;
  margin-right: 32px;
}

.a-grid__col--2--col-gap-8 {
  width: calc(16.6667% - 64.1667px);
  margin-left: 32px;
  margin-right: 32px;
}

.a-grid__col--3--col-gap-8 {
  width: calc(25% - 64.25px);
  margin-left: 32px;
  margin-right: 32px;
}

.a-grid__col--4--col-gap-8 {
  width: calc(33.3333% - 64.3333px);
  margin-left: 32px;
  margin-right: 32px;
}

.a-grid__col--5--col-gap-8 {
  width: calc(41.6667% - 64.4167px);
  margin-left: 32px;
  margin-right: 32px;
}

.a-grid__col--6--col-gap-8 {
  width: calc(50% - 64.5px);
  margin-left: 32px;
  margin-right: 32px;
}

.a-grid__col--7--col-gap-8 {
  width: calc(58.3333% - 64.5833px);
  margin-left: 32px;
  margin-right: 32px;
}

.a-grid__col--8--col-gap-8 {
  width: calc(66.6667% - 64.6667px);
  margin-left: 32px;
  margin-right: 32px;
}

.a-grid__col--9--col-gap-8 {
  width: calc(75% - 64.75px);
  margin-left: 32px;
  margin-right: 32px;
}

.a-grid__col--10--col-gap-8 {
  width: calc(83.3333% - 64.8333px);
  margin-left: 32px;
  margin-right: 32px;
}

.a-grid__col--11--col-gap-8 {
  width: calc(91.6667% - 64.9167px);
  margin-left: 32px;
  margin-right: 32px;
}

.a-grid__col--12--col-gap-8 {
  width: calc(100% - 65px);
  margin-left: 32px;
  margin-right: 32px;
}

.a-grid__col--1--col-gap-9 {
  width: calc(8.33333% - 72.0833px);
  margin-left: 36px;
  margin-right: 36px;
}

.a-grid__col--2--col-gap-9 {
  width: calc(16.6667% - 72.1667px);
  margin-left: 36px;
  margin-right: 36px;
}

.a-grid__col--3--col-gap-9 {
  width: calc(25% - 72.25px);
  margin-left: 36px;
  margin-right: 36px;
}

.a-grid__col--4--col-gap-9 {
  width: calc(33.3333% - 72.3333px);
  margin-left: 36px;
  margin-right: 36px;
}

.a-grid__col--5--col-gap-9 {
  width: calc(41.6667% - 72.4167px);
  margin-left: 36px;
  margin-right: 36px;
}

.a-grid__col--6--col-gap-9 {
  width: calc(50% - 72.5px);
  margin-left: 36px;
  margin-right: 36px;
}

.a-grid__col--7--col-gap-9 {
  width: calc(58.3333% - 72.5833px);
  margin-left: 36px;
  margin-right: 36px;
}

.a-grid__col--8--col-gap-9 {
  width: calc(66.6667% - 72.6667px);
  margin-left: 36px;
  margin-right: 36px;
}

.a-grid__col--9--col-gap-9 {
  width: calc(75% - 72.75px);
  margin-left: 36px;
  margin-right: 36px;
}

.a-grid__col--10--col-gap-9 {
  width: calc(83.3333% - 72.8333px);
  margin-left: 36px;
  margin-right: 36px;
}

.a-grid__col--11--col-gap-9 {
  width: calc(91.6667% - 72.9167px);
  margin-left: 36px;
  margin-right: 36px;
}

.a-grid__col--12--col-gap-9 {
  width: calc(100% - 73px);
  margin-left: 36px;
  margin-right: 36px;
}

.a-grid__col--1--col-gap-10 {
  width: calc(8.33333% - 80.0833px);
  margin-left: 40px;
  margin-right: 40px;
}

.a-grid__col--2--col-gap-10 {
  width: calc(16.6667% - 80.1667px);
  margin-left: 40px;
  margin-right: 40px;
}

.a-grid__col--3--col-gap-10 {
  width: calc(25% - 80.25px);
  margin-left: 40px;
  margin-right: 40px;
}

.a-grid__col--4--col-gap-10 {
  width: calc(33.3333% - 80.3333px);
  margin-left: 40px;
  margin-right: 40px;
}

.a-grid__col--5--col-gap-10 {
  width: calc(41.6667% - 80.4167px);
  margin-left: 40px;
  margin-right: 40px;
}

.a-grid__col--6--col-gap-10 {
  width: calc(50% - 80.5px);
  margin-left: 40px;
  margin-right: 40px;
}

.a-grid__col--7--col-gap-10 {
  width: calc(58.3333% - 80.5833px);
  margin-left: 40px;
  margin-right: 40px;
}

.a-grid__col--8--col-gap-10 {
  width: calc(66.6667% - 80.6667px);
  margin-left: 40px;
  margin-right: 40px;
}

.a-grid__col--9--col-gap-10 {
  width: calc(75% - 80.75px);
  margin-left: 40px;
  margin-right: 40px;
}

.a-grid__col--10--col-gap-10 {
  width: calc(83.3333% - 80.8333px);
  margin-left: 40px;
  margin-right: 40px;
}

.a-grid__col--11--col-gap-10 {
  width: calc(91.6667% - 80.9167px);
  margin-left: 40px;
  margin-right: 40px;
}

.a-grid__col--12--col-gap-10 {
  width: calc(100% - 81px);
  margin-left: 40px;
  margin-right: 40px;
}

@media screen and (max-width: 1400px) {
  .a-grid__col--xl-1--col-gap-xl-1 {
    width: calc(8.33333% - 8.08333px);
    margin-left: 4px;
    margin-right: 4px;
  }

  .a-grid__col--xl-2--col-gap-xl-1 {
    width: calc(16.6667% - 8.16667px);
    margin-left: 4px;
    margin-right: 4px;
  }

  .a-grid__col--xl-3--col-gap-xl-1 {
    width: calc(25% - 8.25px);
    margin-left: 4px;
    margin-right: 4px;
  }

  .a-grid__col--xl-4--col-gap-xl-1 {
    width: calc(33.3333% - 8.33333px);
    margin-left: 4px;
    margin-right: 4px;
  }

  .a-grid__col--xl-5--col-gap-xl-1 {
    width: calc(41.6667% - 8.41667px);
    margin-left: 4px;
    margin-right: 4px;
  }

  .a-grid__col--xl-6--col-gap-xl-1 {
    width: calc(50% - 8.5px);
    margin-left: 4px;
    margin-right: 4px;
  }

  .a-grid__col--xl-7--col-gap-xl-1 {
    width: calc(58.3333% - 8.58333px);
    margin-left: 4px;
    margin-right: 4px;
  }

  .a-grid__col--xl-8--col-gap-xl-1 {
    width: calc(66.6667% - 8.66667px);
    margin-left: 4px;
    margin-right: 4px;
  }

  .a-grid__col--xl-9--col-gap-xl-1 {
    width: calc(75% - 8.75px);
    margin-left: 4px;
    margin-right: 4px;
  }

  .a-grid__col--xl-10--col-gap-xl-1 {
    width: calc(83.3333% - 8.83333px);
    margin-left: 4px;
    margin-right: 4px;
  }

  .a-grid__col--xl-11--col-gap-xl-1 {
    width: calc(91.6667% - 8.91667px);
    margin-left: 4px;
    margin-right: 4px;
  }

  .a-grid__col--xl-12--col-gap-xl-1 {
    width: calc(100% - 9px);
    margin-left: 4px;
    margin-right: 4px;
  }

  .a-grid__col--xl-1--col-gap-xl-2 {
    width: calc(8.33333% - 16.0833px);
    margin-left: 8px;
    margin-right: 8px;
  }

  .a-grid__col--xl-2--col-gap-xl-2 {
    width: calc(16.6667% - 16.1667px);
    margin-left: 8px;
    margin-right: 8px;
  }

  .a-grid__col--xl-3--col-gap-xl-2 {
    width: calc(25% - 16.25px);
    margin-left: 8px;
    margin-right: 8px;
  }

  .a-grid__col--xl-4--col-gap-xl-2 {
    width: calc(33.3333% - 16.3333px);
    margin-left: 8px;
    margin-right: 8px;
  }

  .a-grid__col--xl-5--col-gap-xl-2 {
    width: calc(41.6667% - 16.4167px);
    margin-left: 8px;
    margin-right: 8px;
  }

  .a-grid__col--xl-6--col-gap-xl-2 {
    width: calc(50% - 16.5px);
    margin-left: 8px;
    margin-right: 8px;
  }

  .a-grid__col--xl-7--col-gap-xl-2 {
    width: calc(58.3333% - 16.5833px);
    margin-left: 8px;
    margin-right: 8px;
  }

  .a-grid__col--xl-8--col-gap-xl-2 {
    width: calc(66.6667% - 16.6667px);
    margin-left: 8px;
    margin-right: 8px;
  }

  .a-grid__col--xl-9--col-gap-xl-2 {
    width: calc(75% - 16.75px);
    margin-left: 8px;
    margin-right: 8px;
  }

  .a-grid__col--xl-10--col-gap-xl-2 {
    width: calc(83.3333% - 16.8333px);
    margin-left: 8px;
    margin-right: 8px;
  }

  .a-grid__col--xl-11--col-gap-xl-2 {
    width: calc(91.6667% - 16.9167px);
    margin-left: 8px;
    margin-right: 8px;
  }

  .a-grid__col--xl-12--col-gap-xl-2 {
    width: calc(100% - 17px);
    margin-left: 8px;
    margin-right: 8px;
  }

  .a-grid__col--xl-1--col-gap-xl-3 {
    width: calc(8.33333% - 24.0833px);
    margin-left: 12px;
    margin-right: 12px;
  }

  .a-grid__col--xl-2--col-gap-xl-3 {
    width: calc(16.6667% - 24.1667px);
    margin-left: 12px;
    margin-right: 12px;
  }

  .a-grid__col--xl-3--col-gap-xl-3 {
    width: calc(25% - 24.25px);
    margin-left: 12px;
    margin-right: 12px;
  }

  .a-grid__col--xl-4--col-gap-xl-3 {
    width: calc(33.3333% - 24.3333px);
    margin-left: 12px;
    margin-right: 12px;
  }

  .a-grid__col--xl-5--col-gap-xl-3 {
    width: calc(41.6667% - 24.4167px);
    margin-left: 12px;
    margin-right: 12px;
  }

  .a-grid__col--xl-6--col-gap-xl-3 {
    width: calc(50% - 24.5px);
    margin-left: 12px;
    margin-right: 12px;
  }

  .a-grid__col--xl-7--col-gap-xl-3 {
    width: calc(58.3333% - 24.5833px);
    margin-left: 12px;
    margin-right: 12px;
  }

  .a-grid__col--xl-8--col-gap-xl-3 {
    width: calc(66.6667% - 24.6667px);
    margin-left: 12px;
    margin-right: 12px;
  }

  .a-grid__col--xl-9--col-gap-xl-3 {
    width: calc(75% - 24.75px);
    margin-left: 12px;
    margin-right: 12px;
  }

  .a-grid__col--xl-10--col-gap-xl-3 {
    width: calc(83.3333% - 24.8333px);
    margin-left: 12px;
    margin-right: 12px;
  }

  .a-grid__col--xl-11--col-gap-xl-3 {
    width: calc(91.6667% - 24.9167px);
    margin-left: 12px;
    margin-right: 12px;
  }

  .a-grid__col--xl-12--col-gap-xl-3 {
    width: calc(100% - 25px);
    margin-left: 12px;
    margin-right: 12px;
  }

  .a-grid__col--xl-1--col-gap-xl-4 {
    width: calc(8.33333% - 32.0833px);
    margin-left: 16px;
    margin-right: 16px;
  }

  .a-grid__col--xl-2--col-gap-xl-4 {
    width: calc(16.6667% - 32.1667px);
    margin-left: 16px;
    margin-right: 16px;
  }

  .a-grid__col--xl-3--col-gap-xl-4 {
    width: calc(25% - 32.25px);
    margin-left: 16px;
    margin-right: 16px;
  }

  .a-grid__col--xl-4--col-gap-xl-4 {
    width: calc(33.3333% - 32.3333px);
    margin-left: 16px;
    margin-right: 16px;
  }

  .a-grid__col--xl-5--col-gap-xl-4 {
    width: calc(41.6667% - 32.4167px);
    margin-left: 16px;
    margin-right: 16px;
  }

  .a-grid__col--xl-6--col-gap-xl-4 {
    width: calc(50% - 32.5px);
    margin-left: 16px;
    margin-right: 16px;
  }

  .a-grid__col--xl-7--col-gap-xl-4 {
    width: calc(58.3333% - 32.5833px);
    margin-left: 16px;
    margin-right: 16px;
  }

  .a-grid__col--xl-8--col-gap-xl-4 {
    width: calc(66.6667% - 32.6667px);
    margin-left: 16px;
    margin-right: 16px;
  }

  .a-grid__col--xl-9--col-gap-xl-4 {
    width: calc(75% - 32.75px);
    margin-left: 16px;
    margin-right: 16px;
  }

  .a-grid__col--xl-10--col-gap-xl-4 {
    width: calc(83.3333% - 32.8333px);
    margin-left: 16px;
    margin-right: 16px;
  }

  .a-grid__col--xl-11--col-gap-xl-4 {
    width: calc(91.6667% - 32.9167px);
    margin-left: 16px;
    margin-right: 16px;
  }

  .a-grid__col--xl-12--col-gap-xl-4 {
    width: calc(100% - 33px);
    margin-left: 16px;
    margin-right: 16px;
  }

  .a-grid__col--xl-1--col-gap-xl-5 {
    width: calc(8.33333% - 40.0833px);
    margin-left: 20px;
    margin-right: 20px;
  }

  .a-grid__col--xl-2--col-gap-xl-5 {
    width: calc(16.6667% - 40.1667px);
    margin-left: 20px;
    margin-right: 20px;
  }

  .a-grid__col--xl-3--col-gap-xl-5 {
    width: calc(25% - 40.25px);
    margin-left: 20px;
    margin-right: 20px;
  }

  .a-grid__col--xl-4--col-gap-xl-5 {
    width: calc(33.3333% - 40.3333px);
    margin-left: 20px;
    margin-right: 20px;
  }

  .a-grid__col--xl-5--col-gap-xl-5 {
    width: calc(41.6667% - 40.4167px);
    margin-left: 20px;
    margin-right: 20px;
  }

  .a-grid__col--xl-6--col-gap-xl-5 {
    width: calc(50% - 40.5px);
    margin-left: 20px;
    margin-right: 20px;
  }

  .a-grid__col--xl-7--col-gap-xl-5 {
    width: calc(58.3333% - 40.5833px);
    margin-left: 20px;
    margin-right: 20px;
  }

  .a-grid__col--xl-8--col-gap-xl-5 {
    width: calc(66.6667% - 40.6667px);
    margin-left: 20px;
    margin-right: 20px;
  }

  .a-grid__col--xl-9--col-gap-xl-5 {
    width: calc(75% - 40.75px);
    margin-left: 20px;
    margin-right: 20px;
  }

  .a-grid__col--xl-10--col-gap-xl-5 {
    width: calc(83.3333% - 40.8333px);
    margin-left: 20px;
    margin-right: 20px;
  }

  .a-grid__col--xl-11--col-gap-xl-5 {
    width: calc(91.6667% - 40.9167px);
    margin-left: 20px;
    margin-right: 20px;
  }

  .a-grid__col--xl-12--col-gap-xl-5 {
    width: calc(100% - 41px);
    margin-left: 20px;
    margin-right: 20px;
  }

  .a-grid__col--xl-1--col-gap-xl-6 {
    width: calc(8.33333% - 48.0833px);
    margin-left: 24px;
    margin-right: 24px;
  }

  .a-grid__col--xl-2--col-gap-xl-6 {
    width: calc(16.6667% - 48.1667px);
    margin-left: 24px;
    margin-right: 24px;
  }

  .a-grid__col--xl-3--col-gap-xl-6 {
    width: calc(25% - 48.25px);
    margin-left: 24px;
    margin-right: 24px;
  }

  .a-grid__col--xl-4--col-gap-xl-6 {
    width: calc(33.3333% - 48.3333px);
    margin-left: 24px;
    margin-right: 24px;
  }

  .a-grid__col--xl-5--col-gap-xl-6 {
    width: calc(41.6667% - 48.4167px);
    margin-left: 24px;
    margin-right: 24px;
  }

  .a-grid__col--xl-6--col-gap-xl-6 {
    width: calc(50% - 48.5px);
    margin-left: 24px;
    margin-right: 24px;
  }

  .a-grid__col--xl-7--col-gap-xl-6 {
    width: calc(58.3333% - 48.5833px);
    margin-left: 24px;
    margin-right: 24px;
  }

  .a-grid__col--xl-8--col-gap-xl-6 {
    width: calc(66.6667% - 48.6667px);
    margin-left: 24px;
    margin-right: 24px;
  }

  .a-grid__col--xl-9--col-gap-xl-6 {
    width: calc(75% - 48.75px);
    margin-left: 24px;
    margin-right: 24px;
  }

  .a-grid__col--xl-10--col-gap-xl-6 {
    width: calc(83.3333% - 48.8333px);
    margin-left: 24px;
    margin-right: 24px;
  }

  .a-grid__col--xl-11--col-gap-xl-6 {
    width: calc(91.6667% - 48.9167px);
    margin-left: 24px;
    margin-right: 24px;
  }

  .a-grid__col--xl-12--col-gap-xl-6 {
    width: calc(100% - 49px);
    margin-left: 24px;
    margin-right: 24px;
  }

  .a-grid__col--xl-1--col-gap-xl-7 {
    width: calc(8.33333% - 56.0833px);
    margin-left: 28px;
    margin-right: 28px;
  }

  .a-grid__col--xl-2--col-gap-xl-7 {
    width: calc(16.6667% - 56.1667px);
    margin-left: 28px;
    margin-right: 28px;
  }

  .a-grid__col--xl-3--col-gap-xl-7 {
    width: calc(25% - 56.25px);
    margin-left: 28px;
    margin-right: 28px;
  }

  .a-grid__col--xl-4--col-gap-xl-7 {
    width: calc(33.3333% - 56.3333px);
    margin-left: 28px;
    margin-right: 28px;
  }

  .a-grid__col--xl-5--col-gap-xl-7 {
    width: calc(41.6667% - 56.4167px);
    margin-left: 28px;
    margin-right: 28px;
  }

  .a-grid__col--xl-6--col-gap-xl-7 {
    width: calc(50% - 56.5px);
    margin-left: 28px;
    margin-right: 28px;
  }

  .a-grid__col--xl-7--col-gap-xl-7 {
    width: calc(58.3333% - 56.5833px);
    margin-left: 28px;
    margin-right: 28px;
  }

  .a-grid__col--xl-8--col-gap-xl-7 {
    width: calc(66.6667% - 56.6667px);
    margin-left: 28px;
    margin-right: 28px;
  }

  .a-grid__col--xl-9--col-gap-xl-7 {
    width: calc(75% - 56.75px);
    margin-left: 28px;
    margin-right: 28px;
  }

  .a-grid__col--xl-10--col-gap-xl-7 {
    width: calc(83.3333% - 56.8333px);
    margin-left: 28px;
    margin-right: 28px;
  }

  .a-grid__col--xl-11--col-gap-xl-7 {
    width: calc(91.6667% - 56.9167px);
    margin-left: 28px;
    margin-right: 28px;
  }

  .a-grid__col--xl-12--col-gap-xl-7 {
    width: calc(100% - 57px);
    margin-left: 28px;
    margin-right: 28px;
  }

  .a-grid__col--xl-1--col-gap-xl-8 {
    width: calc(8.33333% - 64.0833px);
    margin-left: 32px;
    margin-right: 32px;
  }

  .a-grid__col--xl-2--col-gap-xl-8 {
    width: calc(16.6667% - 64.1667px);
    margin-left: 32px;
    margin-right: 32px;
  }

  .a-grid__col--xl-3--col-gap-xl-8 {
    width: calc(25% - 64.25px);
    margin-left: 32px;
    margin-right: 32px;
  }

  .a-grid__col--xl-4--col-gap-xl-8 {
    width: calc(33.3333% - 64.3333px);
    margin-left: 32px;
    margin-right: 32px;
  }

  .a-grid__col--xl-5--col-gap-xl-8 {
    width: calc(41.6667% - 64.4167px);
    margin-left: 32px;
    margin-right: 32px;
  }

  .a-grid__col--xl-6--col-gap-xl-8 {
    width: calc(50% - 64.5px);
    margin-left: 32px;
    margin-right: 32px;
  }

  .a-grid__col--xl-7--col-gap-xl-8 {
    width: calc(58.3333% - 64.5833px);
    margin-left: 32px;
    margin-right: 32px;
  }

  .a-grid__col--xl-8--col-gap-xl-8 {
    width: calc(66.6667% - 64.6667px);
    margin-left: 32px;
    margin-right: 32px;
  }

  .a-grid__col--xl-9--col-gap-xl-8 {
    width: calc(75% - 64.75px);
    margin-left: 32px;
    margin-right: 32px;
  }

  .a-grid__col--xl-10--col-gap-xl-8 {
    width: calc(83.3333% - 64.8333px);
    margin-left: 32px;
    margin-right: 32px;
  }

  .a-grid__col--xl-11--col-gap-xl-8 {
    width: calc(91.6667% - 64.9167px);
    margin-left: 32px;
    margin-right: 32px;
  }

  .a-grid__col--xl-12--col-gap-xl-8 {
    width: calc(100% - 65px);
    margin-left: 32px;
    margin-right: 32px;
  }

  .a-grid__col--xl-1--col-gap-xl-9 {
    width: calc(8.33333% - 72.0833px);
    margin-left: 36px;
    margin-right: 36px;
  }

  .a-grid__col--xl-2--col-gap-xl-9 {
    width: calc(16.6667% - 72.1667px);
    margin-left: 36px;
    margin-right: 36px;
  }

  .a-grid__col--xl-3--col-gap-xl-9 {
    width: calc(25% - 72.25px);
    margin-left: 36px;
    margin-right: 36px;
  }

  .a-grid__col--xl-4--col-gap-xl-9 {
    width: calc(33.3333% - 72.3333px);
    margin-left: 36px;
    margin-right: 36px;
  }

  .a-grid__col--xl-5--col-gap-xl-9 {
    width: calc(41.6667% - 72.4167px);
    margin-left: 36px;
    margin-right: 36px;
  }

  .a-grid__col--xl-6--col-gap-xl-9 {
    width: calc(50% - 72.5px);
    margin-left: 36px;
    margin-right: 36px;
  }

  .a-grid__col--xl-7--col-gap-xl-9 {
    width: calc(58.3333% - 72.5833px);
    margin-left: 36px;
    margin-right: 36px;
  }

  .a-grid__col--xl-8--col-gap-xl-9 {
    width: calc(66.6667% - 72.6667px);
    margin-left: 36px;
    margin-right: 36px;
  }

  .a-grid__col--xl-9--col-gap-xl-9 {
    width: calc(75% - 72.75px);
    margin-left: 36px;
    margin-right: 36px;
  }

  .a-grid__col--xl-10--col-gap-xl-9 {
    width: calc(83.3333% - 72.8333px);
    margin-left: 36px;
    margin-right: 36px;
  }

  .a-grid__col--xl-11--col-gap-xl-9 {
    width: calc(91.6667% - 72.9167px);
    margin-left: 36px;
    margin-right: 36px;
  }

  .a-grid__col--xl-12--col-gap-xl-9 {
    width: calc(100% - 73px);
    margin-left: 36px;
    margin-right: 36px;
  }

  .a-grid__col--xl-1--col-gap-xl-10 {
    width: calc(8.33333% - 80.0833px);
    margin-left: 40px;
    margin-right: 40px;
  }

  .a-grid__col--xl-2--col-gap-xl-10 {
    width: calc(16.6667% - 80.1667px);
    margin-left: 40px;
    margin-right: 40px;
  }

  .a-grid__col--xl-3--col-gap-xl-10 {
    width: calc(25% - 80.25px);
    margin-left: 40px;
    margin-right: 40px;
  }

  .a-grid__col--xl-4--col-gap-xl-10 {
    width: calc(33.3333% - 80.3333px);
    margin-left: 40px;
    margin-right: 40px;
  }

  .a-grid__col--xl-5--col-gap-xl-10 {
    width: calc(41.6667% - 80.4167px);
    margin-left: 40px;
    margin-right: 40px;
  }

  .a-grid__col--xl-6--col-gap-xl-10 {
    width: calc(50% - 80.5px);
    margin-left: 40px;
    margin-right: 40px;
  }

  .a-grid__col--xl-7--col-gap-xl-10 {
    width: calc(58.3333% - 80.5833px);
    margin-left: 40px;
    margin-right: 40px;
  }

  .a-grid__col--xl-8--col-gap-xl-10 {
    width: calc(66.6667% - 80.6667px);
    margin-left: 40px;
    margin-right: 40px;
  }

  .a-grid__col--xl-9--col-gap-xl-10 {
    width: calc(75% - 80.75px);
    margin-left: 40px;
    margin-right: 40px;
  }

  .a-grid__col--xl-10--col-gap-xl-10 {
    width: calc(83.3333% - 80.8333px);
    margin-left: 40px;
    margin-right: 40px;
  }

  .a-grid__col--xl-11--col-gap-xl-10 {
    width: calc(91.6667% - 80.9167px);
    margin-left: 40px;
    margin-right: 40px;
  }

  .a-grid__col--xl-12--col-gap-xl-10 {
    width: calc(100% - 81px);
    margin-left: 40px;
    margin-right: 40px;
  }
}

@media screen and (max-width: 1280px) {
  .a-grid__col--l-1--col-gap-l-1 {
    width: calc(8.33333% - 8.08333px);
    margin-left: 4px;
    margin-right: 4px;
  }

  .a-grid__col--l-2--col-gap-l-1 {
    width: calc(16.6667% - 8.16667px);
    margin-left: 4px;
    margin-right: 4px;
  }

  .a-grid__col--l-3--col-gap-l-1 {
    width: calc(25% - 8.25px);
    margin-left: 4px;
    margin-right: 4px;
  }

  .a-grid__col--l-4--col-gap-l-1 {
    width: calc(33.3333% - 8.33333px);
    margin-left: 4px;
    margin-right: 4px;
  }

  .a-grid__col--l-5--col-gap-l-1 {
    width: calc(41.6667% - 8.41667px);
    margin-left: 4px;
    margin-right: 4px;
  }

  .a-grid__col--l-6--col-gap-l-1 {
    width: calc(50% - 8.5px);
    margin-left: 4px;
    margin-right: 4px;
  }

  .a-grid__col--l-7--col-gap-l-1 {
    width: calc(58.3333% - 8.58333px);
    margin-left: 4px;
    margin-right: 4px;
  }

  .a-grid__col--l-8--col-gap-l-1 {
    width: calc(66.6667% - 8.66667px);
    margin-left: 4px;
    margin-right: 4px;
  }

  .a-grid__col--l-9--col-gap-l-1 {
    width: calc(75% - 8.75px);
    margin-left: 4px;
    margin-right: 4px;
  }

  .a-grid__col--l-10--col-gap-l-1 {
    width: calc(83.3333% - 8.83333px);
    margin-left: 4px;
    margin-right: 4px;
  }

  .a-grid__col--l-11--col-gap-l-1 {
    width: calc(91.6667% - 8.91667px);
    margin-left: 4px;
    margin-right: 4px;
  }

  .a-grid__col--l-12--col-gap-l-1 {
    width: calc(100% - 9px);
    margin-left: 4px;
    margin-right: 4px;
  }

  .a-grid__col--l-1--col-gap-l-2 {
    width: calc(8.33333% - 16.0833px);
    margin-left: 8px;
    margin-right: 8px;
  }

  .a-grid__col--l-2--col-gap-l-2 {
    width: calc(16.6667% - 16.1667px);
    margin-left: 8px;
    margin-right: 8px;
  }

  .a-grid__col--l-3--col-gap-l-2 {
    width: calc(25% - 16.25px);
    margin-left: 8px;
    margin-right: 8px;
  }

  .a-grid__col--l-4--col-gap-l-2 {
    width: calc(33.3333% - 16.3333px);
    margin-left: 8px;
    margin-right: 8px;
  }

  .a-grid__col--l-5--col-gap-l-2 {
    width: calc(41.6667% - 16.4167px);
    margin-left: 8px;
    margin-right: 8px;
  }

  .a-grid__col--l-6--col-gap-l-2 {
    width: calc(50% - 16.5px);
    margin-left: 8px;
    margin-right: 8px;
  }

  .a-grid__col--l-7--col-gap-l-2 {
    width: calc(58.3333% - 16.5833px);
    margin-left: 8px;
    margin-right: 8px;
  }

  .a-grid__col--l-8--col-gap-l-2 {
    width: calc(66.6667% - 16.6667px);
    margin-left: 8px;
    margin-right: 8px;
  }

  .a-grid__col--l-9--col-gap-l-2 {
    width: calc(75% - 16.75px);
    margin-left: 8px;
    margin-right: 8px;
  }

  .a-grid__col--l-10--col-gap-l-2 {
    width: calc(83.3333% - 16.8333px);
    margin-left: 8px;
    margin-right: 8px;
  }

  .a-grid__col--l-11--col-gap-l-2 {
    width: calc(91.6667% - 16.9167px);
    margin-left: 8px;
    margin-right: 8px;
  }

  .a-grid__col--l-12--col-gap-l-2 {
    width: calc(100% - 17px);
    margin-left: 8px;
    margin-right: 8px;
  }

  .a-grid__col--l-1--col-gap-l-3 {
    width: calc(8.33333% - 24.0833px);
    margin-left: 12px;
    margin-right: 12px;
  }

  .a-grid__col--l-2--col-gap-l-3 {
    width: calc(16.6667% - 24.1667px);
    margin-left: 12px;
    margin-right: 12px;
  }

  .a-grid__col--l-3--col-gap-l-3 {
    width: calc(25% - 24.25px);
    margin-left: 12px;
    margin-right: 12px;
  }

  .a-grid__col--l-4--col-gap-l-3 {
    width: calc(33.3333% - 24.3333px);
    margin-left: 12px;
    margin-right: 12px;
  }

  .a-grid__col--l-5--col-gap-l-3 {
    width: calc(41.6667% - 24.4167px);
    margin-left: 12px;
    margin-right: 12px;
  }

  .a-grid__col--l-6--col-gap-l-3 {
    width: calc(50% - 24.5px);
    margin-left: 12px;
    margin-right: 12px;
  }

  .a-grid__col--l-7--col-gap-l-3 {
    width: calc(58.3333% - 24.5833px);
    margin-left: 12px;
    margin-right: 12px;
  }

  .a-grid__col--l-8--col-gap-l-3 {
    width: calc(66.6667% - 24.6667px);
    margin-left: 12px;
    margin-right: 12px;
  }

  .a-grid__col--l-9--col-gap-l-3 {
    width: calc(75% - 24.75px);
    margin-left: 12px;
    margin-right: 12px;
  }

  .a-grid__col--l-10--col-gap-l-3 {
    width: calc(83.3333% - 24.8333px);
    margin-left: 12px;
    margin-right: 12px;
  }

  .a-grid__col--l-11--col-gap-l-3 {
    width: calc(91.6667% - 24.9167px);
    margin-left: 12px;
    margin-right: 12px;
  }

  .a-grid__col--l-12--col-gap-l-3 {
    width: calc(100% - 25px);
    margin-left: 12px;
    margin-right: 12px;
  }

  .a-grid__col--l-1--col-gap-l-4 {
    width: calc(8.33333% - 32.0833px);
    margin-left: 16px;
    margin-right: 16px;
  }

  .a-grid__col--l-2--col-gap-l-4 {
    width: calc(16.6667% - 32.1667px);
    margin-left: 16px;
    margin-right: 16px;
  }

  .a-grid__col--l-3--col-gap-l-4 {
    width: calc(25% - 32.25px);
    margin-left: 16px;
    margin-right: 16px;
  }

  .a-grid__col--l-4--col-gap-l-4 {
    width: calc(33.3333% - 32.3333px);
    margin-left: 16px;
    margin-right: 16px;
  }

  .a-grid__col--l-5--col-gap-l-4 {
    width: calc(41.6667% - 32.4167px);
    margin-left: 16px;
    margin-right: 16px;
  }

  .a-grid__col--l-6--col-gap-l-4 {
    width: calc(50% - 32.5px);
    margin-left: 16px;
    margin-right: 16px;
  }

  .a-grid__col--l-7--col-gap-l-4 {
    width: calc(58.3333% - 32.5833px);
    margin-left: 16px;
    margin-right: 16px;
  }

  .a-grid__col--l-8--col-gap-l-4 {
    width: calc(66.6667% - 32.6667px);
    margin-left: 16px;
    margin-right: 16px;
  }

  .a-grid__col--l-9--col-gap-l-4 {
    width: calc(75% - 32.75px);
    margin-left: 16px;
    margin-right: 16px;
  }

  .a-grid__col--l-10--col-gap-l-4 {
    width: calc(83.3333% - 32.8333px);
    margin-left: 16px;
    margin-right: 16px;
  }

  .a-grid__col--l-11--col-gap-l-4 {
    width: calc(91.6667% - 32.9167px);
    margin-left: 16px;
    margin-right: 16px;
  }

  .a-grid__col--l-12--col-gap-l-4 {
    width: calc(100% - 33px);
    margin-left: 16px;
    margin-right: 16px;
  }

  .a-grid__col--l-1--col-gap-l-5 {
    width: calc(8.33333% - 40.0833px);
    margin-left: 20px;
    margin-right: 20px;
  }

  .a-grid__col--l-2--col-gap-l-5 {
    width: calc(16.6667% - 40.1667px);
    margin-left: 20px;
    margin-right: 20px;
  }

  .a-grid__col--l-3--col-gap-l-5 {
    width: calc(25% - 40.25px);
    margin-left: 20px;
    margin-right: 20px;
  }

  .a-grid__col--l-4--col-gap-l-5 {
    width: calc(33.3333% - 40.3333px);
    margin-left: 20px;
    margin-right: 20px;
  }

  .a-grid__col--l-5--col-gap-l-5 {
    width: calc(41.6667% - 40.4167px);
    margin-left: 20px;
    margin-right: 20px;
  }

  .a-grid__col--l-6--col-gap-l-5 {
    width: calc(50% - 40.5px);
    margin-left: 20px;
    margin-right: 20px;
  }

  .a-grid__col--l-7--col-gap-l-5 {
    width: calc(58.3333% - 40.5833px);
    margin-left: 20px;
    margin-right: 20px;
  }

  .a-grid__col--l-8--col-gap-l-5 {
    width: calc(66.6667% - 40.6667px);
    margin-left: 20px;
    margin-right: 20px;
  }

  .a-grid__col--l-9--col-gap-l-5 {
    width: calc(75% - 40.75px);
    margin-left: 20px;
    margin-right: 20px;
  }

  .a-grid__col--l-10--col-gap-l-5 {
    width: calc(83.3333% - 40.8333px);
    margin-left: 20px;
    margin-right: 20px;
  }

  .a-grid__col--l-11--col-gap-l-5 {
    width: calc(91.6667% - 40.9167px);
    margin-left: 20px;
    margin-right: 20px;
  }

  .a-grid__col--l-12--col-gap-l-5 {
    width: calc(100% - 41px);
    margin-left: 20px;
    margin-right: 20px;
  }

  .a-grid__col--l-1--col-gap-l-6 {
    width: calc(8.33333% - 48.0833px);
    margin-left: 24px;
    margin-right: 24px;
  }

  .a-grid__col--l-2--col-gap-l-6 {
    width: calc(16.6667% - 48.1667px);
    margin-left: 24px;
    margin-right: 24px;
  }

  .a-grid__col--l-3--col-gap-l-6 {
    width: calc(25% - 48.25px);
    margin-left: 24px;
    margin-right: 24px;
  }

  .a-grid__col--l-4--col-gap-l-6 {
    width: calc(33.3333% - 48.3333px);
    margin-left: 24px;
    margin-right: 24px;
  }

  .a-grid__col--l-5--col-gap-l-6 {
    width: calc(41.6667% - 48.4167px);
    margin-left: 24px;
    margin-right: 24px;
  }

  .a-grid__col--l-6--col-gap-l-6 {
    width: calc(50% - 48.5px);
    margin-left: 24px;
    margin-right: 24px;
  }

  .a-grid__col--l-7--col-gap-l-6 {
    width: calc(58.3333% - 48.5833px);
    margin-left: 24px;
    margin-right: 24px;
  }

  .a-grid__col--l-8--col-gap-l-6 {
    width: calc(66.6667% - 48.6667px);
    margin-left: 24px;
    margin-right: 24px;
  }

  .a-grid__col--l-9--col-gap-l-6 {
    width: calc(75% - 48.75px);
    margin-left: 24px;
    margin-right: 24px;
  }

  .a-grid__col--l-10--col-gap-l-6 {
    width: calc(83.3333% - 48.8333px);
    margin-left: 24px;
    margin-right: 24px;
  }

  .a-grid__col--l-11--col-gap-l-6 {
    width: calc(91.6667% - 48.9167px);
    margin-left: 24px;
    margin-right: 24px;
  }

  .a-grid__col--l-12--col-gap-l-6 {
    width: calc(100% - 49px);
    margin-left: 24px;
    margin-right: 24px;
  }

  .a-grid__col--l-1--col-gap-l-7 {
    width: calc(8.33333% - 56.0833px);
    margin-left: 28px;
    margin-right: 28px;
  }

  .a-grid__col--l-2--col-gap-l-7 {
    width: calc(16.6667% - 56.1667px);
    margin-left: 28px;
    margin-right: 28px;
  }

  .a-grid__col--l-3--col-gap-l-7 {
    width: calc(25% - 56.25px);
    margin-left: 28px;
    margin-right: 28px;
  }

  .a-grid__col--l-4--col-gap-l-7 {
    width: calc(33.3333% - 56.3333px);
    margin-left: 28px;
    margin-right: 28px;
  }

  .a-grid__col--l-5--col-gap-l-7 {
    width: calc(41.6667% - 56.4167px);
    margin-left: 28px;
    margin-right: 28px;
  }

  .a-grid__col--l-6--col-gap-l-7 {
    width: calc(50% - 56.5px);
    margin-left: 28px;
    margin-right: 28px;
  }

  .a-grid__col--l-7--col-gap-l-7 {
    width: calc(58.3333% - 56.5833px);
    margin-left: 28px;
    margin-right: 28px;
  }

  .a-grid__col--l-8--col-gap-l-7 {
    width: calc(66.6667% - 56.6667px);
    margin-left: 28px;
    margin-right: 28px;
  }

  .a-grid__col--l-9--col-gap-l-7 {
    width: calc(75% - 56.75px);
    margin-left: 28px;
    margin-right: 28px;
  }

  .a-grid__col--l-10--col-gap-l-7 {
    width: calc(83.3333% - 56.8333px);
    margin-left: 28px;
    margin-right: 28px;
  }

  .a-grid__col--l-11--col-gap-l-7 {
    width: calc(91.6667% - 56.9167px);
    margin-left: 28px;
    margin-right: 28px;
  }

  .a-grid__col--l-12--col-gap-l-7 {
    width: calc(100% - 57px);
    margin-left: 28px;
    margin-right: 28px;
  }

  .a-grid__col--l-1--col-gap-l-8 {
    width: calc(8.33333% - 64.0833px);
    margin-left: 32px;
    margin-right: 32px;
  }

  .a-grid__col--l-2--col-gap-l-8 {
    width: calc(16.6667% - 64.1667px);
    margin-left: 32px;
    margin-right: 32px;
  }

  .a-grid__col--l-3--col-gap-l-8 {
    width: calc(25% - 64.25px);
    margin-left: 32px;
    margin-right: 32px;
  }

  .a-grid__col--l-4--col-gap-l-8 {
    width: calc(33.3333% - 64.3333px);
    margin-left: 32px;
    margin-right: 32px;
  }

  .a-grid__col--l-5--col-gap-l-8 {
    width: calc(41.6667% - 64.4167px);
    margin-left: 32px;
    margin-right: 32px;
  }

  .a-grid__col--l-6--col-gap-l-8 {
    width: calc(50% - 64.5px);
    margin-left: 32px;
    margin-right: 32px;
  }

  .a-grid__col--l-7--col-gap-l-8 {
    width: calc(58.3333% - 64.5833px);
    margin-left: 32px;
    margin-right: 32px;
  }

  .a-grid__col--l-8--col-gap-l-8 {
    width: calc(66.6667% - 64.6667px);
    margin-left: 32px;
    margin-right: 32px;
  }

  .a-grid__col--l-9--col-gap-l-8 {
    width: calc(75% - 64.75px);
    margin-left: 32px;
    margin-right: 32px;
  }

  .a-grid__col--l-10--col-gap-l-8 {
    width: calc(83.3333% - 64.8333px);
    margin-left: 32px;
    margin-right: 32px;
  }

  .a-grid__col--l-11--col-gap-l-8 {
    width: calc(91.6667% - 64.9167px);
    margin-left: 32px;
    margin-right: 32px;
  }

  .a-grid__col--l-12--col-gap-l-8 {
    width: calc(100% - 65px);
    margin-left: 32px;
    margin-right: 32px;
  }

  .a-grid__col--l-1--col-gap-l-9 {
    width: calc(8.33333% - 72.0833px);
    margin-left: 36px;
    margin-right: 36px;
  }

  .a-grid__col--l-2--col-gap-l-9 {
    width: calc(16.6667% - 72.1667px);
    margin-left: 36px;
    margin-right: 36px;
  }

  .a-grid__col--l-3--col-gap-l-9 {
    width: calc(25% - 72.25px);
    margin-left: 36px;
    margin-right: 36px;
  }

  .a-grid__col--l-4--col-gap-l-9 {
    width: calc(33.3333% - 72.3333px);
    margin-left: 36px;
    margin-right: 36px;
  }

  .a-grid__col--l-5--col-gap-l-9 {
    width: calc(41.6667% - 72.4167px);
    margin-left: 36px;
    margin-right: 36px;
  }

  .a-grid__col--l-6--col-gap-l-9 {
    width: calc(50% - 72.5px);
    margin-left: 36px;
    margin-right: 36px;
  }

  .a-grid__col--l-7--col-gap-l-9 {
    width: calc(58.3333% - 72.5833px);
    margin-left: 36px;
    margin-right: 36px;
  }

  .a-grid__col--l-8--col-gap-l-9 {
    width: calc(66.6667% - 72.6667px);
    margin-left: 36px;
    margin-right: 36px;
  }

  .a-grid__col--l-9--col-gap-l-9 {
    width: calc(75% - 72.75px);
    margin-left: 36px;
    margin-right: 36px;
  }

  .a-grid__col--l-10--col-gap-l-9 {
    width: calc(83.3333% - 72.8333px);
    margin-left: 36px;
    margin-right: 36px;
  }

  .a-grid__col--l-11--col-gap-l-9 {
    width: calc(91.6667% - 72.9167px);
    margin-left: 36px;
    margin-right: 36px;
  }

  .a-grid__col--l-12--col-gap-l-9 {
    width: calc(100% - 73px);
    margin-left: 36px;
    margin-right: 36px;
  }

  .a-grid__col--l-1--col-gap-l-10 {
    width: calc(8.33333% - 80.0833px);
    margin-left: 40px;
    margin-right: 40px;
  }

  .a-grid__col--l-2--col-gap-l-10 {
    width: calc(16.6667% - 80.1667px);
    margin-left: 40px;
    margin-right: 40px;
  }

  .a-grid__col--l-3--col-gap-l-10 {
    width: calc(25% - 80.25px);
    margin-left: 40px;
    margin-right: 40px;
  }

  .a-grid__col--l-4--col-gap-l-10 {
    width: calc(33.3333% - 80.3333px);
    margin-left: 40px;
    margin-right: 40px;
  }

  .a-grid__col--l-5--col-gap-l-10 {
    width: calc(41.6667% - 80.4167px);
    margin-left: 40px;
    margin-right: 40px;
  }

  .a-grid__col--l-6--col-gap-l-10 {
    width: calc(50% - 80.5px);
    margin-left: 40px;
    margin-right: 40px;
  }

  .a-grid__col--l-7--col-gap-l-10 {
    width: calc(58.3333% - 80.5833px);
    margin-left: 40px;
    margin-right: 40px;
  }

  .a-grid__col--l-8--col-gap-l-10 {
    width: calc(66.6667% - 80.6667px);
    margin-left: 40px;
    margin-right: 40px;
  }

  .a-grid__col--l-9--col-gap-l-10 {
    width: calc(75% - 80.75px);
    margin-left: 40px;
    margin-right: 40px;
  }

  .a-grid__col--l-10--col-gap-l-10 {
    width: calc(83.3333% - 80.8333px);
    margin-left: 40px;
    margin-right: 40px;
  }

  .a-grid__col--l-11--col-gap-l-10 {
    width: calc(91.6667% - 80.9167px);
    margin-left: 40px;
    margin-right: 40px;
  }

  .a-grid__col--l-12--col-gap-l-10 {
    width: calc(100% - 81px);
    margin-left: 40px;
    margin-right: 40px;
  }
}

@media screen and (max-width: 1020px) {
  .a-grid__col--m-1--col-gap-m-1 {
    width: calc(8.33333% - 8.08333px);
    margin-left: 4px;
    margin-right: 4px;
  }

  .a-grid__col--m-2--col-gap-m-1 {
    width: calc(16.6667% - 8.16667px);
    margin-left: 4px;
    margin-right: 4px;
  }

  .a-grid__col--m-3--col-gap-m-1 {
    width: calc(25% - 8.25px);
    margin-left: 4px;
    margin-right: 4px;
  }

  .a-grid__col--m-4--col-gap-m-1 {
    width: calc(33.3333% - 8.33333px);
    margin-left: 4px;
    margin-right: 4px;
  }

  .a-grid__col--m-5--col-gap-m-1 {
    width: calc(41.6667% - 8.41667px);
    margin-left: 4px;
    margin-right: 4px;
  }

  .a-grid__col--m-6--col-gap-m-1 {
    width: calc(50% - 8.5px);
    margin-left: 4px;
    margin-right: 4px;
  }

  .a-grid__col--m-7--col-gap-m-1 {
    width: calc(58.3333% - 8.58333px);
    margin-left: 4px;
    margin-right: 4px;
  }

  .a-grid__col--m-8--col-gap-m-1 {
    width: calc(66.6667% - 8.66667px);
    margin-left: 4px;
    margin-right: 4px;
  }

  .a-grid__col--m-9--col-gap-m-1 {
    width: calc(75% - 8.75px);
    margin-left: 4px;
    margin-right: 4px;
  }

  .a-grid__col--m-10--col-gap-m-1 {
    width: calc(83.3333% - 8.83333px);
    margin-left: 4px;
    margin-right: 4px;
  }

  .a-grid__col--m-11--col-gap-m-1 {
    width: calc(91.6667% - 8.91667px);
    margin-left: 4px;
    margin-right: 4px;
  }

  .a-grid__col--m-12--col-gap-m-1 {
    width: calc(100% - 9px);
    margin-left: 4px;
    margin-right: 4px;
  }

  .a-grid__col--m-1--col-gap-m-2 {
    width: calc(8.33333% - 16.0833px);
    margin-left: 8px;
    margin-right: 8px;
  }

  .a-grid__col--m-2--col-gap-m-2 {
    width: calc(16.6667% - 16.1667px);
    margin-left: 8px;
    margin-right: 8px;
  }

  .a-grid__col--m-3--col-gap-m-2 {
    width: calc(25% - 16.25px);
    margin-left: 8px;
    margin-right: 8px;
  }

  .a-grid__col--m-4--col-gap-m-2 {
    width: calc(33.3333% - 16.3333px);
    margin-left: 8px;
    margin-right: 8px;
  }

  .a-grid__col--m-5--col-gap-m-2 {
    width: calc(41.6667% - 16.4167px);
    margin-left: 8px;
    margin-right: 8px;
  }

  .a-grid__col--m-6--col-gap-m-2 {
    width: calc(50% - 16.5px);
    margin-left: 8px;
    margin-right: 8px;
  }

  .a-grid__col--m-7--col-gap-m-2 {
    width: calc(58.3333% - 16.5833px);
    margin-left: 8px;
    margin-right: 8px;
  }

  .a-grid__col--m-8--col-gap-m-2 {
    width: calc(66.6667% - 16.6667px);
    margin-left: 8px;
    margin-right: 8px;
  }

  .a-grid__col--m-9--col-gap-m-2 {
    width: calc(75% - 16.75px);
    margin-left: 8px;
    margin-right: 8px;
  }

  .a-grid__col--m-10--col-gap-m-2 {
    width: calc(83.3333% - 16.8333px);
    margin-left: 8px;
    margin-right: 8px;
  }

  .a-grid__col--m-11--col-gap-m-2 {
    width: calc(91.6667% - 16.9167px);
    margin-left: 8px;
    margin-right: 8px;
  }

  .a-grid__col--m-12--col-gap-m-2 {
    width: calc(100% - 17px);
    margin-left: 8px;
    margin-right: 8px;
  }

  .a-grid__col--m-1--col-gap-m-3 {
    width: calc(8.33333% - 24.0833px);
    margin-left: 12px;
    margin-right: 12px;
  }

  .a-grid__col--m-2--col-gap-m-3 {
    width: calc(16.6667% - 24.1667px);
    margin-left: 12px;
    margin-right: 12px;
  }

  .a-grid__col--m-3--col-gap-m-3 {
    width: calc(25% - 24.25px);
    margin-left: 12px;
    margin-right: 12px;
  }

  .a-grid__col--m-4--col-gap-m-3 {
    width: calc(33.3333% - 24.3333px);
    margin-left: 12px;
    margin-right: 12px;
  }

  .a-grid__col--m-5--col-gap-m-3 {
    width: calc(41.6667% - 24.4167px);
    margin-left: 12px;
    margin-right: 12px;
  }

  .a-grid__col--m-6--col-gap-m-3 {
    width: calc(50% - 24.5px);
    margin-left: 12px;
    margin-right: 12px;
  }

  .a-grid__col--m-7--col-gap-m-3 {
    width: calc(58.3333% - 24.5833px);
    margin-left: 12px;
    margin-right: 12px;
  }

  .a-grid__col--m-8--col-gap-m-3 {
    width: calc(66.6667% - 24.6667px);
    margin-left: 12px;
    margin-right: 12px;
  }

  .a-grid__col--m-9--col-gap-m-3 {
    width: calc(75% - 24.75px);
    margin-left: 12px;
    margin-right: 12px;
  }

  .a-grid__col--m-10--col-gap-m-3 {
    width: calc(83.3333% - 24.8333px);
    margin-left: 12px;
    margin-right: 12px;
  }

  .a-grid__col--m-11--col-gap-m-3 {
    width: calc(91.6667% - 24.9167px);
    margin-left: 12px;
    margin-right: 12px;
  }

  .a-grid__col--m-12--col-gap-m-3 {
    width: calc(100% - 25px);
    margin-left: 12px;
    margin-right: 12px;
  }

  .a-grid__col--m-1--col-gap-m-4 {
    width: calc(8.33333% - 32.0833px);
    margin-left: 16px;
    margin-right: 16px;
  }

  .a-grid__col--m-2--col-gap-m-4 {
    width: calc(16.6667% - 32.1667px);
    margin-left: 16px;
    margin-right: 16px;
  }

  .a-grid__col--m-3--col-gap-m-4 {
    width: calc(25% - 32.25px);
    margin-left: 16px;
    margin-right: 16px;
  }

  .a-grid__col--m-4--col-gap-m-4 {
    width: calc(33.3333% - 32.3333px);
    margin-left: 16px;
    margin-right: 16px;
  }

  .a-grid__col--m-5--col-gap-m-4 {
    width: calc(41.6667% - 32.4167px);
    margin-left: 16px;
    margin-right: 16px;
  }

  .a-grid__col--m-6--col-gap-m-4 {
    width: calc(50% - 32.5px);
    margin-left: 16px;
    margin-right: 16px;
  }

  .a-grid__col--m-7--col-gap-m-4 {
    width: calc(58.3333% - 32.5833px);
    margin-left: 16px;
    margin-right: 16px;
  }

  .a-grid__col--m-8--col-gap-m-4 {
    width: calc(66.6667% - 32.6667px);
    margin-left: 16px;
    margin-right: 16px;
  }

  .a-grid__col--m-9--col-gap-m-4 {
    width: calc(75% - 32.75px);
    margin-left: 16px;
    margin-right: 16px;
  }

  .a-grid__col--m-10--col-gap-m-4 {
    width: calc(83.3333% - 32.8333px);
    margin-left: 16px;
    margin-right: 16px;
  }

  .a-grid__col--m-11--col-gap-m-4 {
    width: calc(91.6667% - 32.9167px);
    margin-left: 16px;
    margin-right: 16px;
  }

  .a-grid__col--m-12--col-gap-m-4 {
    width: calc(100% - 33px);
    margin-left: 16px;
    margin-right: 16px;
  }

  .a-grid__col--m-1--col-gap-m-5 {
    width: calc(8.33333% - 40.0833px);
    margin-left: 20px;
    margin-right: 20px;
  }

  .a-grid__col--m-2--col-gap-m-5 {
    width: calc(16.6667% - 40.1667px);
    margin-left: 20px;
    margin-right: 20px;
  }

  .a-grid__col--m-3--col-gap-m-5 {
    width: calc(25% - 40.25px);
    margin-left: 20px;
    margin-right: 20px;
  }

  .a-grid__col--m-4--col-gap-m-5 {
    width: calc(33.3333% - 40.3333px);
    margin-left: 20px;
    margin-right: 20px;
  }

  .a-grid__col--m-5--col-gap-m-5 {
    width: calc(41.6667% - 40.4167px);
    margin-left: 20px;
    margin-right: 20px;
  }

  .a-grid__col--m-6--col-gap-m-5 {
    width: calc(50% - 40.5px);
    margin-left: 20px;
    margin-right: 20px;
  }

  .a-grid__col--m-7--col-gap-m-5 {
    width: calc(58.3333% - 40.5833px);
    margin-left: 20px;
    margin-right: 20px;
  }

  .a-grid__col--m-8--col-gap-m-5 {
    width: calc(66.6667% - 40.6667px);
    margin-left: 20px;
    margin-right: 20px;
  }

  .a-grid__col--m-9--col-gap-m-5 {
    width: calc(75% - 40.75px);
    margin-left: 20px;
    margin-right: 20px;
  }

  .a-grid__col--m-10--col-gap-m-5 {
    width: calc(83.3333% - 40.8333px);
    margin-left: 20px;
    margin-right: 20px;
  }

  .a-grid__col--m-11--col-gap-m-5 {
    width: calc(91.6667% - 40.9167px);
    margin-left: 20px;
    margin-right: 20px;
  }

  .a-grid__col--m-12--col-gap-m-5 {
    width: calc(100% - 41px);
    margin-left: 20px;
    margin-right: 20px;
  }

  .a-grid__col--m-1--col-gap-m-6 {
    width: calc(8.33333% - 48.0833px);
    margin-left: 24px;
    margin-right: 24px;
  }

  .a-grid__col--m-2--col-gap-m-6 {
    width: calc(16.6667% - 48.1667px);
    margin-left: 24px;
    margin-right: 24px;
  }

  .a-grid__col--m-3--col-gap-m-6 {
    width: calc(25% - 48.25px);
    margin-left: 24px;
    margin-right: 24px;
  }

  .a-grid__col--m-4--col-gap-m-6 {
    width: calc(33.3333% - 48.3333px);
    margin-left: 24px;
    margin-right: 24px;
  }

  .a-grid__col--m-5--col-gap-m-6 {
    width: calc(41.6667% - 48.4167px);
    margin-left: 24px;
    margin-right: 24px;
  }

  .a-grid__col--m-6--col-gap-m-6 {
    width: calc(50% - 48.5px);
    margin-left: 24px;
    margin-right: 24px;
  }

  .a-grid__col--m-7--col-gap-m-6 {
    width: calc(58.3333% - 48.5833px);
    margin-left: 24px;
    margin-right: 24px;
  }

  .a-grid__col--m-8--col-gap-m-6 {
    width: calc(66.6667% - 48.6667px);
    margin-left: 24px;
    margin-right: 24px;
  }

  .a-grid__col--m-9--col-gap-m-6 {
    width: calc(75% - 48.75px);
    margin-left: 24px;
    margin-right: 24px;
  }

  .a-grid__col--m-10--col-gap-m-6 {
    width: calc(83.3333% - 48.8333px);
    margin-left: 24px;
    margin-right: 24px;
  }

  .a-grid__col--m-11--col-gap-m-6 {
    width: calc(91.6667% - 48.9167px);
    margin-left: 24px;
    margin-right: 24px;
  }

  .a-grid__col--m-12--col-gap-m-6 {
    width: calc(100% - 49px);
    margin-left: 24px;
    margin-right: 24px;
  }

  .a-grid__col--m-1--col-gap-m-7 {
    width: calc(8.33333% - 56.0833px);
    margin-left: 28px;
    margin-right: 28px;
  }

  .a-grid__col--m-2--col-gap-m-7 {
    width: calc(16.6667% - 56.1667px);
    margin-left: 28px;
    margin-right: 28px;
  }

  .a-grid__col--m-3--col-gap-m-7 {
    width: calc(25% - 56.25px);
    margin-left: 28px;
    margin-right: 28px;
  }

  .a-grid__col--m-4--col-gap-m-7 {
    width: calc(33.3333% - 56.3333px);
    margin-left: 28px;
    margin-right: 28px;
  }

  .a-grid__col--m-5--col-gap-m-7 {
    width: calc(41.6667% - 56.4167px);
    margin-left: 28px;
    margin-right: 28px;
  }

  .a-grid__col--m-6--col-gap-m-7 {
    width: calc(50% - 56.5px);
    margin-left: 28px;
    margin-right: 28px;
  }

  .a-grid__col--m-7--col-gap-m-7 {
    width: calc(58.3333% - 56.5833px);
    margin-left: 28px;
    margin-right: 28px;
  }

  .a-grid__col--m-8--col-gap-m-7 {
    width: calc(66.6667% - 56.6667px);
    margin-left: 28px;
    margin-right: 28px;
  }

  .a-grid__col--m-9--col-gap-m-7 {
    width: calc(75% - 56.75px);
    margin-left: 28px;
    margin-right: 28px;
  }

  .a-grid__col--m-10--col-gap-m-7 {
    width: calc(83.3333% - 56.8333px);
    margin-left: 28px;
    margin-right: 28px;
  }

  .a-grid__col--m-11--col-gap-m-7 {
    width: calc(91.6667% - 56.9167px);
    margin-left: 28px;
    margin-right: 28px;
  }

  .a-grid__col--m-12--col-gap-m-7 {
    width: calc(100% - 57px);
    margin-left: 28px;
    margin-right: 28px;
  }

  .a-grid__col--m-1--col-gap-m-8 {
    width: calc(8.33333% - 64.0833px);
    margin-left: 32px;
    margin-right: 32px;
  }

  .a-grid__col--m-2--col-gap-m-8 {
    width: calc(16.6667% - 64.1667px);
    margin-left: 32px;
    margin-right: 32px;
  }

  .a-grid__col--m-3--col-gap-m-8 {
    width: calc(25% - 64.25px);
    margin-left: 32px;
    margin-right: 32px;
  }

  .a-grid__col--m-4--col-gap-m-8 {
    width: calc(33.3333% - 64.3333px);
    margin-left: 32px;
    margin-right: 32px;
  }

  .a-grid__col--m-5--col-gap-m-8 {
    width: calc(41.6667% - 64.4167px);
    margin-left: 32px;
    margin-right: 32px;
  }

  .a-grid__col--m-6--col-gap-m-8 {
    width: calc(50% - 64.5px);
    margin-left: 32px;
    margin-right: 32px;
  }

  .a-grid__col--m-7--col-gap-m-8 {
    width: calc(58.3333% - 64.5833px);
    margin-left: 32px;
    margin-right: 32px;
  }

  .a-grid__col--m-8--col-gap-m-8 {
    width: calc(66.6667% - 64.6667px);
    margin-left: 32px;
    margin-right: 32px;
  }

  .a-grid__col--m-9--col-gap-m-8 {
    width: calc(75% - 64.75px);
    margin-left: 32px;
    margin-right: 32px;
  }

  .a-grid__col--m-10--col-gap-m-8 {
    width: calc(83.3333% - 64.8333px);
    margin-left: 32px;
    margin-right: 32px;
  }

  .a-grid__col--m-11--col-gap-m-8 {
    width: calc(91.6667% - 64.9167px);
    margin-left: 32px;
    margin-right: 32px;
  }

  .a-grid__col--m-12--col-gap-m-8 {
    width: calc(100% - 65px);
    margin-left: 32px;
    margin-right: 32px;
  }

  .a-grid__col--m-1--col-gap-m-9 {
    width: calc(8.33333% - 72.0833px);
    margin-left: 36px;
    margin-right: 36px;
  }

  .a-grid__col--m-2--col-gap-m-9 {
    width: calc(16.6667% - 72.1667px);
    margin-left: 36px;
    margin-right: 36px;
  }

  .a-grid__col--m-3--col-gap-m-9 {
    width: calc(25% - 72.25px);
    margin-left: 36px;
    margin-right: 36px;
  }

  .a-grid__col--m-4--col-gap-m-9 {
    width: calc(33.3333% - 72.3333px);
    margin-left: 36px;
    margin-right: 36px;
  }

  .a-grid__col--m-5--col-gap-m-9 {
    width: calc(41.6667% - 72.4167px);
    margin-left: 36px;
    margin-right: 36px;
  }

  .a-grid__col--m-6--col-gap-m-9 {
    width: calc(50% - 72.5px);
    margin-left: 36px;
    margin-right: 36px;
  }

  .a-grid__col--m-7--col-gap-m-9 {
    width: calc(58.3333% - 72.5833px);
    margin-left: 36px;
    margin-right: 36px;
  }

  .a-grid__col--m-8--col-gap-m-9 {
    width: calc(66.6667% - 72.6667px);
    margin-left: 36px;
    margin-right: 36px;
  }

  .a-grid__col--m-9--col-gap-m-9 {
    width: calc(75% - 72.75px);
    margin-left: 36px;
    margin-right: 36px;
  }

  .a-grid__col--m-10--col-gap-m-9 {
    width: calc(83.3333% - 72.8333px);
    margin-left: 36px;
    margin-right: 36px;
  }

  .a-grid__col--m-11--col-gap-m-9 {
    width: calc(91.6667% - 72.9167px);
    margin-left: 36px;
    margin-right: 36px;
  }

  .a-grid__col--m-12--col-gap-m-9 {
    width: calc(100% - 73px);
    margin-left: 36px;
    margin-right: 36px;
  }

  .a-grid__col--m-1--col-gap-m-10 {
    width: calc(8.33333% - 80.0833px);
    margin-left: 40px;
    margin-right: 40px;
  }

  .a-grid__col--m-2--col-gap-m-10 {
    width: calc(16.6667% - 80.1667px);
    margin-left: 40px;
    margin-right: 40px;
  }

  .a-grid__col--m-3--col-gap-m-10 {
    width: calc(25% - 80.25px);
    margin-left: 40px;
    margin-right: 40px;
  }

  .a-grid__col--m-4--col-gap-m-10 {
    width: calc(33.3333% - 80.3333px);
    margin-left: 40px;
    margin-right: 40px;
  }

  .a-grid__col--m-5--col-gap-m-10 {
    width: calc(41.6667% - 80.4167px);
    margin-left: 40px;
    margin-right: 40px;
  }

  .a-grid__col--m-6--col-gap-m-10 {
    width: calc(50% - 80.5px);
    margin-left: 40px;
    margin-right: 40px;
  }

  .a-grid__col--m-7--col-gap-m-10 {
    width: calc(58.3333% - 80.5833px);
    margin-left: 40px;
    margin-right: 40px;
  }

  .a-grid__col--m-8--col-gap-m-10 {
    width: calc(66.6667% - 80.6667px);
    margin-left: 40px;
    margin-right: 40px;
  }

  .a-grid__col--m-9--col-gap-m-10 {
    width: calc(75% - 80.75px);
    margin-left: 40px;
    margin-right: 40px;
  }

  .a-grid__col--m-10--col-gap-m-10 {
    width: calc(83.3333% - 80.8333px);
    margin-left: 40px;
    margin-right: 40px;
  }

  .a-grid__col--m-11--col-gap-m-10 {
    width: calc(91.6667% - 80.9167px);
    margin-left: 40px;
    margin-right: 40px;
  }

  .a-grid__col--m-12--col-gap-m-10 {
    width: calc(100% - 81px);
    margin-left: 40px;
    margin-right: 40px;
  }
}

@media screen and (max-width: 780px) {
  .a-grid__col--s-1--col-gap-s-1 {
    width: calc(8.33333% - 8.08333px);
    margin-left: 4px;
    margin-right: 4px;
  }

  .a-grid__col--s-2--col-gap-s-1 {
    width: calc(16.6667% - 8.16667px);
    margin-left: 4px;
    margin-right: 4px;
  }

  .a-grid__col--s-3--col-gap-s-1 {
    width: calc(25% - 8.25px);
    margin-left: 4px;
    margin-right: 4px;
  }

  .a-grid__col--s-4--col-gap-s-1 {
    width: calc(33.3333% - 8.33333px);
    margin-left: 4px;
    margin-right: 4px;
  }

  .a-grid__col--s-5--col-gap-s-1 {
    width: calc(41.6667% - 8.41667px);
    margin-left: 4px;
    margin-right: 4px;
  }

  .a-grid__col--s-6--col-gap-s-1 {
    width: calc(50% - 8.5px);
    margin-left: 4px;
    margin-right: 4px;
  }

  .a-grid__col--s-7--col-gap-s-1 {
    width: calc(58.3333% - 8.58333px);
    margin-left: 4px;
    margin-right: 4px;
  }

  .a-grid__col--s-8--col-gap-s-1 {
    width: calc(66.6667% - 8.66667px);
    margin-left: 4px;
    margin-right: 4px;
  }

  .a-grid__col--s-9--col-gap-s-1 {
    width: calc(75% - 8.75px);
    margin-left: 4px;
    margin-right: 4px;
  }

  .a-grid__col--s-10--col-gap-s-1 {
    width: calc(83.3333% - 8.83333px);
    margin-left: 4px;
    margin-right: 4px;
  }

  .a-grid__col--s-11--col-gap-s-1 {
    width: calc(91.6667% - 8.91667px);
    margin-left: 4px;
    margin-right: 4px;
  }

  .a-grid__col--s-12--col-gap-s-1 {
    width: calc(100% - 9px);
    margin-left: 4px;
    margin-right: 4px;
  }

  .a-grid__col--s-1--col-gap-s-2 {
    width: calc(8.33333% - 16.0833px);
    margin-left: 8px;
    margin-right: 8px;
  }

  .a-grid__col--s-2--col-gap-s-2 {
    width: calc(16.6667% - 16.1667px);
    margin-left: 8px;
    margin-right: 8px;
  }

  .a-grid__col--s-3--col-gap-s-2 {
    width: calc(25% - 16.25px);
    margin-left: 8px;
    margin-right: 8px;
  }

  .a-grid__col--s-4--col-gap-s-2 {
    width: calc(33.3333% - 16.3333px);
    margin-left: 8px;
    margin-right: 8px;
  }

  .a-grid__col--s-5--col-gap-s-2 {
    width: calc(41.6667% - 16.4167px);
    margin-left: 8px;
    margin-right: 8px;
  }

  .a-grid__col--s-6--col-gap-s-2 {
    width: calc(50% - 16.5px);
    margin-left: 8px;
    margin-right: 8px;
  }

  .a-grid__col--s-7--col-gap-s-2 {
    width: calc(58.3333% - 16.5833px);
    margin-left: 8px;
    margin-right: 8px;
  }

  .a-grid__col--s-8--col-gap-s-2 {
    width: calc(66.6667% - 16.6667px);
    margin-left: 8px;
    margin-right: 8px;
  }

  .a-grid__col--s-9--col-gap-s-2 {
    width: calc(75% - 16.75px);
    margin-left: 8px;
    margin-right: 8px;
  }

  .a-grid__col--s-10--col-gap-s-2 {
    width: calc(83.3333% - 16.8333px);
    margin-left: 8px;
    margin-right: 8px;
  }

  .a-grid__col--s-11--col-gap-s-2 {
    width: calc(91.6667% - 16.9167px);
    margin-left: 8px;
    margin-right: 8px;
  }

  .a-grid__col--s-12--col-gap-s-2 {
    width: calc(100% - 17px);
    margin-left: 8px;
    margin-right: 8px;
  }

  .a-grid__col--s-1--col-gap-s-3 {
    width: calc(8.33333% - 24.0833px);
    margin-left: 12px;
    margin-right: 12px;
  }

  .a-grid__col--s-2--col-gap-s-3 {
    width: calc(16.6667% - 24.1667px);
    margin-left: 12px;
    margin-right: 12px;
  }

  .a-grid__col--s-3--col-gap-s-3 {
    width: calc(25% - 24.25px);
    margin-left: 12px;
    margin-right: 12px;
  }

  .a-grid__col--s-4--col-gap-s-3 {
    width: calc(33.3333% - 24.3333px);
    margin-left: 12px;
    margin-right: 12px;
  }

  .a-grid__col--s-5--col-gap-s-3 {
    width: calc(41.6667% - 24.4167px);
    margin-left: 12px;
    margin-right: 12px;
  }

  .a-grid__col--s-6--col-gap-s-3 {
    width: calc(50% - 24.5px);
    margin-left: 12px;
    margin-right: 12px;
  }

  .a-grid__col--s-7--col-gap-s-3 {
    width: calc(58.3333% - 24.5833px);
    margin-left: 12px;
    margin-right: 12px;
  }

  .a-grid__col--s-8--col-gap-s-3 {
    width: calc(66.6667% - 24.6667px);
    margin-left: 12px;
    margin-right: 12px;
  }

  .a-grid__col--s-9--col-gap-s-3 {
    width: calc(75% - 24.75px);
    margin-left: 12px;
    margin-right: 12px;
  }

  .a-grid__col--s-10--col-gap-s-3 {
    width: calc(83.3333% - 24.8333px);
    margin-left: 12px;
    margin-right: 12px;
  }

  .a-grid__col--s-11--col-gap-s-3 {
    width: calc(91.6667% - 24.9167px);
    margin-left: 12px;
    margin-right: 12px;
  }

  .a-grid__col--s-12--col-gap-s-3 {
    width: calc(100% - 25px);
    margin-left: 12px;
    margin-right: 12px;
  }

  .a-grid__col--s-1--col-gap-s-4 {
    width: calc(8.33333% - 32.0833px);
    margin-left: 16px;
    margin-right: 16px;
  }

  .a-grid__col--s-2--col-gap-s-4 {
    width: calc(16.6667% - 32.1667px);
    margin-left: 16px;
    margin-right: 16px;
  }

  .a-grid__col--s-3--col-gap-s-4 {
    width: calc(25% - 32.25px);
    margin-left: 16px;
    margin-right: 16px;
  }

  .a-grid__col--s-4--col-gap-s-4 {
    width: calc(33.3333% - 32.3333px);
    margin-left: 16px;
    margin-right: 16px;
  }

  .a-grid__col--s-5--col-gap-s-4 {
    width: calc(41.6667% - 32.4167px);
    margin-left: 16px;
    margin-right: 16px;
  }

  .a-grid__col--s-6--col-gap-s-4 {
    width: calc(50% - 32.5px);
    margin-left: 16px;
    margin-right: 16px;
  }

  .a-grid__col--s-7--col-gap-s-4 {
    width: calc(58.3333% - 32.5833px);
    margin-left: 16px;
    margin-right: 16px;
  }

  .a-grid__col--s-8--col-gap-s-4 {
    width: calc(66.6667% - 32.6667px);
    margin-left: 16px;
    margin-right: 16px;
  }

  .a-grid__col--s-9--col-gap-s-4 {
    width: calc(75% - 32.75px);
    margin-left: 16px;
    margin-right: 16px;
  }

  .a-grid__col--s-10--col-gap-s-4 {
    width: calc(83.3333% - 32.8333px);
    margin-left: 16px;
    margin-right: 16px;
  }

  .a-grid__col--s-11--col-gap-s-4 {
    width: calc(91.6667% - 32.9167px);
    margin-left: 16px;
    margin-right: 16px;
  }

  .a-grid__col--s-12--col-gap-s-4 {
    width: calc(100% - 33px);
    margin-left: 16px;
    margin-right: 16px;
  }

  .a-grid__col--s-1--col-gap-s-5 {
    width: calc(8.33333% - 40.0833px);
    margin-left: 20px;
    margin-right: 20px;
  }

  .a-grid__col--s-2--col-gap-s-5 {
    width: calc(16.6667% - 40.1667px);
    margin-left: 20px;
    margin-right: 20px;
  }

  .a-grid__col--s-3--col-gap-s-5 {
    width: calc(25% - 40.25px);
    margin-left: 20px;
    margin-right: 20px;
  }

  .a-grid__col--s-4--col-gap-s-5 {
    width: calc(33.3333% - 40.3333px);
    margin-left: 20px;
    margin-right: 20px;
  }

  .a-grid__col--s-5--col-gap-s-5 {
    width: calc(41.6667% - 40.4167px);
    margin-left: 20px;
    margin-right: 20px;
  }

  .a-grid__col--s-6--col-gap-s-5 {
    width: calc(50% - 40.5px);
    margin-left: 20px;
    margin-right: 20px;
  }

  .a-grid__col--s-7--col-gap-s-5 {
    width: calc(58.3333% - 40.5833px);
    margin-left: 20px;
    margin-right: 20px;
  }

  .a-grid__col--s-8--col-gap-s-5 {
    width: calc(66.6667% - 40.6667px);
    margin-left: 20px;
    margin-right: 20px;
  }

  .a-grid__col--s-9--col-gap-s-5 {
    width: calc(75% - 40.75px);
    margin-left: 20px;
    margin-right: 20px;
  }

  .a-grid__col--s-10--col-gap-s-5 {
    width: calc(83.3333% - 40.8333px);
    margin-left: 20px;
    margin-right: 20px;
  }

  .a-grid__col--s-11--col-gap-s-5 {
    width: calc(91.6667% - 40.9167px);
    margin-left: 20px;
    margin-right: 20px;
  }

  .a-grid__col--s-12--col-gap-s-5 {
    width: calc(100% - 41px);
    margin-left: 20px;
    margin-right: 20px;
  }

  .a-grid__col--s-1--col-gap-s-6 {
    width: calc(8.33333% - 48.0833px);
    margin-left: 24px;
    margin-right: 24px;
  }

  .a-grid__col--s-2--col-gap-s-6 {
    width: calc(16.6667% - 48.1667px);
    margin-left: 24px;
    margin-right: 24px;
  }

  .a-grid__col--s-3--col-gap-s-6 {
    width: calc(25% - 48.25px);
    margin-left: 24px;
    margin-right: 24px;
  }

  .a-grid__col--s-4--col-gap-s-6 {
    width: calc(33.3333% - 48.3333px);
    margin-left: 24px;
    margin-right: 24px;
  }

  .a-grid__col--s-5--col-gap-s-6 {
    width: calc(41.6667% - 48.4167px);
    margin-left: 24px;
    margin-right: 24px;
  }

  .a-grid__col--s-6--col-gap-s-6 {
    width: calc(50% - 48.5px);
    margin-left: 24px;
    margin-right: 24px;
  }

  .a-grid__col--s-7--col-gap-s-6 {
    width: calc(58.3333% - 48.5833px);
    margin-left: 24px;
    margin-right: 24px;
  }

  .a-grid__col--s-8--col-gap-s-6 {
    width: calc(66.6667% - 48.6667px);
    margin-left: 24px;
    margin-right: 24px;
  }

  .a-grid__col--s-9--col-gap-s-6 {
    width: calc(75% - 48.75px);
    margin-left: 24px;
    margin-right: 24px;
  }

  .a-grid__col--s-10--col-gap-s-6 {
    width: calc(83.3333% - 48.8333px);
    margin-left: 24px;
    margin-right: 24px;
  }

  .a-grid__col--s-11--col-gap-s-6 {
    width: calc(91.6667% - 48.9167px);
    margin-left: 24px;
    margin-right: 24px;
  }

  .a-grid__col--s-12--col-gap-s-6 {
    width: calc(100% - 49px);
    margin-left: 24px;
    margin-right: 24px;
  }

  .a-grid__col--s-1--col-gap-s-7 {
    width: calc(8.33333% - 56.0833px);
    margin-left: 28px;
    margin-right: 28px;
  }

  .a-grid__col--s-2--col-gap-s-7 {
    width: calc(16.6667% - 56.1667px);
    margin-left: 28px;
    margin-right: 28px;
  }

  .a-grid__col--s-3--col-gap-s-7 {
    width: calc(25% - 56.25px);
    margin-left: 28px;
    margin-right: 28px;
  }

  .a-grid__col--s-4--col-gap-s-7 {
    width: calc(33.3333% - 56.3333px);
    margin-left: 28px;
    margin-right: 28px;
  }

  .a-grid__col--s-5--col-gap-s-7 {
    width: calc(41.6667% - 56.4167px);
    margin-left: 28px;
    margin-right: 28px;
  }

  .a-grid__col--s-6--col-gap-s-7 {
    width: calc(50% - 56.5px);
    margin-left: 28px;
    margin-right: 28px;
  }

  .a-grid__col--s-7--col-gap-s-7 {
    width: calc(58.3333% - 56.5833px);
    margin-left: 28px;
    margin-right: 28px;
  }

  .a-grid__col--s-8--col-gap-s-7 {
    width: calc(66.6667% - 56.6667px);
    margin-left: 28px;
    margin-right: 28px;
  }

  .a-grid__col--s-9--col-gap-s-7 {
    width: calc(75% - 56.75px);
    margin-left: 28px;
    margin-right: 28px;
  }

  .a-grid__col--s-10--col-gap-s-7 {
    width: calc(83.3333% - 56.8333px);
    margin-left: 28px;
    margin-right: 28px;
  }

  .a-grid__col--s-11--col-gap-s-7 {
    width: calc(91.6667% - 56.9167px);
    margin-left: 28px;
    margin-right: 28px;
  }

  .a-grid__col--s-12--col-gap-s-7 {
    width: calc(100% - 57px);
    margin-left: 28px;
    margin-right: 28px;
  }

  .a-grid__col--s-1--col-gap-s-8 {
    width: calc(8.33333% - 64.0833px);
    margin-left: 32px;
    margin-right: 32px;
  }

  .a-grid__col--s-2--col-gap-s-8 {
    width: calc(16.6667% - 64.1667px);
    margin-left: 32px;
    margin-right: 32px;
  }

  .a-grid__col--s-3--col-gap-s-8 {
    width: calc(25% - 64.25px);
    margin-left: 32px;
    margin-right: 32px;
  }

  .a-grid__col--s-4--col-gap-s-8 {
    width: calc(33.3333% - 64.3333px);
    margin-left: 32px;
    margin-right: 32px;
  }

  .a-grid__col--s-5--col-gap-s-8 {
    width: calc(41.6667% - 64.4167px);
    margin-left: 32px;
    margin-right: 32px;
  }

  .a-grid__col--s-6--col-gap-s-8 {
    width: calc(50% - 64.5px);
    margin-left: 32px;
    margin-right: 32px;
  }

  .a-grid__col--s-7--col-gap-s-8 {
    width: calc(58.3333% - 64.5833px);
    margin-left: 32px;
    margin-right: 32px;
  }

  .a-grid__col--s-8--col-gap-s-8 {
    width: calc(66.6667% - 64.6667px);
    margin-left: 32px;
    margin-right: 32px;
  }

  .a-grid__col--s-9--col-gap-s-8 {
    width: calc(75% - 64.75px);
    margin-left: 32px;
    margin-right: 32px;
  }

  .a-grid__col--s-10--col-gap-s-8 {
    width: calc(83.3333% - 64.8333px);
    margin-left: 32px;
    margin-right: 32px;
  }

  .a-grid__col--s-11--col-gap-s-8 {
    width: calc(91.6667% - 64.9167px);
    margin-left: 32px;
    margin-right: 32px;
  }

  .a-grid__col--s-12--col-gap-s-8 {
    width: calc(100% - 65px);
    margin-left: 32px;
    margin-right: 32px;
  }

  .a-grid__col--s-1--col-gap-s-9 {
    width: calc(8.33333% - 72.0833px);
    margin-left: 36px;
    margin-right: 36px;
  }

  .a-grid__col--s-2--col-gap-s-9 {
    width: calc(16.6667% - 72.1667px);
    margin-left: 36px;
    margin-right: 36px;
  }

  .a-grid__col--s-3--col-gap-s-9 {
    width: calc(25% - 72.25px);
    margin-left: 36px;
    margin-right: 36px;
  }

  .a-grid__col--s-4--col-gap-s-9 {
    width: calc(33.3333% - 72.3333px);
    margin-left: 36px;
    margin-right: 36px;
  }

  .a-grid__col--s-5--col-gap-s-9 {
    width: calc(41.6667% - 72.4167px);
    margin-left: 36px;
    margin-right: 36px;
  }

  .a-grid__col--s-6--col-gap-s-9 {
    width: calc(50% - 72.5px);
    margin-left: 36px;
    margin-right: 36px;
  }

  .a-grid__col--s-7--col-gap-s-9 {
    width: calc(58.3333% - 72.5833px);
    margin-left: 36px;
    margin-right: 36px;
  }

  .a-grid__col--s-8--col-gap-s-9 {
    width: calc(66.6667% - 72.6667px);
    margin-left: 36px;
    margin-right: 36px;
  }

  .a-grid__col--s-9--col-gap-s-9 {
    width: calc(75% - 72.75px);
    margin-left: 36px;
    margin-right: 36px;
  }

  .a-grid__col--s-10--col-gap-s-9 {
    width: calc(83.3333% - 72.8333px);
    margin-left: 36px;
    margin-right: 36px;
  }

  .a-grid__col--s-11--col-gap-s-9 {
    width: calc(91.6667% - 72.9167px);
    margin-left: 36px;
    margin-right: 36px;
  }

  .a-grid__col--s-12--col-gap-s-9 {
    width: calc(100% - 73px);
    margin-left: 36px;
    margin-right: 36px;
  }

  .a-grid__col--s-1--col-gap-s-10 {
    width: calc(8.33333% - 80.0833px);
    margin-left: 40px;
    margin-right: 40px;
  }

  .a-grid__col--s-2--col-gap-s-10 {
    width: calc(16.6667% - 80.1667px);
    margin-left: 40px;
    margin-right: 40px;
  }

  .a-grid__col--s-3--col-gap-s-10 {
    width: calc(25% - 80.25px);
    margin-left: 40px;
    margin-right: 40px;
  }

  .a-grid__col--s-4--col-gap-s-10 {
    width: calc(33.3333% - 80.3333px);
    margin-left: 40px;
    margin-right: 40px;
  }

  .a-grid__col--s-5--col-gap-s-10 {
    width: calc(41.6667% - 80.4167px);
    margin-left: 40px;
    margin-right: 40px;
  }

  .a-grid__col--s-6--col-gap-s-10 {
    width: calc(50% - 80.5px);
    margin-left: 40px;
    margin-right: 40px;
  }

  .a-grid__col--s-7--col-gap-s-10 {
    width: calc(58.3333% - 80.5833px);
    margin-left: 40px;
    margin-right: 40px;
  }

  .a-grid__col--s-8--col-gap-s-10 {
    width: calc(66.6667% - 80.6667px);
    margin-left: 40px;
    margin-right: 40px;
  }

  .a-grid__col--s-9--col-gap-s-10 {
    width: calc(75% - 80.75px);
    margin-left: 40px;
    margin-right: 40px;
  }

  .a-grid__col--s-10--col-gap-s-10 {
    width: calc(83.3333% - 80.8333px);
    margin-left: 40px;
    margin-right: 40px;
  }

  .a-grid__col--s-11--col-gap-s-10 {
    width: calc(91.6667% - 80.9167px);
    margin-left: 40px;
    margin-right: 40px;
  }

  .a-grid__col--s-12--col-gap-s-10 {
    width: calc(100% - 81px);
    margin-left: 40px;
    margin-right: 40px;
  }
}

@media screen and (max-width: 440px) {
  .a-grid__col--xs-1--col-gap-xs-1 {
    width: calc(8.33333% - 8.08333px);
    margin-left: 4px;
    margin-right: 4px;
  }

  .a-grid__col--xs-2--col-gap-xs-1 {
    width: calc(16.6667% - 8.16667px);
    margin-left: 4px;
    margin-right: 4px;
  }

  .a-grid__col--xs-3--col-gap-xs-1 {
    width: calc(25% - 8.25px);
    margin-left: 4px;
    margin-right: 4px;
  }

  .a-grid__col--xs-4--col-gap-xs-1 {
    width: calc(33.3333% - 8.33333px);
    margin-left: 4px;
    margin-right: 4px;
  }

  .a-grid__col--xs-5--col-gap-xs-1 {
    width: calc(41.6667% - 8.41667px);
    margin-left: 4px;
    margin-right: 4px;
  }

  .a-grid__col--xs-6--col-gap-xs-1 {
    width: calc(50% - 8.5px);
    margin-left: 4px;
    margin-right: 4px;
  }

  .a-grid__col--xs-7--col-gap-xs-1 {
    width: calc(58.3333% - 8.58333px);
    margin-left: 4px;
    margin-right: 4px;
  }

  .a-grid__col--xs-8--col-gap-xs-1 {
    width: calc(66.6667% - 8.66667px);
    margin-left: 4px;
    margin-right: 4px;
  }

  .a-grid__col--xs-9--col-gap-xs-1 {
    width: calc(75% - 8.75px);
    margin-left: 4px;
    margin-right: 4px;
  }

  .a-grid__col--xs-10--col-gap-xs-1 {
    width: calc(83.3333% - 8.83333px);
    margin-left: 4px;
    margin-right: 4px;
  }

  .a-grid__col--xs-11--col-gap-xs-1 {
    width: calc(91.6667% - 8.91667px);
    margin-left: 4px;
    margin-right: 4px;
  }

  .a-grid__col--xs-12--col-gap-xs-1 {
    width: calc(100% - 9px);
    margin-left: 4px;
    margin-right: 4px;
  }

  .a-grid__col--xs-1--col-gap-xs-2 {
    width: calc(8.33333% - 16.0833px);
    margin-left: 8px;
    margin-right: 8px;
  }

  .a-grid__col--xs-2--col-gap-xs-2 {
    width: calc(16.6667% - 16.1667px);
    margin-left: 8px;
    margin-right: 8px;
  }

  .a-grid__col--xs-3--col-gap-xs-2 {
    width: calc(25% - 16.25px);
    margin-left: 8px;
    margin-right: 8px;
  }

  .a-grid__col--xs-4--col-gap-xs-2 {
    width: calc(33.3333% - 16.3333px);
    margin-left: 8px;
    margin-right: 8px;
  }

  .a-grid__col--xs-5--col-gap-xs-2 {
    width: calc(41.6667% - 16.4167px);
    margin-left: 8px;
    margin-right: 8px;
  }

  .a-grid__col--xs-6--col-gap-xs-2 {
    width: calc(50% - 16.5px);
    margin-left: 8px;
    margin-right: 8px;
  }

  .a-grid__col--xs-7--col-gap-xs-2 {
    width: calc(58.3333% - 16.5833px);
    margin-left: 8px;
    margin-right: 8px;
  }

  .a-grid__col--xs-8--col-gap-xs-2 {
    width: calc(66.6667% - 16.6667px);
    margin-left: 8px;
    margin-right: 8px;
  }

  .a-grid__col--xs-9--col-gap-xs-2 {
    width: calc(75% - 16.75px);
    margin-left: 8px;
    margin-right: 8px;
  }

  .a-grid__col--xs-10--col-gap-xs-2 {
    width: calc(83.3333% - 16.8333px);
    margin-left: 8px;
    margin-right: 8px;
  }

  .a-grid__col--xs-11--col-gap-xs-2 {
    width: calc(91.6667% - 16.9167px);
    margin-left: 8px;
    margin-right: 8px;
  }

  .a-grid__col--xs-12--col-gap-xs-2 {
    width: calc(100% - 17px);
    margin-left: 8px;
    margin-right: 8px;
  }

  .a-grid__col--xs-1--col-gap-xs-3 {
    width: calc(8.33333% - 24.0833px);
    margin-left: 12px;
    margin-right: 12px;
  }

  .a-grid__col--xs-2--col-gap-xs-3 {
    width: calc(16.6667% - 24.1667px);
    margin-left: 12px;
    margin-right: 12px;
  }

  .a-grid__col--xs-3--col-gap-xs-3 {
    width: calc(25% - 24.25px);
    margin-left: 12px;
    margin-right: 12px;
  }

  .a-grid__col--xs-4--col-gap-xs-3 {
    width: calc(33.3333% - 24.3333px);
    margin-left: 12px;
    margin-right: 12px;
  }

  .a-grid__col--xs-5--col-gap-xs-3 {
    width: calc(41.6667% - 24.4167px);
    margin-left: 12px;
    margin-right: 12px;
  }

  .a-grid__col--xs-6--col-gap-xs-3 {
    width: calc(50% - 24.5px);
    margin-left: 12px;
    margin-right: 12px;
  }

  .a-grid__col--xs-7--col-gap-xs-3 {
    width: calc(58.3333% - 24.5833px);
    margin-left: 12px;
    margin-right: 12px;
  }

  .a-grid__col--xs-8--col-gap-xs-3 {
    width: calc(66.6667% - 24.6667px);
    margin-left: 12px;
    margin-right: 12px;
  }

  .a-grid__col--xs-9--col-gap-xs-3 {
    width: calc(75% - 24.75px);
    margin-left: 12px;
    margin-right: 12px;
  }

  .a-grid__col--xs-10--col-gap-xs-3 {
    width: calc(83.3333% - 24.8333px);
    margin-left: 12px;
    margin-right: 12px;
  }

  .a-grid__col--xs-11--col-gap-xs-3 {
    width: calc(91.6667% - 24.9167px);
    margin-left: 12px;
    margin-right: 12px;
  }

  .a-grid__col--xs-12--col-gap-xs-3 {
    width: calc(100% - 25px);
    margin-left: 12px;
    margin-right: 12px;
  }

  .a-grid__col--xs-1--col-gap-xs-4 {
    width: calc(8.33333% - 32.0833px);
    margin-left: 16px;
    margin-right: 16px;
  }

  .a-grid__col--xs-2--col-gap-xs-4 {
    width: calc(16.6667% - 32.1667px);
    margin-left: 16px;
    margin-right: 16px;
  }

  .a-grid__col--xs-3--col-gap-xs-4 {
    width: calc(25% - 32.25px);
    margin-left: 16px;
    margin-right: 16px;
  }

  .a-grid__col--xs-4--col-gap-xs-4 {
    width: calc(33.3333% - 32.3333px);
    margin-left: 16px;
    margin-right: 16px;
  }

  .a-grid__col--xs-5--col-gap-xs-4 {
    width: calc(41.6667% - 32.4167px);
    margin-left: 16px;
    margin-right: 16px;
  }

  .a-grid__col--xs-6--col-gap-xs-4 {
    width: calc(50% - 32.5px);
    margin-left: 16px;
    margin-right: 16px;
  }

  .a-grid__col--xs-7--col-gap-xs-4 {
    width: calc(58.3333% - 32.5833px);
    margin-left: 16px;
    margin-right: 16px;
  }

  .a-grid__col--xs-8--col-gap-xs-4 {
    width: calc(66.6667% - 32.6667px);
    margin-left: 16px;
    margin-right: 16px;
  }

  .a-grid__col--xs-9--col-gap-xs-4 {
    width: calc(75% - 32.75px);
    margin-left: 16px;
    margin-right: 16px;
  }

  .a-grid__col--xs-10--col-gap-xs-4 {
    width: calc(83.3333% - 32.8333px);
    margin-left: 16px;
    margin-right: 16px;
  }

  .a-grid__col--xs-11--col-gap-xs-4 {
    width: calc(91.6667% - 32.9167px);
    margin-left: 16px;
    margin-right: 16px;
  }

  .a-grid__col--xs-12--col-gap-xs-4 {
    width: calc(100% - 33px);
    margin-left: 16px;
    margin-right: 16px;
  }

  .a-grid__col--xs-1--col-gap-xs-5 {
    width: calc(8.33333% - 40.0833px);
    margin-left: 20px;
    margin-right: 20px;
  }

  .a-grid__col--xs-2--col-gap-xs-5 {
    width: calc(16.6667% - 40.1667px);
    margin-left: 20px;
    margin-right: 20px;
  }

  .a-grid__col--xs-3--col-gap-xs-5 {
    width: calc(25% - 40.25px);
    margin-left: 20px;
    margin-right: 20px;
  }

  .a-grid__col--xs-4--col-gap-xs-5 {
    width: calc(33.3333% - 40.3333px);
    margin-left: 20px;
    margin-right: 20px;
  }

  .a-grid__col--xs-5--col-gap-xs-5 {
    width: calc(41.6667% - 40.4167px);
    margin-left: 20px;
    margin-right: 20px;
  }

  .a-grid__col--xs-6--col-gap-xs-5 {
    width: calc(50% - 40.5px);
    margin-left: 20px;
    margin-right: 20px;
  }

  .a-grid__col--xs-7--col-gap-xs-5 {
    width: calc(58.3333% - 40.5833px);
    margin-left: 20px;
    margin-right: 20px;
  }

  .a-grid__col--xs-8--col-gap-xs-5 {
    width: calc(66.6667% - 40.6667px);
    margin-left: 20px;
    margin-right: 20px;
  }

  .a-grid__col--xs-9--col-gap-xs-5 {
    width: calc(75% - 40.75px);
    margin-left: 20px;
    margin-right: 20px;
  }

  .a-grid__col--xs-10--col-gap-xs-5 {
    width: calc(83.3333% - 40.8333px);
    margin-left: 20px;
    margin-right: 20px;
  }

  .a-grid__col--xs-11--col-gap-xs-5 {
    width: calc(91.6667% - 40.9167px);
    margin-left: 20px;
    margin-right: 20px;
  }

  .a-grid__col--xs-12--col-gap-xs-5 {
    width: calc(100% - 41px);
    margin-left: 20px;
    margin-right: 20px;
  }

  .a-grid__col--xs-1--col-gap-xs-6 {
    width: calc(8.33333% - 48.0833px);
    margin-left: 24px;
    margin-right: 24px;
  }

  .a-grid__col--xs-2--col-gap-xs-6 {
    width: calc(16.6667% - 48.1667px);
    margin-left: 24px;
    margin-right: 24px;
  }

  .a-grid__col--xs-3--col-gap-xs-6 {
    width: calc(25% - 48.25px);
    margin-left: 24px;
    margin-right: 24px;
  }

  .a-grid__col--xs-4--col-gap-xs-6 {
    width: calc(33.3333% - 48.3333px);
    margin-left: 24px;
    margin-right: 24px;
  }

  .a-grid__col--xs-5--col-gap-xs-6 {
    width: calc(41.6667% - 48.4167px);
    margin-left: 24px;
    margin-right: 24px;
  }

  .a-grid__col--xs-6--col-gap-xs-6 {
    width: calc(50% - 48.5px);
    margin-left: 24px;
    margin-right: 24px;
  }

  .a-grid__col--xs-7--col-gap-xs-6 {
    width: calc(58.3333% - 48.5833px);
    margin-left: 24px;
    margin-right: 24px;
  }

  .a-grid__col--xs-8--col-gap-xs-6 {
    width: calc(66.6667% - 48.6667px);
    margin-left: 24px;
    margin-right: 24px;
  }

  .a-grid__col--xs-9--col-gap-xs-6 {
    width: calc(75% - 48.75px);
    margin-left: 24px;
    margin-right: 24px;
  }

  .a-grid__col--xs-10--col-gap-xs-6 {
    width: calc(83.3333% - 48.8333px);
    margin-left: 24px;
    margin-right: 24px;
  }

  .a-grid__col--xs-11--col-gap-xs-6 {
    width: calc(91.6667% - 48.9167px);
    margin-left: 24px;
    margin-right: 24px;
  }

  .a-grid__col--xs-12--col-gap-xs-6 {
    width: calc(100% - 49px);
    margin-left: 24px;
    margin-right: 24px;
  }

  .a-grid__col--xs-1--col-gap-xs-7 {
    width: calc(8.33333% - 56.0833px);
    margin-left: 28px;
    margin-right: 28px;
  }

  .a-grid__col--xs-2--col-gap-xs-7 {
    width: calc(16.6667% - 56.1667px);
    margin-left: 28px;
    margin-right: 28px;
  }

  .a-grid__col--xs-3--col-gap-xs-7 {
    width: calc(25% - 56.25px);
    margin-left: 28px;
    margin-right: 28px;
  }

  .a-grid__col--xs-4--col-gap-xs-7 {
    width: calc(33.3333% - 56.3333px);
    margin-left: 28px;
    margin-right: 28px;
  }

  .a-grid__col--xs-5--col-gap-xs-7 {
    width: calc(41.6667% - 56.4167px);
    margin-left: 28px;
    margin-right: 28px;
  }

  .a-grid__col--xs-6--col-gap-xs-7 {
    width: calc(50% - 56.5px);
    margin-left: 28px;
    margin-right: 28px;
  }

  .a-grid__col--xs-7--col-gap-xs-7 {
    width: calc(58.3333% - 56.5833px);
    margin-left: 28px;
    margin-right: 28px;
  }

  .a-grid__col--xs-8--col-gap-xs-7 {
    width: calc(66.6667% - 56.6667px);
    margin-left: 28px;
    margin-right: 28px;
  }

  .a-grid__col--xs-9--col-gap-xs-7 {
    width: calc(75% - 56.75px);
    margin-left: 28px;
    margin-right: 28px;
  }

  .a-grid__col--xs-10--col-gap-xs-7 {
    width: calc(83.3333% - 56.8333px);
    margin-left: 28px;
    margin-right: 28px;
  }

  .a-grid__col--xs-11--col-gap-xs-7 {
    width: calc(91.6667% - 56.9167px);
    margin-left: 28px;
    margin-right: 28px;
  }

  .a-grid__col--xs-12--col-gap-xs-7 {
    width: calc(100% - 57px);
    margin-left: 28px;
    margin-right: 28px;
  }

  .a-grid__col--xs-1--col-gap-xs-8 {
    width: calc(8.33333% - 64.0833px);
    margin-left: 32px;
    margin-right: 32px;
  }

  .a-grid__col--xs-2--col-gap-xs-8 {
    width: calc(16.6667% - 64.1667px);
    margin-left: 32px;
    margin-right: 32px;
  }

  .a-grid__col--xs-3--col-gap-xs-8 {
    width: calc(25% - 64.25px);
    margin-left: 32px;
    margin-right: 32px;
  }

  .a-grid__col--xs-4--col-gap-xs-8 {
    width: calc(33.3333% - 64.3333px);
    margin-left: 32px;
    margin-right: 32px;
  }

  .a-grid__col--xs-5--col-gap-xs-8 {
    width: calc(41.6667% - 64.4167px);
    margin-left: 32px;
    margin-right: 32px;
  }

  .a-grid__col--xs-6--col-gap-xs-8 {
    width: calc(50% - 64.5px);
    margin-left: 32px;
    margin-right: 32px;
  }

  .a-grid__col--xs-7--col-gap-xs-8 {
    width: calc(58.3333% - 64.5833px);
    margin-left: 32px;
    margin-right: 32px;
  }

  .a-grid__col--xs-8--col-gap-xs-8 {
    width: calc(66.6667% - 64.6667px);
    margin-left: 32px;
    margin-right: 32px;
  }

  .a-grid__col--xs-9--col-gap-xs-8 {
    width: calc(75% - 64.75px);
    margin-left: 32px;
    margin-right: 32px;
  }

  .a-grid__col--xs-10--col-gap-xs-8 {
    width: calc(83.3333% - 64.8333px);
    margin-left: 32px;
    margin-right: 32px;
  }

  .a-grid__col--xs-11--col-gap-xs-8 {
    width: calc(91.6667% - 64.9167px);
    margin-left: 32px;
    margin-right: 32px;
  }

  .a-grid__col--xs-12--col-gap-xs-8 {
    width: calc(100% - 65px);
    margin-left: 32px;
    margin-right: 32px;
  }

  .a-grid__col--xs-1--col-gap-xs-9 {
    width: calc(8.33333% - 72.0833px);
    margin-left: 36px;
    margin-right: 36px;
  }

  .a-grid__col--xs-2--col-gap-xs-9 {
    width: calc(16.6667% - 72.1667px);
    margin-left: 36px;
    margin-right: 36px;
  }

  .a-grid__col--xs-3--col-gap-xs-9 {
    width: calc(25% - 72.25px);
    margin-left: 36px;
    margin-right: 36px;
  }

  .a-grid__col--xs-4--col-gap-xs-9 {
    width: calc(33.3333% - 72.3333px);
    margin-left: 36px;
    margin-right: 36px;
  }

  .a-grid__col--xs-5--col-gap-xs-9 {
    width: calc(41.6667% - 72.4167px);
    margin-left: 36px;
    margin-right: 36px;
  }

  .a-grid__col--xs-6--col-gap-xs-9 {
    width: calc(50% - 72.5px);
    margin-left: 36px;
    margin-right: 36px;
  }

  .a-grid__col--xs-7--col-gap-xs-9 {
    width: calc(58.3333% - 72.5833px);
    margin-left: 36px;
    margin-right: 36px;
  }

  .a-grid__col--xs-8--col-gap-xs-9 {
    width: calc(66.6667% - 72.6667px);
    margin-left: 36px;
    margin-right: 36px;
  }

  .a-grid__col--xs-9--col-gap-xs-9 {
    width: calc(75% - 72.75px);
    margin-left: 36px;
    margin-right: 36px;
  }

  .a-grid__col--xs-10--col-gap-xs-9 {
    width: calc(83.3333% - 72.8333px);
    margin-left: 36px;
    margin-right: 36px;
  }

  .a-grid__col--xs-11--col-gap-xs-9 {
    width: calc(91.6667% - 72.9167px);
    margin-left: 36px;
    margin-right: 36px;
  }

  .a-grid__col--xs-12--col-gap-xs-9 {
    width: calc(100% - 73px);
    margin-left: 36px;
    margin-right: 36px;
  }

  .a-grid__col--xs-1--col-gap-xs-10 {
    width: calc(8.33333% - 80.0833px);
    margin-left: 40px;
    margin-right: 40px;
  }

  .a-grid__col--xs-2--col-gap-xs-10 {
    width: calc(16.6667% - 80.1667px);
    margin-left: 40px;
    margin-right: 40px;
  }

  .a-grid__col--xs-3--col-gap-xs-10 {
    width: calc(25% - 80.25px);
    margin-left: 40px;
    margin-right: 40px;
  }

  .a-grid__col--xs-4--col-gap-xs-10 {
    width: calc(33.3333% - 80.3333px);
    margin-left: 40px;
    margin-right: 40px;
  }

  .a-grid__col--xs-5--col-gap-xs-10 {
    width: calc(41.6667% - 80.4167px);
    margin-left: 40px;
    margin-right: 40px;
  }

  .a-grid__col--xs-6--col-gap-xs-10 {
    width: calc(50% - 80.5px);
    margin-left: 40px;
    margin-right: 40px;
  }

  .a-grid__col--xs-7--col-gap-xs-10 {
    width: calc(58.3333% - 80.5833px);
    margin-left: 40px;
    margin-right: 40px;
  }

  .a-grid__col--xs-8--col-gap-xs-10 {
    width: calc(66.6667% - 80.6667px);
    margin-left: 40px;
    margin-right: 40px;
  }

  .a-grid__col--xs-9--col-gap-xs-10 {
    width: calc(75% - 80.75px);
    margin-left: 40px;
    margin-right: 40px;
  }

  .a-grid__col--xs-10--col-gap-xs-10 {
    width: calc(83.3333% - 80.8333px);
    margin-left: 40px;
    margin-right: 40px;
  }

  .a-grid__col--xs-11--col-gap-xs-10 {
    width: calc(91.6667% - 80.9167px);
    margin-left: 40px;
    margin-right: 40px;
  }

  .a-grid__col--xs-12--col-gap-xs-10 {
    width: calc(100% - 81px);
    margin-left: 40px;
    margin-right: 40px;
  }
}

@media screen and (max-width: 380px) {
  .a-grid__col--xxs-1--col-gap-xxs-1 {
    width: calc(8.33333% - 8.08333px);
    margin-left: 4px;
    margin-right: 4px;
  }

  .a-grid__col--xxs-2--col-gap-xxs-1 {
    width: calc(16.6667% - 8.16667px);
    margin-left: 4px;
    margin-right: 4px;
  }

  .a-grid__col--xxs-3--col-gap-xxs-1 {
    width: calc(25% - 8.25px);
    margin-left: 4px;
    margin-right: 4px;
  }

  .a-grid__col--xxs-4--col-gap-xxs-1 {
    width: calc(33.3333% - 8.33333px);
    margin-left: 4px;
    margin-right: 4px;
  }

  .a-grid__col--xxs-5--col-gap-xxs-1 {
    width: calc(41.6667% - 8.41667px);
    margin-left: 4px;
    margin-right: 4px;
  }

  .a-grid__col--xxs-6--col-gap-xxs-1 {
    width: calc(50% - 8.5px);
    margin-left: 4px;
    margin-right: 4px;
  }

  .a-grid__col--xxs-7--col-gap-xxs-1 {
    width: calc(58.3333% - 8.58333px);
    margin-left: 4px;
    margin-right: 4px;
  }

  .a-grid__col--xxs-8--col-gap-xxs-1 {
    width: calc(66.6667% - 8.66667px);
    margin-left: 4px;
    margin-right: 4px;
  }

  .a-grid__col--xxs-9--col-gap-xxs-1 {
    width: calc(75% - 8.75px);
    margin-left: 4px;
    margin-right: 4px;
  }

  .a-grid__col--xxs-10--col-gap-xxs-1 {
    width: calc(83.3333% - 8.83333px);
    margin-left: 4px;
    margin-right: 4px;
  }

  .a-grid__col--xxs-11--col-gap-xxs-1 {
    width: calc(91.6667% - 8.91667px);
    margin-left: 4px;
    margin-right: 4px;
  }

  .a-grid__col--xxs-12--col-gap-xxs-1 {
    width: calc(100% - 9px);
    margin-left: 4px;
    margin-right: 4px;
  }

  .a-grid__col--xxs-1--col-gap-xxs-2 {
    width: calc(8.33333% - 16.0833px);
    margin-left: 8px;
    margin-right: 8px;
  }

  .a-grid__col--xxs-2--col-gap-xxs-2 {
    width: calc(16.6667% - 16.1667px);
    margin-left: 8px;
    margin-right: 8px;
  }

  .a-grid__col--xxs-3--col-gap-xxs-2 {
    width: calc(25% - 16.25px);
    margin-left: 8px;
    margin-right: 8px;
  }

  .a-grid__col--xxs-4--col-gap-xxs-2 {
    width: calc(33.3333% - 16.3333px);
    margin-left: 8px;
    margin-right: 8px;
  }

  .a-grid__col--xxs-5--col-gap-xxs-2 {
    width: calc(41.6667% - 16.4167px);
    margin-left: 8px;
    margin-right: 8px;
  }

  .a-grid__col--xxs-6--col-gap-xxs-2 {
    width: calc(50% - 16.5px);
    margin-left: 8px;
    margin-right: 8px;
  }

  .a-grid__col--xxs-7--col-gap-xxs-2 {
    width: calc(58.3333% - 16.5833px);
    margin-left: 8px;
    margin-right: 8px;
  }

  .a-grid__col--xxs-8--col-gap-xxs-2 {
    width: calc(66.6667% - 16.6667px);
    margin-left: 8px;
    margin-right: 8px;
  }

  .a-grid__col--xxs-9--col-gap-xxs-2 {
    width: calc(75% - 16.75px);
    margin-left: 8px;
    margin-right: 8px;
  }

  .a-grid__col--xxs-10--col-gap-xxs-2 {
    width: calc(83.3333% - 16.8333px);
    margin-left: 8px;
    margin-right: 8px;
  }

  .a-grid__col--xxs-11--col-gap-xxs-2 {
    width: calc(91.6667% - 16.9167px);
    margin-left: 8px;
    margin-right: 8px;
  }

  .a-grid__col--xxs-12--col-gap-xxs-2 {
    width: calc(100% - 17px);
    margin-left: 8px;
    margin-right: 8px;
  }

  .a-grid__col--xxs-1--col-gap-xxs-3 {
    width: calc(8.33333% - 24.0833px);
    margin-left: 12px;
    margin-right: 12px;
  }

  .a-grid__col--xxs-2--col-gap-xxs-3 {
    width: calc(16.6667% - 24.1667px);
    margin-left: 12px;
    margin-right: 12px;
  }

  .a-grid__col--xxs-3--col-gap-xxs-3 {
    width: calc(25% - 24.25px);
    margin-left: 12px;
    margin-right: 12px;
  }

  .a-grid__col--xxs-4--col-gap-xxs-3 {
    width: calc(33.3333% - 24.3333px);
    margin-left: 12px;
    margin-right: 12px;
  }

  .a-grid__col--xxs-5--col-gap-xxs-3 {
    width: calc(41.6667% - 24.4167px);
    margin-left: 12px;
    margin-right: 12px;
  }

  .a-grid__col--xxs-6--col-gap-xxs-3 {
    width: calc(50% - 24.5px);
    margin-left: 12px;
    margin-right: 12px;
  }

  .a-grid__col--xxs-7--col-gap-xxs-3 {
    width: calc(58.3333% - 24.5833px);
    margin-left: 12px;
    margin-right: 12px;
  }

  .a-grid__col--xxs-8--col-gap-xxs-3 {
    width: calc(66.6667% - 24.6667px);
    margin-left: 12px;
    margin-right: 12px;
  }

  .a-grid__col--xxs-9--col-gap-xxs-3 {
    width: calc(75% - 24.75px);
    margin-left: 12px;
    margin-right: 12px;
  }

  .a-grid__col--xxs-10--col-gap-xxs-3 {
    width: calc(83.3333% - 24.8333px);
    margin-left: 12px;
    margin-right: 12px;
  }

  .a-grid__col--xxs-11--col-gap-xxs-3 {
    width: calc(91.6667% - 24.9167px);
    margin-left: 12px;
    margin-right: 12px;
  }

  .a-grid__col--xxs-12--col-gap-xxs-3 {
    width: calc(100% - 25px);
    margin-left: 12px;
    margin-right: 12px;
  }

  .a-grid__col--xxs-1--col-gap-xxs-4 {
    width: calc(8.33333% - 32.0833px);
    margin-left: 16px;
    margin-right: 16px;
  }

  .a-grid__col--xxs-2--col-gap-xxs-4 {
    width: calc(16.6667% - 32.1667px);
    margin-left: 16px;
    margin-right: 16px;
  }

  .a-grid__col--xxs-3--col-gap-xxs-4 {
    width: calc(25% - 32.25px);
    margin-left: 16px;
    margin-right: 16px;
  }

  .a-grid__col--xxs-4--col-gap-xxs-4 {
    width: calc(33.3333% - 32.3333px);
    margin-left: 16px;
    margin-right: 16px;
  }

  .a-grid__col--xxs-5--col-gap-xxs-4 {
    width: calc(41.6667% - 32.4167px);
    margin-left: 16px;
    margin-right: 16px;
  }

  .a-grid__col--xxs-6--col-gap-xxs-4 {
    width: calc(50% - 32.5px);
    margin-left: 16px;
    margin-right: 16px;
  }

  .a-grid__col--xxs-7--col-gap-xxs-4 {
    width: calc(58.3333% - 32.5833px);
    margin-left: 16px;
    margin-right: 16px;
  }

  .a-grid__col--xxs-8--col-gap-xxs-4 {
    width: calc(66.6667% - 32.6667px);
    margin-left: 16px;
    margin-right: 16px;
  }

  .a-grid__col--xxs-9--col-gap-xxs-4 {
    width: calc(75% - 32.75px);
    margin-left: 16px;
    margin-right: 16px;
  }

  .a-grid__col--xxs-10--col-gap-xxs-4 {
    width: calc(83.3333% - 32.8333px);
    margin-left: 16px;
    margin-right: 16px;
  }

  .a-grid__col--xxs-11--col-gap-xxs-4 {
    width: calc(91.6667% - 32.9167px);
    margin-left: 16px;
    margin-right: 16px;
  }

  .a-grid__col--xxs-12--col-gap-xxs-4 {
    width: calc(100% - 33px);
    margin-left: 16px;
    margin-right: 16px;
  }

  .a-grid__col--xxs-1--col-gap-xxs-5 {
    width: calc(8.33333% - 40.0833px);
    margin-left: 20px;
    margin-right: 20px;
  }

  .a-grid__col--xxs-2--col-gap-xxs-5 {
    width: calc(16.6667% - 40.1667px);
    margin-left: 20px;
    margin-right: 20px;
  }

  .a-grid__col--xxs-3--col-gap-xxs-5 {
    width: calc(25% - 40.25px);
    margin-left: 20px;
    margin-right: 20px;
  }

  .a-grid__col--xxs-4--col-gap-xxs-5 {
    width: calc(33.3333% - 40.3333px);
    margin-left: 20px;
    margin-right: 20px;
  }

  .a-grid__col--xxs-5--col-gap-xxs-5 {
    width: calc(41.6667% - 40.4167px);
    margin-left: 20px;
    margin-right: 20px;
  }

  .a-grid__col--xxs-6--col-gap-xxs-5 {
    width: calc(50% - 40.5px);
    margin-left: 20px;
    margin-right: 20px;
  }

  .a-grid__col--xxs-7--col-gap-xxs-5 {
    width: calc(58.3333% - 40.5833px);
    margin-left: 20px;
    margin-right: 20px;
  }

  .a-grid__col--xxs-8--col-gap-xxs-5 {
    width: calc(66.6667% - 40.6667px);
    margin-left: 20px;
    margin-right: 20px;
  }

  .a-grid__col--xxs-9--col-gap-xxs-5 {
    width: calc(75% - 40.75px);
    margin-left: 20px;
    margin-right: 20px;
  }

  .a-grid__col--xxs-10--col-gap-xxs-5 {
    width: calc(83.3333% - 40.8333px);
    margin-left: 20px;
    margin-right: 20px;
  }

  .a-grid__col--xxs-11--col-gap-xxs-5 {
    width: calc(91.6667% - 40.9167px);
    margin-left: 20px;
    margin-right: 20px;
  }

  .a-grid__col--xxs-12--col-gap-xxs-5 {
    width: calc(100% - 41px);
    margin-left: 20px;
    margin-right: 20px;
  }

  .a-grid__col--xxs-1--col-gap-xxs-6 {
    width: calc(8.33333% - 48.0833px);
    margin-left: 24px;
    margin-right: 24px;
  }

  .a-grid__col--xxs-2--col-gap-xxs-6 {
    width: calc(16.6667% - 48.1667px);
    margin-left: 24px;
    margin-right: 24px;
  }

  .a-grid__col--xxs-3--col-gap-xxs-6 {
    width: calc(25% - 48.25px);
    margin-left: 24px;
    margin-right: 24px;
  }

  .a-grid__col--xxs-4--col-gap-xxs-6 {
    width: calc(33.3333% - 48.3333px);
    margin-left: 24px;
    margin-right: 24px;
  }

  .a-grid__col--xxs-5--col-gap-xxs-6 {
    width: calc(41.6667% - 48.4167px);
    margin-left: 24px;
    margin-right: 24px;
  }

  .a-grid__col--xxs-6--col-gap-xxs-6 {
    width: calc(50% - 48.5px);
    margin-left: 24px;
    margin-right: 24px;
  }

  .a-grid__col--xxs-7--col-gap-xxs-6 {
    width: calc(58.3333% - 48.5833px);
    margin-left: 24px;
    margin-right: 24px;
  }

  .a-grid__col--xxs-8--col-gap-xxs-6 {
    width: calc(66.6667% - 48.6667px);
    margin-left: 24px;
    margin-right: 24px;
  }

  .a-grid__col--xxs-9--col-gap-xxs-6 {
    width: calc(75% - 48.75px);
    margin-left: 24px;
    margin-right: 24px;
  }

  .a-grid__col--xxs-10--col-gap-xxs-6 {
    width: calc(83.3333% - 48.8333px);
    margin-left: 24px;
    margin-right: 24px;
  }

  .a-grid__col--xxs-11--col-gap-xxs-6 {
    width: calc(91.6667% - 48.9167px);
    margin-left: 24px;
    margin-right: 24px;
  }

  .a-grid__col--xxs-12--col-gap-xxs-6 {
    width: calc(100% - 49px);
    margin-left: 24px;
    margin-right: 24px;
  }

  .a-grid__col--xxs-1--col-gap-xxs-7 {
    width: calc(8.33333% - 56.0833px);
    margin-left: 28px;
    margin-right: 28px;
  }

  .a-grid__col--xxs-2--col-gap-xxs-7 {
    width: calc(16.6667% - 56.1667px);
    margin-left: 28px;
    margin-right: 28px;
  }

  .a-grid__col--xxs-3--col-gap-xxs-7 {
    width: calc(25% - 56.25px);
    margin-left: 28px;
    margin-right: 28px;
  }

  .a-grid__col--xxs-4--col-gap-xxs-7 {
    width: calc(33.3333% - 56.3333px);
    margin-left: 28px;
    margin-right: 28px;
  }

  .a-grid__col--xxs-5--col-gap-xxs-7 {
    width: calc(41.6667% - 56.4167px);
    margin-left: 28px;
    margin-right: 28px;
  }

  .a-grid__col--xxs-6--col-gap-xxs-7 {
    width: calc(50% - 56.5px);
    margin-left: 28px;
    margin-right: 28px;
  }

  .a-grid__col--xxs-7--col-gap-xxs-7 {
    width: calc(58.3333% - 56.5833px);
    margin-left: 28px;
    margin-right: 28px;
  }

  .a-grid__col--xxs-8--col-gap-xxs-7 {
    width: calc(66.6667% - 56.6667px);
    margin-left: 28px;
    margin-right: 28px;
  }

  .a-grid__col--xxs-9--col-gap-xxs-7 {
    width: calc(75% - 56.75px);
    margin-left: 28px;
    margin-right: 28px;
  }

  .a-grid__col--xxs-10--col-gap-xxs-7 {
    width: calc(83.3333% - 56.8333px);
    margin-left: 28px;
    margin-right: 28px;
  }

  .a-grid__col--xxs-11--col-gap-xxs-7 {
    width: calc(91.6667% - 56.9167px);
    margin-left: 28px;
    margin-right: 28px;
  }

  .a-grid__col--xxs-12--col-gap-xxs-7 {
    width: calc(100% - 57px);
    margin-left: 28px;
    margin-right: 28px;
  }

  .a-grid__col--xxs-1--col-gap-xxs-8 {
    width: calc(8.33333% - 64.0833px);
    margin-left: 32px;
    margin-right: 32px;
  }

  .a-grid__col--xxs-2--col-gap-xxs-8 {
    width: calc(16.6667% - 64.1667px);
    margin-left: 32px;
    margin-right: 32px;
  }

  .a-grid__col--xxs-3--col-gap-xxs-8 {
    width: calc(25% - 64.25px);
    margin-left: 32px;
    margin-right: 32px;
  }

  .a-grid__col--xxs-4--col-gap-xxs-8 {
    width: calc(33.3333% - 64.3333px);
    margin-left: 32px;
    margin-right: 32px;
  }

  .a-grid__col--xxs-5--col-gap-xxs-8 {
    width: calc(41.6667% - 64.4167px);
    margin-left: 32px;
    margin-right: 32px;
  }

  .a-grid__col--xxs-6--col-gap-xxs-8 {
    width: calc(50% - 64.5px);
    margin-left: 32px;
    margin-right: 32px;
  }

  .a-grid__col--xxs-7--col-gap-xxs-8 {
    width: calc(58.3333% - 64.5833px);
    margin-left: 32px;
    margin-right: 32px;
  }

  .a-grid__col--xxs-8--col-gap-xxs-8 {
    width: calc(66.6667% - 64.6667px);
    margin-left: 32px;
    margin-right: 32px;
  }

  .a-grid__col--xxs-9--col-gap-xxs-8 {
    width: calc(75% - 64.75px);
    margin-left: 32px;
    margin-right: 32px;
  }

  .a-grid__col--xxs-10--col-gap-xxs-8 {
    width: calc(83.3333% - 64.8333px);
    margin-left: 32px;
    margin-right: 32px;
  }

  .a-grid__col--xxs-11--col-gap-xxs-8 {
    width: calc(91.6667% - 64.9167px);
    margin-left: 32px;
    margin-right: 32px;
  }

  .a-grid__col--xxs-12--col-gap-xxs-8 {
    width: calc(100% - 65px);
    margin-left: 32px;
    margin-right: 32px;
  }

  .a-grid__col--xxs-1--col-gap-xxs-9 {
    width: calc(8.33333% - 72.0833px);
    margin-left: 36px;
    margin-right: 36px;
  }

  .a-grid__col--xxs-2--col-gap-xxs-9 {
    width: calc(16.6667% - 72.1667px);
    margin-left: 36px;
    margin-right: 36px;
  }

  .a-grid__col--xxs-3--col-gap-xxs-9 {
    width: calc(25% - 72.25px);
    margin-left: 36px;
    margin-right: 36px;
  }

  .a-grid__col--xxs-4--col-gap-xxs-9 {
    width: calc(33.3333% - 72.3333px);
    margin-left: 36px;
    margin-right: 36px;
  }

  .a-grid__col--xxs-5--col-gap-xxs-9 {
    width: calc(41.6667% - 72.4167px);
    margin-left: 36px;
    margin-right: 36px;
  }

  .a-grid__col--xxs-6--col-gap-xxs-9 {
    width: calc(50% - 72.5px);
    margin-left: 36px;
    margin-right: 36px;
  }

  .a-grid__col--xxs-7--col-gap-xxs-9 {
    width: calc(58.3333% - 72.5833px);
    margin-left: 36px;
    margin-right: 36px;
  }

  .a-grid__col--xxs-8--col-gap-xxs-9 {
    width: calc(66.6667% - 72.6667px);
    margin-left: 36px;
    margin-right: 36px;
  }

  .a-grid__col--xxs-9--col-gap-xxs-9 {
    width: calc(75% - 72.75px);
    margin-left: 36px;
    margin-right: 36px;
  }

  .a-grid__col--xxs-10--col-gap-xxs-9 {
    width: calc(83.3333% - 72.8333px);
    margin-left: 36px;
    margin-right: 36px;
  }

  .a-grid__col--xxs-11--col-gap-xxs-9 {
    width: calc(91.6667% - 72.9167px);
    margin-left: 36px;
    margin-right: 36px;
  }

  .a-grid__col--xxs-12--col-gap-xxs-9 {
    width: calc(100% - 73px);
    margin-left: 36px;
    margin-right: 36px;
  }

  .a-grid__col--xxs-1--col-gap-xxs-10 {
    width: calc(8.33333% - 80.0833px);
    margin-left: 40px;
    margin-right: 40px;
  }

  .a-grid__col--xxs-2--col-gap-xxs-10 {
    width: calc(16.6667% - 80.1667px);
    margin-left: 40px;
    margin-right: 40px;
  }

  .a-grid__col--xxs-3--col-gap-xxs-10 {
    width: calc(25% - 80.25px);
    margin-left: 40px;
    margin-right: 40px;
  }

  .a-grid__col--xxs-4--col-gap-xxs-10 {
    width: calc(33.3333% - 80.3333px);
    margin-left: 40px;
    margin-right: 40px;
  }

  .a-grid__col--xxs-5--col-gap-xxs-10 {
    width: calc(41.6667% - 80.4167px);
    margin-left: 40px;
    margin-right: 40px;
  }

  .a-grid__col--xxs-6--col-gap-xxs-10 {
    width: calc(50% - 80.5px);
    margin-left: 40px;
    margin-right: 40px;
  }

  .a-grid__col--xxs-7--col-gap-xxs-10 {
    width: calc(58.3333% - 80.5833px);
    margin-left: 40px;
    margin-right: 40px;
  }

  .a-grid__col--xxs-8--col-gap-xxs-10 {
    width: calc(66.6667% - 80.6667px);
    margin-left: 40px;
    margin-right: 40px;
  }

  .a-grid__col--xxs-9--col-gap-xxs-10 {
    width: calc(75% - 80.75px);
    margin-left: 40px;
    margin-right: 40px;
  }

  .a-grid__col--xxs-10--col-gap-xxs-10 {
    width: calc(83.3333% - 80.8333px);
    margin-left: 40px;
    margin-right: 40px;
  }

  .a-grid__col--xxs-11--col-gap-xxs-10 {
    width: calc(91.6667% - 80.9167px);
    margin-left: 40px;
    margin-right: 40px;
  }

  .a-grid__col--xxs-12--col-gap-xxs-10 {
    width: calc(100% - 81px);
    margin-left: 40px;
    margin-right: 40px;
  }

  .a-grid__col--offset-xxs-1 {
    margin-left: calc(8.33333% + 39.9167px);
  }

  .a-grid__col--offset-xxs-2 {
    margin-left: calc(16.6667% + 39.8333px);
  }

  .a-grid__col--offset-xxs-3 {
    margin-left: calc(25% + 39.75px);
  }

  .a-grid__col--offset-xxs-4 {
    margin-left: calc(33.3333% + 39.6667px);
  }

  .a-grid__col--offset-xxs-5 {
    margin-left: calc(41.6667% + 39.5833px);
  }

  .a-grid__col--offset-xxs-6 {
    margin-left: calc(50% + 39.5px);
  }

  .a-grid__col--offset-xxs-7 {
    margin-left: calc(58.3333% + 39.4167px);
  }

  .a-grid__col--offset-xxs-8 {
    margin-left: calc(66.6667% + 39.3333px);
  }

  .a-grid__col--offset-xxs-9 {
    margin-left: calc(75% + 39.25px);
  }

  .a-grid__col--offset-xxs-10 {
    margin-left: calc(83.3333% + 39.1667px);
  }

  .a-grid__col--offset-xxs-11 {
    margin-left: calc(91.6667% + 39.0833px);
  }

  .a-grid__col--offset-xxs-12 {
    margin-left: calc(100% + 39px);
  }
}

@media screen and (max-width: 440px) {
  .a-grid__col--offset-xs-1 {
    margin-left: calc(8.33333% + 39.9167px);
  }

  .a-grid__col--offset-xs-2 {
    margin-left: calc(16.6667% + 39.8333px);
  }

  .a-grid__col--offset-xs-3 {
    margin-left: calc(25% + 39.75px);
  }

  .a-grid__col--offset-xs-4 {
    margin-left: calc(33.3333% + 39.6667px);
  }

  .a-grid__col--offset-xs-5 {
    margin-left: calc(41.6667% + 39.5833px);
  }

  .a-grid__col--offset-xs-6 {
    margin-left: calc(50% + 39.5px);
  }

  .a-grid__col--offset-xs-7 {
    margin-left: calc(58.3333% + 39.4167px);
  }

  .a-grid__col--offset-xs-8 {
    margin-left: calc(66.6667% + 39.3333px);
  }

  .a-grid__col--offset-xs-9 {
    margin-left: calc(75% + 39.25px);
  }

  .a-grid__col--offset-xs-10 {
    margin-left: calc(83.3333% + 39.1667px);
  }

  .a-grid__col--offset-xs-11 {
    margin-left: calc(91.6667% + 39.0833px);
  }

  .a-grid__col--offset-xs-12 {
    margin-left: calc(100% + 39px);
  }
}

@media screen and (max-width: 780px) {
  .a-grid__col--offset-s-1 {
    margin-left: calc(8.33333% + 39.9167px);
  }

  .a-grid__col--offset-s-2 {
    margin-left: calc(16.6667% + 39.8333px);
  }

  .a-grid__col--offset-s-3 {
    margin-left: calc(25% + 39.75px);
  }

  .a-grid__col--offset-s-4 {
    margin-left: calc(33.3333% + 39.6667px);
  }

  .a-grid__col--offset-s-5 {
    margin-left: calc(41.6667% + 39.5833px);
  }

  .a-grid__col--offset-s-6 {
    margin-left: calc(50% + 39.5px);
  }

  .a-grid__col--offset-s-7 {
    margin-left: calc(58.3333% + 39.4167px);
  }

  .a-grid__col--offset-s-8 {
    margin-left: calc(66.6667% + 39.3333px);
  }

  .a-grid__col--offset-s-9 {
    margin-left: calc(75% + 39.25px);
  }

  .a-grid__col--offset-s-10 {
    margin-left: calc(83.3333% + 39.1667px);
  }

  .a-grid__col--offset-s-11 {
    margin-left: calc(91.6667% + 39.0833px);
  }

  .a-grid__col--offset-s-12 {
    margin-left: calc(100% + 39px);
  }
}

@media screen and (max-width: 1020px) {
  .a-grid__col--offset-m-1 {
    margin-left: calc(8.33333% + 39.9167px);
  }

  .a-grid__col--offset-m-2 {
    margin-left: calc(16.6667% + 39.8333px);
  }

  .a-grid__col--offset-m-3 {
    margin-left: calc(25% + 39.75px);
  }

  .a-grid__col--offset-m-4 {
    margin-left: calc(33.3333% + 39.6667px);
  }

  .a-grid__col--offset-m-5 {
    margin-left: calc(41.6667% + 39.5833px);
  }

  .a-grid__col--offset-m-6 {
    margin-left: calc(50% + 39.5px);
  }

  .a-grid__col--offset-m-7 {
    margin-left: calc(58.3333% + 39.4167px);
  }

  .a-grid__col--offset-m-8 {
    margin-left: calc(66.6667% + 39.3333px);
  }

  .a-grid__col--offset-m-9 {
    margin-left: calc(75% + 39.25px);
  }

  .a-grid__col--offset-m-10 {
    margin-left: calc(83.3333% + 39.1667px);
  }

  .a-grid__col--offset-m-11 {
    margin-left: calc(91.6667% + 39.0833px);
  }

  .a-grid__col--offset-m-12 {
    margin-left: calc(100% + 39px);
  }
}

@media screen and (max-width: 1280px) {
  .a-grid__col--offset-l-1 {
    margin-left: calc(8.33333% + 39.9167px);
  }

  .a-grid__col--offset-l-2 {
    margin-left: calc(16.6667% + 39.8333px);
  }

  .a-grid__col--offset-l-3 {
    margin-left: calc(25% + 39.75px);
  }

  .a-grid__col--offset-l-4 {
    margin-left: calc(33.3333% + 39.6667px);
  }

  .a-grid__col--offset-l-5 {
    margin-left: calc(41.6667% + 39.5833px);
  }

  .a-grid__col--offset-l-6 {
    margin-left: calc(50% + 39.5px);
  }

  .a-grid__col--offset-l-7 {
    margin-left: calc(58.3333% + 39.4167px);
  }

  .a-grid__col--offset-l-8 {
    margin-left: calc(66.6667% + 39.3333px);
  }

  .a-grid__col--offset-l-9 {
    margin-left: calc(75% + 39.25px);
  }

  .a-grid__col--offset-l-10 {
    margin-left: calc(83.3333% + 39.1667px);
  }

  .a-grid__col--offset-l-11 {
    margin-left: calc(91.6667% + 39.0833px);
  }

  .a-grid__col--offset-l-12 {
    margin-left: calc(100% + 39px);
  }
}

@media screen and (max-width: 1400px) {
  .a-grid__col--offset-xl-1 {
    margin-left: calc(8.33333% + 39.9167px);
  }

  .a-grid__col--offset-xl-2 {
    margin-left: calc(16.6667% + 39.8333px);
  }

  .a-grid__col--offset-xl-3 {
    margin-left: calc(25% + 39.75px);
  }

  .a-grid__col--offset-xl-4 {
    margin-left: calc(33.3333% + 39.6667px);
  }

  .a-grid__col--offset-xl-5 {
    margin-left: calc(41.6667% + 39.5833px);
  }

  .a-grid__col--offset-xl-6 {
    margin-left: calc(50% + 39.5px);
  }

  .a-grid__col--offset-xl-7 {
    margin-left: calc(58.3333% + 39.4167px);
  }

  .a-grid__col--offset-xl-8 {
    margin-left: calc(66.6667% + 39.3333px);
  }

  .a-grid__col--offset-xl-9 {
    margin-left: calc(75% + 39.25px);
  }

  .a-grid__col--offset-xl-10 {
    margin-left: calc(83.3333% + 39.1667px);
  }

  .a-grid__col--offset-xl-11 {
    margin-left: calc(91.6667% + 39.0833px);
  }

  .a-grid__col--offset-xl-12 {
    margin-left: calc(100% + 39px);
  }
}

.a-grid__col--row-gap-1 {
  margin-bottom: 8px;
}

.a-grid__col--row-gap-2 {
  margin-bottom: 16px;
}

.a-grid__col--row-gap-3 {
  margin-bottom: 24px;
}

.a-grid__col--row-gap-4 {
  margin-bottom: 32px;
}

.a-grid__col--row-gap-5 {
  margin-bottom: 40px;
}

.a-grid__col--row-gap-6 {
  margin-bottom: 48px;
}

.a-grid__col--row-gap-7 {
  margin-bottom: 56px;
}

.a-grid__col--row-gap-8 {
  margin-bottom: 64px;
}

.a-grid__col--row-gap-9 {
  margin-bottom: 72px;
}

.a-grid__col--row-gap-10 {
  margin-bottom: 80px;
}
