.queue-search-panel {
  background-color: #f3f4f6;
  width: 100%;
  font-size: 14px;
  overflow-x: scroll;
  box-shadow: 1px 0 #d1d5db;
}

.queue-search-panel__facet {
  width: 100%;
}

.queue-search-panel__section {
  padding: 1em;
}

.queue-search-panel__section .queue-search-panel__facet {
  margin: .75em .25em;
}

.queue-search-panel__section--highlight {
  background-color: #fff;
}

.queue-search-panel__section--actions {
  align-content: center;
  margin-top: 1em;
  display: flex;
}

.queue-search-panel__sort-by {
  margin-top: 8px;
}
