:root {
  --input-border-color: var(--border-primary);
  --input-border-color--hover: var(--border-bold);
  --input-border-color--focus: var(--border-selected);
  --input-text-color: var(--content-primary);
  --input-text-color-placeholder: var(--content-tertiary);
  --input-background-color: var(--background-primary);
  --input-background-color--hover: var(--background-primary);
  --input-background-color--focus: var(--background-primary);
  --input-padding: var(--space-x1-quarter) var(--space-x1-half);
  --input-border-radius: var(--space-x1);
  --input-font-size: 16px;
  --input-line-height: 1.5;
}

.a-input {
  -webkit-appearance: none;
  -moz-appearance: none;
  background-color: var(--input-background-color);
  border: 1px solid var(--input-border-color);
  border-radius: var(--input-border-radius);
  color: var(--input-text-color);
  font-size: var(--input-font-size);
  line-height: var(--input-line-height);
  padding: var(--input-padding);
  outline: 1px solid rgba(0, 0, 0, 0);
  width: 100%;
}

.a-input.a-input--hover, .a-input.a-input--focus, .a-input.a-input--success, .a-input.a-input--error, .a-input:invalid {
  box-shadow: 0 0 0 1px var(--input-border-color);
}

.a-input.a-input--success {
  --input-border-color: var(--border-positive);
  --input-border-color--hover: var(--border-positive-bold);
}

.a-input.a-input--error, .a-input:invalid {
  --input-border-color: var(--border-negative);
  --input-border-color--hover: var(--border-negative-bold);
}

.a-input.a-input--hover {
  --input-border-color: var(--input-border-color--hover);
  background-color: var(--input-background-color--hover);
}

.a-input.a-input--focus {
  --input-border-color: var(--input-border-color--focus);
  background-color: var(--input-background-color--focus);
}

.a-input.a-input--read-only {
  --input-background-color: var(--background-subtle);
  cursor: default;
}

.a-input.a-input--read-only.a-input--hover {
  --input-background-color--hover: var(--background-subtle);
  background-color: var(--input-background-color--hover);
}

.a-input.a-input--read-only.a-input--focus {
  --input-background-color--focus: var(--background-subtle);
  background-color: var(--input-background-color--focus);
}

.a-input::-moz-focus-inner {
  border: 0;
}

.a-input::placeholder {
  color: var(--input-text-color-placeholder);
}

.a-input:disabled {
  --input-text-color: var(--content-tertiary);
  cursor: not-allowed;
}

.a-input.a-input--s {
  --input-padding: 6px 8px;
  --input-border-radius: 6px;
}
