.o-date-picker__input {
  border: none;
  width: auto;
  max-width: 136px;
  margin: 0;
  font-family: Lato;
  line-height: 24px;
}

.o-date-picker__input:focus {
  outline: none;
  box-shadow: 0 0 0 2px #a8d4f0;
}

.o-date-picker__input:after {
  color: inherit;
  content: "";
  padding: 0 5px;
  font-family: "Font Awesome 6 Pro";
  font-weight: 400;
}

.o-date-picker__input::placeholder {
  color: inherit;
}

.o-date-picker__input::-webkit-calendar-picker-indicator {
  color: rgba(0, 0, 0, 0);
  z-index: 1;
  background: none;
  width: 100%;
  max-width: 32px;
  height: auto;
  margin-inline-start: 0;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
}

.o-date-picker__input::-webkit-inner-spin-button {
  z-index: 1;
}

.o-date-picker__input::-webkit-clear-button {
  z-index: 1;
}

@media (max-width: 780px) {
  .o-date-picker__input {
    width: 100%;
    max-width: none;
  }
}

date-input-polyfill {
  z-index: 9999 !important;
}
