.report-preview {
  background-color: var(--background-subtle);
  min-height: 100vh;
}

.report-preview__image {
  box-shadow: var(--elevation-shadow-l3);
  border-radius: 16px;
  align-self: center;
  width: 100%;
  max-width: 820px;
}

.report-preview__secondary-text {
  color: var(--content-secondary);
}
