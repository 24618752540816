.age-screener, .age-screener__input-wrapper {
  text-align: center;
  flex-direction: column;
}

.age-screener__input-wrapper {
  margin-top: 16px;
}

.age-screener__input {
  margin-bottom: 12px;
}

.age-screener__input, .age-screener__button {
  width: 100%;
}
