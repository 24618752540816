.m-dropdown-item {
  background: var(--elevation-background-l3);
  cursor: pointer;
  padding: var(--space-x2);
  text-align: start;
  justify-content: space-between;
  font-size: 1rem;
  text-decoration: none;
  display: flex;
}

.m-dropdown-item.m-dropdown-item--with-checkbox {
  cursor: default;
}

.m-dropdown-item.m-dropdown-item--disabled {
  cursor: not-allowed;
}

.m-dropdown-item:hover:not(.m-dropdown-item--with-checkbox):not(.m-dropdown-item--disabled) {
  background: var(--background-primary-hover);
}

.m-dropdown-item:active:not(.m-dropdown-item--with-checkbox):not(.m-dropdown-item--disabled) {
  background: var(--background-primary-pressed);
}

.m-dropdown-item:focus {
  outline-offset: -3px;
  transition: all var(--duration-fast);
  outline: 3px solid #50a9e2;
}

.m-dropdown-item.m-dropdown-item--selected:not(.m-dropdown-item--with-checkbox) .m-dropdown-item__icon, .m-dropdown-item.m-dropdown-item--selected:not(.m-dropdown-item--with-checkbox) .m-dropdown-item__main-text--black {
  color: var(--content-brand);
}

.m-dropdown-item .m-dropdown-item__icon {
  line-height: 20px;
}

.m-dropdown-item__checkbox {
  margin-right: 16px;
}

.m-dropdown-item--as-button {
  border: none;
  width: 100%;
}

.m-dropdown-item--with-bottom-border {
  border-bottom: 1px solid var(--border-primary);
}

.m-dropdown-item__label-wrapper {
  flex-grow: 1;
}

.m-dropdown-item__label-wrapper--with-icon {
  width: calc(100% - var(--space-x2));
  margin-right: 0;
}

.m-dropdown-item__checkbox-margin {
  margin-top: 5px;
}
