.language-toggle {
  background-color: var(--background-bold);
  border-radius: var(--space-x2);
  padding: var(--space-x0-half);
  align-items: center;
  gap: var(--space-x0-half);
  width: fit-content;
  display: flex;
}

.language-toggle__button {
  border-radius: var(--space-x1-half);
  align-items: center;
  display: flex;
}

.language-toggle__dirty-indicator {
  align-self: flex-start;
}
