.question-wrapper [inert] {
  filter: grayscale();
}

.practice-view--mobile .question-wrapper {
  border: unset;
  border-radius: unset;
}

.question-wrapper__body {
  margin: 0 auto;
}
