.standards-table {
  margin-bottom: 32px;
}

.standards-table .o-table__header, .standards-table .o-table__cell {
  width: 25%;
  min-width: 25%;
  word-break: initial;
}

.standards-table .o-table__header:last-child, .standards-table .o-table__cell:last-child {
  width: 50%;
  min-width: 50%;
}

.standards-table .o-table__cell .o-table__cell-text {
  display: initial;
  max-width: 100%;
  overflow: initial;
  -webkit-box-orient: initial;
  -webkit-line-clamp: initial;
}

.standards-table .markdown-renderer-v2 {
  line-height: 1.4;
}

.standards-table .paragraph {
  text-overflow: ellipsis;
  overflow: hidden;
}
