.a-badge {
  border-radius: var(--space-x10);
  cursor: default;
  font-size: var(--text-s);
  padding: var(--space-x0-half) var(--space-x1);
  text-align: center;
  align-items: center;
  gap: var(--space-x1);
  flex-direction: row;
  display: inline-flex;
}

.a-badge--clickable {
  cursor: pointer;
  border: none;
}

.a-badge--status-info {
  background: var(--background-info);
  color: var(--content-primary-inverse);
}

.a-badge--status-info.a-badge--clickable:hover {
  background: var(--background-info-hover);
}

.a-badge--status-info.a-badge--clickable:active {
  background: var(--background-info-pressed);
}

.a-badge--status-info--subtle, .a-badge--status-info--default {
  background: var(--background-info-subtle);
  color: var(--content-info);
}

.a-badge--status-info--subtle.a-badge--clickable:hover, .a-badge--status-info--default.a-badge--clickable:hover {
  background: var(--background-info-subtle-hover);
}

.a-badge--status-info--subtle.a-badge--clickable:active, .a-badge--status-info--default.a-badge--clickable:active {
  background: var(--background-info-subtle-pressed);
}

.a-badge--status-info--default {
  border: 1px solid var(--border-info);
}

.a-badge--status-positive {
  background: var(--background-positive);
  color: var(--content-primary-inverse);
}

.a-badge--status-positive.a-badge--clickable:hover {
  background: var(--background-positive-hover);
}

.a-badge--status-positive.a-badge--clickable:active {
  background: var(--background-positive-pressed);
}

.a-badge--status-positive--subtle, .a-badge--status-positive--default {
  background: var(--background-positive-subtle);
  color: var(--content-positive);
}

.a-badge--status-positive--subtle.a-badge--clickable:hover, .a-badge--status-positive--default.a-badge--clickable:hover {
  background: var(--background-positive-subtle-hover);
}

.a-badge--status-positive--subtle.a-badge--clickable:active, .a-badge--status-positive--default.a-badge--clickable:active {
  background: var(--background-positive-subtle-pressed);
}

.a-badge--status-positive--default {
  border: 1px solid var(--border-positive);
}

.a-badge--status-negative {
  background: var(--background-negative);
  color: var(--content-primary-inverse);
}

.a-badge--status-negative.a-badge--clickable:hover {
  background: var(--background-negative-hover);
}

.a-badge--status-negative.a-badge--clickable:active {
  background: var(--background-negative-pressed);
}

.a-badge--status-negative--subtle, .a-badge--status-negative--default {
  background: var(--background-negative-subtle);
  color: var(--content-negative);
}

.a-badge--status-negative--subtle.a-badge--clickable:hover, .a-badge--status-negative--default.a-badge--clickable:hover {
  background: var(--background-negative-subtle-hover);
}

.a-badge--status-negative--subtle.a-badge--clickable:active, .a-badge--status-negative--default.a-badge--clickable:active {
  background: var(--background-negative-subtle-pressed);
}

.a-badge--status-negative--default {
  border: 1px solid var(--border-negative);
}

.a-badge--status-warning {
  background: var(--background-warning);
  color: var(--content-primary-inverse);
}

.a-badge--status-warning.a-badge--clickable:hover {
  background: var(--background-warning-hover);
}

.a-badge--status-warning.a-badge--clickable:active {
  background: var(--background-warning-pressed);
}

.a-badge--status-warning--subtle, .a-badge--status-warning--default {
  background: var(--background-warning-subtle);
  color: var(--content-warning);
}

.a-badge--status-warning--subtle.a-badge--clickable:hover, .a-badge--status-warning--default.a-badge--clickable:hover {
  background: var(--background-warning-subtle-hover);
}

.a-badge--status-warning--subtle.a-badge--clickable:active, .a-badge--status-warning--default.a-badge--clickable:active {
  background: var(--background-warning-subtle-pressed);
}

.a-badge--status-warning--default {
  border: 1px solid var(--border-warning);
}

.a-badge--status-neutral {
  background: var(--background-bold);
  color: var(--content-primary);
}

.a-badge--status-neutral.a-badge--clickable:hover {
  background: var(--background-bold-hover);
}

.a-badge--status-neutral.a-badge--clickable:active {
  background: var(--background-bold-pressed);
}

.a-badge--status-neutral--subtle, .a-badge--status-neutral--default {
  background: var(--background-subtle);
  color: var(--content-secondary);
}

.a-badge--status-neutral--subtle.a-badge--clickable:hover, .a-badge--status-neutral--default.a-badge--clickable:hover {
  background: var(--background-subtle-hover);
}

.a-badge--status-neutral--subtle.a-badge--clickable:active, .a-badge--status-neutral--default.a-badge--clickable:active {
  background: var(--background-subtle-pressed);
}

.a-badge--status-neutral--default {
  border: 1px solid var(--border-bold);
}

.a-badge__icon {
  font-size: 13px;
}

.a-badge--icon-right {
  flex-direction: row-reverse;
}

.a-badge--size-small {
  font-size: var(--text-xs);
  padding: var(--space-x0-quarter) calc(var(--space-x0-half)  + var(--space-x0-quarter));
}

.a-badge--size-large {
  font-size: var(--text-m);
  padding: var(--space-x1) var(--space-x1-half);
}
