.drawer__shade {
  opacity: 1;
  pointer-events: auto;
  will-change: opacity;
  z-index: 9000;
  background: rgba(0, 0, 0, .6);
  width: 100%;
  height: 100%;
  transition: left cubic-bezier(.23, 1, .32, 1), opacity .3s cubic-bezier(.23, 1, .32, 1);
  position: fixed;
  top: 0;
  left: 0;
  transform: translateZ(0);
}

.drawer__shade--hidden {
  opacity: 0;
  pointer-events: none;
  transition: left 0s cubic-bezier(.23, 1, .32, 1) .3s, opacity .3s cubic-bezier(.23, 1, .32, 1);
  left: -100%;
}

.drawer {
  z-index: 9999;
  background: #fff;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  flex-flow: column;
  flex: 0 auto;
  justify-content: flex-start;
  align-items: stretch;
  width: 30em;
  max-width: 80%;
  height: 100%;
  transition: transform .3s cubic-bezier(.23, 1, .32, 1);
  display: flex;
  position: fixed;
  top: 0;
  right: 0;
  overflow: auto;
  transform: translate3d(0, 0, 0);
  box-shadow: 0 3px 10px rgba(0, 0, 0, .157), 0 3px 10px rgba(0, 0, 0, .227);
}

@media (max-width: 600px) {
  .drawer {
    width: 100%;
  }
}

.drawer--hidden {
  transform: translate3d(100vw, 0, 0);
}

.drawer-header {
  color: #374151;
  background: #f3f4f6;
  justify-content: space-between;
  min-height: 3.75em;
  padding: 1.25em 3.5em 1.25em 1.25em;
  font-size: 1.05rem;
  display: flex;
  position: relative;
}

.drawer-header__title {
  flex-flow: wrap;
  align-items: center;
  padding: 0 .5em 0 0;
  font-weight: bold;
  display: flex;
}

.drawer-header__close-btn {
  color: #d1d5db;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 1.1539em;
  font-size: 1.1375rem;
  display: flex;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
}

.drawer-header__close-btn:hover, .drawer-header__close-btn:focus {
  color: #374151;
}

.drawer-footer {
  background-color: #fff;
  padding: 0 1em;
  display: flex;
  position: relative;
}

.drawer-footer__close-btn {
  -webkit-appearance: none;
  -moz-appearance: none;
  color: #fff;
  cursor: pointer;
  background-color: #50a9e2;
  border: 0;
  border-radius: .15em;
  width: 100%;
  margin: 0 0 1em;
  padding: .85em 1em .8em;
  font-size: 1em;
  font-weight: bold;
  text-decoration: none;
  transition: box-shadow .1s ease-in-out, transform .1s ease-in-out;
}

.drawer-footer__close-btn::selection {
  background-color: rgba(0, 0, 0, 0);
}

.drawer-footer__close-btn ::selection {
  background-color: rgba(0, 0, 0, 0);
}

.drawer-footer__close-btn.disabled, .drawer-footer__close-btn.disabled:hover, .drawer-footer__close-btn:disabled, .drawer-footer__close-btn:disabled:hover {
  color: #d1d5db;
  background-color: #6b7280;
}

.drawer-footer__close-btn:not(:disabled):not(:active):hover {
  transform: translateY(-1px)scale(1.01);
  box-shadow: 0 2px 9px rgba(0, 0, 0, .2);
}

.drawer-footer__disclaimer {
  color: #374151;
}

.drawer-content {
  color: #374151;
  background: #fff;
  flex-flow: column;
  flex-grow: 1;
  justify-content: flex-start;
  align-items: stretch;
  width: 100%;
  padding: 1em;
  display: flex;
  position: relative;
  overflow-y: auto;
}

.a-body--drawer-active {
  overflow: hidden;
}

.drawer--dark-scheme .drawer-header {
  background-color: #fff;
}

.drawer--dark-scheme .drawer-content, .drawer--dark-scheme .drawer-footer {
  background-color: #f3f4f6;
}

.drawer--blue-scheme {
  background-color: #1a699e;
}

.drawer--blue-scheme .drawer-header {
  background-color: #1e7cb8;
}

.drawer--blue-scheme .drawer-content, .drawer--blue-scheme .drawer-footer {
  background-color: #1a699e;
}

.drawer-wrapper--left .drawer-header__title {
  color: #fff;
}

.drawer-wrapper--left .drawer {
  left: 0;
}

.drawer-wrapper--left .drawer-content {
  padding: 0;
}

.drawer-wrapper--left .drawer-header__close-btn {
  color: rgba(0, 0, 0, .3);
}

.drawer-wrapper--left .drawer__shade {
  transition: right cubic-bezier(.23, 1, .32, 1), opacity .3s cubic-bezier(.23, 1, .32, 1);
}

.drawer-wrapper--left .drawer--hidden {
  transform: translate3d(-100vw, 0, 0);
}

.drawer-wrapper--left .drawer__shade--hidden {
  transition: right 0s cubic-bezier(.23, 1, .32, 1) .3s, opacity .3s cubic-bezier(.23, 1, .32, 1);
}
