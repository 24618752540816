.o-shelf {
  -webkit-overflow-scrolling: touch;
  border-bottom: 1px solid rgba(0, 0, 0, 0);
  overflow: auto;
}

.o-shelf--border-regular {
  border-color: #e5e7eb;
}

.o-shelf--border-darker {
  border-color: #d1d5db;
}

.o-shelf--bg-white {
  background-color: #fff;
}

.o-shelf--bg-page {
  background-color: #f9fafb;
}

.o-shelf--curve-offset-xl {
  margin-top: -40px;
  padding-top: 40px;
}

.o-shelf--curve-offset-l {
  margin-top: -30px;
  padding-top: 30px;
}

.o-shelf--curve-offset-m {
  margin-top: -20px;
  padding-top: 20px;
}

.o-shelf--curve-offset-s {
  margin-top: -10px;
  padding-top: 10px;
}

.o-shelf--curve-offset-xs {
  margin-top: -8px;
  padding-top: 8px;
}
