.consent-option-button, .consent-option-back-button, .consent-option-action-button {
  width: 100%;
}

.consent-option-back-button span {
  margin-right: 10px;
}

.consent-option-copy {
  text-align: center;
}
