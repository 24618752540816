.file-input {
  border-style: dashed;
  border-width: 1px;
  border-radius: 8px;
}

.file-input:focus-within {
  outline: 3px solid #50a9e2;
}

.file-input:hover {
  color: #155884;
  background-color: #d3eaf8;
  border-style: solid;
  border-color: #50a9e2;
}

.file-input:active {
  color: #104060;
  background-color: #a8d4f0;
  border-style: solid;
  border-color: #1e7cb8;
}

.file-input--error:hover {
  color: #9c251c;
  background-color: #f7d7d4;
  border-color: #dc4538;
}

.file-input--error:active {
  color: #821f17;
  background-color: #eb968e;
  border-color: #cc3224;
}

.file-input--warning:hover {
  color: #b45309;
  background-color: #fef3c7;
  border-color: #b45309;
}

.file-input__icon {
  flex-grow: 0;
  flex-basis: 16px;
  font-size: 24px;
}

.file-input__input {
  opacity: 0;
  width: 100%;
  height: 100%;
  position: absolute;
}
