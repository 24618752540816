.o-table__header {
  flex: 120px;
  align-items: center;
  min-width: 120px;
  padding: 8px 16px;
  font-size: 15px;
  line-height: 24px;
}

.o-table__header:not(.o-table__header--no-clamp) {
  white-space: nowrap;
}

.o-table__header--left {
  justify-content: flex-start;
}

.o-table__header--center {
  justify-content: center;
}

.o-table__header--right {
  justify-content: flex-end;
}

.o-table__header-sort {
  margin-left: 4px;
}

.o-table__selection-header {
  flex: 0 auto;
  min-width: auto;
  padding-right: 0;
}

.o-table__action-menu-header {
  min-width: auto;
  max-width: 56px;
}

.o-table__header-text {
  text-overflow: ellipsis;
  overflow: hidden;
}

.o-table--data .o-table__header {
  border-bottom: 1px solid #d1d5db;
  padding: 8px 12px;
}

.o-table--data .o-table__header:not(:last-child) {
  border-right: 1px solid #d1d5db;
}

.o-table--basic .o-table__header {
  border-bottom: 1px solid #d1d5db;
  border-right: 1px solid #d1d5db;
}

.o-table--basic .o-table__header:last-child {
  border-right: none;
}
