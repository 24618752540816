.question-tracker-modal .modal__content {
  padding-left: 0;
  padding-right: 0;
}

.question-tracker-modal .modal__content .simple-modal__close-btn {
  z-index: 1;
  height: 0;
  padding-top: 30px;
  padding-right: 30px;
  bottom: auto;
}

.question-tracker-modal .modal__content .unbutton {
  background-color: inherit;
  color: #9ca3af;
  font-weight: 400;
}

.question-tracker-modal .modal__content button.unbutton {
  padding: inherit;
}

.question-tracker-modal .modal__content .sg-tab--active.unbutton {
  color: #50a9e2;
}

.question-tracker-modal .simple-modal__body {
  padding-left: 0;
  padding-right: 0;
}

.question-tracker-modal .sg-tabs {
  border-bottom: 1px solid #e2e4e6;
  padding-left: 15px;
}

.question-tracker-modal .sg-tab {
  border: none;
}

.question-tracker-modal .sg-table__tbody .sg-table__tr {
  background-color: #fff;
}

.question-tracker-modal .sg-table__th {
  display: none;
}

.question-tracker-modal .sg-table__td {
  border-right: none;
}

.question-tracker-modal .sg-table__td:last-of-type {
  text-align: right;
}

@media (max-width: 600px) {
  .question-tracker-modal .sg-table__td:last-of-type {
    text-align: left;
  }
}

.question-tracker-modal__header {
  padding-left: 20px;
  padding-right: 20px;
}

.question-tracker-modal__header-name {
  font-size: 1.6em;
  font-weight: 700;
}

.question-tracker-modal__header-description {
  color: #6b7280;
  margin-top: 10px;
  margin-bottom: 20px;
}

.question-tracker-modal__bottom-close-button-wrapper {
  justify-content: flex-end;
  padding: 60px 20px 10px;
  display: flex;
}

@media (max-width: 600px) {
  .question-tracker-modal__bottom-close-button {
    flex-grow: 1;
  }
}

.question-association-details__name {
  font-weight: 700;
}

.question-association-details__classroom-content {
  color: #6b7280;
}

.question-tracker-modal__names-content-divider {
  margin-left: 7px;
  margin-right: 7px;
}
