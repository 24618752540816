:export {
  titleBarHeight: 80px;
  mobileToolbarHeight: 53px;
}

.practice-view-spinner-wrapper {
  justify-content: center;
  align-items: center;
  height: calc(100vh - 140px);
  font-size: 32px;
  display: flex;
}
