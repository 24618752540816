:root {
  --reports-table-border: 1px solid #d1d5db;
}

.reports-table__wrapper {
  -webkit-overflow-scrolling: touch;
  scroll-behavior: smooth;
  border-top: none;
  width: fit-content;
  min-width: 100%;
  max-width: 100%;
  overflow: auto hidden;
}

.reports-table__table {
  border-spacing: 0;
  min-width: 100%;
}

.reports-table__table tr:first-child th {
  border-top: var(--reports-table-border);
}

.reports-table__table tr th:first-child, .reports-table__table td:first-child {
  border-left: none;
}

.reports-table__table tr:last-child td {
  border-bottom: none;
}

.reports-table__table tr:last-child td:first-child {
  border-bottom-left-radius: 8px;
}

.reports-table__table tr th {
  border-top: var(--reports-table-border);
  text-align: left;
  z-index: 11;
  position: sticky;
}

.reports-table__table tr th:first-child {
  border-top-left-radius: 8px;
}

.reports-table__table tr th:last-child {
  border-top-right-radius: 8px;
}

.reports-table__table tr th:first-child:before {
  content: "";
  background: none;
  border-top-left-radius: 8px;
  width: 20px;
  height: 20px;
  display: block;
  position: absolute;
  top: -1px;
  left: -1px;
  overflow: hidden;
  box-shadow: -2px -2px #fff;
}

.reports-table__table tr th:last-child:after {
  content: "";
  background: none;
  border-top-right-radius: 8px;
  width: 20px;
  height: 20px;
  display: block;
  position: absolute;
  top: -1px;
  right: -1px;
  overflow: hidden;
  box-shadow: 2px -2px #fff;
}

.reports-table__table tr th:not(:last-child) {
  border-right: var(--reports-table-border);
}

.reports-table__table tr th:first-child {
  z-index: 14;
  left: 0;
}

.reports-table__table tr:last-child th {
  border-bottom: var(--reports-table-border);
}

.reports-table__table tr td:first-child {
  z-index: 11;
  min-width: 200px;
  position: sticky;
  left: 0;
}

.reports-table__table tr:hover td {
  background-color: #f9fafb;
}

.reports-table__table tr:not(:hover) .reports-table__column-button {
  opacity: 0;
}

.reports-table__table .reports-table__drilldown-button {
  transform: rotate(45deg);
}

.reports-table__table tr:not(:hover) td:first-child {
  background-color: #fff;
}

.reports-table__table tr td {
  border-bottom: var(--reports-table-border);
  min-width: 130px;
}

.reports-table__table tr td:not(:last-child) {
  border-right: var(--reports-table-border);
}

.reports-table__table th, .reports-table__table td {
  padding: 8px 12px;
}

.reports-table__table th {
  background-color: #fff;
  top: 0;
}

.report-table__thead {
  z-index: 12;
}

.reports-table__header {
  font-size: 15px;
}

.reports-table__cell {
  font-size: 14px;
}

.reports-table__show-rows {
  flex-grow: 0;
}

.reports-table__show-rows .m-field__label {
  font-weight: normal;
}
