.parental-consent, .parental-consent__input-wrapper {
  text-align: center;
  flex-direction: column;
}

.parental-consent__input-wrapper {
  margin-top: 16px;
}

.parental-consent__input {
  margin-bottom: 12px;
}

.parental-consent__input, .parental-consent__button {
  width: 100%;
}
