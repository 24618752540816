.folders-modal {
  min-width: 450px;
  max-width: 550px;
}

.folders-modal-empty-state {
  flex-direction: column;
  align-items: center;
  padding: 32px;
  display: flex;
}

.folders-modal__header {
  border-bottom: 1px solid #e5e7eb;
}

.folders-list-group__container {
  max-height: 50vh;
  padding: 0;
  overflow: auto;
}

.folders-list-group__item {
  align-items: center;
  display: flex;
}

.folders-list-group__item:last-child {
  border-bottom: none;
}

.folders-list-group__item-title {
  flex-direction: column;
  margin-left: 16px;
  display: flex;
  position: relative;
  bottom: 2px;
}

.folders-list-group__time-string {
  white-space: nowrap;
  margin: auto 0 auto 16px;
}

.folders-list-group__item-text {
  justify-content: space-between;
  width: 100%;
  display: flex;
}

.folders-modal__new-folder-modal {
  width: 450px;
}

.folders-modal__save-button {
  margin-left: 4px;
}

@media (max-width: 440px) {
  .folders-modal {
    min-width: 0;
  }

  .folders-list-group__container {
    max-height: none;
  }

  .folders-list-group__item-text {
    flex-direction: column;
    display: flex;
  }

  .folders-modal__new-folder-modal {
    max-width: 100%;
    height: auto;
  }
}
