.sso-button {
  flex-flow: row;
  flex: auto;
  justify-content: center;
  align-items: center;
  display: flex;
}

.sso-button__logo {
  width: 1em;
  height: 1em;
  margin: 0 .5em 0 0;
}
