.subheader {
  color: #fff;
  z-index: 100;
  background-color: #1e7cb8;
  flex-flow: row;
  justify-content: center;
  width: 100%;
  height: 60px;
  padding: 0 1em;
  display: flex;
  position: fixed;
  top: 60px;
  left: 0;
}

.subheader:empty:before {
  content: "​";
  padding: .5em 0;
  display: block;
}

.subheader a {
  color: #fff;
  text-decoration: none;
}

.subheader__wrapper {
  color: #fff;
  flex-flow: row;
  justify-content: space-between;
  width: 100%;
  max-width: 2880px;
  display: flex;
}

.subheader__content, .subheader__custom-content-wrapper {
  align-items: center;
  font-size: .875rem;
  font-weight: bold;
  display: flex;
}

.subheader__content .flag {
  margin-top: 0;
  margin-bottom: 0;
}

.subheader__title {
  margin: 0 .5em 0 0;
  font-size: 1rem;
}

.subheader__links {
  display: flex;
}

.subheader__links::selection {
  background-color: rgba(0, 0, 0, 0);
}

.subheader__links ::selection {
  background-color: rgba(0, 0, 0, 0);
}

@media (max-width: 600px) {
  .subheader__links {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
}

.subheader__links-link {
  cursor: pointer;
  text-transform: capitalize;
  white-space: nowrap;
  background-color: #1e7cb8;
  border-radius: 32px;
  padding: .5em 1em;
  font-size: .875rem;
  font-weight: bold;
  transition: background .2s;
  display: inline-block;
}

.subheader__links-link, .subheader__links-link a {
  text-decoration: none;
}

.subheader__links-link:empty:not([type="button"]) {
  display: none;
}

.subheader__links-link::selection {
  background-color: rgba(0, 0, 0, 0);
}

.subheader__links-link ::selection {
  background-color: rgba(0, 0, 0, 0);
}

.subheader__links-link:hover, .subheader__links-link:focus {
  background-color: #1a699e;
  outline-width: 0;
  transition: background-color .25s;
}

@media (max-width: 600px) {
  .subheader__links-link:hover, .subheader__links-link:focus {
    background-color: #1e7cb8;
    outline-width: 0;
    transition: background-color .25s;
  }
}

.subheader__links-link:hover {
  background: #1a699e;
}

.subheader__links-link.active {
  background-color: #1a699e;
}

.subheader__links-link.active:after {
  display: none;
}

.subheader__links-link:not(:last-child) {
  margin-right: .625em;
}

.subheader__custom-content-wrapper, .subheader__custom-content--left:not(:empty), .subheader__custom-content--right:not(:empty) {
  flex-grow: 1;
}

.subheader__custom-content--left {
  justify-content: flex-start;
}

.subheader__custom-content--right {
  justify-content: flex-end;
}

@media (max-width: 600px) {
  .subheader--has-links {
    margin: 0;
    padding: 0;
  }

  .subheader--has-links .subheader__content {
    width: 100%;
  }

  .subheader--has-links .subheader__custom-content-wrapper {
    display: none;
  }

  .subheader--has-links .subheader__links {
    width: 100%;
  }

  .subheader--has-links .subheader__links-link {
    cursor: pointer;
    background-color: #1e7cb8;
    border-radius: 3px;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 60px;
    margin: 0;
    padding: 0;
    font-size: .875rem;
    font-weight: bold;
    display: none;
  }

  .subheader--has-links .subheader__links-link, .subheader--has-links .subheader__links-link a {
    text-decoration: none;
  }

  .subheader--has-links .subheader__links-link:empty:not([type="button"]) {
    display: none;
  }

  .subheader--has-links .subheader__links-link::selection {
    background-color: rgba(0, 0, 0, 0);
  }

  .subheader--has-links .subheader__links-link ::selection {
    background-color: rgba(0, 0, 0, 0);
  }

  .subheader--has-links .subheader__links-link:hover, .subheader--has-links .subheader__links-link:focus {
    background-color: #1a699e;
    outline-width: 0;
    transition: background-color .25s;
  }
}

@media (max-width: 600px) and (max-width: 600px) {
  .subheader--has-links .subheader__links-link:hover, .subheader--has-links .subheader__links-link:focus {
    background-color: #1e7cb8;
    outline-width: 0;
    transition: background-color .25s;
  }
}

@media (max-width: 600px) {
  .subheader--has-links .subheader__links-link:not(:first-child) {
    border-top: 1px solid rgba(255, 255, 255, .1);
  }

  .subheader--has-links .subheader__links-link:not(:last-child) {
    border-bottom: 1px solid rgba(0, 0, 0, .1);
  }

  .subheader--has-links .subheader__links-link.active {
    background-color: rgba(0, 0, 0, 0);
    border-top: 0;
    border-bottom: 0;
    order: -1;
    display: flex;
  }

  .subheader--has-links .subheader__links-link.active:after {
    content: "";
    margin-left: .5em;
    font-family: "Font Awesome 6 Pro";
    display: inline-block;
    transform: translateY(-1px);
  }

  .subheader--has-links.show-mobile-menu .subheader__links-link {
    display: flex;
  }

  .subheader--has-links.show-mobile-menu .subheader__links-link:first-child:not(.active) {
    border-top: 1px solid rgba(255, 255, 255, .1);
  }
}
