.ie-breadcrumbs {
  z-index: 100;
  background-color: #1e7cb8;
  width: 100%;
  height: 60px;
  padding: 0 1em;
  font-weight: bold;
  display: flex;
  position: fixed;
  top: 60px;
}

.ie-breadcrumbs__item {
  color: #fff;
  cursor: pointer;
  white-space: nowrap;
  align-items: center;
  padding: 0 2em 0 1em;
  text-decoration: none;
  display: flex;
  overflow: hidden;
}

.ie-breadcrumbs__item:first-child {
  min-width: 10em;
}

.ie-breadcrumbs__item:last-of-type:not(:only-of-type) {
  cursor: default;
}

.ie-breadcrumbs__item:not(:last-of-type):hover .ie-breadcrumbs__item-hover-text, .ie-breadcrumbs__item:only-of-type:hover .ie-breadcrumbs__item-hover-text {
  opacity: .6;
  transform: translateY(-.6em)translateY(-1px);
}

.ie-breadcrumbs__item:not(:last-of-type):hover .ie-breadcrumbs__item-label, .ie-breadcrumbs__item:only-of-type:hover .ie-breadcrumbs__item-label {
  transform: translateY(.4em);
}

.ie-breadcrumbs__item:last-of-type:not(:only-of-type) {
  pointer-events: none;
}

.ie-breadcrumbs__item:not(:only-of-type):after {
  content: "";
  background: linear-gradient(to top right, rgba(0, 0, 0, 0) calc(50% - 1px), rgba(255, 255, 255, .25) 0 50%, rgba(0, 0, 0, 0) 0) top / 100% 50% no-repeat, linear-gradient(to bottom right, rgba(0, 0, 0, 0) calc(50% - 1px), rgba(255, 255, 255, .25) 0 50%, rgba(0, 0, 0, 0) 0) bottom / 100% 50% no-repeat;
  width: 1em;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
}

.ie-breadcrumbs__item-hover-text {
  opacity: 0;
  font-size: .9em;
  font-style: italic;
  transition: opacity .25s, transform .25s;
  position: absolute;
  transform: translateY(0);
}

.ie-breadcrumbs__item-label {
  text-overflow: ellipsis;
  min-width: 7em;
  transition: transform .25s;
  overflow: hidden;
  transform: translateY(0);
}

.ie-breadcrumbs__access-label {
  margin: 0 0 0 .75em;
}
