.a-nav-item {
  color: var(--content-primary-inverse-static);
  height: 100%;
  padding: var(--space-x1) var(--space-x2);
  align-items: center;
  text-decoration: none;
  display: flex;
}

.a-nav-item .a-icon--size-standalone {
  font-size: 20px;
}

.a-nav-item.a-nav-item--disabled:hover {
  cursor: not-allowed;
  opacity: .5;
}

.a-nav-item:focus {
  outline-color: var(--color-brand-400);
  outline-offset: calc(-1 * var(--space-x0-half));
  outline-style: solid;
  outline-width: var(--space-x0-half);
}

.a-nav-item--has-states {
  cursor: pointer;
}

.a-nav-item--has-states.a-nav-item--active, .a-nav-item--has-states.a-nav-item--active:hover, .a-nav-item--has-states:hover {
  background: var(--color-brand-700);
}

.a-nav-item--has-states:active {
  background: var(--color-brand-800);
}
