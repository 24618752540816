.reports-selector__outer-wrapper {
  flex: 1;
  padding: 40px 56px;
}

.reports-selector__wrapper {
  flex-direction: column;
  gap: 24px;
  width: 100%;
  max-width: 560px;
}

.reports-selector__list-item {
  justify-content: space-between;
  display: flex;
}

.reports-selector__list-item-button-wrapper {
  flex-direction: row;
  flex-shrink: 0;
  align-items: center;
  display: flex;
}

.reports-selector__no-options-found {
  flex-direction: column;
  align-items: center;
  gap: 16px;
  padding: 16px 8px;
  display: flex;
}
