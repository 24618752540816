:export {
  titleBarHeight: 80px;
  mobileToolbarHeight: 53px;
}

.practice-view {
  height: calc(100vh - 60px);
  margin-top: -60px;
}

.practice-view--without-global-nav {
  height: calc(100dvh - 24px);
}

.practice-view__main {
  margin: 0 auto;
  padding: 0 16px;
}

.practice-view__stage-component-scroll-handler {
  z-index: 1;
  height: 100%;
  padding: 16px;
  top: 0;
  left: 0;
}

.practice-view__stage-component-wrapper {
  max-width: 784px;
}

.practice-view__questions-wrapper {
  min-width: 360px;
  max-width: 360px;
}

.practice-view__questions-list {
  width: 360px;
}

.practice-view__question-area {
  opacity: 1;
  padding-top: 8px;
  transition: opacity .25s;
}

@media (max-width: 780px) {
  .practice-view__main {
    padding: 0;
    position: relative;
  }

  .practice-view__question-area {
    padding: 0;
  }

  .practice-view__mobile-redesign {
    padding: 4px;
  }

  .question-wrapper {
    border: none;
    border-radius: 0;
  }

  .practice-view__stage-component-scroll-handler {
    padding: 8px;
  }

  .practice-view__questions-wrapper {
    z-index: 1;
    position: absolute;
    top: 0;
    bottom: 0;
  }

  .practice-view__question-area--dim {
    opacity: .5;
  }

  .practice-view__questions-list {
    max-width: calc(100vw - 32px);
    transition: left .25s;
    left: -100%;
  }

  .practice-view__questions-list--in-view {
    left: 16px;
  }
}
