.reset-password-wrapper {
  justify-content: center;
  font-size: .875rem;
  display: flex;
  position: relative;
}

.reset-password-wrapper h1 {
  font-size: 1.125rem;
}

.reset-password-wrapper h2 {
  margin: 0;
  font-size: 1.125rem;
}

.reset-password-wrapper h3 {
  margin: 0;
  font-size: .9375rem;
}

.reset-password-wrapper h4 {
  margin: 0;
  font-size: .875rem;
}

.reset-password-content {
  color: #374151;
  flex-flow: column;
  flex: auto;
  width: 100%;
  max-width: 1088px;
  padding: 1em;
  display: flex;
  position: relative;
}

.reset-password-meta {
  flex-flow: column;
  align-items: center;
  display: flex;
}

.reset-password-meta-title {
  text-align: center;
  justify-content: center;
  display: flex;
}

.reset-password-meta-description {
  text-align: center;
  justify-content: center;
  font-size: 1rem;
  display: flex;
}
