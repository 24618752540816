.sg-checkbox-toggle {
  cursor: pointer;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  flex-basis: 100%;
  justify-content: space-between;
  align-items: center;
  font-weight: normal;
  display: flex;
}

.sg-checkbox-toggle input {
  opacity: 0;
  pointer-events: none;
  width: 0;
  height: 0;
  position: absolute;
  overflow: hidden;
}

.sg-checkbox-toggle span, .sg-checkbox-toggle div {
  pointer-events: none;
}

.sg-checkbox-toggle--disabled {
  cursor: not-allowed;
  opacity: .5;
}

.sg-checkbox-toggle__toggle-wrapper {
  align-items: center;
  display: flex;
}

.sg-checkbox-toggle__toggle {
  -webkit-user-select: none;
  user-select: none;
  border-radius: 2em;
  width: 2.5em;
  height: 1.25em;
  display: inline-block;
  position: relative;
}

.sg-checkbox-toggle__toggle:before, .sg-checkbox-toggle__toggle:after {
  content: "";
  display: block;
  position: absolute;
}

.sg-checkbox-toggle__toggle:before {
  will-change: background-color;
  background-color: #d1d5db;
  border-radius: 1em;
  width: 2.5em;
  height: 1.25em;
  transition: background-color .2s cubic-bezier(.24, 0, .5, 1);
  left: 0;
}

.sg-checkbox-toggle__toggle:after {
  content: "";
  will-change: transform, border-color;
  background: #fff;
  border: 2px solid #d1d5db;
  border-radius: 60px;
  width: 1.25em;
  height: 1.25em;
  transition: all .35s cubic-bezier(.5, 1, .5, 1);
  transform: translateX(0);
}

input:checked + .sg-checkbox-toggle__toggle:before {
  background-color: #059669;
  transition: background-color .2s cubic-bezier(0, 0, 0, .1);
}

input:checked + .sg-checkbox-toggle__toggle:after {
  border-color: #059669;
  transform: translateX(1.25em);
}

input:not(:disabled):focus + .sg-checkbox-toggle__toggle {
  outline-offset: 1px;
  outline: 4px auto #d1d5db;
  transition: outline, outline-offset;
}
