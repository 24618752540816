.add-questions-to-templates__content {
  color: #fff;
  text-align: center;
  background-color: #6b7280;
  width: 100%;
  padding: .8em;
  font-size: 1em;
}

.add-questions-to-templates__selection-details {
  padding: 1.5em 1em;
  font-weight: bold;
}

.add-questions-to-templates__selection-details .add-questions-to-templates__selection-details--max-limit-warning {
  margin: .5em 1.5em 0;
  font-style: italic;
  font-weight: normal;
  display: block;
}

.add-questions-to-templates__add-button {
  cursor: pointer;
  text-align: center;
  background-color: #059669;
  border-radius: 2px;
  width: 100%;
  padding: .8em;
  font-weight: bold;
}

.add-questions-to-templates__select-button--disabled, .add-questions-to-templates__add-button--disabled {
  opacity: .5;
}

.add-questions-to-templates__select-button--disabled:hover, .add-questions-to-templates__add-button--disabled:hover {
  cursor: default;
}

.add-questions-to-templates__content-selectors {
  justify-content: space-between;
  height: 1em;
  display: flex;
}

.add-questions-to-templates__select-button {
  cursor: pointer;
  z-index: 99;
  width: 25%;
  font-size: .8em;
  text-decoration: underline;
}
