.software-considerations .a-card {
  max-width: 1088px;
  margin: 0 auto 2em;
}

.software-considerations img {
  width: 56px;
  min-width: 56px;
  height: 56px;
  min-height: 56px;
}

@media (max-width: 780px) {
  .software-considerations img {
    display: none;
  }
}

.software-considerations-section__content {
  margin-left: 2em;
}

.software-considerations-section__content :last-child {
  margin-bottom: 0;
}

@media (max-width: 780px) {
  .software-considerations-section__content {
    margin-left: 0;
  }
}
