.questions-list-paginator {
  background-color: #f3f4f6;
  border-top: 1px solid rgba(0, 0, 0, .1);
  justify-content: space-between;
  align-items: center;
  height: 44px;
  padding: 0 1em;
  font-weight: bold;
  display: flex;
}

.questions-list-paginator__page-indicator {
  margin-bottom: .5em;
  font-weight: bold;
}

.questions-list-paginator__form-sizer {
  height: 0;
  transition: height .25s;
  overflow: hidden;
}

.questions-list-paginator__form-sizer[style*="height"] {
  border-bottom: 1px solid rgba(0, 0, 0, .1);
}

.questions-list-paginator__settings-form {
  background-color: #f9fafb;
  padding: 1em 1em 2em;
}

.questions-list-paginator__input-wrapper {
  margin-bottom: .5em;
  display: block;
}
