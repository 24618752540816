.user-avatar__container {
  color: #d7ecf9;
  opacity: .75;
  text-transform: uppercase;
  background: #1e7cb8;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  padding: .25em;
  font-size: 10px;
  font-weight: bold;
  display: flex;
}

.user-avatar__container--gray {
  background: #9ca3af;
}
