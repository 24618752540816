.queue-date-filters {
  flex-direction: column;
  display: flex;
}

.queue-date-filters > * {
  margin-right: 0;
}

.queue-date-filters .date-range__item {
  width: calc(50% - 5px);
  max-width: calc(50% - 5px);
  margin: 0;
}
