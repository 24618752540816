.m-input-stepper {
  grid-template-rows: 1fr auto;
  grid-template-columns: 1fr 2fr 1fr;
  align-items: center;
  gap: 8px;
  max-width: 168px;
  display: grid;
}

.m-input-stepper input::-webkit-outer-spin-button {
  -webkit-appearance: none;
}

.m-input-stepper input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

.m-input-stepper input[type="number"] {
  -moz-appearance: textfield;
}

.m-input-stepper__input {
  text-align: center;
}

.m-input-stepper__text {
  text-align: center;
  grid-area: 2 / 1 / auto / -1;
  width: 100%;
  line-height: 150%;
}
