.passage-supplement {
  background-color: #fff;
  border: 1px solid #d1d5db;
  border-radius: 3px;
  margin: 1em 0;
}

.passage-supplement__toggle {
  color: #4b5563;
  cursor: pointer;
  background-color: #f3f4f6;
  border: 0 solid #d1d5db;
  align-items: center;
  width: 100%;
  padding: .75em 1em;
  font-size: 14px;
  font-weight: bolder;
  display: flex;
}

.passage-supplement__toggle:hover {
  color: #374151;
  background-color: #e5e7eb;
  transition: background-color .25s, color .25s;
}

.passage-supplement__toggle--expanded {
  border-bottom-width: 1px;
}

.passage-supplement__icon {
  margin-right: .5em;
  display: inline-block;
}

.passage-supplement__passage {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

@media screen and (max-width: 380px) {
  .passage-supplement__passage {
    margin-left: 0 !important;
  }
}

li.passage-supplement-line {
  align-items: flex-start;
  padding: .5em;
  line-height: 1.5;
  display: flex;
  margin: 0 !important;
}

li.passage-supplement-line:nth-child(2n) {
  background-color: #f3f4f6;
}

.passage-supplement-line__number {
  margin-top: 1px;
  margin-right: 8px;
}
