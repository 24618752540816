.attempts-bar {
  border-radius: var(--space-x0-quarter);
  height: var(--space-x1);
  align-items: stretch;
  gap: var(--space-x0-quarter);
  background-color: rgba(0, 0, 0, 0);
  display: flex;
  overflow: hidden;
}

.attempts-bar__correct {
  background-color: var(--background-positive);
}

.attempts-bar__incorrect {
  background-color: var(--background-negative);
}

.attempts-bar__unattempted {
  background-color: var(--background-bold-pressed);
}

.answer-breakdown {
  gap: var(--space-x0-half);
  flex-direction: column;
  display: flex;
}

.answer-breakdown__attempt-chips-container {
  justify-content: center;
  gap: var(--space-x0-half);
  display: flex;
}

.answer-breakdown__attempt-chip {
  border-radius: var(--space-x0-half);
  height: 24px;
  padding: var(--space-x0-half) var(--space-x1);
  justify-content: center;
  align-items: center;
  gap: var(--space-x1);
  width: 100%;
  margin-bottom: 0;
  margin-right: 0;
  display: flex;
}

.answer-breakdown__attempt-chip-correct {
  background-color: var(--background-positive-subtle);
  color: var(--content-positive);
}

.answer-breakdown__attempt-chip-correct span {
  color: var(--content-positive);
}

.answer-breakdown__attempt-chip-incorrect {
  background-color: var(--background-negative-subtle);
  color: var(--content-negative);
}

.answer-breakdown__attempt-chip-incorrect span {
  color: var(--content-negative);
}

.answer-breakdown__attempt-chip-unattempted {
  background-color: var(--background-subtle);
  color: var(--content-secondary);
}

.answer-breakdown__attempt-chip-unattempted span {
  color: var(--content-secondary);
}

.answer-breakdown__attempt-chip-total-answered {
  background-color: var(--background-info-subtle);
  color: var(--content-info);
}

.answer-breakdown__attempt-chip-total-answered span {
  color: var(--content-info);
}

.simple-answer-breakdown {
  align-items: center;
  gap: var(--space-x1);
  display: flex;
}

.simple-answer-breakdown__correct, .simple-answer-breakdown__correct span {
  color: var(--content-positive);
}

.simple-answer-breakdown__incorrect, .simple-answer-breakdown__incorrect span {
  color: var(--content-negative);
}

.simple-answer-breakdown__unattempted span {
  color: var(--content-secondary);
}
