.questions-list-top-widget__bar-wrapper {
  background-color: #f3f4f6;
  border-bottom: 1px solid rgba(0, 0, 0, .1);
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 44px;
  font-weight: bold;
  display: flex;
}

button.questions-list-top-widget__bar-wrapper {
  cursor: pointer;
}

button.questions-list-top-widget__bar-wrapper .questions-list-top-widget__text-wrapper, button.questions-list-top-widget__bar-wrapper .questions-list-top-widget__text-wrapper:hover {
  color: #50a9e2;
}

.questions-list-top-widget__text-wrapper {
  height: 100%;
  margin-right: 10px;
  padding: 0 1em;
  font-weight: bold;
}

button.questions-list-top-widget__text-wrapper, button.questions-list-top-widget__text-wrapper:hover {
  color: #50a9e2;
  cursor: pointer;
}

.questions-list-top-widget__icon-wrapper {
  height: 100%;
  padding: 0 1em;
  display: inline-block;
}

button.questions-list-top-widget__icon-wrapper {
  cursor: pointer;
}

.questions-list-top-widget__icon {
  font-size: 20px;
}

.questions-list-top-widget__icon-notifier {
  background-color: #e36e63;
  border: 1px solid #f3f4f6;
  border-radius: 50%;
  width: 10px;
  height: 10px;
  position: absolute;
  top: 10px;
  right: 10px;
}

.questions-list-top-widget__drawer-wrapper {
  width: 100%;
  height: 0;
  transition: height .2s;
  position: absolute;
  top: 100%;
  overflow: hidden;
}

.questions-list-top-widget__drawer-wrapper--open {
  border-bottom: 1px solid rgba(0, 0, 0, .1);
}

.questions-list-top-widget__drawer-sizer {
  background-color: #f9fafb;
  padding: 1em;
}
