.questions-list-header {
  align-items: center;
  display: flex;
}

.questions-list-header__heading {
  flex-grow: 1;
}

.questions-list-header__popover {
  max-width: 300px;
}

.questions-list-header__sort-item, .questions-list-header__filter-item {
  text-align: left;
  align-items: center;
  width: 100%;
  display: flex;
}

.questions-list-header__sort-label, .questions-list-header__filter-label {
  text-transform: capitalize;
  flex-grow: 1;
}

.temp-disabled-helper__children--disabled {
  opacity: .5;
  pointer-events: none;
}

.temp-disabled-helper__tooltip {
  width: 175px;
}
