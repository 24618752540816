.questions-list-paginator-v2 {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.questions-list-paginator-v2__label {
  opacity: .65;
}

.questions-list-paginator-v2__loading-indicator {
  opacity: .65;
  margin: 0 auto;
  position: absolute;
  bottom: calc(100% + 16px);
  left: 0;
  right: 0;
}
