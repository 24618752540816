.current-alignment {
  flex-direction: column;
  align-items: flex-start;
  margin: 10px 0 0;
  display: flex;
}

.current-alignment__content {
  background-color: #f3f4f6;
  border-radius: .25em;
  margin-top: 5px;
  padding: .25em .75em calc(.25em + 1px);
}

.question-set-alignment-modal {
  overflow: hidden;
}

.question-set-alignment-modal .simple-modal__body {
  padding: 0;
}

.question-set-alignment-modal .simple-modal__body .sg-tabs {
  border-bottom: 1px solid #e5e7eb;
}

.question-set-alignment-modal .simple-modal__body .sg-tab {
  border-bottom-width: 0;
}

.question-set-alignment-modal__actions-wrapper {
  justify-content: flex-end;
  padding: 10px;
  display: flex;
}

.guide-level-alignment-list {
  max-height: 50vh;
  margin: 0;
  padding: 0;
  list-style-type: none;
  overflow: auto;
}

.guide-level-alignment-list__item {
  align-items: center;
  padding: 20px 10px;
  display: flex;
}

.guide-level-alignment-list__item:nth-child(2n) {
  background-color: #fafbfc;
}

.guide-level-alignment-list__item--1-level {
  padding-left: 20px;
}

.guide-level-alignment-list__item--2-level {
  padding-left: 40px;
}

.guide-level-alignment-list__item--3-level {
  padding-left: 60px;
}

.guide-level-alignment-list__item--exam-section {
  padding-left: 20px;
}

.guide-level-alignment-list__toggle-expand-level {
  margin-right: 5px;
}

.guide-level-alignment-list__set-alignment-button {
  border: 2px solid #9ca3af;
  border-radius: 50%;
  width: 15px;
  height: 15px;
  margin-right: 10px;
  display: inline-block;
  transform: translateY(1px);
}

.guide-level-alignment-list__set-alignment-button--active {
  background-color: #50a9e2;
  border-color: #50a9e2;
}
